import { Dropdown, StatusLabel } from '@Atoms';
import { formatCurrency, formatPercentage } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { DiscountBillable, Pagination, PaginationTable } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DiscountRequestProps {
	discountRequests?: Pagination<DiscountBillable[]>;
	handleListAction: (action: string, item: DiscountBillable) => void;
	setPagination: (params: PaginationTable) => void;
	setSearch: (searchQuery: string) => void;
}

const DiscountRequestPage: FC<DiscountRequestProps> = (props) => {
	const {
		discountRequests, handleListAction, setPagination, setSearch,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={discountRequests?.data || []}
						pagination
						onChange={(page, size) => setPagination({ page, size })}
						onSearching={setSearch}
						total={discountRequests?.total}
					>
						<TableColumn headerText="ID" render={(item) => item.discountRequestId} />
						<TableColumn headerText={t('name')} render={(item) => item.note} />
						<TableColumn headerText={t('coloborator')} render={(item) => item.seller?.name || ''} />
						<TableColumn headerText={t('amount')} render={(item) => (item.isPercentage ? formatPercentage(item.amount) : formatCurrency(item.amount))} />
						<TableColumn headerText={t('type')} render={(item) => item.type?.name} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerOptions={{ align: 'right' }}
							headerText={t('action')}
							align="right"
							render={(item) => (
								<Dropdown
									disabled={item.status.id !== 1}
									data={[
										t('approve'),
										t('reject'),
									]}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(action) => {
										handleListAction(action, item);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default DiscountRequestPage;
