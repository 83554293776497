import {
	Autocomplete, DefaultButton, Space, TextField,
} from '@Atoms';
import { Close } from '@mui/icons-material';
import {
	Alert,
	Box, DialogActions, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { useDropdown } from '@Providers';
import { OrphanTollReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UpdateStatusProps{
	updateStatus: boolean;
	handleClose: () => void;
	initialValues: OrphanTollReq;
	validate: (values: OrphanTollReq) => FormikErrors<OrphanTollReq>;
	handleSubmit: (values: OrphanTollReq, formikHelpers: FormikHelpers<OrphanTollReq>) => void
}

const UpdateStatusItem: FC<UpdateStatusProps> = (props) => {
	const {
		updateStatus, handleClose, initialValues, handleSubmit, validate,
	} = props;
	const { t } = useTranslation('translations');
	const { tollStatus } = useDropdown();

	if (updateStatus) {
		return (
			<Box>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography>{t('changeStatus')}</Typography>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Stack>
				<Divider />
				<Space height={2} />
				<Alert severity="error" icon={false}>
					<Typography>
						{t('updateStatusModalMessage')}
					</Typography>
					<Space height={1} />
					<Typography>
						{t('updateStatusModalDesctiption')}
					</Typography>
				</Alert>
				<Formik
					initialValues={initialValues}
					validate={validate}
					onSubmit={handleSubmit}
				>
					{({
						values, touched, errors, setFieldValue, handleChange, isSubmitting,
					}) => (
						<Form>
							<Space height={2} />
							<Autocomplete
								options={tollStatus || []}
								renderOption={(AutoCompleteprops, item) => (
									<li {...AutoCompleteprops} key={item.id}>
										{item.name}
									</li>
								)}
								error={touched.status && Boolean(errors.status)}
								helperText={touched.status && String(errors.status || '')}
								defaultValue={values.status}
								getOptionLabel={(item) => item.name || ''}
								isOptionEqualToValue={(option, value) => option === value}
								label={t('status')}
								disabled={isSubmitting}
								onChange={(_, item) => setFieldValue('status', item)}
								placeholder=""
							/>
							<Space height={2} />
							<TextField
								multiline
								minRows={3}
								id="reason"
								error={touched.reason && Boolean(errors.reason)}
								helperText={touched.reason && errors.reason}
								onChange={handleChange}
								disabled={isSubmitting}
								defaultValue={values.reason}
								label={t('motive')}
							/>
							<Space height={2} />
							<DialogActions>
								<DefaultButton title={t('confirm')} type="submit" loading={isSubmitting} />
								<DefaultButton
									color="info"
									title={t('close')}
									onClick={handleClose}
									disabled={isSubmitting}
								/>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Box>
		);
	}
	return <div />;
};

export default UpdateStatusItem;
