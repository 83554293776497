import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import { bannerApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Banner } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListBannersPage from './ListBannersPage';

const ListBanners = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const [bannerToDelete, setBannerToDelete] = useState<number>();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [pagination] = useState({ page: 0, size: 10 });
	const [search] = useState('');
	const [banners, setBanners] = useState([] as Banner[]);

	const changeStatus = useRequest(([param]) => bannerApi.changeBannerStatus(param));

	useEffect(() => {
		setTitle(t('banners'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getBanners = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			setGlobalLoading(true);
			bannerApi.getBanners(listDataControl, abortCtrl.signal)
				.then(({ data }) => setBanners(data.data))
				.catch((err) => {
					addToast({ message: err.response?.data?.message, error: true });
				})
				.finally(() => setGlobalLoading(false));
		};
		getBanners();
		return () => abortCtrl.abort();
	}, []);

	useEffectCustom(() => {
		const changeStatusLocally = () => {
			const { data } = changeStatus;
			if (data) {
				setBanners((prev) => {
					const mPrev = prev;
					const index = prev?.findIndex((el) => el.id === changeStatus.data?.id);
					if (mPrev && index !== undefined) {
						mPrev[index] = data;
					}
					return mPrev;
				});
			}
		};
		changeStatusLocally();
	}, [changeStatus.data]);

	const handleListAction = (action: string, item?: Banner) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.ADD_NEW_BANNER);
			break;
		case t('changeStatus'):
			changeStatus.execute(item?.id);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_BANNER.formatMap({ id: item?.id }));
			break;
		case t('remove'):
			setBannerToDelete(item?.id);
			setShowConfirmModal(true);
			break;
		default:
			break;
		}
	};

	const removeBanner = () => {
		if (bannerToDelete) {
			setGlobalLoading(true);
			bannerApi.deleteBannerByID(bannerToDelete)
				.then(() => {
					setBanners((prev) => {
						const mPrev = prev;
						const index = mPrev?.findIndex((el) => el.id === bannerToDelete);
						mPrev?.splice(index, 1);
						return mPrev;
					});
					setShowConfirmModal(false);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => setGlobalLoading(false));
		}
	};

	return (
		<>
			<Dialog
				open={showConfirmModal}
				handleClose={() => setShowConfirmModal(false)}
				icon="error"
				title={t('deleteMessage')}
				description={t('deleteDescription')}
				primaryButtonText={t('delete')}
				primaryButtonAction={removeBanner}
				primaryButtonColor="error"
				secondaryButtonAction={() => setShowConfirmModal(false)}
				secondaryButtonText={t('cancel')}
			/>
			<ListBannersPage
				handleListAction={handleListAction}
				banners={banners}
			/>
		</>
	);
};

export default ListBanners;
