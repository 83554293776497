import { PaperContainer } from '@Molecules';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TrackVehiclePage = () => {
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12} />
			<Grid item sm={12} md={12}>
				<Grid container spacing={3}>
					<Grid item sm={12} md={12}>
						<PaperContainer>
							<Typography variant="h6" fontWeight="lighter">
								{t('noTrackingInfo')}
							</Typography>
						</PaperContainer>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TrackVehiclePage;
