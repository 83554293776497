import { CarItem, DefaultButton, TableItem } from '@Atoms';
import { DashboardContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { Phone } from '@mui/icons-material';
import { Reservation } from '@Types';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontSizes } from '@Constant';

const RESERVATION_TYPE = 1;

interface DelayedProps {
   data: Reservation[] | undefined;
   onButtonClick?: (action: string, id: number, typeId?: number) => void;
}

const DelayedDeliveries: FC<DelayedProps> = (props) => {
	const { data, onButtonClick } = props;
	const { t } = useTranslation('translations');
	return (
		<DashboardContainer
			title={t('delayedDeliveries')}
			badgeNumber={data?.length}
			hidden
		>
			<DataTable data={data || []} search={false}>
				<TableColumn
					headerText={t('enrollmentModel')}
					render={(item) => (
						<CarItem
							car={item.car}
							sx={{ '&:hover': { cursor: 'pointer' } }}
							onClick={() => onButtonClick?.call(0, t('enrollmentModel'), item.car?.id)}
						/>
					)}
				/>
				<TableColumn
					headerText={t('driver')}
					render={(item) => (
						<TableItem
							title={item.driver?.name}
							subtitle={item.driver?.phone}
							subIcon={<Phone sx={{ fontSize: FontSizes.SM, mr: '3px' }} />}
						/>
					)}
				/>
				<TableColumn headerText={t('estimatDel')} render={(item) => moment(item.endDate).format('DD/MM/YYYY HH:mm')} />
				<TableColumn
					headerText={t('reservation')}
					headerOptions={{ align: 'right' }}
					align="right"
					render={(item) => (
						<DefaultButton
							color={item.reservationType?.id === RESERVATION_TYPE ? 'primary' : 'info'}
							title={`${item.reservationType?.id === RESERVATION_TYPE ? t('reservation') : t('wayBill')} #${item.reservationId}`}
							onClick={() => {
								onButtonClick?.call(0, t('reservation'), item.reservationId, item.reservationType.id);
							}}
						/>
					)}
				/>
			</DataTable>
		</DashboardContainer>
	);
};

export default DelayedDeliveries;
