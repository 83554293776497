import {
	Box, Container, Grid, Link, Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { logo } from '@Images';
import {
	Image, Space, TextField, DefaultButton,
} from '@Atoms';
import { useTranslation } from 'react-i18next';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { LoginReq } from '@Types';

interface LoginPageProps{
	initialValues: LoginReq;
	handleSubmit: (props: LoginReq, helpers: FormikHelpers<LoginReq>) => void;
	validate: (values: LoginReq) => FormikErrors<LoginReq>;
	forgotPassword?: () => void
}

const LoginPage: FC<LoginPageProps> = (props) => {
	const {
		initialValues, handleSubmit, validate, forgotPassword,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Container>
			<Container maxWidth="xs">
				<Space height={5} />

				<Image src={logo} height="100%" width="100%" alt="AJ Rent Logo" />

				<Space height={3} />
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validate={validate}
				>
					{({
						values, handleChange, errors, isSubmitting, touched,
					}) => (
						<Form>
							<TextField
								label={t('email')}
								value={values.email}
								name="email"
								error={touched.email && Boolean(errors.email)}
								helperText={touched.email && errors.email}
								onChange={handleChange}
								disabled={isSubmitting}
							/>

							<Space height={2} />

							<TextField
								label={t('password')}
								type="password"
								value={values.password}
								error={touched.password && Boolean(errors.password)}
								helperText={touched.password && errors.password}
								name="password"
								onChange={handleChange}
								disabled={isSubmitting}
							/>

							<Space height={2} />

							<DefaultButton
								loading={isSubmitting}
								fullWidth
								title={t('authenticate')}
								type="submit"
							/>
						</Form>
					)}
				</Formik>
				<Space height={1} />
				<Grid container sx={{ textAlign: 'center' }}>
					<Grid item xs>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link component="button" onClick={() => forgotPassword?.call(0)} variant="caption">
							{t('forgotPassword')}
						</Link>
					</Grid>
				</Grid>
				<Space height={2} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						{`${process.env.REACT_APP_NAME} - ${t('copyright')} © ${new Date().getFullYear()}`}
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default LoginPage;
