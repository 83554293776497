import { FontSizes } from '@Constant';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
	Box, Stack, Typography,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';

interface TableItemProps {
   title: string;
   subtitle?: string;
   onClick?: () => void;
   icon?: boolean;
   customIcon?: ReactNode;
   subIcon?: ReactNode;
   textBeforeTitle?: string;
   color?: string
   link?: boolean;
}

const TableItem: FC<TableItemProps> = (props) => {
	const {
		title, subtitle, onClick, icon, customIcon, subIcon, textBeforeTitle, color, link,
	} = props;
	return (
		<>
			<Stack direction="row" onClick={onClick}>
				{customIcon && (
					<>
						{textBeforeTitle}
						{' '}
						{customIcon}
						{' '}
					</>
				)}
				{icon && (
					<ArrowUpward sx={{
						fontSize: '13px',
					}}
					/>
				)}
				<Typography
					sx={{
						fontSize: FontSizes.MD,
						display: '-webkit-box',
						overflow: 'hidden',
						WebkitBoxOrient: 'vertical',
						maxLines: 2,
						wordBreak: 'break-word',
						textOverflow: 'ellipsis',
						WebkitLineClamp: 2,
						maxWidth: '300px',
						mr: 1,
						'&:hover': {
							cursor: link ? 'pointer' : 'text',
						},

					}}
					color={color}
				>
					{title}
				</Typography>
			</Stack>
			<Box sx={{
				fontSize: '11px',
				marginLeft: '1px',
				wordBreak: 'break-word',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				display: '-webkit-box',
				WebkitLineClamp: 1,
				WebkitBoxOrient: 'vertical',
			}}
			>
				{icon && <ArrowDownward sx={{ fontSize: '11px', mr: 1 }} />}
				{subIcon}
				{subtitle}
			</Box>
		</>
	);
};

export default TableItem;
