/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	JSXElementConstructor, useLayoutEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useDropdown } from '@Providers';
import { useTheme } from '@mui/material';
import { splashLoading } from '@Lottie';
// import { delay } from '@General';

export const LoadingMessage: React.FC = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: splashLoading,
		innerWidth: 200,
		innerHeight: 400,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<div style={{
			backgroundColor: theme.palette.background.paper,
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		}}
		>
			<Lottie
				options={defaultOptions}
				style={{ height: 200, width: 400, padding: 10 }}
			/>
			<p>{t('splash')}</p>
		</div>
	);
};

const withSplashScreen = (WrappedComponent: JSXElementConstructor<any>) => (props: any) => {
	const [loading, setLoading] = useState<boolean>(true);
	const { getDropdowns } = useDropdown();

	useLayoutEffect(() => {
		const getAllDropdowns = async () => {
			try {
				// await delay(500);
				await getDropdowns();
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		getAllDropdowns();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{loading && <LoadingMessage />}
			{!loading && <WrappedComponent {...props} />}
		</div>
	);
};

export default withSplashScreen;
