/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalizationProvider, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { FC } from 'react';
import { useLanguage } from '@Providers';
import { useTranslation } from 'react-i18next';
import TextField from '../input/TextField';

interface DatePickerProps {
	label?: string;
	inputFormat?: string;
	value?: any;
	disabled?: boolean;
	onChange?: (date: any) => void;
	error?: boolean;
	helperText?: string;
}

const DatePicker: FC<DatePickerProps> = (props) => {
	const {
		label, inputFormat = 'HH:mm', value, disabled, onChange, error, helperText,
	} = props;
	const { localeFns } = useLanguage();
	const { t } = useTranslation('translations');
	return (
		<LocalizationProvider locale={localeFns} dateAdapter={AdapterDateFns}>
			<MobileTimePicker
				label={label}
				inputFormat={inputFormat}
				value={value}
				disableCloseOnSelect={false}
				disabled={disabled}
				cancelText={t('cancel')}
				onChange={(date) => onChange?.call(0, date)}
				renderInput={(params) => (
					<TextField
						disabled={disabled}
						error={error}
						helperText={helperText}
						size="small"
						name="date"
						fullWidth
						{...params}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default DatePicker;
