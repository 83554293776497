import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { brandApi } from '@Requests';
import { AppRoutes } from '@Routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateBrandPage from './CreateBrandPage';

const CreateBrand = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [brand, setBrand] = useState<string>('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTitle(`${id ? t('edit') : t('new')} ${t('brand')}`);
	}, [setTitle, id, t]);

	useEffectCustom(() => {
		const getBrand = async () => {
			brandApi
				.getBrandByID(id ?? '')
				.then((res) => setBrand(res.data.name))
				.catch(({ response }) => {
					addToast({ message: response.data.message, error: true });
				});
		};
		if (id) {
			getBrand();
		}
	}, []);

	const handleClick = () => {
		if (brand === '') {
			return setError(t('emptyField'));
		}
		setLoading(true);
		if (id) {
			brandApi.updateBrand(id ?? '', brand)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_BRANDS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: false,
				}))
				.finally(() => setLoading(false));
		} else {
			brandApi.saveBrand(brand)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_BRANDS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: false,
				}))
				.finally(() => setLoading(false));
		}
		return null;
	};
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setBrand(event.target.value);
		setError('');
	};

	return (
		<CreateBrandPage
			error={error}
			brand={brand}
			loading={loading}
			handleClick={handleClick}
			handleChange={handleChange}
		/>
	);
};

export default CreateBrand;
