import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Autocomplete, DefaultButton, DropdownItem, Space, TextField,
} from '@Atoms';
import { Divider, Grid } from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { QuestionReq } from '@Types';
import { PaperContainer } from '@Molecules';

interface NewQuestionProps {
   edit?: boolean;
   initialValues: QuestionReq;
   handleSubmit: (values: QuestionReq, formikHelpers: FormikHelpers<QuestionReq>) => void;
   validate: (values: QuestionReq) => FormikErrors<QuestionReq>
}

const NewQuestionPage:FC<NewQuestionProps> = (props) => {
	const {
		edit, initialValues, handleSubmit, validate,
	} = props;
	const { t } = useTranslation('translations');

	const language = [
		{
			id: 2,
			isoCode: '620',
			name: 'Portuguese',
			phoneCode: '+351',
			shortName: 'pt',
			language: 'pt',
		},
		{
			id: 1, isoCode: '620', name: 'English', phoneCode: '44', shortName: 'gb', language: 'en',
		},
	];

	return (
		<Grid container spacing={3}>
			<Grid item md={12} sm={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						enableReinitialize
						onSubmit={handleSubmit}
						validate={validate}
					>
						{({
							touched, values, errors, handleChange, setFieldValue, isSubmitting,
						}) => (
							<Form>

								<TextField
									title={t('question')}
									label={t('question')}
									disabled={isSubmitting}
									type="text"
									id="question"
									error={touched.question && Boolean(errors.question)}
									helperText={touched.question && errors.question}
									value={values.question}
									onChange={handleChange}
								/>
								<Space height={2} />
								<TextField
									title={t('answer')}
									label={t('answer')}
									disabled={isSubmitting}
									type="text"
									id="answer"
									error={touched.answer && Boolean(errors.answer)}
									helperText={touched.answer && errors.answer}
									value={values.answer}
									onChange={handleChange}
								/>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											options={language}
											label={t('language')}
											disabled={isSubmitting}
											defaultValue={language.find((el) => el.id === values.languageId)}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											placeholder={t('language')}
											error={Boolean(errors.languageId)}
											helperText={errors.languageId}
											renderOption={(inputprops, item) => (
												<li {...inputprops}>
													<DropdownItem country={item} />
												</li>
											)}
											getOptionLabel={(item) => item.name}
											onChange={(_, item) => setFieldValue('languageId', item?.id)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<DefaultButton
									loading={isSubmitting}
									title={edit ? t('update') : t('create')}
									type="submit"
								/>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default NewQuestionPage;
