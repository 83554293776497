import { DefaultButton, Space } from '@Atoms';
import { formatCurrency } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Box, Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Financial } from '@Types';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PendingFinancialProps{
	values?: Financial[];
	handleListAction: (action: string, id: number) => void
}
const PendingFinancialsPage: FC<PendingFinancialProps> = (props) => {
	const { values, handleListAction } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12} />
			<Grid item xs={12} md={12}>
				<PaperContainer>
					{values?.map((item, index) => (
						<div key={`financial-${index.toString()}`}>
							<Box sx={{ background: theme.palette.divider, p: 1 }}>{`${item.customer?.customerName} - ${t('total')}: ${putDecimalPoints(item.total)} €`}</Box>
							<Space height={3} />
							<DataTable data={item.transactions || []} search={false}>
								<TableColumn headerText="ID" render={(transactionItem) => transactionItem.id} />
								<TableColumn headerText={t('date')} render={(transactionItem) => moment(transactionItem.dueDate).format('MM/DD/YYYY')} />
								<TableColumn headerText={t('dueDate')} render={(transactionItem) => moment(transactionItem.dueDate).format('MM/DD/YYYY')} />
								<TableColumn headerText={t('value')} render={(transactionItem) => `${formatCurrency(transactionItem.value)} €`} />
								<TableColumn headerText={t('formOfPayment')} render={(transactionItem) => transactionItem.paymentMethod} />
								<TableColumn
									headerText={t('reservation')}
									render={(transactionItem) => (
										<DefaultButton
											title={`#${transactionItem.reservationId}`}
											onClick={() => handleListAction(t('reservation'), transactionItem.reservationId)}
										/>
									)}
								/>
							</DataTable>
							<Space height={3} />
						</div>
					))}
				</PaperContainer>
			</Grid>

		</Grid>
	);
};

export default PendingFinancialsPage;
