import { DefaultButton, ListItem, Space } from '@Atoms';
import { FontSizes } from '@Constant';
import { formatDate } from '@Helpers';
import { Box } from '@mui/material';
import { CarRecReserva } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface VehicleReservationItem {
	reservation?: CarRecReserva;
	handleListAction: (action: string, id?: number) => void;
}

const VehicleReservationItem: FC<VehicleReservationItem> = (props) => {
	const { reservation, handleListAction } = props;
	const { t } = useTranslation('translations');
	return (
		<Box>
			<ListItem
				title={t('client')}
				value={reservation?.clientUser || ''}
				direction="row"
				margin="0px"
				fontSize={`${FontSizes.SM}px`}
			/>
			<Space height={1} />
			<ListItem
				title={t('withdrawal')}
				value={formatDate(reservation?.startDate) || ''}
				direction="row"
				margin="0px"
				fontSize={`${FontSizes.SM}px`}
			/>
			<Space height={1} />
			<ListItem
				title={t('local')}
				value={reservation?.office || ''}
				direction="row"
				margin="0px"
				fontSize={`${FontSizes.SM}px`}
			/>
			<Space height={1} />
			<DefaultButton title={t('details')} onClick={() => handleListAction(t('details'), reservation?.id)} />
		</Box>
	);
};

export default VehicleReservationItem;
