/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useFeedback, useTitleBar } from '@Providers';
import { useNavigate, useParams } from 'react-router-dom';
import {
	State, Country, City, UserReq, UserPendingFinancials, UserBalence,
} from '@Types';
import { FormikHelpers } from 'formik';
import { placesApi, userApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { getMinBirthDate, validateNif } from '@Helpers';
import { useEffectCustom } from '@Hooks';
// import { validateEmail } from '@Validation';
import { DROPDOWN_ACTION } from '@Constant';
import { phoneString, postCodeString, taxIdString } from '@Masks';
import CreateUserPage, { SetFieldValue } from './CreateUserPage';
import EditUserButtons from './EditUserButtons';
import UnbilledTransactions from './UnbilledTransactions';

const NIF_LENGTH = 10;

const CreateUser = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { id } = useParams();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();
	const { user, setCurrentUser } = useAuth();

	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
	const [countries, setCountries] = useState([] as Country[]);
	const [states, setStates] = useState([] as State[]);
	const [cities, setCities] = useState([] as City[]);
	const [initialValues, setInitialValues] = useState<UserReq>({
		profileImage: {} as File,
		profileImageUrl: '',
		stateId: {} as State,
		countryId: {} as Country,
		cityId: {} as City,
		nationalityId: {} as Country,
		phoneCountryId: {} as Country,
		name: '',
		email: '',
		phone: '',
		taxId: '',
		neighborhood: '',
		alternateCityName: '',
		address: '',
		birthdate: getMinBirthDate(),
		postalCode: '',
		disableRequireDocs: true,
		documents: [
			{
				countryId: {} as Country,
				value: '',
				expirationDate: null,
				typeId: 1,
				issueDate: null,
			},
			{
				countryId: {} as Country,
				value: '',
				expirationDate: null,
				typeId: 2,
				issueDate: null,
			},
			{
				countryId: {} as Country,
				value: '',
				expirationDate: null,
				typeId: 3,
				issueDate: null,
			},
		],
	});
	const [pendingFinancials, setPendingFinancials] = useState<UserPendingFinancials[]>([]);
	const [userBalance, setUserBalance] = useState<UserBalence>({ balance: 0 });
	const [isEdit, setEdit] = useState(false);

	const getStates = async (countryId: number) => {
		const mStates = await placesApi.getStates(countryId);
		return mStates;
	};

	const getCities = async (stateId: number) => {
		const mCities = await placesApi.getCities(stateId);
		return mCities;
	};

	const getDropdowns = async () => {
		setEdit(true);
		setTitle(`${t('new')} ${t('user')}`);
		setPendingFinancials([] as UserPendingFinancials[]);
		setInitialValues({
			profileImage: {} as File,
			profileImageUrl: '',
			stateId: {} as State,
			countryId: {} as Country,
			cityId: {} as City,
			nationalityId: {} as Country,
			phoneCountryId: {} as Country,
			name: '',
			email: '',
			phone: '',
			taxId: '',
			neighborhood: '',
			alternateCityName: '',
			address: '',
			birthdate: getMinBirthDate(),
			postalCode: '',
			disableRequireDocs: true,
			documents: [
				{
					countryId: {} as Country,
					value: '',
					expirationDate: null,
					typeId: 1,
					issueDate: null,
				},
				{
					countryId: {} as Country,
					value: '',
					expirationDate: null,
					typeId: 2,
					issueDate: null,
				},
				{
					countryId: {} as Country,
					value: '',
					expirationDate: null,
					typeId: 3,
					issueDate: null,
				},
			],
		});
		const mCountries = await placesApi.getCountries();
		const mStates = await getStates(mCountries.data.data[0].id);
		const mCities = await getCities(mStates.data[0].id);

		setCountries(mCountries.data.data);
		setStates(mStates.data);
		setCities(mCities.data);
	};

	const getUser = () => {
		setGlobalLoading(true);
		userApi.getUserById(id ?? '')
			.then(async ({ data }) => {
				// Get countries , states, cities, user pending bills and Previous balance

				const mCountries = await placesApi.getCountries();
				const mStates = await getStates(data.country?.id);
				const mCities = await getCities(data.state?.id);
				const MpendingFinancials = await userApi.getUserPendingFinancials(id ?? '');
				const mUserBalance = await userApi.getUserPreviousBalence(id ?? '');
				setUserBalance(mUserBalance.data);
				if (MpendingFinancials) {
					setPendingFinancials(MpendingFinancials.data);
				}
				setCountries(mCountries.data?.data);
				setStates(mStates.data);
				setCities(mCities.data);
				const { documents } = initialValues;
				data.documents?.forEach((element) => {
					if (element.typeId === 1) {
						documents[0] = {
							value: element.value,
							typeId: 1,
							countryId: element?.country,
							expirationDate: new Date(element.expirationDate),
							issueDate: new Date(element.issueDate),
						};
					}
					if (element.typeId === 2) {
						documents[1] = {
							value: element.value,
							typeId: 2,
							countryId: element?.country,
							expirationDate: new Date(element.expirationDate),
							issueDate: new Date(element.issueDate),
						};
					}
					if (element.typeId === 3) {
						documents[2] = {
							countryId: element?.country,
							expirationDate: new Date(element.expirationDate),
							issueDate: new Date(element.issueDate),
							value: element.value,
							typeId: 3,
						};
					}
				});
				setInitialValues({
					...initialValues,
					profileImage: {} as File,
					profileImageUrl: data.profileImage,
					name: data.name,
					nationalityId: data.nationality,
					birthdate: new Date(data.birthdate),
					phoneCountryId: data.phoneCountry,
					phone: phoneString(data.phone),
					email: data.email,
					countryId: data.country,
					stateId: data.state,
					cityId: data.city,
					alternateCityName: data.alternateCityName,
					address: data.address,
					neighborhood: data.neighborhood,
					postalCode: postCodeString(data.postalCode),
					taxId: taxIdString(data.taxId),
					disableRequireDocs: data.disableRequireDocs,
					documents,
				});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	useEffectCustom(() => {
		if (id) {
			setTitle(`${t('user')} ${t('detail')}`);
			getUser();
		} else {
			getDropdowns();
		}
	}, [t, setTitle, id]);

	const checkNif = async (
		value: string,
		setFieldValue: SetFieldValue,
	) => {
		if (value.length === NIF_LENGTH) {
			if (parseInt(value.charAt(0), 10) > 4) {
				addDialog({ title: t('invalidNif'), message: t('userNifError'), error: true });
				setFieldValue('taxId', '');
				return null;
			}
			const isCorrect = validateNif(value);
			if (!isCorrect) {
				setFieldValue('taxId', '');
				addDialog({ title: t('invalidNif'), message: t('invalidNifMessage'), error: true });
			}
			const matchedUser = await userApi.getUserByFiscalNumber(value);
			if (matchedUser.data) {
				/** CHECK IF THE TAXID belong to the same user */
				if (id && matchedUser.data.id.toString() === id) {
					return null;
				}
				setFieldValue('taxId', '');
				addDialog({ title: t('invalidNif'), message: t('duplicateMessage').format(matchedUser.data.name), error: true });
				return null;
			}
		}
		return null;
	};

	const validateDocument = (
		value: string,
		typeId: number,
		countryId: number,
		setFieldValue: SetFieldValue,
		field: string,
	) => {
		userApi.validateUserDocument(value, typeId, countryId)
			.catch((err) => {
				setFieldValue(field, '');
				addDialog({
					message: err.response.data.message,
					title: err.response.data.title,
					error: true,
				});
			});
	};

	const handleSubmit = (values: UserReq, formikHelpers: FormikHelpers<UserReq>) => {
		if (id) {
			userApi.updateUser(values, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					if (user?.userId.toString() === id) {
						setCurrentUser({
							...res.data,
							counters: user.counters,
							userId: res.data.id,
							authenticationToken: user.authenticationToken,
							refreshToken: user.refreshToken,
						});
						navigate(AppRoutes.LIST_USERS);
					} else {
						navigate(AppRoutes.LIST_USERS);
					}
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => {
					formikHelpers.setSubmitting(false);
				});
		} else {
			userApi.addUser(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_USERS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => {
					formikHelpers.setSubmitting(false);
				});
		}
	};

	const getDropdownById = async (action: string, dropdownId: number) => {
		switch (action) {
		case DROPDOWN_ACTION.COUNTRY:
		{
			const mStates = await getStates(dropdownId);
			setStates(mStates.data);
			break;
		}
		case DROPDOWN_ACTION.STATE:
		{
			const mCities = await getCities(dropdownId);
			setCities(mCities.data);
			break;
		}
		default:
			break;
		}
	};

	const resetPassword = () => {
		setGlobalLoading(true);
		setResetPasswordLoading(true);
		userApi.resetUserPassword(id)
			.then((res) => addToast({
				message: res.message,
				error: false,
			}))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => {
				setGlobalLoading(false);
				setResetPasswordLoading(false);
			});
	};

	const handleButtonClick = (action: string) => {
		switch (action) {
		case t('resetPassword'):
			resetPassword();
			break;
		case t('creditCards'):
			navigate(AppRoutes.ADD_PAYMENT_CARD.formatMap({ clientId: id, typeId: 1 }));
			break;
		case t('crm'):
			navigate(AppRoutes.USER_CRM.formatMap({ userId: id }));
			break;
		default:
			break;
		}
	};

	const handleReservationClick = (reservationId: number) => {
		navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('user')}`);
		setEdit(true);
	};

	return (
		<>
			{id && (
				<EditUserButtons
					handleButtonClick={handleButtonClick}
					loading={resetPasswordLoading}
				/>
			)}
			{pendingFinancials.length > 0 && (
				<UnbilledTransactions
					pendingFinancials={pendingFinancials}
					handleReservationClick={handleReservationClick}
					total={userBalance.balance}
				/>
			)}
			<CreateUserPage
				initialValues={initialValues}
				countries={countries}
				states={states}
				cities={cities}
				handleSubmit={handleSubmit}
				checkNif={checkNif}
				getDropdown={getDropdownById}
				isEdit={isEdit}
				handleEditClick={handleEditClick}
				validateUserDocuments={validateDocument}
			/>
		</>
	);
};

export default CreateUser;
