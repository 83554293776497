import {
	ApiResponse,
	CarFeature,
	FeatureType,
	ListDataControl,
	Pagination,
} from '@Types';
import { CarFeatureReq } from 'data/models/car-feature.model';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getFeatures(params: ListDataControl):
   Promise<ApiResponse<Pagination<CarFeature[]>>> {
	const resp = await reqGet({
		url: Endpoint.FEATURES.format(params.page, params.size, params.search),
	});
	const { data } = resp;
	return data;
}
export async function changeFeatureStatus(featureId: number): Promise<ApiResponse<CarFeature>> {
	const resp = await reqPost({
		url: Endpoint.FEATURE_STATUS.format(featureId),
	});
	const { data } = resp;
	return data;
}

export async function getFeatureById(id: number | string): Promise<ApiResponse<CarFeature>> {
	const resp = await reqGet({
		url: Endpoint.FEATURE_BY_ID.format(id),
	});
	const { data } = resp;
	return data;
}

export async function updateFeature(feature: CarFeatureReq, featureId: string):
	Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.FEATURE_BY_ID.format(featureId),
		data: { ...feature, typeId: feature.typeId.id },
	});
	const { data } = resp;
	return data;
}

export async function newFeature(feature: CarFeatureReq): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.NEW_FEATURE,
		data: { ...feature, typeId: feature.typeId.id },
	});
	const { data } = resp;
	return data;
}

export const getFeatureTypes = async (): Promise<ApiResponse<FeatureType[]>> => {
	const resp = await reqGet({
		url: Endpoint.FEATURE_TYPES,
	});
	const { data } = resp;
	return data;
};
