import React, { FC } from 'react';
import { Grid } from '@mui/material';
import {
	Car, CarFreePeriods, CarRecReserva, Reservation,
} from '@Types';
import { Space } from '@Atoms';
import VehicleCard from './VehicleCard';
import VehicleBookings from './VehicleBookings';
import ReservationSearch from './ReservationSearch';
import FreePeriods from './FreePeriods';

interface VehicleDetailPage {
	handleListAction: (action: string, id?: number) => void;
	car?: Car;
	carReservations?: CarRecReserva[];
	onReservationSearch: () => void;
   onChange: (key: string, value: Date) => void;
   reservation?: Reservation;
   loading?: boolean;
   error?: string;
   onResClick: (id: number) => void;
   date: {date: Date, time: Date};
   freePeriods: CarFreePeriods[] | undefined;
   reservationError?: boolean
}

const VehicleDetailPage:FC<VehicleDetailPage> = ({
	car,
	handleListAction,
	carReservations,
	onReservationSearch,
	onChange,
	reservation,
	loading,
	error,
	onResClick,
	date,
	freePeriods,
	reservationError,
}) => (
	<Grid container spacing={3}>
		<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
			<VehicleCard car={car} handleListAction={handleListAction} />
		</Grid>
		<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
			<VehicleBookings
				reservations={carReservations}
				handleListItem={handleListAction}
			/>
		</Grid>
		<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
			<ReservationSearch
				onChange={onChange}
				onReservationSearch={onReservationSearch}
				reservation={reservation}
				loading={loading}
				error={error}
				onResClick={onResClick}
				date={date}
				reservationError={reservationError}
			/>
			<Space height={2} />
			<FreePeriods data={freePeriods} />
		</Grid>
	</Grid>
);

export default VehicleDetailPage;
