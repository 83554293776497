/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ApiResponse,
	UserPendingFinancials,
	Pagination,
	NameAndId,
	User,
	UserCards,
	ListDataControl,
	MessageReq,
	UserReq,
	UserBalence,
	Organization,
} from '@Types';
import { normalize, normalizePhone } from '@Masks';
import { uploadFile } from './upload.service';
import { reqPost, reqGet } from '../request.api';
import Endpoint from '../endpoints.api';

export async function getAllUsers(params: ListDataControl, statusId?: number):
   Promise<ApiResponse<Pagination<User[]>>> {
	const resp = await reqGet({
		url: Endpoint.USERS.format(params.page, params.size, params.search, statusId || ''),
	});
	const { data } = resp;
	return data;
}

export async function getUserById(userId: string | number): Promise<ApiResponse<User>> {
	const resp = await reqGet({
		url: Endpoint.USER_BY_ID.format(userId),
	});
	const { data } = resp;
	return data;
}

export async function getUserPendingFields(userId: number): Promise<ApiResponse<string[]>> {
	const resp = await reqGet({
		url: Endpoint.USER_PENDING_FIELDS.format(userId),
	});
	const { data } = resp;
	return data;
}

export async function getUserPendingDocuments(userId: number): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.USER_PENDING_DOCUMENTS.format(userId),
	});
	const { data } = resp;
	return data;
}
export async function getUserPendingFinancials(userId: number | string):
   Promise<ApiResponse<UserPendingFinancials[]>> {
	const resp = await reqGet({
		url: Endpoint.USER_PENDING_FINANCIALS.format(userId),
	});
	const { data } = resp;
	return data;
}

export async function getUserPreviousBalence(id: string): Promise<ApiResponse<UserBalence>> {
	const resp = await reqGet({
		url: Endpoint.USER_BALANCE.format(id),
	});
	const { data } = resp;
	return data;
}

export async function addUser(mData: UserReq):
   Promise<ApiResponse<void>> {
	let downlink = '';
	const { profileImage } = mData;
	if (profileImage?.size) {
		const image = new FormData();
		image.append('file', profileImage);
		const res = await uploadFile(image);
		downlink = res.data.file;
	}
	const documents = [] as any;
	mData.documents?.forEach((document) => {
		if (document.value) {
			documents.push({ ...document, countryId: document?.countryId?.id });
		}
	});
	const dataToUpload: any = {
		...mData,
		profileImage: downlink,
		countryId: mData.countryId.id,
		cityId: mData.cityId.id === 0 ? null : mData.cityId.id,
		stateId: mData.stateId.id === 0 ? null : mData.stateId.id,
		nationalityId: mData.nationalityId.id,
		phoneCountryId: mData.phoneCountryId.id,
		phone: normalizePhone(mData.phone),
		postalCode: normalize(mData.postalCode),
		taxId: normalize(mData.taxId),
		documents,
	};
	if (mData.disableRequireDocs) {
		delete dataToUpload.documents;
	}
	delete dataToUpload.profileImageUrl;
	const resp = await reqPost({
		url: Endpoint.USER,
		data: { ...dataToUpload },
	});
	const { data } = resp;
	return data;
}

export async function updateUser(
	mData: UserReq,
	id: string,
): Promise<ApiResponse<User>> {
	let downlink = mData.profileImageUrl;
	const { profileImage } = mData;
	if (profileImage?.size) {
		const image = new FormData();
		image.append('file', profileImage);
		const res = await uploadFile(image);
		downlink = res.data.file;
	} else {
		downlink = mData.profileImageUrl;
	}
	const documents = [] as any;
	mData.documents?.forEach((document) => {
		if (document.value) {
			documents.push({ ...document, countryId: document?.countryId?.id });
		}
	});
	const dataToUpload: any = {
		...mData,
		profileImage: downlink,
		countryId: mData.countryId?.id,
		cityId: mData.cityId?.id === 0 ? null : mData.cityId?.id,
		stateId: mData.stateId?.id === 0 ? null : mData.stateId?.id,
		nationalityId: mData.nationalityId?.id,
		phoneCountryId: mData.phoneCountryId?.id,
		phone: normalizePhone(mData.phone),
		postalCode: normalize(mData.postalCode),
		taxId: normalize(mData.taxId),
		documents,
	};
	if (mData.disableRequireDocs) {
		delete dataToUpload.documents;
	}
	const resp = await reqPost({
		url: Endpoint.USER_BY_ID.format(id),
		data: { ...dataToUpload },
	});
	const { data } = resp;
	return data;
}

export async function changeUserStatus(userId: number | string): Promise<ApiResponse<User>> {
	const resp = await reqPost({
		url: Endpoint.USER_STATUS.format(userId),
	});
	const { data } = resp;
	return data;
}

export async function promoteUserToAdmin(
	userId: string|number|undefined,
	organizations: Organization[],
):
Promise<ApiResponse<void>> {
	const organizationsIds = organizations.reduce((orgIds, el) => {
		orgIds.push(el.id);
		return orgIds;
	}, [] as number[]);
	const resp = await reqPost({
		url: Endpoint.PROMOTE_ADM,
		data: { userId, organizationsIds },
	});
	const { data } = resp;
	return data;
}

export async function sendMessageToUser(message: MessageReq, userId: string):
	Promise<ApiResponse<void>> {
	const modals: number[] = [];
	if (message.pushNotification) {
		modals.push(1);
	}
	if (message.email) {
		modals.push(2);
	}
	if (message.sms) {
		modals.push(3);
	}
	const dataToUpload = {
		message: message.message,
		title: message.title,
		modals,
	};
	const resp = await reqPost({
		url: Endpoint.SEND_MESSAGE.format(userId),
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
}

export async function resetUserPassword(userId?: number | string): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.RESET_USER_PASSWORD.format(userId),
	});
	const { data } = resp;
	return data;
}

export async function getUserByFiscalNumber(id: string): Promise<ApiResponse<User>> {
	const taxId = normalize(id);
	const resp = await reqPost({
		url: Endpoint.USER_BY_TAXID,
		data: { taxId },
	});
	const { data } = resp;
	return data;
}

export async function getUserCards(id: string): Promise<ApiResponse<UserCards>> {
	const resp = await reqGet({
		url: Endpoint.USER_CARDS.format(id),
	});
	const { data } = resp;
	return data;
}

export async function validateUserDocument(value: string, typeId: number, countryId: number):
	Promise<ApiResponse<boolean>> {
	const resp = await reqGet({
		url: Endpoint.USER_VERIFY_DOCUMENTS.format(value, typeId, countryId),
	});
	const { data } = resp;
	return data;
}
