import {
	Autocomplete, DatePicker, DefaultButton, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Divider, Grid } from '@mui/material';
import { useDropdown } from '@Providers';
import { CampaignReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currency } from '@Masks';
import { Edit, Save } from '@mui/icons-material';

interface CreateCampaignProps {
	initialValues: CampaignReq;
	validate: (values: CampaignReq) => FormikErrors<CampaignReq>;
	handleSubmit: (values: CampaignReq, formikHelpers: FormikHelpers<CampaignReq>) => void;
	isEdit: boolean;
	handleEditClick: () => void
}

export const discountType = [
	{ name: 'Percentual', id: true },
	{ name: 'Absoluto', id: false },
];

const DISCOUNT_LENGTH = 7;
const CAMPAIGN_NAME_MAX_LENGTH = 50;

const CreateCampaignForm: FC<CreateCampaignProps> = (props) => {
	const {
		initialValues, handleSubmit, validate, isEdit, handleEditClick,
	} = props;
	const { t } = useTranslation('translations');
	const { vehicleCategories, billableItems } = useDropdown();
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values, touched, errors, handleChange, setFieldValue, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
										<TextField
											label={t('name')}
											id="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											title=""
											inputProps={{ maxLength: CAMPAIGN_NAME_MAX_LENGTH }}
											value={values.name}
											disabled={isSubmitting || !isEdit}
											type="text"
											onChange={handleChange}
										/>
									</Grid>

									<Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
										<DatePicker
											label={t('start')}
											error={touched.startDate && Boolean(errors.startDate)}
											helperText={touched.startDate && String(errors.startDate || '')}
											value={values.startDate}
											disabled={isSubmitting || !isEdit}
											onChange={(value) => setFieldValue('startDate', value)}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
										<DatePicker
											label={t('end')}
											error={touched.endDate && Boolean(errors.endDate)}
											helperText={touched.endDate && String(errors.endDate || '')}
											value={values.endDate}
											disabled={isSubmitting || !isEdit}
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
										<TextField
											multiline
											disabled={isSubmitting || !isEdit}
											minRows={3.5}
											label={t('description')}
											error={touched.description && Boolean(errors.description)}
											helperText={touched.description && errors.description}
											id="description"
											value={values.description}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={8} sm={8} xs={12}>
										<Grid container spacing={3}>
											<Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
												<Autocomplete
													options={discountType}
													renderOption={(inputprops, item) => (
														<li {...inputprops} key={item.name}>
															{item.name}
														</li>
													)}
													disabled={isSubmitting || !isEdit}
													getOptionLabel={(item) => item.name || ''}
													defaultValue={values.isPercentage}
													error={touched.isPercentage && Boolean(errors.isPercentage)}
													helperText={touched.isPercentage && String(errors.isPercentage || '')}
													label={t('discountType')}
													placeholder=""
													onChange={(_, item) => setFieldValue('isPercentage', item)}
												/>
											</Grid>
											<Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
												{values.isPercentage.id ? (
													<TextField
														id="discount"
														label={t('value')}
														disabled={isSubmitting || !isEdit}
														error={touched.discount && Boolean(errors.discount)}
														helperText={touched.discount && errors.discount}
														title=""
														value={values.discount}
														onChange={(event) => {
															if (parseFloat(event.target.value) > 100) return;
															setFieldValue('discount', event.target.value);
														}}
													/>
												) : (
													<TextField
														disabled={isSubmitting || !isEdit}
														id="discount"
														label={t('value')}
														value={values.discount}
														title=""
														type="text"
														error={touched.discount && Boolean(errors.discount)}
														helperText={touched.discount && errors.discount}
														onChange={(event) => {
															if (event.target.value.length > DISCOUNT_LENGTH) return;
															setFieldValue('discount', currency(event).target.value);
														}}
													/>
												)}
											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={3}>
											<Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
												<Autocomplete
													options={vehicleCategories || []}
													label={t('linkedCategories')}
													groupBy={(item) => item?.tariff.name || ''}
													placeholder=""
													disabled={isSubmitting || !isEdit}
													defaultValue={values.categories}
													multiple
													error={touched.categories && Boolean(errors.categories)}
													helperText={touched.categories && String(errors.categories || '')}
													renderOption={(inputprops, item) => (
														<li {...inputprops} key={item.id}>
															{item.name}
														</li>
													)}
													getOptionLabel={(item) => item?.name || ''}
													onChange={(_, item) => {
														setFieldValue('categories', item);
													}}
												/>
											</Grid>

											<Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('campaignItem')}
													placeholder=""
													options={billableItems || []}
													disabled={isSubmitting || !isEdit}
													error={touched.itemId && Boolean(errors.itemId)}
													helperText={touched.itemId && String(errors.itemId || '')}
													renderOption={(inputprops, item) => (
														<li {...inputprops} key={item.id}>
															{item.name}
														</li>
													)}
													defaultValue={values.itemId}
													getOptionLabel={(item) => item.name || ''}
													onChange={(_, item) => setFieldValue('itemId', item)}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<TextField
											multiline
											disabled={isSubmitting || !isEdit}
											minRows={3}
											label={t('regulation')}
											id="rules"
											error={touched.rules && Boolean(errors.rules)}
											helperText={touched.rules && errors.rules}
											value={values.rules}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && <DefaultButton title={t('save')} type="submit" startIcon={<Save />} loading={isSubmitting} />}
								{!isEdit && <DefaultButton onClick={handleEditClick} title={t('edit')} startIcon={<Edit />} />}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateCampaignForm;
