/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { FC } from 'react';
import { useLanguage } from '@Providers';
import { useTranslation } from 'react-i18next';
import TextField from '../input/TextField';

interface DatePickerProps {
	label?: string;
	inputFormat?: string;
	value?: any;
	disabled?: boolean;
	onChange?: (date: any) => void;
	error?: boolean;
	helperText?: string;
	minDate?: any;
	maxDate?: Date;
	clearable?: boolean
}

const DatePicker: FC<DatePickerProps> = (props) => {
	const {
		label, inputFormat = 'dd/MM/yyyy', value, disabled, onChange, error, helperText, minDate, maxDate, clearable,
	} = props;
	const { localeFns } = useLanguage();
	const { t } = useTranslation('translations');
	return (
		<LocalizationProvider locale={localeFns} dateAdapter={AdapterDateFns}>
			<MobileDatePicker
				label={label}
				inputFormat={inputFormat}
				value={value}
				disableCloseOnSelect={false}
				disabled={disabled}
				cancelText={t('cancel')}
				minDate={minDate}
				maxDate={maxDate}
				onChange={(date) => onChange?.call(0, date)}
				clearable={clearable}
				renderInput={(params) => (
					<TextField
						disabled={disabled}
						error={error}
						helperText={helperText}
						size="small"
						name="date"
						fullWidth
						{...params}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default DatePicker;
