import { FontSizes } from '@Constant';
import {
	Box, SxProps, Theme, useTheme,
} from '@mui/material';
import { Car } from '@Types';
import React, { FC } from 'react';

type ShortCar = {licensePlate: string; name: string}

interface Props {
  car: Car | ShortCar;
  hideName?: boolean;
  sx?: SxProps<Theme>;
  onClick?: () => void
}

const CarItem: FC<Props> = (props) => {
	const {
		car, hideName, sx, onClick,
	} = props;
	const theme = useTheme();
	return (
		<>
			<Box
				sx={{
					...sx,
					backgroundColor: theme.palette.divider,
					paddingLeft: '6px',
					paddingRight: '6px',
					paddingTop: '4px',
					paddingBottom: '4px',
					fontSize: 11,
					borderRadius: '0.25rem',
					fontWeight: 'bold',
					mr: 1,
				}}
				component="span"
				onClick={() => onClick?.call(0)}
			>
				{car?.licensePlate}
			</Box>
			{!hideName && <Box sx={{ fontSize: FontSizes.SM, mt: 1 }}>{car?.name}</Box>}
		</>
	);
};

export default CarItem;
