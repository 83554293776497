import { Space, TableItem } from '@Atoms';
import { putDecimalPoints } from '@Masks';
import { ReportProblem } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { useFeedback, useTitleBar } from '@Providers';
import { reportApi } from '@Requests';
import { AppRoutes } from '@Routes';
import {
	Commission,
	CommissionReq, CommissionResponse, Organization, Tariff,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommissionReportForm from './CommissionReportForm';
import CommissionReportPage from './CommissionReportPage';

const CREDIT_NOTE = 'NC';

const CommissionReport = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();
	const theme = useTheme();

	const [initialValues] = useState({
		pickups: [] as Organization[],
		tariffs: [] as Tariff[],
		startDate: new Date(),
		endDate: new Date(),
	});
	const [commissionReport, setCommissionReport] = useState([] as CommissionResponse[]);

	useEffect(() => {
		setTitle(t('billingByBranches'));
	}, [setTitle, t]);

	const validate = (values: CommissionReq) => {
		const errors = {} as FormikErrors<CommissionReq>;
		if (!values.pickups) {
			errors.pickups = t('emptyField');
		}
		if (values.pickups?.length < 1) {
			errors.pickups = t('emptyField');
		}
		if (!values.tariffs) {
			errors.tariffs = t('emptyField');
		}
		if (values.tariffs?.length < 1) {
			errors.tariffs = t('emptyField');
		}
		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (values: CommissionReq, formikHelpers: FormikHelpers<CommissionReq>) => {
		reportApi.getCommissionReport(values)
			.then((response) => setCommissionReport(response.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	const handleListAction = (
		action: string,
		id: number,
		invoiceId?: number,
		fullIdentifier?: string,
	) => {
		switch (action) {
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap(({ id })));
			break;
		case t('invoice'):
			if (fullIdentifier?.startsWith(CREDIT_NOTE)) {
				navigate(AppRoutes.RECEIPT.formatMap(({ id: invoiceId })));
			} else {
				navigate(AppRoutes.INVOICES.formatMap(({ invoiceId, reservationId: id })));
			}
			break;
		default:
			break;
		}
	};

	const returnCommission = (commission: Commission) => {
		const {
			commissionRate,
			commissionRateBase,
			netAmount,
			discount,
			itemsDiscount,
			tollAmount,
			currentCommissionRateBase,
		} = commission;
		// (commissionRate / 100) *  ($tr->netAmount -
		// ($tr->discount + $tr->itemsDiscount) - $tr->tollAmount )
		const value = `${putDecimalPoints(((netAmount - (discount + itemsDiscount) - tollAmount) * (commissionRate / 100)).toFixed(2))} €`;
		let title = '';
		let icon = null as React.ReactNode;
		let beforeText = '';
		if (commissionRateBase !== currentCommissionRateBase) {
			icon = <ReportProblem style={{ color: theme.palette.primary.main, fontSize: 13 }} />;
			beforeText = '##';
		}
		if (commissionRate === commissionRateBase) {
			title = `${putDecimalPoints(commissionRateBase)}%`;
			// title = `${putDecimalPoints((commissionRate / 100) *
			// (netAmount - (discount + itemsDiscount) - tollAmount))}%`;
		} else {
			title = `${commissionRateBase}% => ${commissionRate}%`;
		}
		return (
			<TableItem
				textBeforeTitle={beforeText}
				customIcon={icon}
				title={title}
				subtitle={value}
			/>
		);
	};

	return (
		<>
			<CommissionReportForm
				initialValues={initialValues}
				validate={validate}
				handleSubmit={handleSubmit}
			/>
			<Space height={3} />
			<CommissionReportPage
				commissions={commissionReport}
				handleListAction={handleListAction}
				returnCommission={returnCommission}
			/>
		</>
	);
};

export default CommissionReport;
