import { FontSizes } from '@Constant';
import { IconButton, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface FinancialButtonProps {
	onClick: () => void
}

const FinancialButton: FC<FinancialButtonProps> = (props) => {
	const { onClick } = props;
	const theme = useTheme();
	return (
		<IconButton
			onClick={onClick}
			sx={{
				p: '3px',
				borderRadius: '2px',
				m: 0,
				fontWeight: 'bold',
				fontSize: FontSizes.MD,
				bgcolor: theme.palette.error.main,
				color: theme.palette.error.contrastText,
				'&:hover': {
					bgcolor: theme.palette.error.darker,
				},
			}}
		>
			€
		</IconButton>
	);
};

export default FinancialButton;
