import { AddCircle } from '@mui/icons-material';
import React, { FC } from 'react';
import { DataTable, TableColumn } from '@Organisms';
import {
	DefaultButton,
	StatusLabel,
	Dropdown,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Tariff, Pagination, PaginationTable } from '@Types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

interface ListTariffProps {
   handleListAction: (action: string, item?: Tariff) => void;
   tariffs?: Pagination<Tariff[]>;
   setPagination: (params: PaginationTable) => void;
   setSearch: (search: string) => void
}

const ListTarriffPage: FC<ListTariffProps> = (props) => {
	const {
		handleListAction, tariffs, setPagination, setSearch,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
					startIcon={<AddCircle />}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>

					<DataTable
						data={tariffs?.data || []}
						pagination
						total={tariffs?.total}
						onChange={(page, rowsPerPage) => setPagination({ page, size: rowsPerPage })}
						onSearching={(query) => setSearch(query)}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('name')} render={(item) => item.name} />
						<TableColumn headerText={t('description')} render={(item) => item.description} />
						<TableColumn headerText={t('type')} render={(item) => item.type.name} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<Dropdown
									label={t('action')}
									data={[t('detail'), t('changeStatus')]}
									renderItem={(dropdownItem) => ({
										optionValue: dropdownItem,
										optionLabel: dropdownItem,
									})}
									onChange={(action) => handleListAction(action, item)}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>

		</Grid>
	);
};

export default ListTarriffPage;
