import {
	Autocomplete, DatePicker, DefaultButton, TextField, Space,
} from '@Atoms';
import { taxId } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useDropdown } from '@Providers';
import { InvoiceReportReq } from '@Types';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AccountingFormProps {
	initialValues: InvoiceReportReq;
	handleSubmit: (values: InvoiceReportReq, formikHelpers: FormikHelpers<InvoiceReportReq>) => void
}

const AccountingForm: FC<AccountingFormProps> = (props) => {
	const { initialValues, handleSubmit } = props;
	const { t } = useTranslation('translations');
	const { counters, documentTypes } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
					>
						{({
							setFieldValue, handleChange, values, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
										<DatePicker
											disabled={isSubmitting}
											label={t('startDate')}
											value={values.startDate}
											helperText=""
											onChange={(value) => setFieldValue('startDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
										<DatePicker
											disabled={isSubmitting}
											label={t('endDate')}
											value={values.endDate}
											helperText=""
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
										<TextField
											disabled={isSubmitting}
											label={t('reservation')}
											helperText=""
											id="startDate"
											type="number"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
										<Autocomplete
											options={counters || []}
											disabled={isSubmitting}
											label={t('pickupCounter')}
											multiple
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											defaultValue={values.Pickups}
											getOptionLabel={(item) => item.officialName}
											placeholder=""
											onChange={(_, item) => {
												setFieldValue('Pickups', item);
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<Autocomplete
											options={documentTypes || []}
											disabled={isSubmitting}
											label={t('docType')}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											defaultValue={values.documentType}
											getOptionLabel={(item) => item.name || ''}
											placeholder=""
											onChange={(_, item) => {
												setFieldValue('documentType', item);
											}}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<TextField
											disabled={isSubmitting}
											title=""
											label={t('billNum')}
											helperText=""
											id="billNumber"
											type="number"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={1} lg={1} md={2} sm={6} xs={6}>
										<TextField
											disabled={isSubmitting}
											title=""
											label={t('year')}
											helperText=""
											id="year"
											type="number"
											onChange={(e) => {
												e.target.value = Math.max(0, parseInt(e.target.value, 10))
													.toString()
													.slice(0, 4);
												setFieldValue('year', e.target.value);
											}}
										/>
									</Grid>
									<Grid item xl={1} lg={1} md={2} sm={6} xs={6}>
										<TextField
											disabled={isSubmitting}
											title=""
											label={t('series')}
											helperText=""
											id="series"
											type="text"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={4} sm={6} xs={6}>
										<TextField
											disabled={isSubmitting}
											label={`${t('nif')} / ${t('taxId')}`}
											helperText=""
											id="nifId"
											onChange={(e) => {
												setFieldValue('nifId', taxId(e).target.value);
											}}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={4} sm={6} xs={6}>
										<DefaultButton
											title={t('translations:search')}
											loading={isSubmitting}
											type="submit"
											startIcon={<Search />}
										/>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default AccountingForm;
