import { Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton, Space, TextField } from '@Atoms';
import { Form, Formik, FormikHelpers } from 'formik';

interface ReasonType {reason: string}

interface ItemProps {
   onSubmit: (values: ReasonType, formikHelpers: FormikHelpers<{reason: string}>) => void;
   close: () => void
}

const CancelInvoiceItem: FC<ItemProps> = (props) => {
	const { onSubmit, close } = props;
	const { t } = useTranslation('translations');

	return (
		<div>
			<div>
				<ErrorOutline />
			</div>
			<Typography>{t('IrreversableAction')}</Typography>
			<Typography color="error">{t('attention')}</Typography>
			<Typography>{t('reason')}</Typography>
			<Space height={2} />
			<Formik
				initialValues={{ reason: '' }}
				validate={(values) => {
					if (!values.reason) {
						return { reason: t('emptyField') };
					}
					return {};
				}}
				onSubmit={onSubmit}
			>
				{({
					values, touched, errors, handleChange, isSubmitting,
				}) => (
					<Form>
						<TextField
							onChange={handleChange}
							disabled={isSubmitting}
							id="reason"
							error={touched.reason && Boolean(errors.reason)}
							helperText={touched.reason && errors.reason}
							value={values.reason}
						/>
						<Space height={2} />
						<DefaultButton
							loading={isSubmitting}
							title={t('confirm')}
							type="submit"
							sx={{ mr: 1 }}
						/>
						<DefaultButton
							disabled={isSubmitting}
							title={t('cancel')}
							onClick={close}
							color="info"
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default CancelInvoiceItem;
