import { DefaultButton } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { ArrowBack } from '@mui/icons-material';
import {
	Grid, Typography, Box, useTheme,
} from '@mui/material';
import { PdfViewer } from '@Organisms';
import { InvoiceCostumer } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ReceiptProps {
	data?: InvoiceCostumer;
	handleButtonAction: (action: string) => void;
	id?: string;
	loading: boolean
}

const ReceiptPage: FC<ReceiptProps> = (props) => {
	const {
		data, handleButtonAction, id, loading,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<DefaultButton
					startIcon={<ArrowBack />}
					title={t('backButton')}
					onClick={() => handleButtonAction(t('backButton'))}
				/>
				<DefaultButton
					title={t('reservation')}
					sx={{ ml: 1 }}
					onClick={() => handleButtonAction(t('reservation'))}
				/>
			</Grid>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Box sx={{ height: '100%' }}>
						{data?.invoice?.pdfFileBase64 && <PdfViewer source={data.invoice.pdfFileBase64} /> }
						{(!loading && !data?.invoice?.pdfFileBase64) && (
							<Box sx={{
								bgcolor: theme.palette.error.main,
								p: 2,
								borderRadius: 1,
								color: theme.palette.background.paper,
							}}
							>
								<Typography>
									{t('receiptError')}
								</Typography>
								<Typography>
									{t('receiptErrorDesc1')}
								</Typography>
								<Typography>
									{t('receiptErrorDesc2')}
									{' '}
									#
									{' '}
									{id}
								</Typography>
							</Box>
						)}
					</Box>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ReceiptPage;
