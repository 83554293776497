import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { discountApi } from '@Requests';
import { DiscountBillable } from '@Types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DiscountListPage from './DiscountListPage';

const ListDiscount = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();

	const getBillable = useRequest(() => discountApi.getBillableDiscounts());

	useEffect(() => {
		setTitle(t('applicableItems'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		getBillable.execute();
	}, []);

	const onEditClick = (item: DiscountBillable) => {
		navigate(`/discount/byquantity/edit/${item.id}`, { replace: true, state: item.name }); // send name to next screen
	};

	return (
		<DiscountListPage
			onEditClick={onEditClick}
			billibleItems={getBillable.data}
		/>
	);
};

export default ListDiscount;
