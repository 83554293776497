import { useFeedback, useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Organization, Status, TariffReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import { tariffApi } from '@Requests';
import { AppRoutes } from '@Routes';
import CreateTariffPage from './CreateTerrifPage';

const CORPORATE_TYPE = 2;

const CreateTariff = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({
		name: '',
		description: '',
		days: 0,
		typeId: {} as Status,
		commissionRate: 0,
		organizations: [] as Organization[],
	});
	const [isEdit, setEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('teriff')} ${t('detail')}`);
		} else {
			setTitle(`${t('new')} ${t('teriff')}`);
			setEdit(true);
		}
	}, [setTitle, id, t]);

	useEffect(() => {
		const getTariff = () => {
			if (id) {
				tariffApi
					.getTariffById(id)
					.then((res) => {
						setInitialValues({
							name: res.data.name,
							description: res.data.description,
							days: res.data.days,
							typeId: res.data.type,
							commissionRate: res.data.commissionRate,
							organizations: res.data.organizations,
						});
					})
					.catch(({ response }) => {
						addToast({ message: response.data.message, error: true });
					});
			}
		};
		let unsubscribe = false;
		if (!unsubscribe) {
			getTariff();
		}
		return () => {
			unsubscribe = true;
		};
	}, [id, addToast]);

	const validate = (values: TariffReq) => {
		const errors = {} as FormikErrors<TariffReq>;
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.days) {
			if (values.days !== 0) {
				errors.days = t('emptyField');
			}
		}
		if (values.days < 0) {
			errors.days = t('greaterThanZeroError');
		}
		if (!values.description) {
			errors.description = t('emptyField');
		}
		if (!values.commissionRate) {
			errors.commissionRate = t('emptyField');
		}
		if (values.commissionRate < 0) {
			errors.commissionRate = t('greaterThanZeroError');
		}
		if (!values.typeId) {
			errors.typeId = t('emptyField');
		}
		if (values.typeId && Object.keys(values.typeId).length === 0) {
			errors.typeId = t('emptyField');
		}
		if (values.typeId && values.typeId.id === CORPORATE_TYPE) {
			if (values.organizations.length === 0) {
				errors.organizations = t('emptyField');
			}
		}
		return errors;
	};

	const handleSubmit = (values: TariffReq, formikHelpers: FormikHelpers<TariffReq>) => {
		if (id) {
			tariffApi
				.updateTariffById(values, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ALL_TERRIFS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => {
					formikHelpers.setSubmitting(false);
				});
		} else {
			tariffApi
				.addNewTariff(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ALL_TERRIFS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setEdit(true);
		setTitle(`${t('edit')} ${t('teriff')}`);
	};

	return (
		<CreateTariffPage
			initialValues={initialValues}
			validate={validate}
			handleSubmit={handleSubmit}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
		/>
	);
};

export default CreateTariff;
