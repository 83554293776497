import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '@Atoms';
import { CRM_ACTIONS } from '@Constant';
import { AccountBox, Launch, FilterList } from '@mui/icons-material';
import { PaperContainer } from '@Molecules';

interface CrmButtonProps {
	user: boolean;
	reservation: boolean;
   onClick: (action: string) => void;
}

const CrmButtons: FC<CrmButtonProps> = (props) => {
	const { user, reservation, onClick } = props;
	const { t } = useTranslation('translations');

	return (
		<PaperContainer>
			{(user && reservation) && (
				<DefaultButton
					title={t('listAll')}
					onClick={() => {
						onClick(CRM_ACTIONS.LIST_ALL);
					}}
					startIcon={<FilterList />}
				/>
			)}

			{reservation && (
				<DefaultButton
					title={t('reservationDetails')}
					onClick={() => {
						onClick(CRM_ACTIONS.RESERVATION_DETAIL);
					}}
					sx={{ ml: 1 }}
					startIcon={<Launch />}
				/>
			)}
			{user && (
				<DefaultButton
					title={t('userDetails')}
					sx={{ ml: 1 }}
					onClick={() => {
						onClick(CRM_ACTIONS.USER_DETAIL);
					}}
					startIcon={<AccountBox />}
				/>
			)}
		</PaperContainer>
	);
};

export default CrmButtons;
