import {
	ListDataControl,
	ApiResponse,
	Pagination,
	CreateArticlesReq,
	Article,
	NameAndId,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './upload.service';

export const getArticles = async (props: ListDataControl, signal: AbortSignal):
    Promise<ApiResponse<Pagination<Article[]>>> => {
	const { page, size, search } = props;
	const resp = await reqGet({
		url: Endpoint.GET_ALL_ARTICLES.format(page, size, search),
		signal,
	});
	const data = resp.data as ApiResponse<Pagination<Article[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const changeArticleStatus = async (articleId: string):
	Promise<ApiResponse<Article>> => {
	const resp = await reqPost({
		url: Endpoint.ARTICLE_STATUS.format(articleId),
	});
	const data = resp.data as ApiResponse<Article>;
	return data;
};

export const getArticleById = async (id: string, signal: AbortSignal):
	Promise<ApiResponse<Article>> => {
	const resp = await reqGet({
		url: Endpoint.ARTICLE_BY_ID.format(id),
		signal,
	});
	const data = resp.data as ApiResponse<Article>;
	data.statusCode = resp?.status;
	return data;
};

export const createArticle = async (model: CreateArticlesReq): Promise<ApiResponse<Article>> => {
	let { image } = model;
	if (typeof image === 'object') {
		if (image.size) {
			const data = new FormData();
			data.append('file', image);
			const res = await uploadFile(data);
			image = res.data.file;
		}
	}
	const resp = await reqPost({
		url: Endpoint.CREATE_ARTICLE,
		data: { ...model, image },
	});
	const data = resp.data as ApiResponse<Article>;
	data.statusCode = resp?.status;
	return data;
};

export const editArticle = async (articleId: string, model: CreateArticlesReq):
    Promise<ApiResponse<Article>> => {
	let { image } = model;
	if (typeof image === 'object') {
		if (image.size) {
			const data = new FormData();
			data.append('file', image);
			const res = await uploadFile(data);
			image = res.data.file;
		}
	}
	const resp = await reqPost({
		url: Endpoint.ARTICLE_BY_ID.format(articleId),
		data: { ...model, image },
	});
	const data = resp.data as ApiResponse<Article>;
	data.statusCode = resp?.status;
	return data;
};

export const getArticleStatus = async (signal: AbortSignal):
Promise<ApiResponse<NameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.ARTICLE_STATUSES,
		signal,
	});
	const data = resp.data as ApiResponse<NameAndId[]>;
	data.statusCode = resp.status;
	return data;
};
