import { DefaultButton, Space } from '@Atoms';
import { Box, Typography, useTheme } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
	SetupIntent, SetupIntentResult, StripeError,
} from '@stripe/stripe-js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CheckoutProps {
   clientSecret: string | undefined;
   name?: string;
   setStripeResponse: (parmas: SetupIntent | null) => void;
   setStripeError: (params: StripeError | null) => void;
}

const CheckoutForm: React.FC<CheckoutProps> = (props) => {
	const {
		clientSecret, name, setStripeResponse, setStripeError,
	} = props;
	const stripe = useStripe();
	const elements = useElements();
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (elements == null) {
			return;
		}
		if (clientSecret) {
			const cardElement = elements.getElement(CardElement);
			if (cardElement) {
				setLoading(true);
				stripe
					?.confirmCardSetup(clientSecret, {
						payment_method:
						{ card: cardElement, billing_details: { name } },
					})
					.then((res: SetupIntentResult) => {
						if (res.setupIntent) {
							setStripeError(null);
							setStripeResponse(res.setupIntent);
							cardElement.clear();
						} else if (res.error) {
							setStripeError(res.error);
							setStripeResponse(null);
						}
					})
					.catch(({ error }: { error: StripeError }) => {
						setStripeError(error);
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Space height={2} />
			<Box sx={{
				border: `1px solid ${theme.palette.divider}`,
				p: 1,
				borderRadius: 1,
				color: 'white',
			}}
			>
				<CardElement options={{
					style: {
						base:
					{ color: theme.palette.text.primary },
					},
				}}
				/>
			</Box>
			<Space height={2} />
			<Typography variant="caption">{t('newCardNote')}</Typography>
			<Space height={2} />
			<DefaultButton type="submit" title={t('addCard')} loading={loading} />
		</form>
	);
};

export default CheckoutForm;
