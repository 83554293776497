import React, { FC } from 'react';
import { ISocialNetwork } from '@Types';
import { Grid } from '@mui/material';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { useTranslation } from 'react-i18next';
import {
	DateLabel, DefaultButton, Dropdown, StatusLabel,
} from '@Atoms';

interface SocialNetworkProps {
	socialNetworks?: ISocialNetwork[];
	handleListAction: (action: string, id?: number) => void
}

const ListSocialNetworkPage: FC<SocialNetworkProps> = (props) => {
	const { socialNetworks, handleListAction } = props;
	const { t } = useTranslation('translations');

	const listActions = [t('changeStatus'), t('edit'), t('delete')];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable data={socialNetworks || []} search={false}>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('userName')} render={(item) => item.userName} />
						<TableColumn headerText={t('type')} render={(item) => item.type?.name} />
						<TableColumn headerText={t('webUrl')} render={(item) => item.type?.webUrl} />
						<TableColumn headerText={t('appUrl')} render={(item) => item.type?.appUrl} />
						<TableColumn headerText={t('recorded')} render={(item) => <DateLabel date={item.registration} />} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							headerOptions={{ align: 'right' }}
							align="right"
							render={(item) => (
								<Dropdown
									variant="contained"
									label={t('action')}
									data={listActions}
									onChange={(action) => handleListAction(action, item.id)}
									renderItem={(option) => ({ optionLabel: option, optionValue: option })}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListSocialNetworkPage;
