import { ApiResponse, Card } from '@Types';
import Endpoint from '../endpoints.api';
import { reqDelete, reqGet } from '../request.api';

export async function getPaymentCards({ clientId, typeId }: { clientId: string; typeId: number }):
   Promise<ApiResponse<Card>> {
	const resp = await reqGet({
		url: Endpoint.USER_CARDS.format(clientId, typeId),
	});
	const { data } = resp;
	return data;
}
export async function deletePaymentCards({
	cardId,
	clientId,
	clientType,
}: {
   cardId: string;
   clientId?: string;
   clientType?: string;
}): Promise<ApiResponse<void>> {
	const resp = await reqDelete({
		url: Endpoint.CARD_BY_ID.format(cardId),
		data: { clientId, clientType },
	});
	const { data } = resp;
	return data;
}

export async function checkValidCard(userId?: number, organizationId?: number):
	Promise<ApiResponse<{haveCard: boolean}>> {
	const resp = await reqGet({
		url: Endpoint.CHECK_VALID_CARD.format(userId || '', organizationId || ''),
	});
	const { data } = resp;
	return data;
}
