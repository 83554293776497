import { useRequest } from '@Api';
import { useFeedback, useTitleBar } from '@Providers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { socialNetworkApi } from '@Requests';
import { useEffectCustom } from '@Hooks';
import { AppRoutes } from '@Routes';
import ListSocialNetworkPage from './ListSocialNetworkPage';

const ListSocialNetwork = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const navigate = useNavigate();
	const abortCtrl = new AbortController();
	const { addDialog } = useFeedback();

	const socialNetworks = useRequest(() => socialNetworkApi.getSocialNetworks(abortCtrl.signal));
	const changeStatus = useRequest(([params]) => socialNetworkApi.socialNetworkStatus(params));

	useEffect(() => {
		setTitle(t('socialNetworks'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		socialNetworks.execute();
		return () => {
			abortCtrl.abort();
		};
	}, []);

	useEffectCustom(() => {
		const { data } = changeStatus;
		if (data) {
			socialNetworks.setData((mPrev) => {
				const index = mPrev?.findIndex((el) => el.id === data.id);
				const prev = mPrev;
				if (index !== undefined && prev) {
					prev[index] = data;
				}
				return prev;
			});
		}
	}, [changeStatus.data]);

	const deleteNetwork = (id?: number) => {
		setGlobalLoading(true);
		socialNetworkApi.deleteSocialNetwork(id)
			.then(() => {
				socialNetworks.setData((mPrev) => {
					const index = mPrev?.findIndex((el) => el.id === id);
					const prev = mPrev;
					if (index !== undefined && prev) {
						prev.splice(index, 1);
					}
					return prev;
				});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	const handleListAction = (action: string, id?: number) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.CREATE_SOCIAL_NETWORK);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_SOCIAL_NETWORK.formatMap({ id }));
			break;
		case t('delete'):
			deleteNetwork(id);
			break;
		case t('changeStatus'):
			changeStatus.execute(id);
			break;
		default:
			break;
		}
	};
	return (
		<ListSocialNetworkPage
			socialNetworks={socialNetworks.data}
			handleListAction={handleListAction}
		/>
	);
};

export default ListSocialNetwork;
