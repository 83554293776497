/* eslint-disable no-console */
import { useTitleBar, useFeedback } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { serviceApi, reservationApi, discountApi } from '@Requests';
import {
	DiscountBillable, NameAndId, RequestDiscountReq, Service, ServicesReq,
} from '@Types';
import { useEffectCustom } from '@Hooks';
import { useRequest } from '@Api';
import { Header, RequestDiscountDailog } from '@Organisms';
import { AppRoutes } from '@Routes';
import { Space } from '@Atoms';
import { FormikErrors, FormikHelpers } from 'formik';
import ServicesPage from './ServicesPage';
import ServicesForm from './ServicesForm';

const Services = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { reservationId } = useParams();
	const { addDialog, addToast } = useFeedback();
	const navigate = useNavigate();

	const [services, setServices] = useState([] as Service[]);
	const [reservationDiscounts, setReservationDiscounts] = useState([] as DiscountBillable[]);
	const [reqDiscountDialog, setReqDiscountDialog] = useState(false);
	const [items, setItems] = useState<number>();
	const [itemError, setItemError] = useState('');
	const [initialValues] = useState({
		type: {} as NameAndId,
		billableItemId: {} as DiscountBillable,
		value: '',
		quantity: 1,
		discountRequestId: {} as DiscountBillable,
	});

	const [reqDiscountValues] = useState({
		discount: '',
		isPercent: false,
		note: '',
		itemId: [] as Service[],
	});

	const reservation = useRequest(([params]) => reservationApi.getReservationById(params));
	const billableItems = useRequest(([params]) => (
		discountApi.getBillableDiscounts(params, true)));
		// Sending true because we don't need to show daily

	useEffect(() => {
		setTitle(t('services'));
	}, [t, setTitle]);

	const getData = async () => {
		serviceApi.getReservationService(reservationId ?? '')
			.then((res) => setServices(res.data))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}));
		reservation.execute(reservationId);
		const tempResDiscount = await discountApi.getDiscountsByReservation(reservationId ?? '', 2);
		setReservationDiscounts(tempResDiscount.data?.data);
	};

	useEffectCustom(() => {
		if (reservationId) {
			getData();
		}
	}, [reservationId]);

	const backButtonAction = () => {
		navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
	};

	const reqDiscount = async (values: RequestDiscountReq) => {
		setReqDiscountDialog(false);
		setGlobalLoading(true);
		discountApi.requestDiscount({
			...values,
			itemId: [items],
			typeId: 2,
			reservationId,
		}).then(async (res) => {
			addToast({ message: res.message, error: false });
			const tempResDiscount = await discountApi.getDiscountsByReservation(reservationId ?? '', 2);
			setReservationDiscounts(tempResDiscount.data?.data);
		})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	const validate = (values: ServicesReq) => {
		const errors = {} as FormikErrors<ServicesReq>;
		if (!values.billableItemId) {
			errors.billableItemId = t('emptyField');
		}
		if (values.billableItemId && Object.keys(values.billableItemId).length === 0) {
			errors.billableItemId = t('emptyField');
		}
		if (!values.value) {
			errors.value = t('emptyField');
		}
		if (!values.quantity) {
			errors.quantity = t('emptyField');
		}
		return errors;
	};

	const createService = (item: ServicesReq, formikHelpers: FormikHelpers<ServicesReq>) => {
		serviceApi.addNewReservationService(reservationId ?? '', item)
			.then((res) => {
				addToast({ message: res.message, error: false });
				setServices(res.data);
				formikHelpers.resetForm({});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	const closeDialog = () => {
		setReqDiscountDialog(false);
	};

	const onReqDiscountClick = () => {
		if (!items) {
			return setItemError(t('selectAnItem'));
		}
		setItemError('');
		setReqDiscountDialog(true);
		return null;
	};

	const handleTransactionClick = () => {
		navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId }));
	};

	const handleItemSelect = (itemId: number) => {
		setItems(itemId);
	};

	const getBillableItems = (item: NameAndId | null) => {
		if (item) {
			billableItems.execute(item.id);
		}
	};

	const deleteReservationService = (id: number) => {
		setGlobalLoading(true);
		serviceApi
			.deleteReservationService(reservationId ?? '', id.toString())
			.then(() => {
				setServices((prev) => prev?.filter((el) => el.id !== id));
			})
			.catch(({ response }) => {
				addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				});
			})
			.finally(() => setGlobalLoading(false));
	};

	const validateReqDisForm = (values: RequestDiscountReq) => {
		const errors = {} as FormikErrors<RequestDiscountReq>;
		if (!values.discount) {
			errors.discount = t('emptyField');
		}
		if (!values.note) {
			errors.note = t('emptyField');
		}
		return errors;
	};

	return (
		<>
			{reqDiscountDialog && (
				<RequestDiscountDailog
					initialValues={reqDiscountValues}
					open={reqDiscountDialog}
					validate={validateReqDisForm}
					handleClose={closeDialog}
					handleSubmit={reqDiscount}
				/>
			)}
			<Header
				reservation={reservation.data}
				onBackPress={backButtonAction}
			/>
			<Space height={3} />
			<ServicesForm
				initialValues={initialValues}
				discounts={reservationDiscounts}
				validate={validate}
				handleSubmit={createService}
				onReqDiscountClick={onReqDiscountClick}
				onTransactionsClick={handleTransactionClick}
				onItemSelect={handleItemSelect}
				getBillableItems={getBillableItems}
				billableItems={billableItems.data?.data}
				itemError={itemError}
			/>
			<Space height={3} />
			<ServicesPage
				services={services}
				deleteReservationService={deleteReservationService}
			/>
		</>
	);
};

export default Services;
