/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import DefaultButton from '../default-button/DefaultButton';

interface DropdownProps<T> {
   variant?: 'text' | 'outlined' | 'contained';
   data: T[];
   label: string;
   renderItem: (item: T) => { optionValue: any; optionLabel: string };
   onChange: (value: any) => void;
   handleButtonClick: () => void;
   icon: React.ReactNode
}

const SplitButtonDropdown = <T, >(props: DropdownProps<T>) => {
	const {
		data, variant = 'contained', label, onChange, renderItem, handleButtonClick, icon,
	} = props;
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleClick = () => {
		handleButtonClick();
	};

	const handleMenuItemClick = (
		value: any,
	) => {
		onChange.call(0, value);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (
			anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<ButtonGroup
				variant="contained"
				ref={anchorRef}
				aria-label="split button"
				disableElevation
			>
				<DefaultButton
					title={label}
					startIcon={icon}
					variant={variant}
					onClick={handleClick}
				/>
				<Button
					size="small"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{data.map((option) => {
										const { optionValue, optionLabel } = renderItem(option);
										return (
											<MenuItem
												key={optionLabel}
												onClick={() => handleMenuItemClick(optionValue)}
											>
												{optionLabel}
											</MenuItem>
										);
									})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

export default SplitButtonDropdown;
