/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CheckBox, DefaultButton, Space, TextField,
} from '@Atoms';
import { currency } from '@Masks';
import { Close } from '@mui/icons-material';
import {
	Dialog as DialogMui,
	Slide,
	DialogTitle,
	useTheme,
	DialogContent,
	DialogActions,
	Typography,
	Divider,
	RadioGroup,
	FormControlLabel,
	Radio,
	Stack,

} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DataTable, TableColumn } from '@Organisms';
import { Item, RequestDiscountReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RequestDiscountDailogProps {
	open: boolean;
	initialValues: RequestDiscountReq;
	handleClose: () => void;
	data?: Item[];
	validate: (values: RequestDiscountReq) => FormikErrors<RequestDiscountReq>;
	handleSubmit: (values: RequestDiscountReq,
		formikHelpers: FormikHelpers<RequestDiscountReq>) => void;
}

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const RequestDiscountDailog: React.FC<RequestDiscountDailogProps> = (props) => {
	const {
		open,
		initialValues,
		handleClose,
		data,
		validate,
		handleSubmit,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<DialogMui
				sx={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) }}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle sx={{ textAlign: 'center' }}>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="h6">{t('requestDiscount')}</Typography>
						<Close
							sx={{
								'&:hover': {
									cursor: 'pointer',
								},
							}}
							onClick={() => {
								handleClose();
							}}
						/>
					</Stack>
				</DialogTitle>
				<Formik
					initialValues={initialValues}
					validate={validate}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{({
						errors, touched, setFieldValue, handleChange, values,
					}) => (
						<Form>
							<DialogContent sx={{ textAlign: 'center' }}>
								{data && (
									<>
										<DataTable data={values?.itemId || []} search={false}>
											<TableColumn
												headerText={t('add')}
												render={(item) => (
													<CheckBox
														title=""
														checked={item?.checked}
														setChecked={() => {
															const items = [...values.itemId];
															const index = items.findIndex((el) => el.id === item.id);
															if (index === undefined) return null;
															items[index] = { ...items[index], checked: !items[index].checked };
															return setFieldValue('itemId', items);
														}}
													/>
												)}
											/>
											<TableColumn headerText="#" render={(item) => item.id + 1} />
											<TableColumn headerText="ID" render={(item) => item.itemId} />
											<TableColumn headerText={t('name')} render={(item) => item?.name} />
										</DataTable>
										<Space height={2} />
										<Divider />
										<Space height={2} />
										<Typography variant="caption" color="error">{errors.itemId}</Typography>
										<Space height={2} />
									</>
								)}
								<TextField
									title="0"
									label={t('discount')}
									value={values.discount}
									error={touched.discount && Boolean(errors.discount)}
									helperText={touched.discount && errors.discount}
									id="discount"
									onChange={(event) => setFieldValue('discount', currency(event).target.value)}
								/>
								<Space height={2} />
								<RadioGroup
									row
									aria-label="percentage"
									name="row-radio-buttons-group"
									onChange={(event, value) => {
										if (value === 'true') {
											setFieldValue('isPercent', true);
										} else {
											setFieldValue('isPercent', false);
										}
									}}
									value={String(values.isPercent)}
								>
									<FormControlLabel value="false" control={<Radio color="primary" />} label="€" />
									<FormControlLabel value="true" control={<Radio color="primary" />} label="%" />
								</RadioGroup>
								<Space height={2} />
								<TextField
									multiline
									minRows={3}
									error={touched.note && Boolean(errors.note)}
									helperText={touched.note && errors.note}
									id="note"
									onChange={handleChange}
								/>

							</DialogContent>
							<DialogActions>
								<DefaultButton title={t('requestDiscount')} type="submit" />
								<DefaultButton
									color="secondary"
									title={t('cancel')}
									onClick={handleClose}
								/>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</DialogMui>
		</div>
	);
};

export default RequestDiscountDailog;
