import { replaceCommaWithDecimal } from '@Helpers';
import { normalize, normalizePhone } from '@Masks';
import {
	ApiResponse, ListDataControl, Organization, OrganizationReq, Pagination,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getCounters(params: ListDataControl, statusId?: number):
   Promise<ApiResponse<Pagination<Organization[]>>> {
	const resp = await reqGet({
		url: Endpoint.COUNTERS.format(params.page, params.size, params.search, statusId || ''),
	});
	const data: ApiResponse<Pagination<Organization[]>> = await resp.data;
	return data;
}

export async function getCounterById(id: string): Promise<ApiResponse<Organization>> {
	const resp = await reqGet({
		url: Endpoint.COUNTER_BY_ID.format(id),
	});
	const { data } = resp;
	return data;
}

export async function updateCounterById(counter: OrganizationReq, id?: string):
	Promise<ApiResponse<void>> {
	const dataToUpload = {
		address: counter.address,
		addressComplement: counter.addressComplement,
		cityId: counter.cityId.id,
		countryId: counter.countryId.id,
		email: counter.email,
		establishmentNumber: counter.establishmentNumber,
		externalId: counter.externalId,
		fantasyName: counter.fantasyName,
		latitude: replaceCommaWithDecimal(counter.latitude),
		longitude: replaceCommaWithDecimal(counter.longitude),
		neighborhood: counter.neighborhood,
		officialName: counter.officialName,
		phone: normalizePhone(counter.phone),
		phoneCountryId: counter.phoneCountryId.id,
		postalCode: normalize(counter.postalCode),
		stateId: counter.stateId.id,
		taxId: normalize(counter.taxId),
	};
	const resp = await reqPost({
		url: Endpoint.COUNTER_BY_ID.format(id),
		data: dataToUpload,
	});
	if (resp.status !== 200) {
		throw new Error('');
	}
	const { data } = resp;
	return data;
}

export async function createCounter(counter: OrganizationReq): Promise<ApiResponse<void>> {
	const dataToUpload = {
		address: counter.address,
		addressComplement: counter.addressComplement,
		cityId: counter.cityId.id,
		countryId: counter.countryId.id,
		email: counter.email,
		establishmentNumber: counter.establishmentNumber,
		externalId: counter.externalId,
		fantasyName: counter.fantasyName,
		latitude: replaceCommaWithDecimal(counter.latitude),
		longitude: replaceCommaWithDecimal(counter.longitude),
		neighborhood: counter.neighborhood,
		officialName: counter.officialName,
		phone: normalizePhone(counter.phone),
		phoneCountryId: counter.phoneCountryId.id,
		postalCode: normalize(counter.postalCode),
		stateId: counter.stateId.id,
		taxId: normalize(counter.taxId),
	};
	const resp = await reqPost({
		url: Endpoint.COUNTER,
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
}

export async function changeCounterStatus(counterId: number): Promise<ApiResponse<Organization>> {
	const resp = await reqPost({
		url: Endpoint.COUNTER_STATUS.format(counterId),
	});
	const data: ApiResponse<Organization> = await resp.data;
	return data;
}
