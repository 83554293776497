import { Grid } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { CarFeature, Pagination, PaginationTable } from '@Types';
import { DefaultButton, Dropdown, StatusLabel } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';

interface ListCharacteristicsProps {
   handleListAction: (action: string, item?: CarFeature) => void;
   setSearch: (search: string) => void;
   setPagination: (params:PaginationTable) => void;
   features?: Pagination<CarFeature[]>
}

const ListCharacteristicPage: FC<ListCharacteristicsProps> = (props) => {
	const {
		handleListAction, setSearch, setPagination, features,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={features?.data || []}
						pagination
						onSearching={(query) => setSearch(query)}
						onChange={(page, rowsPerPage) => {
							setPagination({ page, size: rowsPerPage });
						}}
						total={features?.total}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('featur')} render={(item) => item.name} />
						<TableColumn headerText={t('groups')} render={(item) => item.type.name} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<Dropdown
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									data={[t('detail'), t('changeStatus')]}
									onChange={(name) => handleListAction(name, item)}

								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListCharacteristicPage;
