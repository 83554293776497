import { formatCurrency } from '@Helpers';
import { useEffectCustom } from '@Hooks';
import { normalizeCurrency } from '@Masks';
import { useFeedback, useTitleBar } from '@Providers';
import { maintenanceApi } from '@Requests';
import { AppRoutes } from '@Routes';
import {
	Attachment,
	Car, Maintenance, NameAndId, NewMaintenance, Organization,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateCarMaintenance from './CreateCarMaintenance';

const CreateMaintenance = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams<{ id: string }>();
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({
		description: '',
		startDate: new Date(),
		intendedOutDate: new Date(),
		carId: {} as Car,
		typeId: {} as NameAndId,
		organizationId: {} as Organization,
		totalAmount: '',
		fileName: {} as File,
		fileDescription: '',
	});
	const [attachment, setAttachment] = useState([] as Attachment[]);
	const [isEdit, setEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('maintenance')} ${t('detail')}`);
		} else {
			setEdit(true);
			setTitle(`${t('new')} ${t('maintenance')}`);
		}
	}, [setTitle, id, t]);

	const setMaintenance = (data: Maintenance) => {
		setInitialValues({
			description: data.description,
			startDate: new Date(data.startDate),
			intendedOutDate: new Date(data.intendedOutDate),
			carId: data.car,
			typeId: data.type,
			organizationId: data.organization,
			totalAmount: formatCurrency(data.totalAmount),
			fileName: {} as File,
			fileDescription: '',
		});
		setAttachment(data.attachments);
	};

	useEffectCustom(() => {
		const getMaintenance = () => {
			if (id) {
				maintenanceApi.getMaintenanceById(id)
					.then(({ data }) => {
						setMaintenance(data);
					});
			}
		};
		let unsubscribe = false;
		if (!unsubscribe) {
			getMaintenance();
		}
		return () => {
			unsubscribe = true;
		};
	}, [id]);

	const validate = (values: NewMaintenance) => {
		const errors = {} as FormikErrors<NewMaintenance>;
		const totalAmount = normalizeCurrency(values.totalAmount);
		if (!values.carId) {
			errors.carId = t('emptyField');
		}
		if (values.carId && Object.keys(values.carId).length === 0) {
			errors.carId = t('emptyField');
		}
		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		if (!values.intendedOutDate) {
			errors.intendedOutDate = t('emptyField');
		}
		if (!values.typeId) {
			errors.typeId = t('emptyField');
		}
		if (values.typeId && Object.keys(values.typeId).length === 0) {
			errors.typeId = t('emptyField');
		}
		if (!values.organizationId) {
			errors.organizationId = t('emptyField');
		}
		if (values.organizationId && Object.keys(values.organizationId).length === 0) {
			errors.organizationId = t('emptyField');
		}
		if (!totalAmount || totalAmount === 0) {
			errors.totalAmount = t('emptyField');
		}
		if (values.intendedOutDate < values.startDate) {
			errors.intendedOutDate = t('intendedDateError');
		}
		if (values.fileName.size) {
			if (!values.fileDescription) {
				errors.fileDescription = t('emptyField');
			}
		}
		return errors;
	};

	const handleSubmit = (values: NewMaintenance, formikHelpers: FormikHelpers<NewMaintenance>) => {
		if (id) {
			maintenanceApi.updateMaintenanceById(values, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					/** If a file was uploaded keep the user on the page else navigate to list */
					if (!values.fileDescription) {
						navigate(AppRoutes.MAINTENANCE);
					} else {
						setMaintenance(res.data);
						formikHelpers.setFieldValue('fileName', {});
						formikHelpers.setFieldValue('fileDescription', '');
					}
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			maintenanceApi.createNewMaintenance(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.MAINTENANCE);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('maintenance')}`);
		setEdit(true);
	};

	return (
		<CreateCarMaintenance
			initialValues={initialValues}
			id={!!id}
			attachments={attachment}
			validate={validate}
			handleSubmit={handleSubmit}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
		/>
	);
};

export default CreateMaintenance;
