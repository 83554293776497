import { Space } from '@Atoms';
import { useFeedback, useTitleBar } from '@Providers';
import { reportApi } from '@Requests';
import {
	NameAndId, Pagination, TollReport, TollReportReq,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '@Routes';
import { useNavigate } from 'react-router-dom';
import { useEffectCustom } from '@Hooks';
import TollBookingForm from './TollBookingForm';
import TollBookingPage from './TollBookingPage';

const TollBooking = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const [tollReports, setTollReports] = useState<Pagination<TollReport[]>>();
	const [initialValues] = useState({
		types: [] as NameAndId[],
		startDate: new Date(),
		endDate: new Date(),
	});
	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');
	const [options, setOptions] = useState<TollReportReq>();

	const initialMount = true;

	useEffect(() => {
		setTitle(t('tollReservation'));
	}, [setTitle, t]);

	const validate = (values: TollReportReq) => {
		const errors = {} as FormikErrors<TollReportReq>;
		if (!values.types) {
			errors.types = t('emptyField');
		}
		if (values.types.length === 0) {
			errors.types = t('emptyField');
		}
		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (values: TollReportReq, formikHelpers?: FormikHelpers<TollReportReq>) => {
		setOptions(values);
		const listDataControl = {
			page: pagination.page,
			size: pagination.size,
			search,
		};
		reportApi.getTollReports(values, listDataControl)
			.then((res) => setTollReports(res.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => {
				formikHelpers?.setSubmitting(false);
				setGlobalLoading(false);
			});
	};

	useEffectCustom(() => {
		if (initialMount && options) {
			setGlobalLoading(true);
			handleSubmit(options);
		}
	}, [pagination, search]);

	const onReservationClick = (id: number) => {
		navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
	};

	return (
		<>
			<TollBookingForm
				initialValues={initialValues}
				validate={validate}
				handleSubmit={handleSubmit}
			/>
			<Space height={3} />
			<TollBookingPage
				data={tollReports}
				handleReservationClick={onReservationClick}
				setSearch={setSearch}
				setPagination={setPagination}
			/>
		</>
	);
};

export default TollBooking;
