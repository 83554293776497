import { Autocomplete, Space, TextField } from '@Atoms';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box, Grid, IconButton, Typography,
} from '@mui/material';
import { DiscountItem, ItemRelatedToDiscount } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ListQuantityDiscountProps {
	billableItems?: ItemRelatedToDiscount<DiscountItem[]>;
	handleDelete: (itemDiscountId: number, index: number) => void;
	loading: boolean
}

const ListQuantityDiscounts: FC<ListQuantityDiscountProps> = (props) => {
	const { billableItems, handleDelete, loading } = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					{billableItems?.items.map((item, index) => (
						<Box key={`${item.category.name}-${index.toString()}`} sx={{ mb: 2 }}>
							{billableItems?.items[index - 1]?.category?.tariff?.id !== item.category.tariff.id
							&& (
								<>
									<Typography>{item.category.tariff.name}</Typography>
									<Space height={2} />
								</>
							)}
							<Grid container spacing={3}>
								<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
									<TextField
										label={t('from')}
										id=""
										helperText=""
										title=""
										value={item.minAmount}
										disabled
										type="number"
									/>
								</Grid>
								<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
									<TextField
										label={t('discount')}
										id=""
										helperText=""
										title=""
										value={putDecimalPoints(item.discount)}
										disabled
										type="text"
									/>
								</Grid>
								<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
									<Autocomplete
										label={t('type')}
										placeholder=""
										options={['-Sem escolha-', 'Percentual', 'Absoluto']}
										disabled
										defaultValue={item.isPercentage ? 'Percentual' : 'Absoluto'}
									/>
								</Grid>
								<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
									<Autocomplete
										label={t('categor')}
										options={['-Sem escolha-', 'Percentual', 'Absoluto']}
										disabled
										defaultValue={item.category.name}
										placeholder=""
									/>
								</Grid>
								<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
									<IconButton
										color="error"
										disabled={loading}
										aria-label="delete discount"
										component="span"
										onClick={() => handleDelete(item.itemDiscountId, index)}
									>
										<DeleteIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Box>
					))}

				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListQuantityDiscounts;
