import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { organizationApi, reservationApi, userApi } from '@Requests';
import {
	Organization, Reservation, ReservationReq, User,
} from '@Types';
import { FormikHelpers } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ChangeReservationPage from './ChangeReservationPage';

const ChangeReservation: FC = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const navigate = useNavigate();
	const { addToast, addDialog } = useFeedback();

	const [initialValues, setInitialValues] = useState({} as ReservationReq);
	const [users, setUsers] = useState([] as User[]);
	const [companies, setCompanies] = useState([] as Organization[]);
	const [userName, setUserName] = useState('');
	const [reservation, setReservation] = useState({} as Reservation);

	useEffect(() => {
		setTitle(`${t('changeReservation')} #${id}`);
	}, [setTitle, t, id]);

	const getData = () => {
		reservationApi.getReservationById(id ?? '')
			.then(({ data }) => {
				setInitialValues({
					...initialValues,
					organizationId: data.organization,
					note: data.notes,
					additionalDriverId: data.additionalDriver,

				});
				setReservation(data);
				setUserName(data.user.name);
			});
	};

	useEffectCustom(() => {
		getData();
	}, [id]);

	const getDropdown = (action: string, search: string) => {
		if (!search) return null;
		const listDataControl = {
			page: 1,
			size: 10000,
			search,
		};
		switch (action) {
		case t('company'):
			organizationApi.getOrganizations(listDataControl)
				.then(({ data }) => setCompanies(data.data));
			break;
		case t('secondDriver'):
			userApi.getAllUsers(listDataControl)
				.then(({ data }) => setUsers(data.data));
			break;
		default:
			break;
		}
		return null;
	};

	const handleSubmit = (values: ReservationReq, formikHelpers: FormikHelpers<ReservationReq>) => {
		const dataToUpload = {
			organizationId: reservation.organization || values.organizationId,
			userId: reservation.user,
			mainDriverId: reservation.mainDriver,
			additionalDriverId: reservation.additionalDriver || values.additionalDriverId,
			pickupOrganizationId: reservation.pickupOrganization,
			returnOrganizationId: reservation.returnOrganization,
			startDate: new Date(reservation.startDate),
			endDate: new Date(reservation.endDate),
			startTime: new Date(reservation.startDate),
			endTime: new Date(reservation.endDate),
			groupId: reservation.group,
			categoryId: reservation.category,
			carId: reservation.car,
			statusId: reservation.status,
			securityDeposit: String(reservation.securityDeposit),
			paymentMethodId: reservation.paymentMethod,
			chargeOvertime: reservation.chargeOvertime,
			campaignId: reservation.campaign,
			note: reservation.notes || values.note,
			optionals: reservation.optionals,
			dossier: reservation.dossier,
			carChanged: false,
			fuelLevel: 0,
			mileage: 0,
			sellerUser: {} as User,
		};
		reservationApi.updateReservationById(id ?? '', dataToUpload)
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(-1);
				getData();
			})
			.catch(({ response }) => addDialog({
				title: response?.data?.title || '',
				message: response?.data?.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	return (
		<ChangeReservationPage
			initialValues={initialValues}
			users={users}
			companies={companies}
			handleSubmit={handleSubmit}
			getDropdown={getDropdown}
			name={userName}
			reservation={reservation}
		/>
	);
};

export default ChangeReservation;
