import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { discountApi } from '@Requests';
import { DiscountBillable } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DiscountRequestPage from './DiscountRequestPage';

const DiscountRequest = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();

	const discountRequests = useRequest(([params]) => discountApi.getDiscountsByReservation('', '', params));
	const approveDiscountRequest = useRequest(([params]) => discountApi.approveDiscount(params));
	const rejectDiscountRequest = useRequest(([params]) => discountApi.rejectDiscount(params));

	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const [search, setSearch] = useState('');

	useEffect(() => {
		setTitle(`${t('discount')} ${t('requested')}`);
	}, [setTitle, t]);

	useEffectCustom(() => {
		const listDataControl = {
			page: pagination.page,
			size: pagination.size,
			search,
		};
		discountRequests.execute(listDataControl);
	}, [pagination, search]);

	const changeStatusLocally = (mData: DiscountBillable) => {
		const data = mData;
		discountRequests.setData((mPrev) => {
			const prev = mPrev;
			const index = prev?.data.findIndex((el) => el.discountRequestId === data?.discountRequestId);
			if (prev && index !== undefined && data) {
				prev.data[index] = data;
			}
			return prev;
		});
	};

	useEffectCustom(() => {
		if (approveDiscountRequest.data) {
			changeStatusLocally(approveDiscountRequest.data);
		}
	}, [approveDiscountRequest.data]);

	useEffectCustom(() => {
		if (rejectDiscountRequest.data) {
			changeStatusLocally(rejectDiscountRequest.data);
		}
	}, [rejectDiscountRequest.data]);

	const approveDiscount = (item: DiscountBillable) => {
		approveDiscountRequest.execute(item.discountRequestId);
	};

	const rejectDiscount = async (item: DiscountBillable) => {
		rejectDiscountRequest.execute(item.discountRequestId);
	};

	const handleListAction = (action: string, item: DiscountBillable) => {
		switch (action) {
		case t('approve'):
			approveDiscount(item);
			break;
		case t('reject'):
			rejectDiscount(item);
			break;
		default:
			break;
		}
	};

	return (
		<DiscountRequestPage
			discountRequests={discountRequests.data}
			handleListAction={handleListAction}
			setPagination={setPagination}
			setSearch={setSearch}
		/>
	);
};

export default DiscountRequest;
