import { DefaultButton, Space } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { ChatBubbleRounded, CreditCard, Lock } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EditUserButtonProps {
	handleButtonClick: (action: string) => void;
	loading: boolean
}

const EditUserButtons: FC<EditUserButtonProps> = (props) => {
	const { handleButtonClick, loading } = props;
	const { t } = useTranslation('translations');
	return (
		<>
			<PaperContainer>
				<Stack direction="row">
					<DefaultButton
						title={t('resetPassword')}
						startIcon={<Lock />}
						loading={loading}
						onClick={() => handleButtonClick(t('resetPassword'))}
					/>
					<DefaultButton
						title={t('creditCards')}
						sx={{ ml: 1 }}
						startIcon={<CreditCard />}
						onClick={() => handleButtonClick(t('creditCards'))}
					/>
					<DefaultButton
						title={t('crm')}
						sx={{ ml: 1 }}
						startIcon={<ChatBubbleRounded />}
						onClick={() => handleButtonClick(t('crm'))}
					/>
				</Stack>
			</PaperContainer>
			<Space height={3} />
		</>
	);
};

export default EditUserButtons;
