import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { cardApi, userApi } from '@Requests';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PaymentCardPage from './PaymentCardPage';

const PaymentCard = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { clientId, typeId } = useParams();
	const { addToast, addDialog } = useFeedback();
	const [name, setName] = useState('');

	const userCards = useRequest(([params]) => cardApi.getPaymentCards(params));

	useEffect(() => {
		setTitle(t('paymentCards'));
	}, [setTitle, t]);

	useEffectCustom(async () => {
		if (clientId && typeId) {
			const types = { clientId, typeId };
			userCards.execute(types);
			if (typeId === '1') {
				const user = await userApi.getUserById(clientId);
				setName(user.data.name);
			}
		}
	}, [clientId, typeId]);

	const getData = () => {
		const types = { clientId, typeId };
		userCards.execute(types);
	};

	const deleteCard = (cardId: string) => {
		setGlobalLoading(true);
		const cardToDelete = {
			cardId,
			clientId,
			clientType: typeId,
		};
		cardApi.deletePaymentCards(cardToDelete)
			.then((res) => {
				addToast({ message: res.message, error: false });
				userCards.setData((prev) => {
					const mPrev = prev;
					const index = mPrev?.methods.findIndex((el) => el.id === cardId);
					if (index !== undefined && mPrev) {
						mPrev?.methods.splice(index, 1);
					}
					return mPrev;
				});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	return (
		<PaymentCardPage
			cards={userCards.data}
			name={name}
			deleteCard={deleteCard}
			refreshCard={getData}
		/>
	);
};

export default PaymentCard;
