/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ApiResponse, ApiResponseBase } from '@Types';
import { AxiosError } from 'axios';
import { useCache, useTitleBar } from '@Providers';

// const TAG = 'useRequest';

type Options = {
  cache?: boolean;
  cacheKey?: string;
}

const defaultOptions = {
	cache: false,
};

const getCacheKey = (key: string, ...params:any): string => key.format(params);

export const useRequest = <T>(request: (...params: any) =>
Promise<ApiResponse<T>>,
	options?: Options,
) => {
	const mOptions = { ...defaultOptions, ...options };
	const { addToCache, getFromCache } = useCache();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<T>();
	const [info, setInfo] = useState<ApiResponseBase>();
	const [error, setError] = useState<boolean>();
	const { setGlobalLoading } = useTitleBar();

	/**
    * Execute Api Request
    */

	const execute = async (...params: any) => {
		setGlobalLoading(true);
		setLoading(true);
		const enableCache = !!mOptions?.cache;
		const cacheKey = getCacheKey(mOptions?.cacheKey || '', params);
		if (enableCache) {
			if (!options?.cacheKey) { // if is undefined, null or empty
				throw new Error('Cache is enabled but Cache Key has not been set');
			}
			const cache = getFromCache(cacheKey);
			if (cache) {
				const resp = cache as ApiResponse<T>;
				setData(resp.data);
				setInfo(resp as ApiResponseBase);
				setError(!!resp.errorType);
				setLoading(false);
				return !!resp.errorType;
			}
		}
		try {
			const response = await request(params);
			if (enableCache) {
				addToCache(cacheKey, response);
			}
			setData(response.data);
			setInfo(response as ApiResponseBase);
			setError(!!response.errorType);
			return !!response.errorType;
		} catch (err) {
			const axiosResponse = err as AxiosError;
			const resp = axiosResponse.response?.data as ApiResponseBase;
			setInfo({
				message: resp.message, title: resp.title || '', errorType: resp.errorType, statusCode: axiosResponse.response?.status,
			});
			setError(true);
			return true;
		} finally {
			setLoading(false);
			setGlobalLoading(false);
		}
	};

	return {
		execute,
		loading,
		info,
		data,
		error,
		setData,
	};
};
