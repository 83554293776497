import LocalStorageWrapper from '@LocalStorage';
import { Box, useTheme } from '@mui/material';
import { useAuth } from '@Providers';
import { SideBar } from '@Organisms';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { withSplash } from '@Hocs';
import { ErrorBoundary } from '@Error';

const Main = () => {
	const { setCurrentUser } = useAuth();
	const theme = useTheme();

	const logoutUser = () => {
		LocalStorageWrapper.clear();
		setCurrentUser(null);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<SideBar logoutUser={logoutUser} />
			<Box component="main" sx={{ flexGrow: 1, marginTop: 8 }}>
				<Box sx={{ minHeight: '93vh', p: theme.spacing(3) }}>
					<ErrorBoundary>
						<Outlet />
					</ErrorBoundary>
				</Box>
				{/* <Footer /> */}
			</Box>
		</Box>
	);
};

export default withSplash(Main);
