import { replaceComma } from '@Masks';
import {
	ApiResponse, Category, CategoryReq, ListDataControl, Pagination,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './upload.service';

export const getCategories = async (params: ListDataControl, tariffId?: number, StatusId?: number):
   Promise<ApiResponse<Pagination<Category[]>>> => {
	const resp = await reqGet({
		url: Endpoint.CATEGORIES.format(params.page, params.size, params.search, tariffId || '', StatusId || ''),
	});
	const data: ApiResponse<Pagination<Category[]>> = await resp.data;
	return data;
};

export const saveCategory = async (category: CategoryReq): Promise<ApiResponse<void>> => {
	const { coverLink, imageLink } = category;
	let coverImageLink = coverLink;
	let imageUploadLink = imageLink;
	if (category.image || category.coverImage) {
		const image = new FormData();
		const coverImage = new FormData();
		image.append('file', category.image);
		coverImage.append('file', category.coverImage);
		if (category.coverImage) {
			const res = await uploadFile(coverImage);
			coverImageLink = res.data.file;
		}
		if (category.image) {
			const resp = await uploadFile(image);
			imageUploadLink = resp.data.file;
		}
	}
	const dataToUpload = {
		name: category.name,
		cta: category.cta,
		pricePerDay: replaceComma(category.pricePerDay),
		pricePerHour: replaceComma(category.pricePerHour),
		image: imageUploadLink,
		coverImage: coverImageLink,
		tariffId: category.tariffId.id,
	};
	const resp = await reqPost({
		url: Endpoint.CATEGORY,
		data: dataToUpload,
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const getCategoryByID = async (id: number |string): Promise<ApiResponse<Category>> => {
	const resp = await reqGet({
		url: Endpoint.CATEGORY_BY_ID.format(id),
	});
	const data: ApiResponse<Category> = await resp.data;
	return data;
};

export const updateCategory = async (category: CategoryReq, categoryId: string):
   Promise<ApiResponse<void>> => {
	const { coverLink, imageLink } = category;
	let imageUploadLink = imageLink;
	let coverImageLink = coverLink;
	if (category.image.size || category.coverImage.size) {
		const image = new FormData();
		const coverImage = new FormData();
		image.append('file', category.image);
		coverImage.append('file', category.coverImage);
		if (category.coverImage.size) {
			const res = await uploadFile(coverImage);
			coverImageLink = res.data.file;
		}
		if (category.image.size) {
			const resp = await uploadFile(image);
			imageUploadLink = resp.data.file;
		}
	}
	const dataToUpload = {
		categoryId,
		name: category.name,
		cta: category.cta,
		pricePerDay: replaceComma(category.pricePerDay),
		pricePerHour: replaceComma(category.pricePerHour),
		image: imageUploadLink,
		coverImage: coverImageLink,
		tariffId: category.tariffId.id,
	};
	const resp = await reqPost({
		url: Endpoint.CATEGORY_BY_ID.format(categoryId),
		data: dataToUpload,
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const updateCategoryStatus = async (id: number): Promise<ApiResponse<Category>> => {
	const resp = await reqPost({
		url: Endpoint.CATEGORY_STATUS.format(id),
	});

	if (resp.status !== 200) {
		throw new Error('Something went wrong');
	}
	const data: ApiResponse<Category> = await resp.data;
	return data;
};
