import {
	Autocomplete, DatePicker, DefaultButton,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TollReportReq } from '@Types';

interface TollBookingProps {
	initialValues: TollReportReq;
	validate: (values: TollReportReq) => FormikErrors<TollReportReq>;
	handleSubmit: (values: TollReportReq, formikHelpers: FormikHelpers<TollReportReq>) => void
}

const dropdownOptions = [
	{ name: 'Regular (Reserva)', id: 1 },
	{ name: 'Guia de transporte', id: 2 },
];

const TollBookingForm: FC<TollBookingProps> = (props) => {
	const { initialValues, handleSubmit, validate } = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values, setFieldValue, isSubmitting, errors,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											placeholder=""
											label={t('type')}
											multiple
											error={!!errors.types}
											helperText={String(errors.types || '')}
											options={dropdownOptions}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name}
											onChange={(_, item) => {
												setFieldValue('types', item);
											}}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<DatePicker
											label={t('startDate')}
											value={values.startDate}
											helperText={String(errors.startDate || '')}
											error={!!errors.startDate}
											disabled={isSubmitting}
											onChange={(value) => {
												setFieldValue('startDate', value);
											}}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<DatePicker
											label={t('endDate')}
											value={values.endDate}
											helperText={String(errors.endDate || '')}
											error={!!errors.endDate}
											disabled={isSubmitting}
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
										<DefaultButton title={t('search')} type="submit" loading={isSubmitting} />
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default TollBookingForm;
