/* eslint-disable no-param-reassign */
import React from 'react';
import { noImage } from '@Images';
import {
	Box, CircularProgress, Stack, useTheme,
} from '@mui/material';

export interface ImageProps {
   src: string;
   height?: number | string;
   width?: number | string;
   alt: string;
   fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
   style?: React.CSSProperties;
   fallbackImage?: string
}

const Image = (props: ImageProps) => {
	const {
		src, height, width, alt, fit, style, fallbackImage,
	} = props;
	const [loading, setLoading] = React.useState(true);
	const theme = useTheme();
	const [imagePlaceholder, setImagePlaceholder] = React.useState('');

	React.useEffect(() => {
		if (fallbackImage) {
			setImagePlaceholder(fallbackImage);
		} else {
			setImagePlaceholder(noImage);
		}
	}, [fallbackImage]);

	const onLoad = () => {
		setLoading(false);
	};
	const onLoadStart = () => {
		setLoading(true);
	};

	return (
		<Box sx={{ height, width, border: `1px solid ${theme.palette.divider}` }}>
			{loading && (
				<Stack
					direction="column"
					alignItems="center"
					justifyContent="center"
					sx={{
						position: 'absolute',
						width,
						height,
						zIndex: 100,
					}}
				>
					<CircularProgress color="info" size={20} thickness={2.5} />
				</Stack>
			)}
			<img
				src={src}
				height={height}
				width={width}
				alt={alt}
				style={{ objectFit: fit ?? 'fill', ...style }}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null;
					currentTarget.src = imagePlaceholder ?? '';
				}}
				onLoad={onLoad}
				onLoadStart={onLoadStart}
			/>
		</Box>

	);
};

export default Image;
