import {
	Autocomplete, CheckBox, DatePicker, DefaultButton, Description, Space, TextField, Title,
} from '@Atoms';
import { FontSizes } from '@Constant';
import { carRegisteration, currency } from '@Masks';
import { PaperContainer } from '@Molecules';
import { AddCircle } from '@mui/icons-material';
import {
	Card,
	CardActions,
	CardMedia,
	Divider,
	Grid,
	Slider,
	Typography,
} from '@mui/material';
import { useDropdown } from '@Providers';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { camera } from '@Images';
import { CarReq } from '@Types';

type TWarning = {
	vehicle: string;
	contract: string
}

interface CreateVehicleProps {
	initialValues: CarReq;
	handleSubmit: (values: CarReq, formikHelpers: FormikHelpers<CarReq>) => void;
	validate: (values: CarReq) => FormikErrors<CarReq>;
	warning: TWarning
}

const TITLE_TEXT_SX = { fontSize: FontSizes.LG, fontWeight: 'bold' };
const TODAY = new Date();

const CreateVehiclePage: FC<CreateVehicleProps> = (props) => {
	const {
		initialValues, handleSubmit, validate, warning,
	} = props;
	const { t } = useTranslation('translations');
	const {
		brands,
		colors,
		vehicleCategories,
		counters,
		companies,
		fleets,
		fuels,
		displacements,
		carType,
	} = useDropdown();

	const coverImageRef = useRef<HTMLInputElement | null>(null);
	const inputMiniatureFile = useRef<HTMLInputElement | null>(null);

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						enableReinitialize
						validate={validate}
						validateOnChange={false}
					>
						{({
							setFieldValue,
							errors,
							values,
							handleChange,
							isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<input
										type="file"
										id="coverImage"
										ref={coverImageRef}
										onChange={(event) => {
											if (event?.target?.files) {
												setFieldValue('coverImage', event.target?.files[0]);
												setFieldValue('coverImageLink', URL.createObjectURL(event.target?.files[0]));
											}
										}}
										style={{ display: 'none' }}
									/>
									<input
										type="file"
										id="image"
										ref={inputMiniatureFile}
										onChange={(event) => {
											if (event?.target?.files) {
												setFieldValue('image', event.target?.files[0]);
												setFieldValue('imageLink', URL.createObjectURL(event.target?.files[0]));
											}
										}}
										style={{ display: 'none' }}
									/>
									<Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
										<Grid container spacing={4}>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													options={brands || []}
													defaultValue={values.brandId}
													placeholder=""
													error={Boolean(errors.brandId)}
													helperText={String(errors.brandId || '')}
													isOptionEqualToValue={(option, value) => option.id === value.id}
													disabled={isSubmitting}
													label={t('brand')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													getOptionLabel={(item) => item.name}
													onChange={(_, item) => setFieldValue('brandId', item)}
												/>
											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TextField
													label={t('model')}
													title=""
													disabled={isSubmitting}
													id="name"
													type="text"
													value={values.name}
													error={Boolean(errors.name)}
													helperText={errors.name}
													onChange={handleChange}
												/>
											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TextField
													label={t('shortName')}
													title=""
													disabled={isSubmitting}
													id="shortName"
													type="text"
													value={values.shortName}
													error={Boolean(errors.shortName)}
													helperText={errors.shortName}
													onChange={handleChange}
												/>
											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={4}>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

												<Autocomplete
													label={t('carType')}
													options={carType || []}
													defaultValue={values.carTypeId}
													placeholder=""
													disabled={isSubmitting}
													isOptionEqualToValue={(option, value) => option.id === value.id}
													error={Boolean(errors.carTypeId)}
													helperText={String(errors.carTypeId || '')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													getOptionLabel={(item) => item.name || ''}
													onChange={(_, item) => setFieldValue('carTypeId', item)}
												/>

											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('color')}
													options={colors || []}
													defaultValue={values.colorId}
													placeholder=""
													disabled={isSubmitting}
													isOptionEqualToValue={(option, value) => option.id === value.id}
													error={Boolean(errors.colorId)}
													helperText={String(errors.colorId || '')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.value}
														</li>
													)}
													getOptionLabel={(item) => item.value}
													onChange={(_, item) => setFieldValue('colorId', item)}
												/>

											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('category')}
													options={vehicleCategories || []}
													multiple
													disabled={isSubmitting}
													placeholder=""
													isOptionEqualToValue={(option, value) => option.id === value.id}
													error={Boolean(errors.categories)}
													helperText={String(errors.categories || '')}
													defaultValue={values.categories}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													getOptionLabel={(item) => item.name}
													onChange={(_, item) => {
														setFieldValue('categories', item);
													}}
												/>

											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={4}>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('currentLocation')}
													options={counters || []}
													defaultValue={values.officeId}
													disabled={isSubmitting}
													placeholder=""
													isOptionEqualToValue={(option, value) => option.id === value.id}
													error={Boolean(errors.officeId)}
													helperText={String(errors.officeId || '')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.officialName}
														</li>
													)}
													getOptionLabel={(item) => item.officialName}
													onChange={(_, item) => setFieldValue('officeId', item)}
												/>

											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('provider')}
													options={companies || []}
													defaultValue={values.supplierId}
													disabled={isSubmitting}
													placeholder=""
													isOptionEqualToValue={(option, value) => option.id === value.id}
													error={Boolean(errors.supplierId)}
													helperText={String(errors.supplierId || '')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.officialName}
														</li>
													)}
													getOptionLabel={(item) => item.officialName || ''}
													onChange={(_, item) => setFieldValue('supplierId', item)}
												/>

											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TextField
													label={t('purchasePrice')}
													disabled={isSubmitting}
													id="purchasePrice"
													type="text"
													value={values.purchasePrice}
													error={Boolean(errors.purchasePrice)}
													helperText={errors.purchasePrice}
													onChange={(value) => {
														setFieldValue('purchasePrice', currency(value).target.value);
													}}
												/>

											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={4}>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('fleetType')}
													options={fleets || []}
													placeholder=""
													isOptionEqualToValue={(option, value) => option.id === value.id}
													disabled={isSubmitting}
													error={Boolean(errors.fleetId)}
													helperText={String(errors.fleetId || '')}
													defaultValue={values.fleetId}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													getOptionLabel={(item) => item.name}
													onChange={(_, item) => setFieldValue('fleetId', item)}
												/>

											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('fuel')}
													options={fuels || []}
													placeholder=""
													isOptionEqualToValue={(option, value) => option.id === value.id}
													disabled={isSubmitting}
													error={Boolean(errors.fuelTypeId)}
													helperText={String(errors.fuelTypeId || '')}
													defaultValue={values.fuelTypeId}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													getOptionLabel={(item) => item.name}
													onChange={(_, item) => setFieldValue('fuelTypeId', item)}
												/>

											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TextField
													label={t('licensePlate')}
													disabled={isSubmitting}
													id="licensePlate"
													type="text"
													value={values.licensePlate}
													error={Boolean(errors.licensePlate)}
													helperText={errors.licensePlate}
													onChange={(event) => {
														setFieldValue('licensePlate', carRegisteration(event).target.value); // to
													}}
												/>

											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={4}>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<DatePicker
													label={t('licenseRegistration')}
													value={values.licenseDate}
													maxDate={TODAY}
													disabled={isSubmitting}
													error={Boolean(errors.licenseDate)}
													helperText={String(errors.licenseDate || '')}
													onChange={(value) => setFieldValue('licenseDate', value)}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<TextField
													label={t('chassis')}
													disabled={isSubmitting}
													id="chassis"
													value={values.chassis}
													error={Boolean(errors.chassis)}
													helperText={errors.chassis}
													type="text"
													onChange={handleChange}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<TextField
													label={t('mileage')}
													disabled={isSubmitting}
													id="mileage"
													value={values.mileage}
													error={Boolean(errors.mileage)}
													helperText={errors.mileage}
													type="number"
													onChange={(event) => {
														if (parseInt(event.target.value, 10) >= 0) {
															setFieldValue('mileage', event.target.value);
														}
													}}
												/>
											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={4}>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<TextField
													label={t('year')}
													disabled={isSubmitting}
													id="year"
													value={values.year}
													error={Boolean(errors.year)}
													helperText={errors.year}
													inputProps={{ maxLength: 4 }}
													type="number"
													onChange={(e) => {
														e.target.value = Math.max(0, parseInt(e.target.value, 10))
															.toString().slice(0, 4);
														setFieldValue('year', e.target.value);
													}}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<Autocomplete
													options={displacements}
													placeholder=""
													defaultValue={displacements.find((el) => (
														el.value === values.cubicCapacity))}
													disabled={isSubmitting}
													error={Boolean(errors.cubicCapacity)}
													helperText={String(errors.cubicCapacity || '')}
													label={t('displacement')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													getOptionLabel={(item) => item.name}
													onChange={(_, item) => setFieldValue('cubicCapacity', item?.value)}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<TextField
													label={`${t('firstReveiw')} (km)`}
													disabled={isSubmitting}
													id="firstInspection"
													value={values.firstInspection}
													error={Boolean(errors.firstInspection)}
													helperText={errors.firstInspection}
													InputLabelProps={{ shrink: true }}
													type="number"
													onChange={(event) => {
														if (parseInt(event.target.value, 10) >= 0) {
															setFieldValue('firstInspection', event.target.value);
														}
													}}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<TextField
													label={`${t('reviewEvery')} (km)`}
													disabled={isSubmitting}
													id="inspectionFrequency"
													type="number"
													InputLabelProps={{ shrink: true }}
													value={values.inspectionFrequency}
													error={Boolean(errors.inspectionFrequency)}
													helperText={errors.inspectionFrequency}
													onChange={(event) => {
														if (parseInt(event.target.value, 10) >= 0) {
															setFieldValue('inspectionFrequency', event.target.value);
														}
													}}
												/>
											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={3}>
											<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
												<Description text={t('fuelLevel')} />
												<Slider
													aria-label="fuel-level"
													defaultValue={values.fuelLevel}
													value={values.fuelLevel}
													disabled={isSubmitting}
													valueLabelDisplay="auto"
													step={1}
													marks
													min={0}
													max={8}
													onChange={(e, value) => {
														setFieldValue('fuelLevel', value);
													}}
												/>
											</Grid>
											{values.featureInputs?.map((item, index) => (
												<Grid item xl={2} lg={2} md={2} sm={12} xs={12} key={`inputs-${item.featureId}`}>
													<TextField
														label={item.name}
														disabled={isSubmitting}
														type="number"
														value={item.value}
														helperText=""
														onChange={(event) => {
															if (parseInt(event.target.value, 10) >= 0
															&& event.target.value !== null) {
																setFieldValue(`featureInputs[${index}].value`, event.target.value);
															}
														}}
													/>
												</Grid>
											))}
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
										<Title sx={TITLE_TEXT_SX} text={t('characteristics')} />
										<div>
											<Title sx={TITLE_TEXT_SX} text={`${t('features')}:`} />
										</div>
										<Typography variant="caption" color="error">
											{warning.vehicle ?? errors.vehicleCheckboxes}
										</Typography>
										{values.vehicleCheckboxes?.map((el, index) => (
											<div key={`contract-${index.toString()}`}>
												<CheckBox
													title={el.name}
													disabled={isSubmitting}
													checked={el.value === 1}
													highlight
													highlighted={el.highlight}
													setChecked={() => {
														setFieldValue(`vehicleCheckboxes[${index}].value`, el.value === 1 ? 0 : 1);
													}}
													setHighlight={(value) => {
														setFieldValue(`vehicleCheckboxes[${index}].highlight`, value);
														if (value) {
															setFieldValue(`vehicleCheckboxes[${index}].value`, 1);
														}
													}}
												/>
											</div>
										))}
										<div>
											<Title sx={TITLE_TEXT_SX} text={t('contract')} variant="h6" />
										</div>
										<Typography color="error" variant="caption">
											{warning.contract ?? errors.contractCheckboxes}
										</Typography>
										{values.contractCheckboxes?.map((el, index) => (
											<div key={`contract-${index.toString()}`}>
												<CheckBox
													title={el.name}
													checked={el.value === 1}
													disabled={isSubmitting}
													highlight
													highlighted={el.highlight}
													setChecked={() => {
														setFieldValue(`contractCheckboxes[${index}].value`, el.value === 1 ? 0 : 1);
													}}
													setHighlight={(value) => {
														setFieldValue(`contractCheckboxes[${index}].highlight`, value);
														if (value) {
															setFieldValue(`contractCheckboxes[${index}].value`, 1);
														}
													}}
												/>
											</div>
										))}
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={3} sm={6} xs={12}>
										<Card sx={{ maxWidth: '100%' }}>
											<CardMedia
												sx={{
													height: 0,
													paddingTop: '70.25%',
												}}
												image={values.coverImageLink || camera}
												title="Upload Picture"
											/>
											<CardActions disableSpacing>
												<DefaultButton
													title={t('coverPicture')}
													disabled={isSubmitting}
													onClick={() => {
														coverImageRef.current?.click();
													}}
													startIcon={<AddCircle />}
												/>
											</CardActions>
										</Card>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<Card sx={{ maxWidth: '100%' }}>
											<CardMedia
												sx={{
													height: 0,
													paddingTop: '70.25%',
												}}
												image={values.imageLink || camera}
												title="Upload Picture"
											/>
											<CardActions disableSpacing>
												<DefaultButton
													title={t('miniature')}
													disabled={isSubmitting}
													onClick={() => {
														inputMiniatureFile.current?.click();
													}}
													startIcon={<AddCircle />}
												/>
											</CardActions>
										</Card>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<DefaultButton title={t('save')} type="submit" loading={isSubmitting} />
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateVehiclePage;
