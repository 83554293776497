import { FontSizes } from '@Constant';
import { DirectionsCar } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TransferTariffItem = () => {
	const { t } = useTranslation('translations');
	return (
		<Stack direction="row" alignItems="center">
			<DirectionsCar color="primary" sx={{ fontSize: FontSizes.SM }} />
			<Typography color="primary" sx={{ fontSize: FontSizes.SM }}>
				€
				{' '}
				{t('transfer')}
			</Typography>
		</Stack>
	);
};

export default TransferTariffItem;
