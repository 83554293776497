import { Autocomplete, Space } from '@Atoms';
import { Typography } from '@mui/material';
import { useDropdown } from '@Providers';
import { Organization, User } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LinkUserItemProps {
	user: User | null;
	onChange: (counter: Organization[]) => void;
	selectedCounterError: string;
	selectedCounters: Organization[]
}

const LinkUserItem: FC<LinkUserItemProps> = (props) => {
	const {
		user, onChange, selectedCounterError, selectedCounters,
	} = props;
	const { counters } = useDropdown();
	const { t } = useTranslation('translations');
	return (
		<>
			<Typography>{t('linkToCounterDescription').format(user?.name)}</Typography>
			<Space height={1} />
			<Autocomplete
				label={t('counters')}
				placeholder=""
				multiple
				defaultValue={selectedCounters}
				options={counters || []}
				error={!!selectedCounterError}
				helperText={selectedCounterError}
				getOptionLabel={(item) => `${item.fantasyName || ''} - ${item.address || ''}`}
				renderOption={(inputprops, item) => (
					<li {...inputprops} key={item.id}>
						{item.fantasyName}
						{' '}
						-
						{' '}
						{item.address}
					</li>
				)}
				onChange={(_, item) => onChange(item)}
			/>
		</>
	);
};

export default LinkUserItem;
