import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Card, CardActions, CardMedia, Divider, Grid,
} from '@mui/material';
import {
	Formik, Form, FormikHelpers, FormikErrors,
} from 'formik';
import { CategoryReq } from '@Types';
import {
	Autocomplete,
	DefaultButton, Space, TextField,
} from '@Atoms';
import { AddCircle, Edit } from '@mui/icons-material';
import { currency } from '@Masks';
import { useDropdown } from '@Providers';
import { PaperContainer } from '../../../molecules';

interface CreateCategoryProps {
   initialValues: CategoryReq;
   validate: (values: CategoryReq) => FormikErrors<CategoryReq>;
   handleSubmit: (values: CategoryReq, formikHelpers: FormikHelpers<CategoryReq>) => void;
   isEdit: boolean;
   handleEditClick: () => void
}
const CreateCategoryPage:React.FC<CreateCategoryProps> = (props) => {
	const {
		initialValues, validate, handleSubmit, isEdit, handleEditClick,
	} = props;
	const { t } = useTranslation('translations');
	const { tariffs } = useDropdown();

	const inputCoverFile = useRef<HTMLInputElement | null>(null);
	const inputMiniatureFile = useRef<HTMLInputElement | null>(null);

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							setFieldValue,
							values,
							touched,
							errors,
							handleChange,
							isSubmitting,
						}) => (
							<Form>
								<input
									type="file"
									id="coverImage"
									ref={inputCoverFile}
									onChange={(event) => {
										if (event?.target?.files) {
											setFieldValue('coverImage', event?.target?.files[0]);
											setFieldValue('coverLink', URL.createObjectURL(event.target.files[0]));
										}
									}}
									style={{ display: 'none' }}
								/>
								<input
									type="file"
									id="image"
									ref={inputMiniatureFile}
									onChange={(event) => {
										if (event?.target?.files) {
											setFieldValue('image', event.target.files[0]);
											setFieldValue('imageLink', URL.createObjectURL(event.target.files[0]));
										}
									}}
									style={{ display: 'none' }}
								/>
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
										<TextField
											id="name"
											label={t('name')}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.name}
											onChange={handleChange}
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
										<TextField
											id="cta"
											label="CTA(Call to Action)"
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.cta}
											onChange={handleChange}
											error={touched.cta && Boolean(errors.cta)}
											helperText={touched.cta && errors.cta}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
										<Autocomplete
											label={t('teriff')}
											placeholder=""
											options={tariffs || []}
											defaultValue={values.tariffId}
											disabled={isSubmitting || !isEdit}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											error={touched.tariffId && Boolean(errors.tariffId)}
											helperText={touched.tariffId && String(errors.tariffId || '')}
											onChange={(_, item) => {
												setFieldValue('tariffId', item);
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<TextField
											id="pricePerDay"
											label={`${t('dailyPrice')} (€)`}
											disabled={isSubmitting || !isEdit}
											type="text"
											inputProps={{ maxLength: 6 }}
											value={values.pricePerDay}
											onChange={(event) => {
												setFieldValue('pricePerDay', currency(event).target.value);
											}}
											error={touched.pricePerDay && Boolean(errors.pricePerDay)}
											helperText={touched.pricePerDay && errors.pricePerDay}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<TextField
											id="pricePerHour"
											label={`${t('hourlyPrice')} (€)`}
											disabled={isSubmitting || !isEdit}
											type="text"
											inputProps={{ maxLength: 11 }}
											value={values.pricePerHour}
											onChange={(event) => {
												setFieldValue('pricePerHour', currency(event).target.value);
											}}
											error={touched.pricePerHour && Boolean(errors.pricePerHour)}
											helperText={touched.pricePerHour && errors.pricePerHour}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={3} sm={6} xs={12}>
										<Card sx={{ maxWidth: '100%' }}>
											<CardMedia
												sx={{
													height: 0,
													paddingTop: '70.25%',
												}}
												image={values.coverLink || 'https://test.ajrent.pt/ws/box/image-placeholder.png'}
												title="Upload Picture"
											/>
											<CardActions disableSpacing>
												<DefaultButton
													title={t('coverPicture')}
													disabled={isSubmitting || !isEdit}
													onClick={() => {
														inputCoverFile.current?.click();
													}}
													startIcon={<AddCircle />}
												/>
											</CardActions>
										</Card>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<Card sx={{ maxWidth: '100%' }}>
											<CardMedia
												sx={{
													height: 0,
													paddingTop: '70.25%',
												}}
												image={values.imageLink || 'https://test.ajrent.pt/ws/box/image-placeholder.png'}
												title="Upload Picture"
											/>
											<CardActions disableSpacing>
												<DefaultButton
													title={t('miniature')}
													disabled={isSubmitting || !isEdit}
													onClick={() => {
														inputMiniatureFile.current?.click();
													}}
													startIcon={<AddCircle />}
												/>
											</CardActions>
										</Card>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && <DefaultButton loading={isSubmitting} title={t('save')} type="submit" />}
								{!isEdit && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} />}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateCategoryPage;
