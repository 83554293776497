import { DefaultButton, Space } from '@Atoms';
import { formatCurrency } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { ArrowBack, Cancel } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { PdfViewer } from '@Organisms';
import { InvoiceCostumer } from '@Types';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const INVOICE_TYPE_RECEIPT = 2;

interface InvoiceProps {
	handleListAction: (action: string) => void;
	invoice?: InvoiceCostumer;
	showCancel: boolean;
	creditNoteComponent: ReactNode;
	emailComponent: ReactNode;
	receiptComponent: ReactNode
	pendingPayment: number
}

const InvoicePage: FC<InvoiceProps> = (props) => {
	const {
		handleListAction,
		invoice,
		showCancel,
		creditNoteComponent,
		emailComponent,
		receiptComponent,
		pendingPayment,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<DefaultButton
					title={t('backButton')}
					startIcon={<ArrowBack />}
					onClick={() => {
						handleListAction(t('backButton'));
					}}
				/>
				<DefaultButton
					title={t('reservation')}
					sx={{ ml: 1 }}
					onClick={() => {
						handleListAction(t('reservation'));
					}}
				/>
				{showCancel && (
					<DefaultButton
						startIcon={<Cancel />}
						title={t('cancelInvoice')}
						sx={{ ml: 1 }}
						onClick={() => {
							handleListAction(t('cancelInvoice'));
						}}
						color="error"
					/>
				)}
			</Grid>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Grid container spacing={3}>
						<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
							<Box sx={{ height: '100%' }}>
								{invoice?.invoice?.pdfFileBase64 && (
									<PdfViewer source={invoice?.invoice?.pdfFileBase64} />)}
							</Box>
						</Grid>
						<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
							{receiptComponent}
							<Space height={2} />
							{creditNoteComponent}
							{(pendingPayment > 0 && invoice?.invoice?.type?.id === INVOICE_TYPE_RECEIPT) && (
								<Typography variant="h5" fontWeight="lighter" color="error">
									{t('pendingPayment')}
									{formatCurrency(pendingPayment)}
									{' '}
									€
								</Typography>
							)}
							{pendingPayment <= 0 && <Typography variant="h5" fontWeight="lighter" color="skyblue">{t('noPending')}</Typography>}
							<Space height={2} />
							{emailComponent}
						</Grid>
					</Grid>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default InvoicePage;
