/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const rootEl = document.getElementById('root');

function render() {
	ReactDOM.render(<App />, rootEl);
}

if (module.hot) {
	module.hot.accept('./App', () => {
		setTimeout(render);
	});
}

render();
