import { formatCurrency, normalizeCurrency } from '@Helpers';
import { useFeedback, useTitleBar } from '@Providers';
import { categoryApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { CategoryReq, Tariff } from '@Types';
import { FormikHelpers, FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateCategoryPage from './CreateCategoryPage';

const CreateCategory = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({
		name: '',
		cta: '',
		pricePerDay: '',
		pricePerHour: '',
		tariffId: {} as Tariff,
		image: {} as File,
		coverImage: {} as File,
		imageLink: '',
		coverLink: '',
	});
	const [isEdit, setEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('categor')} ${t('detail')}`);
		} else {
			setEdit(true);
			setTitle(`${t('new')} ${t('categor')}`);
		}
	}, [setTitle, t, id]);

	useEffect(() => {
		if (id) {
			categoryApi.getCategoryByID(id)
				.then(({ data }) => setInitialValues({
					name: data.name,
					cta: data.cta,
					pricePerDay: formatCurrency(data.pricePerDay),
					pricePerHour: formatCurrency(data.pricePerHour),
					tariffId: data.tariff,
					image: {} as File,
					coverImage: {} as File,
					imageLink: data.image,
					coverLink: data.coverImage,
				}));
		}
	}, [id]);

	const validate = (values: CategoryReq) => {
		const errors = {} as FormikErrors<CategoryReq>;
		const pricePerDay = parseFloat(normalizeCurrency(values.pricePerDay));
		const pricePerHour = parseFloat(normalizeCurrency(values.pricePerHour));
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.cta) {
			errors.cta = t('emptyField');
		}
		if (!pricePerDay) {
			errors.pricePerDay = t('emptyField');
		}
		if (!pricePerHour) {
			errors.pricePerHour = t('emptyField');
		}
		if (!values.tariffId) {
			errors.tariffId = t('emptyField');
		}
		if (values.tariffId && Object.keys(values.tariffId).length === 0) {
			errors.tariffId = t('emptyField');
		}

		return errors;
	};

	const handleSubmit = (data: CategoryReq, formikHelpers: FormikHelpers<CategoryReq>) => {
		if (id) {
			categoryApi.updateCategory(data, id ?? '')
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_CATEGORIES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			categoryApi.saveCategory(data)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_CATEGORIES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setEdit(true);
		setTitle(`${t('edit')} ${t('categor')}`);
	};

	return (
		<CreateCategoryPage
			initialValues={initialValues}
			validate={validate}
			handleSubmit={handleSubmit}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
		/>
	);
};

export default CreateCategory;
