import React, { FC } from 'react';
import { Status } from '@Types';
import { useTheme } from '@mui/material';
import { statusStyles } from './StatusLabel.styles';

interface StatusProps {
   status: Status | undefined;
   width?: string;
   justify?: string
}

const StatusLabel: FC<StatusProps> = ({ status, width = '8rem', justify = 'flex-start' || 'flex-end' }) => {
	const theme = useTheme();
	return (
		<div style={{
			width, display: 'flex', flexDirection: 'row', justifyContent: justify,
		}}
		>
			<span style={statusStyles(theme, status?.color)}>{status?.name}</span>
		</div>
	);
};

export default StatusLabel;
