import { Space } from '@Atoms';
import { useFeedback, useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommissionAdjustmentReq, Invoice, Organization } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import { reportApi } from '@Requests';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import { getFirstDayOfMonth } from '@Helpers';
import CommissionAdjustmentForm from './CommissionAdjustmentForm';
import CommissionAdjustmentPage from './CommissionAdjustmentPage';

const CommissionAdjustment = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const [commissionAdjustments, setCommissionAdjustment] = useState([] as Invoice[]);
	const [diffTotal, setDiffTotal] = useState(0);
	const [initialValues, setInitialValues] = useState({
		startDate: getFirstDayOfMonth(),
		endDate: new Date(),
		counterId: undefined as Organization | undefined,
	});
	const [search, setSearch] = useState('');

	useEffect(() => {
		setTitle(t('diverCreditNotes'));
	}, [setTitle, t]);

	const getData = () => {
		reportApi.getCommissionAdjustmentReport(initialValues, search)
			.then((res) => {
				const temp = [] as Invoice[];
				res.data.invoices.map((el) => temp.push({ ...el, difference: el.amount - el.oldAmount }));
				setCommissionAdjustment(temp);
				setDiffTotal(res.data.diffSum);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }));
	};

	useEffectCustom(() => {
		if (initialValues.counterId) {
			getData();
		}
	}, [search]);

	const validate = (values: CommissionAdjustmentReq) => {
		const errors = {} as FormikErrors<CommissionAdjustmentReq>;
		if (!values.counterId) {
			errors.counterId = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		return errors;
	};

	const handleListAction = (action: string, id: number) => {
		switch (action) {
		case t('invoice'):
			navigate(AppRoutes.RECEIPT.formatMap({ id }));
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
			break;
		default:
			break;
		}
	};

	const handleSubmit = (
		values: CommissionAdjustmentReq,
		formikHelpers: FormikHelpers<CommissionAdjustmentReq>,
	) => {
		setInitialValues(values);
		reportApi
			.getCommissionAdjustmentReport(values, '')
			.then((res) => {
				const temp = [] as Invoice[];
				res.data.invoices.map((el) => temp.push({ ...el, difference: el.amount - el.oldAmount }));
				setCommissionAdjustment(temp);
				setDiffTotal(res.data.diffSum);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => {
				formikHelpers.setSubmitting(false);
			});
	};

	return (
		<>
			<CommissionAdjustmentForm
				initialValues={initialValues}
				validate={validate}
				handleSubmit={handleSubmit}
			/>
			<Space height={3} />
			<CommissionAdjustmentPage
				commissionAdjustment={commissionAdjustments}
				diffTotal={diffTotal}
				handleListAction={handleListAction}
				setSearch={setSearch}
			/>
		</>
	);
};

export default CommissionAdjustment;
