import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { discountApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Campaign } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListCampaignPage from './ListCampaignPage';

const ListCampaign = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const [search, setSearch] = useState('');

	const discountCampaigns = useRequest(([params]) => discountApi.getDiscountCampaign(params));
	const changeStatus = useRequest(([params]) => discountApi.changeCampaignStatus(params));

	useEffect(() => {
		setTitle(t('campaigns'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unmounted = false;
		if (!unmounted) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			discountCampaigns.execute(listDataControl);
		}
		return () => {
			unmounted = true;
		};
	}, [pagination, search]);

	const changeCampaignLocally = () => {
		const { data } = changeStatus;
		discountCampaigns.setData((mPrev) => {
			const prev = mPrev;
			const index = prev?.data.findIndex((el) => el.id === changeStatus.data?.id);
			if (prev && index !== undefined && data) {
				prev.data[index] = data;
			}
			return prev;
		});
	};

	useEffectCustom(() => {
		if (changeStatus.data) {
			changeCampaignLocally();
		}
	}, [changeStatus.data]);

	const handleDropdownClick = (name: string, item?: Campaign) => {
		switch (name) {
		case t('new'):
			navigate(AppRoutes.NEW_DISCOUNT_CAMPAIGN);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_DISCOUNT_CAMPAIGN.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeStatus.execute(item?.id);
			break;
		default:
			break;
		}
	};

	return (
		<ListCampaignPage
			campaigns={discountCampaigns.data}
			setPagination={setPagination}
			setSearch={setSearch}
			handleListAction={handleDropdownClick}
		/>
	);
};

export default ListCampaign;
