/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ListDataControl,
	Pagination,
	ApiResponse,
	Campaign,
	CampaignReq,
	DiscountBillable,
	DiscountItem,
	ItemRelatedToDiscount,
	NameAndId,
	DiscountItemReq,
} from '@Types';
import { replaceComma } from '@Masks';
import { encodeDate, normalizeCurrency } from '@Helpers';
import Endpoint from '../endpoints.api';
import { reqDelete, reqGet, reqPost } from '../request.api';

export async function getBillableDiscounts(type?: number, removeDaily?: boolean):
	Promise<ApiResponse<Pagination<DiscountBillable[]>>> {
	const resp = await reqGet({
		url: Endpoint.BILLABLE_ITEMS.format(type),
	});
	const data = resp.data as ApiResponse<Pagination<DiscountBillable[]>>;
	if (removeDaily) {
		const dailyIndex = data.data.data.findIndex((el) => el.id === 8);
		data.data.data.splice(dailyIndex, 1);
	}
	return data;
}

export const getListBillableItemsByCategory = async (quantity: number, categoryId: number):
Promise<ApiResponse<DiscountItem[]>> => {
	const resp = await reqGet({
		url: Endpoint.BILLABLE_DISCOUNTS_BY_CATEGORY.format(quantity, categoryId),
	});
	const { data } = resp;
	return data;
};

export async function getAllItemsOfDiscount(itemId: number | string):
   Promise<ApiResponse<ItemRelatedToDiscount<DiscountItem[]>>> {
	const resp = await reqGet({
		url: Endpoint.ITEMS_RELATED_TO_BILLABLE_ITEM.format(itemId),
	});
	const data = resp.data as ApiResponse<ItemRelatedToDiscount<DiscountItem[]>>;
	data.data.items = data.data.items.sort((a, b) => (
		b.category.id - a.category.id
	));
	data.data.items = data.data.items.sort((a, b) => (
		a.category.tariff.name.localeCompare(b.category.tariff.name)
	));
	return data;
}

export async function deleteBillableDiscount(itemId: number | string, discountId: number):
   Promise<ApiResponse<void>> {
	const resp = await reqDelete({
		url: Endpoint.DELETE_BILLABLE_ITEM.format(itemId, discountId),
	});
	const { data } = resp;
	return data;
}
export async function addNewBillableItem(itemId: string, item: DiscountItemReq):
	Promise<ApiResponse<void>> {
	const dataToUpload = {
		...item,
		categoryId: item.categoryId.id,
		itemId,
		discount: replaceComma(item.discount),
	};

	const resp = await reqPost({
		url: Endpoint.ITEMS_RELATED_TO_BILLABLE_ITEM.format(itemId),
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
}
export async function getDiscountCampaign(
	params: ListDataControl,
	categoryId?: number,
):
   Promise<ApiResponse<Pagination<Campaign[]>>> {
	const resp = await reqGet({
		url: Endpoint.CAMPAIGNS.format(params.page, params.size, params.search, categoryId || ''),
	});
	const data = resp.data as ApiResponse<Pagination<Campaign[]>>;
	return data;
}
export async function getDiscountCampaignForReservation(
	params: ListDataControl,
	categoryId?: number,
	targetDate?: Date,
):
   Promise<ApiResponse<Pagination<Campaign[]>>> {
	const encodedDate = targetDate ? encodeDate(targetDate) : '';
	const resp = await reqGet({
		url: Endpoint.CAMPAIGNS.format(params.page, params.size, params.search, categoryId || '', encodedDate),
	});
	const data = resp.data as ApiResponse<Pagination<Campaign[]>>;
	data.data.data = data.data.data.filter((el) => {
		const endDate = new Date(el.endDate);
		const today = new Date();
		if (el.status.id === 1 && endDate > today) {
			return el;
		}
	});

	return data;
}

export async function getDiscountCampaignById(id: number | string):
   Promise<ApiResponse<Campaign>> {
	const resp = await reqGet({
		url: Endpoint.CAMPAIGN_BY_ID.format(id),
	});
	const { data } = resp;
	return data;
}

export async function getDiscountsByReservation(
	reservationId?: string,
	typeId?: number | string,
	listDataControl?: ListDataControl,
):
   Promise<ApiResponse<Pagination<DiscountBillable[]>>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_DISCOUNT.format(
			reservationId,
			typeId,
			listDataControl?.page,
			listDataControl?.size,
			listDataControl?.search,
		),
	});
	const { data } = resp;
	return data;
}

export async function requestDiscount(payload: any):
	Promise<ApiResponse<void>> {
	const dataToupload: any = {
		...payload,
		amount: parseFloat(normalizeCurrency(payload.discount)),
	};
	delete dataToupload.discount;

	const resp = await reqPost({
		url: Endpoint.REQUEST_DISCOUNT,
		data: dataToupload,
	});
	const { data } = resp;
	return data;
}

export async function approveDiscount(id: number): Promise<ApiResponse<DiscountBillable>> {
	const resp = await reqPost({
		url: Endpoint.APPROVE_DISCOUNT_REQUEST.format(id),
	});
	const { data } = resp;
	return data;
}

export async function rejectDiscount(id: number): Promise<ApiResponse<DiscountBillable>> {
	const resp = await reqPost({
		url: Endpoint.REJECT_DISCOUNT_REQUEST.format(id),
	});
	const { data } = resp;
	return data;
}

export async function changeCampaignStatus(id: number): Promise<ApiResponse<Campaign>> {
	const resp = await reqPost({
		url: Endpoint.CAMPAIGN_STATUS.format(id),
	});
	const { data } = resp;
	return data;
}

export async function addNewCampaign(item: CampaignReq): Promise<ApiResponse<void>> {
	const campaignToAdd = {
		...item,
		startDate: item.startDate.toISOString(),
		// Time , moment(new Date().getTime()).format('HH:mm:ss')
		endDate: item.endDate.toISOString(), // Time , '23:59:59'
		discount: replaceComma(item.discount),
		categories: item.categories.reduce((catIds, el) => {
			catIds.push(el.id); return catIds;
		}, [] as number[]),
		isPercentage: item.isPercentage.id,
		itemId: item.itemId.id,
	};
	const resp = await reqPost({
		url: Endpoint.CAMPAGN,
		data: campaignToAdd,
	});
	const { data } = resp;
	return data;
}

export async function updateCompaign(item: CampaignReq, id: number | string):
   Promise<ApiResponse<void>> {
	const campaignToAdd = {
		...item,
		startDate: item.startDate.toISOString(),
		// Time , moment(new Date().getTime()).format('HH:mm:ss')
		endDate: item.endDate.toISOString(), // Time , '23:59:59'
		discount: replaceComma(item.discount),
		categories: item.categories.reduce((catIds, el) => {
			catIds.push(el.id); return catIds;
		}, [] as number[]),
		isPercentage: item.isPercentage.id,
		itemId: item.itemId.id,
	};

	const resp = await reqPost({
		url: Endpoint.CAMPAIGN_BY_ID.format(id),
		data: campaignToAdd,
	});
	const { data } = resp;
	return data;
}

export async function getDiscountTypes(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.DISCOUNT_TYPES,
	});

	const data: ApiResponse<NameAndId[]> = await resp.data;
	return data;
}
