import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { reservationApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Reservation } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useFeedback, useTitleBar } from '@Providers';
import ListReservationPage from './ListReservationPage';

const STARTED_RESERVATION = 2;

const ListReservation = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { carId } = useParams();
	const { addDialog } = useFeedback();

	const reservations = useRequest(([param1, param2]) => (
		reservationApi.getAllReservations(param1, param2)
	));

	const [pagination, setPagination] = useState({ size: 10, page: 0 });
	const [search, setSearch] = useState('');

	useEffect(() => {
		setTitle(t('reservations'));
	}, [t, setTitle]);

	useEffectCustom(() => {
		let unmounted = false;
		if (!unmounted) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			reservations.execute(listDataControl, carId);
		}
		return () => {
			unmounted = true;
		};
	}, [pagination, search, carId]);

	const getProof = (id?: string) => {
		setGlobalLoading(true);
		reservationApi
			.reservationProof(id)
			.then((res) => {
				const file = new Blob([res], { type: 'application/pdf' });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch((err) => {
				throw Error(err);
			})
			.finally(() => setGlobalLoading(false));
	};

	const handleDropdownClick = (action: string, item?: Reservation) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.NEW_RESERVATION);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: item?.id }));
			break;
		case t('contract'):
			navigate(AppRoutes.RESERVATION_CONTRACT.formatMap({ reservationId: item?.id }));
			break;
		case t('proof'):
			getProof(item?.id.toString());
			break;
		case t('services'):
			navigate(AppRoutes.RESERVATION_SERVICES.formatMap({ reservationId: item?.id }));
			break;
		case t('transactions'):
			// Check if reservation is started
			if (item?.status.id === STARTED_RESERVATION) {
				// Check Reservation has contract
				if (item.contractId) {
					navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: item?.id }));
				} else {
					addDialog({
						title: t('noContract'),
						message: t('noContractMessage'),
						error: true,
					});
				}
			} else {
				navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: item?.id }));
			}
			break;
		default:
			break;
		}
	};
	const getDropdownOptions = (item: Reservation): string[] => {
		if (item.status.id === 2 || item.status.id === 2) {
			return [t('edit'), t('contract'), t('services'), t('transactions')];
		}
		return [t('edit'), t('proof'), t('services'), t('transactions')];
	};
	return (
		<ListReservationPage
			reservations={reservations.data}
			getDropdownOptions={getDropdownOptions}
			handleListAction={handleDropdownClick}
			setSearch={setSearch}
			setPagination={setPagination}
		/>
	);
};

export default ListReservation;
