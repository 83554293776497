import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { DefaultButton, TableItem } from '@Atoms';
import { DiscountBillable, Pagination } from '@Types';

interface DiscountListProps {
   onEditClick: (item: DiscountBillable) => void
   billibleItems?: Pagination<DiscountBillable[]>
}

const DiscountListPage: FC<DiscountListProps> = (props) => {
	const { onEditClick, billibleItems } = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12} />
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable data={billibleItems?.data || []} search={false}>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('type')} render={(item) => item.type.name} />
						<TableColumn headerText={t('name')} render={(item) => <TableItem title={item.name} subtitle={item.description} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<DefaultButton
									startIcon={<Edit sx={{ fontSize: 13 }} />}
									title={t('edit')}
									onClick={() => {
										onEditClick(item);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default DiscountListPage;
