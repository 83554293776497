/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';
import { useAuth } from '@Providers';
import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import { useEffectCustom } from '@Hooks';
import { getFingerprint } from '@General';

interface Setting_Context_Props {
   changeCounter: (id: number) => void;
   getCounter: () => Promise<void>;
   counter: number;
   updateUIMode: () => void;
   uniqueHash: string;
   setUserFirstCounter: (id: number | null | undefined) => void
}

interface Context_Props {
	updateUIMode: () => void
}

export const SettingContext = createContext<Setting_Context_Props>({} as Setting_Context_Props);

export const SettingProvider: FC<Context_Props> = ({ children, updateUIMode }) => {
	const { user } = useAuth();
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
	const [counter, setCounter] = useState<number>(user?.counters[0].organizationId!);
	const [uniqueHash, setUniqueHash] = useState<string>('');

	const changeCounter = async (id: number) => {
		setCounter(id);
		await LocalStorageWrapper.set(LocalStorageKeys.COUNTER, id.toString());
		window.location.reload();
	};

	const setUserFirstCounter = (id: number | null | undefined) => {
		if (id) {
			setCounter(id);
		}
	};

	const getCounter = async () => {
		try {
			const tempCounter: string = await LocalStorageWrapper.get(LocalStorageKeys.COUNTER) ?? '1';
			if (tempCounter) {
				setCounter(parseInt(tempCounter, 10));
			} else {
				const userCounter = user?.counters[0].organizationId;
				if (userCounter) {
					await changeCounter(userCounter);
				}
			}
		} catch (error) {
			setCounter(1);
		}
	};

	useEffectCustom(() => {
		const mHash: string|null = LocalStorageWrapper.get(LocalStorageKeys.HASH);
		if (mHash) {
			setUniqueHash(mHash);
		} else {
			const genHash = getFingerprint();
			setUniqueHash(genHash.toString());
			LocalStorageWrapper.set(LocalStorageKeys.HASH, genHash);
		}
	}, []);

	useEffectCustom(() => {
		getCounter();
	}, [user]);

	const value = useMemo(() => ({
		counter,
		changeCounter,
		getCounter,
		updateUIMode,
		uniqueHash,
		setUserFirstCounter,
	}), [counter, updateUIMode]);

	return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>;
};

export default function useSetting() {
	return useContext(SettingContext);
}
