import { Star, StarBorder } from '@mui/icons-material';
import {
	Checkbox, Typography, Stack, useTheme, IconButton,
} from '@mui/material';
import React, { FC } from 'react';

 interface CheckboxProps {
   title: string;
   checked: boolean;
   setChecked: () => void;
   highlight?: boolean;
   setHighlight?: (value: boolean) => void;
   highlighted?: boolean;
   disabled?: boolean;
}
const CheckBox: FC<CheckboxProps> = (props) => {
	const {
		title,
		checked,
		setChecked,
		setHighlight,
		highlight,
		highlighted,
		disabled,
	} = props;
	const theme = useTheme();

	const iconSx = {
		color: theme.palette.primary.main,
		fontSize: 25,
		'&:hover': {
			cursor: 'pointer',
		},
	};

	return (
		<Stack direction="row" alignItems="center">
			<Checkbox checked={checked} onChange={setChecked} color="primary" disabled={disabled} />
			<Typography sx={{ fontSize: '13px', fontWeight: 'lighter' }}>{title}</Typography>
			{highlight && setHighlight && (

				highlighted ? (
					<IconButton sx={{ p: 0, ml: 1 }} onClick={() => setHighlight(!highlighted)}>
						<Star sx={iconSx} />
					</IconButton>
				) : (
					<IconButton sx={{ ml: 1, p: 0 }} onClick={() => setHighlight(!highlighted)}>
						<StarBorder sx={iconSx} />
					</IconButton>
				)

			)}
		</Stack>
	);
};

export default CheckBox;
