import { DefaultButton, Space } from '@Atoms';
import { FontSizes } from '@Constant';
import { formatCurrency } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Circle } from '@mui/icons-material';
import {
	Alert, AlertTitle, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { UserPendingFinancials } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UnbilledTransactionsProps {
	pendingFinancials?: UserPendingFinancials[];
	handleReservationClick: (id: number) => void;
	total?: number
}

const UnbilledTransactions: FC<UnbilledTransactionsProps> = (props) => {
	const { t } = useTranslation('translations');
	const { pendingFinancials, handleReservationClick, total } = props;
	return (
		<PaperContainer>
			<Alert severity="error" icon={false}>
				<AlertTitle sx={{ fontSize: FontSizes.MD }}>
					{t('unpaidTransactionWarning')}
				</AlertTitle>
				<List sx={{ p: 0 }}>
					{pendingFinancials?.map((el, index) => (
						<ListItem sx={{ p: 0 }} key={`pending-${index.toString()}`}>
							<ListItemIcon sx={{ minWidth: 15 }}>
								<Circle sx={{ fontSize: FontSizes.SM }} />
							</ListItemIcon>
							<DefaultButton
								size="small"
								variant="text"
								title={`#${el.reservationId}`}
								onClick={() => handleReservationClick(el.reservationId)}
							/>
							<ListItemText primaryTypographyProps={{ fontSize: FontSizes.MD }} primary={`${t('value')} ${putDecimalPoints(el.amount)}`} />
						</ListItem>
					))}
				</List>
			</Alert>
			<Space height={2} />
			{total !== 0 && (
				<Alert severity="info" icon={false}>
					<AlertTitle sx={{ fontSize: FontSizes.MD }}>
						{t('customerBalence')}
						{' '}
						{' '}
						<strong>
							€
							{' '}
							{formatCurrency(total)}
						</strong>
					</AlertTitle>
				</Alert>
			)}
		</PaperContainer>
	);
};

export default UnbilledTransactions;
