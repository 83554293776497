import React from 'react';

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d+$))|[^0-9|-]/g, '') || '';

const padDigits = (digits: string) => {
	const desiredLength = 3;
	const actualLength = digits.length;

	if (actualLength >= desiredLength) {
		return digits;
	}

	const amountToAdd = desiredLength - actualLength;
	const padding = '0'.repeat(amountToAdd);

	return padding + digits;
};

const removeLeadingZeros = (number: string) => number.replace(/^0+([0-9]+)/, '$1');

const addDecimalToNumber = (number: string) => {
	const centsStartingPosition = number.length - 2;
	const dollars = removeLeadingZeros(
		number.substring(0, centsStartingPosition),
	);
	const cents = number.substring(centsStartingPosition);
	return `${dollars},${cents}`;
};

export const currency = (event: React.ChangeEvent<HTMLTextAreaElement |HTMLInputElement>) => {
	const mEvent = event;
	const { value } = event.target;
	const digits = getDigitsFromValue(value);
	const digitsWithPadding = padDigits(digits);
	mEvent.target.value = addDecimalToNumber(digitsWithPadding);
	return mEvent;
};

export const stringWithOutSpace = (
	event: React.ChangeEvent<HTMLTextAreaElement |HTMLInputElement>,
) => {
	const mEvent = event;
	let { value } = event.target;
	value = value.replace(/\s/g, '');
	mEvent.target.value = value;
	return mEvent;
};

export const currencyNumber = (e: number | string | undefined) => {
	let value = `${e}`;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d)(\d{2})$/, '$1,$2');
	value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
	return value;
};

export const putDecimalPoints = (value: string | number | undefined | null) => {
	let val = `${typeof value === 'number' ? value.toFixed(2) : value || 0}`;
	if (val.includes('.')) {
		val = val.replace('.', ',');
	} else {
		val = val.concat(',00');
	}
	return val;
};

export const normalizeCurrency = (e: string | number) => {
	let value = `${e}`;
	value = value.replace('.', '');
	value = value.replace(',', '');
	return parseInt(value, 10) / 100;
};

export const taxId = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	e.target.maxLength = 9;
	let { value } = e.target;
	value = value.replace(/\D/g, '');
	value = value.replace(/^(\d{8})(\d)/, '$1-$2');
	e.target.value = value;
	return e;
};
export const taxIdString = (e: string | number | null) => {
	if (!e) {
		return '';
	}
	let value = e.toString();
	value = value.replace(/\D/g, '');
	value = value.replace(/^(\d{8})(\d)/, '$1-$2');
	return value;
};

export const carRegisteration = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	let { value } = e.target;
	e.target.maxLength = 8;
	value = value.replace(/[^a-z0-9]/gi, '');
	value = value.replace(/(.{2})(?=.)/g, '$1-');
	value = value.toUpperCase();
	e.target.value = value;
	return e;
};

export const postCode = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	e.target.maxLength = 8;
	let { value } = e.target;
	value = value.replace(/\D/g, '');
	value = value.replace(/^(\d{4})(\d)/, '$1-$2');
	e.target.value = value;
	return e;
};

export const postCodeString = (e: string | number | null) => {
	if (!e) {
		return '';
	}
	let value = e.toString();
	value = value.replace(/\D/g, '');
	value = value.replace(/^(\d{4})(\d)/, '$1-$2');
	return value;
};

export const phone = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	e.target.maxLength = 11;
	let { value } = e.target;
	value = value.replace(/\D+$/g, '');
	value = value.replace(/(\d{3})(\d)/, '$1 $2');
	e.target.value = value;
	return e;
};

export const phoneString = (value?: string) => {
	let val = value;
	val = val?.replace(/(\d{3})(?=\S)/g, '$1 ');
	return val || '';
};

export const latitude = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	e.target.maxLength = 10;
	let { value } = e.target;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{2})(\d)/, '$1,$2');
	e.target.value = value;
	return e;
};

export const longitude = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	e.target.maxLength = 10;
	let { value } = e.target;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{3})(\d)/, '$1,$2');
	e.target.value = value;
	return e;
};

export const replaceDecimal = (value: string | number | null) => {
	if (!value) {
		return '';
	}
	if (typeof value === 'number') {
		let val = String(value);
		val = val.replace('.', ',');
		return val;
	}
	return value.replace('.', ',');
};

export const replaceComma = (value: string | number) => {
	const tempValue = parseFloat(String(value).replace(',', '.'));
	return tempValue;
};

export const normalizePhone = (value: string) => {
	const tempValue = value.replaceAll(' ', '');
	return tempValue;
};

export const normalize = (value: string) => {
	const tempValue = value.replace('-', '');
	return tempValue;
};

export const acceptNumbers = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
	let { value } = e.target;
	value = value.replace(/\D/g, '');
	value = value.replace(/^0[0-9].*$/, '');
	e.target.value = value;
	return e;
};
