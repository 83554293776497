import { DateLabel, DefaultButton, Space } from '@Atoms';
import { formatCurrency } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Grid, Typography, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Invoice } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CommissionAdjustmentProps {
	commissionAdjustment?: Invoice[];
	diffTotal?: number;
	handleListAction: (action: string, id: number) => void;
	setSearch: (query: string)=>void
}

const CommissionAdjustmentPage: FC<CommissionAdjustmentProps> = (props) => {
	const {
		commissionAdjustment, diffTotal, handleListAction, setSearch,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<DataTable
						data={commissionAdjustment || []}
						onSearching={(query) => {
							setSearch(query);
						}}
					>
						<TableColumn headerText="ID" render={(item) => item.invoiceId} />
						<TableColumn headerText={t('recorded')} render={(item) => <DateLabel date={item.registration} />} />
						<TableColumn
							headerText={t('reservation')}
							render={(item) => (
								<DefaultButton
									title={`#${item.reservationId}`}
									onClick={() => handleListAction(t('reservation'), item.reservationId)}
								/>
							)}
						/>
						<TableColumn headerText={t('previousValue')} render={(item) => `${putDecimalPoints(item.oldAmount)} €`} />
						<TableColumn headerText={t('correctedValue')} render={(item) => `${putDecimalPoints(item.amount)} €`} />
						<TableColumn headerText={t('difference')} render={(item) => `${putDecimalPoints(item.difference)} €`} />
						<TableColumn
							headerText={t('invoice')}
							render={(item) => (
								item.invoiceId && (
									<DefaultButton
										variant="text"
										title={item.fullIdentifier}
										onClick={() => handleListAction(t('invoice'), item.invoiceId)}
									/>
								)
							)}
						/>
					</DataTable>
					{commissionAdjustment?.length === 0 && (
						<>
							<Space height={2} />
							<Typography variant="h5" textAlign="center">{t('noRecords')}</Typography>
							<Space height={2} />
						</>
					)}
					<Grid container spacing={3}>
						<Grid item xl={2} lg={2} md={3} sm={8} xs={8}>
							<Typography sx={{
								backgroundColor: theme.palette.error.main,
								mt: '2px',
								p: '8px',
								color: theme.palette.background.default,
							}}
							>
								{t('totalDifference')}
								:
								{' '}
								{formatCurrency(diffTotal)}
							</Typography>
						</Grid>
					</Grid>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CommissionAdjustmentPage;
