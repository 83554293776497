import { Grid, Stack, Typography } from '@mui/material';
import { AddCircle, FilterAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DataTable, TableColumn } from '@Organisms';
import { putDecimalPoints } from '@Masks';
import { CarFine, Pagination, PaginationTable } from '@Types';
import React, { FC } from 'react';
import { PaperContainer } from '@Molecules';
import {
	CarItem, DateLabel, DefaultButton, Dropdown, Space,
} from '@Atoms';

interface ListFineProps {
   handleListAction: (action: string, item?: CarFine) => void;
   filteredByVehicle: boolean;
   fines?: Pagination<CarFine[]>;
   setPagination: (params: PaginationTable) => void;
   setSearch: (query: string) => void
 }

const ListFinePage: FC<ListFineProps> = (props) => {
	const {
		handleListAction,
		filteredByVehicle,
		fines,
		setPagination,
		setSearch,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<Stack direction="row">
					<DefaultButton
						title={t('new')}
						onClick={() => {
							handleListAction(t('new'));
						}}
						startIcon={<AddCircle />}
					/>
					{filteredByVehicle && (
						<DefaultButton
							title={t('listOfAllVehicles')}
							sx={{ ml: 1 }}
							onClick={() => {
								handleListAction(t('listOfAllVehicles'));
							}}
							startIcon={<FilterAlt />}
						/>
					)}
				</Stack>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>

					<DataTable
						data={fines?.data || []}
						total={fines?.total}
						onChange={(page, size) => setPagination({ page, size })}
						onSearching={setSearch}
						pagination
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn
							headerText={t('registration')}
							render={(item) => (
								<CarItem car={item.car} />
							)}
						/>
						<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.date} />} />
						<TableColumn headerText={t('local')} render={(item) => item.local} />
						<TableColumn headerText={t('observation')} render={(item) => item.note} />
						<TableColumn headerText={t('value')} render={(item) => `	€${putDecimalPoints(item.amount)}`} />
						<TableColumn
							align="right"
							render={(item) => (
								<Dropdown
									data={[t('detail')]}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(action) => handleListAction(action, item)}
								/>
							)}
						/>
					</DataTable>
					{fines && fines?.data?.length === 0 && (
						<>
							<Space height={1} />
							<Typography textAlign="center" variant="h6">{t('noRecords')}</Typography>
						</>
					)}
					<Space height={2} />
				</PaperContainer>
			</Grid>

		</Grid>
	);
};

export default ListFinePage;
