import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@mui/icons-material';
import { Pagination, PaginationTable, Question } from '@Types';
import { DataTable, TableColumn } from '@Organisms';
import {
	Dropdown, DefaultButton, StatusLabel, Text,
} from '@Atoms';
import { Grid } from '@mui/material';
import { PaperContainer } from '@Molecules';

interface QuestionPageProps {
   handleListAction: (name: string, item?: Question) => void;
   questions?: Pagination<Question[]>
   setPagination: (params: PaginationTable) => void;
   setSearch: (searchQuery: string) => void
}

const QuestionPage: FC<QuestionPageProps> = (props) => {
	const {
		handleListAction, questions, setPagination, setSearch,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>

					<DataTable
						data={questions?.data || []}
						total={questions?.total}
						pagination
						onSearching={(query) => setSearch(query)}
						onChange={(page, size) => setPagination({ page, size })}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('question')} render={(item) => <Text maxLines={2}>{item.question}</Text>} />
						<TableColumn headerText={t('answer')} render={(item) => <Text maxLines={3}>{item.answer}</Text>} />
						<TableColumn headerText={t('language')} render={(item) => item.language?.name} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							render={(item) => (
								<Dropdown
									label={t('action')}
									data={[t('edit'), t('changeStatus')]}
									renderItem={(dropdownItem) => ({
										optionValue: dropdownItem,
										optionLabel: dropdownItem,
									})}
									onChange={(value) => handleListAction(value, item)}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default QuestionPage;
