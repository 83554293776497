import AppRouter from './AppRouter';

enum AppRoutes {
	LOGIN = '/login',
	FORGOT_PASSWORD = '/forgot-password',
   RESET_PASSWORD = '/reset-password/:token',
	MAIN = '/',
   DASHBOARD='/dashboard',
	CHANGE_PASSWORD = '/user/change-password',
   NEW_RESERVATION= '/reservation/new',
   EDIT_RESERVATION= '/reservation/edit/:id',
   LIST_ALL_RESERVATIONS= '/reservation/listall',
   LIST_CAR_RESERVATIONS= '/reservation/listall/:carId',
   RESERVATION_SERVICES= '/reservation/services/:reservationId',
   RESERVATION_TRANSACTIONS= '/reservation/transactions/:reservationId',
   RESERVATION_TICKETS= '/reservation/tickets',
   EDIT_RESERVATION_TICKETS= '/reservation/tickets/edit/:id',
   CHANGE_RESERVATION = '/reservation/change/:id',
   RESERVATION_CONTRACT= '/contract/:reservationId',
   FINALIZE_RESERVATION= '/reservations/finalize/:id',
   crm= '/reservation/crm/:reservationId/:userId',
   USER_CRM='/user/crm/:userId',
   RESERVATION_CRM='/reservation/crm/:reservationId',
   LIST_ALL_TRANSPORT_GUIDES= '/waybills/listall',
   ADD_NEW_TRANSPORT_GUIDE= '/waybills/new',
   EDIT_TRANSPORT_GUIDE= '/way-bills/edit/:id',
   CREATE_USER= '/users/new',
   EDIT_USER= '/users/edit/:id',
   LIST_USERS= '/users/list',
   PROMOTE_USER_TO_ADMIN= '/users/promote-to-admin/:id',
   NEW_MESSAGE= '/users/new-message/:id',
   ADD_PAYMENT_CARD= '/payment-cards/:clientId/:typeId',
   LIST_ADMINISTRATORS= '/users/administrators',
   ORGANIZATIONS= '/organizations/list',
   ADD_NEW_ORGANIZATION= '/organizations/create',
   EDIT_ORGANIZATION= '/organizations/edit/:id',
   LIST_COUNTERS= '/counters/listing',
   ADD_NEW_COUNTER= '/counters/new',
   EDIT_COUNTER= '/counters/edit/:id',
   NEW_MAINTENANCE= '/maintenance/new',
   EDIT_MAINTENANCE= '/maintenance/edit/:id',
   MAINTENANCE= '/maintenance/list',
   MAINTENANCE_VEHICLE = '/maintenance/:id',
   REMOVE_FROM_MAINTENANCE= '/maintenance/remove/:id',
   VEHICLES= '/vehiclemanagment/vehicles',
   NEW_VEHICLE= '/vehiclemanagment/vehicles/create',
   EDIT_VEHICLE= '/vehiclemanagment/vehicles/edit/:id',
   TRACK_VEHICLE='/vehiclemanagment/location/:id',
   VEHICLE_DETAIL= '/vehiclemanagment/vehicles/detail/:id',
   VEHICLE_FINES= '/vehiclemanagment/fines',
   VEHICLE_FINES_ID= '/vehiclemanagment/fines/:id',
   NEW_VEHICLE_FINE= '/fines/form',
   EDIT_VEHICLE_FINE= '/fines/form/:id',
   VEHICLE_CATEGORIES= '/vehiclemanagment/categories',
   NEW_VEHICLE_CATREGORY= '/vehiclemanagment/categories/new',
   EDIT_VEHICLE_CATEGORY = '/vehiclemanagment/categories/edit/:id',
   VEHICLE_BRANDS= '/vehiclemanagment/brands',
   NEW_VEHICLE_BRAND= '/vehiclemanagment/brands/new',
   EDIT_VEHICLE_BRAND= '/vehiclemanagment/brands/edit/:id',
   VEHICLE_CHARACTERISTICS= '/vehiclemanagment/characteristics',
   NEW_VEHICLE_CHARACTERISTICS= '/vehiclemanagment/characteristics/form',
   EDIT_VEHICLE_CHARACTERISTICS= '/vehiclemanagment/characteristics/form/:id',
   SOCIAL_NETWORK = '/social-network/list',
   EDIT_SOCIAL_NETWORK='/social-network/edit/:id',
   CREATE_SOCIAL_NETWORK='/social-network/create',
   COUNTRIES= '/places/countries',
   STATES= '/places/states',
   CITIES= '/places/cities',
   CREATE_ARTICLES= '/article/create',
   EDIT_ARTICLES= '/article/edit/:id',
   LIST_ARTICLES= '/article/listall',
   ADD_NEW_TERRIF= '/tarrifs/new',
   EDIT_TERRIF= '/tarrifs/edit/:id',
   LIST_ALL_TERRIFS= '/tarrifs/listall',
   DISCOUNT_CAMPAIGN= '/discounts/campaign',
   NEW_DISCOUNT_CAMPAIGN= '/discounts/campaign/create',
   EDIT_DISCOUNT_CAMPAIGN= '/discounts/campaign/edit/:id',
   DISCOUNTS_BY_QUANTITY= '/discounts/byquantity',
   EDIT_DISCOUNT_BY_QUANTITY= '/discount/byquantity/edit/:id',
   TOLLS_NO_BONDS= '/tolls/nobonds',
   TOLLS_NOT_BILLED= '/tolls/notbilled',
   TOLLS_PROCESS= '/tolls/process',
   REPORTS_DIVERGENT_CATEGORIES= '/reports/divergent-categories',
   REPORTS_PENDING_FINANCIALS= '/reports/pending-financials',
   REPORTS_MAINTENANCE_COST= '/reports/maintenance-cost',
   REPORTS_TOLL_BOOKING= '/reports/toll-bookings',
   REPORTS_COMMISSION= '/reports/commissions',
   REPORTS_ACCOUNTING= '/reports/accounting',
   REPORTS_UNGENERATED_INVOICES= '/reports/ungenerated-invoices',
   REPORTS_COMMISSION_ADJUSTMENT= '/reports/commission-adjustment',
   REPORTS_TRANSACTIONS_GENERATE_BILLING= '/reports/transactions/generate-billing',
   FAQS= '/faqs',
   ADD_NEW_FAQ= '/faqs/create',
   EDIT_FAQ= '/faq/edit/:id',
   APP_BANNERS= '/app-banners',
   ADD_NEW_BANNER= '/app-banners/form',
   EDIT_BANNER= '/app-banners/form/:id',
   DEFINITIONS= '/definitions',
   INVOICES= '/invoices/:invoiceId/:reservationId',
   RECEIPT= '/receipts/:id',
   DISCOUNT_REQUESTS = '/discounts/requested',
}

export { AppRoutes, AppRouter };
