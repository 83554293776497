import { PaperContainer } from '@Molecules';
import { Box, Grid } from '@mui/material';
import { PdfViewer } from '@Organisms';
import React, { FC } from 'react';
// import { useTranslation } from 'react-i18next';
import { Contract, User } from '@Types';
import EmailCard from '../invoice/EmailCard';

interface ContractProps {
	contract?: Contract;
	customers?: User[];
	handleSendEmail: () => void;
	handleChange: (email?: string) => void
	values: {email: string, error: string, loading: boolean}
}

const ContractPage: FC<ContractProps> = (props) => {
	const {
		contract, customers, handleSendEmail, handleChange, values,
	} = props;
	// const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Grid container spacing={3}>
						<Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
							<Box sx={{ height: '70vh' }}>
								<PdfViewer source={contract?.pdfFileBase64} />
							</Box>
						</Grid>
						<Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
							<EmailCard
								handleSendClick={handleSendEmail}
								users={customers || []}
								values={values}
								handleChange={handleChange}
							/>
						</Grid>
					</Grid>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ContractPage;
