/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultButton } from '@Atoms';
import { CheckCircle, Error } from '@mui/icons-material';
import {
	Dialog as DialogMui,
	Slide,
	DialogTitle,
	useTheme,
	DialogContent,
	DialogContentText,
	DialogActions,

} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const ICON_STRING = {
	success: 'success',
	error: 'error',
};

type ButtonProps = 'success' | 'error' | 'inherit' | 'primary' | 'secondary' | 'info' | 'warning' | undefined

interface DialogProps {
	icon?: 'success' | 'error';
	open: boolean;
	handleClose: () => void;
	title?: string | React.ReactNode;
	description?: string | React.ReactNode;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	primaryButtonAction?: () => void;
	secondaryButtonAction?: () => void;
	hideButtons?: boolean;
	padding?: number;
	primaryButtonColor?: ButtonProps;

}

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const Dialog: React.FC<DialogProps> = (props) => {
	const {
		open,
		handleClose,
		icon,
		title,
		description,
		primaryButtonText,
		primaryButtonAction,
		secondaryButtonAction,
		secondaryButtonText,
		hideButtons,
		padding = 0,
		primaryButtonColor = 'primary',
	} = props;
	const theme = useTheme();
	return (
		<div>
			<DialogMui
				sx={{ paddingLeft: theme.spacing(padding), paddingRight: theme.spacing(padding) }}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				{icon && (icon === ICON_STRING.success
					? (
						<DialogTitle sx={{ textAlign: 'center' }}>
							<CheckCircle color="success" fontSize="large" />
						</DialogTitle>
					)
					: (
						<DialogTitle sx={{ textAlign: 'center' }}>
							<Error color="error" fontSize="large" />
						</DialogTitle>
					))}
				{title && <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>}
				<DialogContent sx={{ textAlign: 'center' }}>
					{typeof description === 'string' ? (
						<DialogContentText id="alert-dialog-description">
							{`${description}`}
						</DialogContentText>
					) : description}

				</DialogContent>
				{!hideButtons && (
					<DialogActions>
						<DefaultButton
							color={primaryButtonColor}
							title={primaryButtonText || 'OK'}
							onClick={() => (primaryButtonAction ? primaryButtonAction?.call(0) : handleClose())}
						/>
						{secondaryButtonText && (
							<DefaultButton
								color="info"
								title={secondaryButtonText}
								onClick={() => secondaryButtonAction?.call(0)}
							/>
						)}
					</DialogActions>
				)}
			</DialogMui>
		</div>
	);
};

export default Dialog;
