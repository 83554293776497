import { ApiResponse, Tolerance } from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getTolerance(): Promise<ApiResponse<Tolerance[]>> {
	const resp = await reqGet({
		url: Endpoint.TOLERANCE,
	});
	const { data } = resp;
	return data;
}

export async function updateTolerance(tolerance: {
  toleranceModels: Tolerance[];
}): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.TOLERANCE,
		data: tolerance,
	});
	const { data } = resp;
	return data;
}
