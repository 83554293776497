/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	useTheme,
} from '@mui/material';
import React, { MutableRefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import { TableColumnProps } from './TableColumn';

interface DataTableProps {
   data: any[];
   children: React.ReactElement<TableColumnProps>[] | React.ReactElement<TableColumnProps>;
   pagination?: boolean;
   search?: boolean;
   shadedHead?: boolean;
   total?: number;
   onChange?: (page: number, rowsPerPage: number) => void;
   onSearching?: (query: string) => void;
   stripedRows?: boolean;
   resetPagination?: MutableRefObject<() => void>
}

const defaultOptions = {
	pagination: false,
	search: true,
};

const DataTable: React.FC<DataTableProps> = (mProps) => {
	const props = { ...defaultOptions, ...mProps };

	const { t } = useTranslation('translations');
	const theme = useTheme();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [search, setSearch] = React.useState('');
	const isSearching = React.useRef(false);
	const initialMount = React.useRef(true);

	const total = props.total || props.data.length;

	const children = (Array.isArray(props.children) ? props.children : [props.children]) as
		React.ReactElement<TableColumnProps>[];

	const headers = children.map((child, index) => {
		const header = child.props.headerOptions?.render === undefined
			? child.props.headerText
			: child.props.headerOptions?.render(index);
		return (
			<TableCell sx={{ fontWeight: 'bold' }} key={index} align={child.props.headerOptions?.align} padding={child.props.headerOptions?.padding}>
				{header}
			</TableCell>
		);
	});

	const rows = props.data.map((item, itemIndex) => {
		let rowColor = '';
		if (props.stripedRows) {
			if (itemIndex % 2 === 0) rowColor = theme.palette.divider;
		}
		return (
			<TableRow key={itemIndex.toString()} style={{ backgroundColor: rowColor }}>
				{children.map((child, index) => (
					<TableCell key={index.toString()} align={child.props.align} padding={child.props.padding}>
						{child.props.render(item)}
					</TableCell>
				))}
			</TableRow>
		);
	});

	const footers = children.map((child, index) => {
		const { footer } = child.props;
		if (footer !== undefined) {
			return <TableCell key={index.toString()}>{footer}</TableCell>;
		}
		return null;
	});

	const onChange = () => {
		props.onChange?.call(0, page + 1, rowsPerPage);
	};

	const onSearching = () => {
		isSearching.current = true;
		setPage(0);
		props.onSearching?.call(0, search);
		isSearching.current = false;
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffectCustom(() => {
		const delayDebounceFn = setTimeout(() => {
			onSearching();
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [search]);

	useEffectCustom(() => {
		if (!isSearching.current && !initialMount.current) {
			onChange();
		}
		initialMount.current = false;
	}, [page, rowsPerPage]);

	const resetPagination = () => {
		setPage(0);
		setRowsPerPage(10);
	};

	useEffect(() => {
		if (props.resetPagination !== undefined) {
			props.resetPagination.current = resetPagination;
		}
	}, [props.resetPagination]);

	return (
		<Box>
			{props.search && (
				<TextField
					helperText=""
					id=""
					placeholder={t('search')}
					title={t('search')}
					disabled={false}
					type="text"
					size="small"
					onChange={((event) => {
						resetPagination();
						setSearch(event.target.value);
					})}
					fullWidth
				/>
			)}
			<TableContainer>
				<Table size="small">
					<TableHead style={{ backgroundColor: props.shadedHead ? theme.palette.divider : '' }}>
						<TableRow>{headers}</TableRow>
					</TableHead>
					<TableBody>{rows}</TableBody>
					<TableFooter>{footers}</TableFooter>
				</Table>
			</TableContainer>
			{props.pagination && (
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={total}
					rowsPerPage={rowsPerPage}
					page={page}
					showFirstButton
					showLastButton
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Box>
	);
};

export default DataTable;
