/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth, useSetting, useTitleBar } from '@Providers';
import { useTranslation } from 'react-i18next';
import {
	Avatar, LinearProgress, Stack, useTheme,
} from '@mui/material';
import { DefaultSelect, Dropdown, Space } from '@Atoms';
import { ThemeToggle } from '@Molecules';
import { KeyboardArrowDown } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppRoutes } from '@Routes';
import { FontSizes } from '@Constant';
import { useSidebarRoutes } from '@Hooks';

const drawerWidth = 240;

interface SidebarProps {
   window?: () => Window;
   logoutUser: () => void;
}

export const SideBar = (props: SidebarProps) => {
	const { window, logoutUser } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const theme = useTheme();
	const location = useLocation();
	const [pathname, setPathName] = React.useState(location.pathname);

	const navigate = useNavigate();

	const { user } = useAuth();
	const { changeCounter, counter } = useSetting();
	const { sidebarRoutes } = useSidebarRoutes();

	const { t } = useTranslation('translations');

	const [activeItemIndex, setActiveIndex] = React.useState(0);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	React.useEffect(() => {
		setPathName(location.pathname);
	}, [location]);

	const handleChange = (value: string) => {
		switch (value) {
		case t('mydata'):
			navigate(AppRoutes.EDIT_USER.formatMap({ id: user?.userId }));
			break;
		case t('changePassword'):
			navigate('/user/change-password');
			break;
		case t('logout'):
			logoutUser();
			break;
		default:
			break;
		}
	};

	const handleNavigate = (route?: string) => {
		if (route) {
			navigate(route);
		}
	};

	const drawer = (
		<div style={{ paddingLeft: 8, paddingRight: 8 }} className="no-scroll">
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Space height={1} />
				<Avatar alt="user image" src={user?.profileImage} sx={{ height: 40, width: 40 }} />
				<Space height={1} />
				<Typography
					variant="caption"
					sx={{
						textAlign: 'center',
						width: '100px',
						display: '-webkit-box',
						overflow: 'hidden',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
					}}
				>
					{user?.name}
				</Typography>
				<Dropdown
					variant="text"
					data={[t('mydata'), t('changePassword'), t('logout')]}
					label={t('general')}
					renderItem={(item) => ({ optionValue: item, optionLabel: item })}
					onChange={handleChange}
				/>
				<Space height={2} />
			</Box>
			<Divider />
			<List sx={{ p: 0 }}>
				{sidebarRoutes?.map((item: any, index: number) => {
					const background = (item.route === pathname || pathname.startsWith(item?.link)) ? theme.palette.highlighted.main : '';
					return (
						<div key={`sidebar-item-${item.route}`}>
							{index % 3 === 0 ? <Divider /> : null}
							<ListItem
								button
								sx={{
									pl: '10px',
									pr: '0px',
									opacity: 0.7,
									height: '45px',
									borderRadius: 1,
									justifyContent: 'space-between',
									backgroundColor: background,
									'&:hover': {
										opacity: 1,
										backgroundColor: theme.palette.divider,
									},
								}}
								onClick={() => {
									if (!item.children && item.route) {
										navigate(item.link);
									}
									setActiveIndex((prev) => {
										if (index === prev) {
											return 0;
										}
										return index;
									});
								}}
							>
								<ListItemIcon sx={{ p: 0, minWidth: 25 }}>{item.icon}</ListItemIcon>
								<ListItemText
									primary={item.route}
									primaryTypographyProps={{
										fontSize: FontSizes.MD,
										color: theme.palette.icon.main,
									}}
								/>
								{item.children && (
									<ListItemIcon sx={{
										pl: 0, ml: 0, justifyContent: 'flex-end',
									}}
									>
										{
											activeItemIndex === index
												? <KeyboardArrowDown style={{ color: theme.palette.icon.main }} />
												: <ChevronLeftIcon style={{ color: theme.palette.icon.main }} />
										}
									</ListItemIcon>
								)}
							</ListItem>
							{item.children && activeItemIndex === index
						&& (
							<>
								{item.children.map((child: any) => {
									const childBackground = (child.route === pathname || pathname.startsWith(child?.route)) ? theme.palette.highlighted.main : '';
									return (
										<ListItem
											button
											key={`sidebar-child-${child.route}`}
											sx={{
												paddingLeft: '20px',
												mt: '3px',
												height: 30,
												opacity: 0.7,
												borderRadius: 1,
												backgroundColor: childBackground,
												'&:hover': {
													opacity: 1,
													backgroundColor: theme.palette.divider,
												},
											}}
											onClick={() => {
												handleNavigate(child.route);
											}}
										>
											<ListItemIcon
												style={{
													minWidth: 25,
												}}
											>
												{child.icon}
											</ListItemIcon>
											<ListItemText
												primary={child.title}
												primaryTypographyProps={{
													fontSize: FontSizes.MD,
													color: theme.palette.icon.main,
												}}
											/>
										</ListItem>
									);
								})}
							</>
						)}
						</div>
					);
				})}
			</List>
		</div>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	const { title, loading } = useTitleBar();

	return (
		<>
			<CssBaseline />
			<AppBar
				position="fixed"
				color="inherit"
				elevation={0}
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
				}}
			>
				<Toolbar>
					<IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
						<MenuIcon />
					</IconButton>
					<Box sx={{
						flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
					}}
					>
						<Typography variant="h5">{title}</Typography>
						<Box />
						<Box />
						<Box>
							<Stack width={200} direction="row" alignItems="center">
								<DefaultSelect
									options={user?.counters || []}
									label=""
									renderItem={(item) => ({
										optionLabel: item.fantasyName,
										optionValue: item.organizationId,
									})}
									value={counter}
									onChange={(event) => {
										changeCounter(parseInt(event.target.value, 10));
										navigate(AppRoutes.DASHBOARD);
									}}
								/>
								<ThemeToggle />
							</Stack>
							{/* <LanguageSelector /> */}
						</Box>
					</Box>
				</Toolbar>
				{loading && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress />
					</Box>
				)}
			</AppBar>
			<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="dashboard sidebar links">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
		</>
	);
};

export default SideBar;
