import { PaperContainer } from '@Molecules';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, TableColumn } from '@Organisms';
import { CarFreePeriods } from '@Types';
import { DateLabel } from '@Atoms';

interface FreePeriodProps {
   data: CarFreePeriods[] | undefined;
}

const FreePeriods: FC<FreePeriodProps> = ({ data }) => {
	const { t } = useTranslation('translations');
	return (
		<PaperContainer title={t('freePeriods')} dropdown>
			<DataTable data={data || []} search={false}>
				<TableColumn headerText={t('start')} render={(item) => <DateLabel date={item.start} showTime />} />
				<TableColumn headerText={t('end')} render={(item) => <DateLabel date={item.end} showTime />} />
				<TableColumn headerText={t('quantityDays')} render={(item) => item.daysFree} />
			</DataTable>
		</PaperContainer>
	);
};

export default FreePeriods;
