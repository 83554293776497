import {
	ApiResponse, CommunicationLog, CommunicationLogReq, NameAndId,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getCommLogs = async (reservationId?: string, userId?: string):
   Promise<ApiResponse<CommunicationLog[]>> => {
	const resp = await reqGet({
		url: Endpoint.LIST_CRM.format(reservationId, userId),
	});
	const { data } = resp;
	return data;
};

export const addNewCommLog = async (payload: CommunicationLogReq):
	Promise<ApiResponse<CommunicationLog>> => {
	const resp = await reqPost({
		url: Endpoint.CRM,
		data: {
			...payload,
			subjectTypeId: payload.subjectTypeId.id,
			receptivityId: payload.receptivityId.id,
			modalTypeId: payload.modalTypeId.id,
			comment: payload.comment.toString('html'),
		},
	});
	const { data } = resp;
	return data;
};

export async function getSubjectTypes(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.SUBJECT_TYPES,
	});
	const data: ApiResponse<NameAndId[]> = await resp.data;
	return data;
}

export async function getModalTypes(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.MODAL_TYPES,
	});
	const data: ApiResponse<NameAndId[]> = await resp.data;
	return data;
}
export async function getReceptivities(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.RECEPTIVITIES,
	});
	const data: ApiResponse<NameAndId[]> = await resp.data;
	return data;
}
