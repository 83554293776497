import { DateLabel, DefaultButton, StatusLabel } from '@Atoms';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { CreditCard, Note, TouchApp } from '@mui/icons-material';
import {
	Grid, Stack, useTheme, Box,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Transaction } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface TransactionListingProps{
	data?: Transaction[];
	handleInvoiceAction: (action: string, item: Transaction) => void;
	loadingItemId: number
}

const TransactionsListing: FC<TransactionListingProps> = (props) => {
	const { data, handleInvoiceAction, loadingItemId } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<DataTable data={data || []} search={false}>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('dueDate')} render={(item) => <DateLabel date={item.dueDate} />} />
						<TableColumn
							headerText={t('method')}
							render={(item) => (
								<>
									<div>{item.method?.name}</div>
									{item.externalReceiptURL && (
										<Stack
											direction="row"
											sx={{ '&:hover': { cursor: 'pointer' } }}
											alignItems="center"
											onClick={() => {
												window.open(item.externalReceiptURL, '_blank');
											}}
										>
											<CreditCard sx={{ color: theme.palette.primary.main }} />
											{' '}
											<Box component="span">{t('receipt')}</Box>
										</Stack>
									)}
								</>
							)}
						/>
						<TableColumn headerText={t('value')} render={(item) => putDecimalPoints(item.netAmount)} />
						<TableColumn headerText={t('discount')} render={(item) => putDecimalPoints(item.discount + item.itemsDiscount)} />
						<TableColumn headerText={t('finalValue')} render={(item) => putDecimalPoints(item.netAmount - (item.discount + item.itemsDiscount))} />
						<TableColumn headerText={t('vat')} render={(item) => putDecimalPoints((item.netAmount - (item.discount + item.itemsDiscount)) * 0.23)} />
						<TableColumn headerText={t('subTotal')} render={(item) => putDecimalPoints(item.amount)} />
						<TableColumn headerText={t('invoice')} render={(item) => item?.invoice?.fullIdentifier} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => item.amount && (
								<>
									{(!item?.invoice?.externalId) && (
										<DefaultButton
											color="primary"
											startIcon={<TouchApp />}
											title={t('generateInvoice')}
											loading={item.id === loadingItemId}
											onClick={() => {
												handleInvoiceAction(t('generateInvoice'), item);
											}}
										/>
									)}
									{item?.invoice?.externalId && (
										<DefaultButton
											sx={{ bgcolor: theme.palette.cardBorder.main }}
											startIcon={<Note />}
											title={t('openInvoice')}
											loading={item.id === loadingItemId}
											onClick={() => {
												handleInvoiceAction(t('openInvoice'), item);
											}}
										/>
									)}
								</>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default TransactionsListing;
