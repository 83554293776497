/* eslint-disable @typescript-eslint/no-explicit-any */
import { replaceCommaWithDecimal } from '@Helpers';
import { normalize, normalizePhone } from '@Masks';
import {
	ApiResponse, ListDataControl, Organization, Pagination,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getOrganizations(params: ListDataControl, statusId?: number):
   Promise<ApiResponse<Pagination<Organization[]>>> {
	const resp = await reqGet({
		url: Endpoint.ORGANIZATIONS.format(params.page, params.size, params.search, statusId || ''),
	});
	const data: ApiResponse<Pagination<Organization[]>> = await resp.data;
	return data;
}

export async function changeOrganizationStatus(organizationId: number):
   Promise<ApiResponse<Organization>> {
	const resp = await reqPost({
		url: Endpoint.ORGANIZATION_STATUS.format(organizationId),
	});
	const data: ApiResponse<Organization> = await resp.data;
	return data;
}

export async function addNewOrganization(organization: any): Promise<ApiResponse<void>> {
	const dataToUpload = {
		address: organization.address,
		addressComplement: organization.addressComplement,
		cityId: organization.cityId?.id,
		countryId: organization.countryId.id,
		email: organization.email,
		establishmentNumber: organization.establishmentNumber,
		externalId: organization.externalId,
		fantasyName: organization.fantasyName,
		latitude: replaceCommaWithDecimal(organization.latitude),
		longitude: replaceCommaWithDecimal(organization.longitude),
		neighborhood: organization.neighborhood,
		officialName: organization.officialName,
		phone: normalizePhone(organization.phone),
		phoneCountryId: organization.phoneCountryId.id,
		postalCode: normalize(organization.postalCode),
		stateId: organization.stateId?.id,
		taxId: normalize(organization.taxId),
	};
	const resp = await reqPost({
		url: Endpoint.ORGANIZATION,
		data: dataToUpload,
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
}

export async function getOrganizationById(organizationId: number | string):
   Promise<ApiResponse<Organization>> {
	const resp = await reqGet({
		url: Endpoint.ORGANIZATION_BY_ID.format(organizationId),
	});
	const { data } = resp;
	return data;
}

export const updateOrganization = async (organization: any, organizationId?: number | string):
   Promise<ApiResponse<void>> => {
	const dataToUpload = {
		address: organization.address,
		addressComplement: organization.addressComplement,
		cityId: organization.cityId?.id,
		countryId: organization.countryId.id,
		email: organization.email,
		establishmentNumber: organization.establishmentNumber,
		externalId: organization.externalId,
		fantasyName: organization.fantasyName,
		latitude: replaceCommaWithDecimal(organization.latitude),
		longitude: replaceCommaWithDecimal(organization.longitude),
		neighborhood: organization.neighborhood,
		officialName: organization.officialName,
		phone: normalizePhone(organization.phone),
		phoneCountryId: organization.phoneCountryId.id,
		postalCode: normalize(organization.postalCode),
		stateId: organization.stateId?.id,
		taxId: normalize(organization.taxId),
	};
	const resp = await reqPost({
		url: Endpoint.ORGANIZATION_BY_ID.format(organizationId),
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
};
