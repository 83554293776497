import { Transaction } from '@Types';
import React, { FC } from 'react';
import {
	DialogTitle,
	DialogContent,
	Box,
	useTheme,
	DialogActions,
	Stack,
	Typography,
	IconButton,

} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { useTranslation } from 'react-i18next';
import {
	CarItem, DateLabel, DefaultButton, Description,
} from '@Atoms';
import { formatCurrency, formatDateOnly } from '@Helpers';
import { FontSizes } from '@Constant';
import { Close, Print } from '@mui/icons-material';

interface TollItemProps {
	tolls?: Transaction[];
	handleClose: () => void;
	total?: number
}

const TollItem: FC<TollItemProps> = (props) => {
	const { tolls, handleClose, total } = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const printRef = React.useRef<HTMLDivElement>(null);

	const handlePrintClick = () => {
		const w = window.open();
		if (printRef.current?.innerHTML && w) {
			w.document.write(printRef.current.innerHTML);
			const tables = Array.from(w.document.getElementsByTagName('table'));
			tables?.forEach((mTable) => {
				const table = mTable;
				table.style.borderTop = '1px solid #000000';
				table.style.borderSpacing = '0';
				table.style.width = '100%';
				const rows = Array.from(w.document.getElementsByTagName('td'));
				rows.forEach((mRow) => {
					const row = mRow;
					row.style.border = '1px solid #999999';
				});
			});
			w?.print();
		}
		w?.close();
	};

	return (
		<>
			<DialogTitle sx={{ textAlign: 'center' }}>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Typography>{t('tollRegister')}</Typography>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Stack>
			</DialogTitle>
			<div ref={printRef}>
				<Typography display="none">{t('tolls')}</Typography>
				<DialogContent sx={{ textAlign: 'center' }}>
					<DataTable data={tolls || []} search={false}>
						<TableColumn
							headerText={t('cod')}
							render={(item) => (
								<>
									{item.transactionId}
									<div>
										<Description fontWeight="bold" fontSize={FontSizes.XS} text={item.status?.id === 0 ? t('tollAccept') : item.status?.name} />
									</div>
									<Description fontSize={FontSizes.XS} text={`${t('receivedOn')}: ${formatDateOnly(item.registration)}`} />
								</>
							)}
						/>
						<TableColumn
							headerText={t('enrollDev')}
							render={(item) => (
								<>
									<CarItem car={{ name: '', licensePlate: item.carPlate }} />
									{item.obenumber}
								</>
							)}
						/>
						<TableColumn
							headerText={`${t('location')}/${t('date')}`}
							render={(item) => (
								<>
									{item.outTollName}
									<DateLabel date={item.inDate} showTime />
								</>
							)}
						/>
						<TableColumn
							headerText={`${t('value')}/${t('invoice')}`}
							render={(item) => (
								<>
									<div>{`€ ${formatCurrency(item.fare)}`}</div>
									<Box
										component="span"
										sx={{
											bgcolor: item.isBilled
												? theme.palette.success.main
												: theme.palette.error.main,
											color: theme.palette.background.default,
											p: '3px',
											borderRadius: '3px',
											fontSize: FontSizes.SM,
										}}
									>
										{item.isBilled ? t('billed') : t('noInvoice')}
									</Box>
								</>
							)}
						/>
					</DataTable>
				</DialogContent>
				<Typography variant="subtitle1" color="error">
					{t('totalTolls')}
					:
					{' '}
					€
					{' '}
					{formatCurrency(total)}
				</Typography>
			</div>
			<DialogActions>
				<DefaultButton startIcon={<Print />} onClick={handlePrintClick} title={t('print')} />
				<DefaultButton title={t('close')} onClick={handleClose} />
			</DialogActions>
		</>
	);
};

export default TollItem;
