import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { reservationApi } from '@Requests';
import { validateEmail } from '@Validation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ContractPage from './ContractPage';

const Contract = () => {
	const { t } = useTranslation('translations');
	const { reservationId } = useParams();
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();

	const reservationContract = useRequest(([params]) => reservationApi.reservationContract(params));
	const reservationCostumers = useRequest(([params]) => (
		reservationApi.getReservationCustomers(params)));

	const [values, setValues] = useState({ email: '', loading: false, error: '' });

	useEffect(() => {
		setTitle(`${t('contract')} #${reservationId}`);
	}, [t, setTitle, reservationId]);

	const getData = () => {
		reservationCostumers.execute(reservationId);
		reservationContract.execute(reservationId);
	};

	useEffectCustom(() => {
		if (reservationId) {
			getData();
		}
	}, []);

	const sendContractToEmail = () => {
		setValues({ ...values, loading: true });
		reservationApi.sendContract(reservationId ?? '', values.email)
			.then((res) => addToast({ message: res.message, error: false }))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setValues({ ...values, loading: false }));
	};

	const handleEmailChange = (value?: string) => {
		if (validateEmail(values.email)) {
			return setValues({ ...values, email: '', error: t('noEmail') });
		}
		setValues({ ...values, email: value || '', error: '' });
		return null;
	};

	return (
		<ContractPage
			contract={reservationContract.data}
			customers={reservationCostumers.data}
			handleSendEmail={sendContractToEmail}
			handleChange={handleEmailChange}
			values={values}
		/>
	);
};

export default Contract;
