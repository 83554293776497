import { padId } from '@Helpers';
import { useEffectCustom } from '@Hooks';
import { Close } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import { reservationApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Ticket, TicketMessageReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, {
	useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import EditTicketPage from './EditTicketPage';
import HistoryTable from './HistoryTable';

const EditTicket = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const navigate = useNavigate();
	const { addToast, addDialog } = useFeedback();

	const [initialValues] = useState({
		message: '',
		fileAttachmentDescription: '',
		fileAttachment: {} as File,
	} as TicketMessageReq);
	const [showHistory, setShowHistory] = useState(false);
	const [loading, setLoading] = useState(false);
	const [tickets, setTickets] = useState<Ticket>();

	/** Original Object for local Search */
	const [originalTickets, setOriginalTickets] = useState<Ticket>();

	useEffect(() => {
		setTitle(`${t('ticket')} ${padId(id ?? '', 4)}`);
	}, [setTitle, t, id]);

	useEffectCustom(() => {
		const getData = () => {
			reservationApi.getTicketById(id ?? '')
				.then(({ data }) => {
					setTickets(data);
					setOriginalTickets(data);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}));
		};
		getData();
	}, []);

	const changeStatus = (statusId: number) => {
		setLoading(true);
		reservationApi.changeTicketStatus(statusId, id ?? '')
			.then((res) => setTickets(res.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: false }))
			.finally(() => setLoading(false));
	};

	const handleActionClick = (action: string, itemId?: number) => {
		switch (action) {
		case t('back'):
			navigate(AppRoutes.RESERVATION_TICKETS);
			break;
		case t('history'):
			setShowHistory(true);
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: itemId }));
			break;
		default:
			changeStatus(itemId ?? 0);
			break;
		}
	};

	const validate = (values: TicketMessageReq) => {
		const errors = {} as FormikErrors<TicketMessageReq>;
		if (!values.message) {
			errors.message = t('emptyField');
		}
		if (values.fileAttachment.size) {
			if (!values.fileAttachmentDescription) {
				errors.fileAttachmentDescription = t('emptyField');
			}
		}
		return errors;
	};

	const submitMessage = (values: TicketMessageReq, helpers: FormikHelpers<TicketMessageReq>) => {
		reservationApi.addTicketMessage(values, id ?? '')
			.then((res) => {
				addToast({ message: res.message, error: false });
				setTickets(res.data);
				helpers.resetForm({});
			})
			.catch(({ response }) => {
				addDialog({
					title: response.title,
					message: response.data.message,
					error: true,
				});
			})
			.finally(() => helpers.setSubmitting(false));
	};

	const closeHistory = () => {
		setShowHistory(false);
	};

	const handleMessageSearch = (search: string) => {
		if (tickets) {
			setTickets(() => {
				/** Initialize with the object having all the items */
				const mPrev = { ...originalTickets } as Ticket;
				const messages = mPrev?.messages?.filter((el) => (
					/** See for match with any field id, note, user name */
					el.note.startsWith(search)
					|| el.id.toString().startsWith(search)
					|| el.user.name.startsWith(search)));
				if (mPrev && messages) {
					mPrev.messages = messages;
				}
				/** Return the filtered object to setTickets */
				return mPrev;
			});
		}
	};

	return (
		<>
			<Dialog
				open={showHistory}
				handleClose={closeHistory}
				title={(
					<Stack direction="row" justifyContent="space-between">
						<Typography>{t('history')}</Typography>
						<IconButton onClick={closeHistory} sx={{ p: 0, m: 0 }}>
							<Close />
						</IconButton>
					</Stack>
				)}
				description={(
					<HistoryTable ticket={tickets} />
				)}
				primaryButtonText={t('close')}
			/>
			<EditTicketPage
				ticket={tickets}
				onActionClick={handleActionClick}
				initialValues={initialValues}
				validate={validate}
				onSaveMessage={submitMessage}
				loading={loading}
				handleMessageSearch={handleMessageSearch}
			/>
		</>
	);
};

export default EditTicket;
