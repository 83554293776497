/* eslint-disable @typescript-eslint/no-explicit-any */
import { encodeDate } from '@Helpers';
import {
	ApiResponse,
	Pagination,
	Transaction,
	LegacyTolls,
	NotBilledToll,
	ListDataControl,
	ProcessTollReq,
	LegacyTollsReq,
	NameAndId,
	OrphanTollReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getUnbilledTolls(params: ListDataControl):
   Promise<ApiResponse<Pagination<NotBilledToll[]>>> {
	const resp = await reqGet({
		url: Endpoint.PENDING_TOLLS.format(params.size, params.page, params.search),
	});
	const { data } = resp;
	return data;
}

export async function getOrphanTolls(params: ListDataControl):
   Promise<ApiResponse<Pagination<Transaction[]>>> {
	const resp = await reqGet({
		url: Endpoint.TOLLS_NO_RESERVATION.format(params.page, params.size, params.search),
	});

	const { data } = resp;
	return data;
}

export async function registerPayment(transactionId?: number): Promise<ApiResponse<void>> {
	const resp = await reqGet({
		url: Endpoint.REGISTER_PAYMENT.format(transactionId),
		// data: { transactionId },
	});
	const { data } = resp;
	return data;
}

export async function linkToll(payload: any): Promise<ApiResponse<void>> {
	const resp = await reqGet({
		url: Endpoint.LINK_TOLL.format(payload.reservationId, payload.transactionId),
		data: payload,
	});
	const { data } = resp;
	return data;
}

export async function changeTollStatus(payload: OrphanTollReq): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.TOLL_STATUS_LOCAL,
		data: { ...payload, status: payload.status.id },
	});
	const { data } = resp;
	return data;
}

export async function legacyTolls(payload: LegacyTollsReq):
Promise<ApiResponse<Pagination<LegacyTolls[]>>> {
	const startDate = encodeURIComponent(encodeDate(payload.startDate));
	const endDate = encodeURIComponent(encodeDate(payload.endDate));
	const plate = payload.registration ? `TextPlate=${payload.registration}` : '';
	const resp = await reqGet({
		url: Endpoint.TOLL_MIGRATION.format(startDate, endDate, plate),
	});
	const { data } = resp;
	return data;
}

export async function processToll(values: ProcessTollReq, transactionId: number[]):
   Promise<ApiResponse<void>> {
	const transactionQuery = `${transactionId.map((el) => `TransactionIds=${el}&`)}`;
	const finalQuery = transactionQuery.replaceAll(',', '');
	const resp = await reqPost({
		url: Endpoint.TOLL_LIVE_STATUS.format(values.StatusId.id, finalQuery, values.DriverId.id),
	});
	const { data } = resp;
	return data;
}

export async function tollsStatusTypes(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.TOLL_STATUS_TYPES,
	});
	const data: ApiResponse<NameAndId[]> = await resp.data;
	return data;
}

export async function tollsByReservation(reservationId: string):
   Promise<ApiResponse<Pagination<Transaction[]>>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_TOLLS.format(reservationId),
	});
	const { data } = resp;
	return data;
}
