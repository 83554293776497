/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import { normalizeCurrency } from '@Helpers';
import {
	ApiResponse, InvoiceItem, Service, ServicesReq, Transaction,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqDelete, reqGet, reqPost } from '../request.api';

export async function getReservationService(reservationId: string, signal?: AbortSignal): Promise<ApiResponse<Service[]>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_ITEMS.format(reservationId),
		signal,
	});
	const { data } = resp;
	return data;
}

export async function unbilledReservationItems(reservationId: string): Promise<ApiResponse<Service[]>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_ITEMS.format(reservationId),
	});
	const { data } = resp;
	data.data = data.data.filter((el: Service) => !el.transaction);
	return data;
}

export async function unbilledAndUngenerated(reservationId:string): Promise<ApiResponse<Service[]>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_ITEMS.format(reservationId),
	});
	const { data } = resp;
	data.data = data.data.filter((el: { transaction: Transaction; }) => {
		if (!el.transaction) {
			return el;
		}
		if (!el.transaction?.invoice) {
			return el;
		}
	});
	return data;
}

export async function deleteReservationService(reservationId: string, itemId: string): Promise<ApiResponse<void>> {
	const resp = await reqDelete({
		url: Endpoint.DELETE_RESERVATION_ITEM.format(reservationId, itemId),
	});
	const { data } = resp;
	return data;
}

export async function getInvoiceItems(invoiceId: string): Promise<ApiResponse<InvoiceItem[]>> {
	const resp = await reqGet({
		url: Endpoint.INVOICE_ITEMS.format(invoiceId),
	});
	const { data } = resp;
	return data;
}

export async function addNewReservationService(reservationId: string, item: ServicesReq): Promise<ApiResponse<Service[]>> {
	const dataToUpload = {
		quantity: item.quantity,
		value: parseFloat(normalizeCurrency(item.value)),
		reservationId,
		billableItemId: item.billableItemId.id,
		discountRequestId: item.discountRequestId.discountRequestId,
	};

	const resp = await reqPost({
		url: Endpoint.RESERVATION_ITEMS.format(reservationId),
		data: dataToUpload,
	});

	const { data } = resp;
	return data;
}
