import {
	Autocomplete, CheckBox, DefaultButton, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Edit } from '@mui/icons-material';
import { Box, Divider, Grid } from '@mui/material';
import { useDropdown } from '@Providers';
import { CarFeatureReq } from 'data/models/car-feature.model';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface NewCharacterProps {
   initialValues: CarFeatureReq;
   handleSubmit: (values: CarFeatureReq, formikHelpers: FormikHelpers<CarFeatureReq>) => void;
   validate: (values: CarFeatureReq) => FormikErrors<CarFeatureReq>;
   isEdit: boolean;
   handleEditClick: () => void
}

const NewCharacteristicPage: FC<NewCharacterProps> = (props) => {
	const { t } = useTranslation('translations');
	const {
		initialValues, handleSubmit, validate, isEdit, handleEditClick,
	} = props;
	const { featureTypes } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validate={validate}
					>
						{({
							values,
							touched,
							errors,
							handleChange,
							setFieldValue,
							isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={10} lg={10} md={10} xs={12}>
										<TextField
											label={t('characteristic')}
											id="name"
											type="text"
											disabled={isSubmitting || !isEdit}
											value={values.name}
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={10} lg={10} md={10} xs={12}>
										<TextField
											label={t('shortName')}
											id="shortName"
											type="text"
											disabled={isSubmitting || !isEdit}
											value={values.shortName}
											error={touched.shortName && Boolean(errors.shortName)}
											helperText={touched.shortName && errors.shortName}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={10} lg={10} md={10} xs={12}>
										<Autocomplete
											label={t('type')}
											placeholder=""
											options={featureTypes || []}
											disabled={isSubmitting || !isEdit}
											defaultValue={values.typeId}
											renderOption={(inputprops, item) => (
												<li {...inputprops}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('typeId', item)}
											error={touched.typeId && Boolean(errors.typeId)}
											helperText={touched.typeId && String(errors.typeId || '')}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Box sx={{ pl: 2 }}>
									<CheckBox
										title={t('quantitative')}
										checked={!values.isBoolean}
										disabled={isSubmitting || !isEdit}
										setChecked={() => {
											setFieldValue('isBoolean', !values.isBoolean);
										}}
									/>
								</Box>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && <DefaultButton title={t('save')} loading={isSubmitting} type="submit" />}
								{!isEdit && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} />}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default NewCharacteristicPage;
