import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { featureApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { CarFeature } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListCharacteristicPage from './ListCharacteristicPage';

const ListCharacteristics = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({ page: 0, size: 10, search: '' });

	const features = useRequest(([params]) => featureApi.getFeatures(params));
	const changeFeatureStatus = useRequest((params) => featureApi.changeFeatureStatus(params));

	useEffect(() => {
		setTitle(t('characteristics'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unmounted = false;
		if (!unmounted) {
			features.execute(pagination);
		}
		return () => {
			unmounted = true;
		};
	}, [pagination]);

	useEffectCustom(() => {
		const { data } = changeFeatureStatus;
		if (data) {
			features.setData((mPrev) => {
				const prev = mPrev;
				const index = prev?.data.findIndex((el) => el.id === data?.id);
				if (prev && index !== undefined) {
					prev.data[index] = data;
				}
				return prev;
			});
		}
	}, [changeFeatureStatus.data]);

	const handleListAction = (name: string, item?: CarFeature) => {
		switch (name) {
		case t('detail'):
			navigate(AppRoutes.EDIT_VEHICLE_CHARACTERISTICS.formatMap({ id: item?.id }));
			break;
		case t('new'):
			navigate(AppRoutes.NEW_VEHICLE_CHARACTERISTICS);
			break;
		case t('changeStatus'):
			changeFeatureStatus.execute(item?.id);
			break;
		default:
			break;
		}
	};

	return (
		<ListCharacteristicPage
			handleListAction={handleListAction}
			setPagination={(paginationTable) => setPagination({ ...pagination, ...paginationTable })}
			setSearch={(search) => {
				setPagination({ page: 0, size: 10, search });
			}}
			features={features.data}
		/>
	);
};

export default ListCharacteristics;
