import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import { tollApi, reservationApi } from '@Requests';
import { NameAndId, OrphanTollReq, Transaction } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkPaymentItem from './LinkPaymentItem';
import NoBondsPage from './NoBondsPage';
import UpdateStatusItem from './UpdateStatusItem';

const NoBonds = () => {
	const { t } = useTranslation(['translations', 'gen', 'tolls', 'dashboardStrings']);
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast, addDialog } = useFeedback();

	const orphanTolls = useRequest(([params]) => tollApi.getOrphanTolls(params));
	const reservation = useRequest(([params]) => reservationApi.getReservationById(params));

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');
	const [openModal, setModalOpen] = useState(false);
	const [modalOptions, setModalOptions] = useState({
		updateStatus: false,
		link: false,
		registerPayment: false,
	});
	const [item, setItem] = useState<Transaction>();
	const [dialogContent, setDialogContent] = useState({ title: '', description: '' });
	const [reservationId, setReservationId] = useState('');
	const [reservationIdError, setReservationIdError] = useState('');

	const changeStatusInitialValues = {
		reason: '',
		status: {} as NameAndId,
		transactionId: 0,
	};

	useEffect(() => {
		setTitle(`${t('tollsWithoutReservations')}`);
	}, [setTitle, t]);

	const getData = () => {
		const listDataControl = {
			page: pagination.page,
			size: pagination.size,
			search,
		};
		orphanTolls.execute(listDataControl);
	};

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			getData();
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	const handleDialogClose = () => {
		setModalOpen(false);
		setModalOptions({
			updateStatus: false,
			link: false,
			registerPayment: false,
		});
	};

	const changeTollStatus = (values: OrphanTollReq, formikHelpers: FormikHelpers<OrphanTollReq>) => {
		const payload = { ...values, transactionId: item?.transactionId ?? 0 };
		tollApi.changeTollStatus(payload)
			.then((res) => {
				addToast({ message: res.message, error: false });
				getData();
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => {
				formikHelpers.setSubmitting(false);
				handleDialogClose();
			});
	};

	const handleListAction = (action: string, tollItem?: Transaction) => {
		switch (action) {
		case t('update'):
			setItem(tollItem);
			setModalOpen(true);
			setModalOptions({
				updateStatus: true,
				link: false,
				registerPayment: false,
			});
			break;
		case t('link'):
			setItem(tollItem);
			setModalOpen(true);
			setModalOptions({
				updateStatus: false,
				link: true,
				registerPayment: false,
			});
			setDialogContent({
				title: t('bookingCode'),
				description: '',
			});
			break;
		case t('register'):
			setItem(tollItem);
			setModalOpen(true);
			setModalOptions({
				updateStatus: false,
				link: false,
				registerPayment: true,
			});
			setDialogContent({
				title: t('confirmMessage'),
				description: '',
			});
			break;

		default:
			break;
		}
	};

	const registerTollPayment = () => {
		setGlobalLoading(true);
		tollApi.registerPayment(item?.transactionId)
			.then((res) => {
				addToast({
					message: res.message,
					error: false,
				});
				getData();
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	const linkTollToReservation = () => {
		setGlobalLoading(true);
		handleDialogClose();
		const data = { transactionId: item?.transactionId, reservationId };
		tollApi.linkToll(data)
			.then((res) => {
				addToast({ message: res.message, error: false });
				getData();
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => {
				reservation.setData(undefined);
				setGlobalLoading(false);
			});
	};

	const handleDialogConfirm = () => {
		if (modalOptions.registerPayment) {
			registerTollPayment();
			handleDialogClose();
		}
		if (modalOptions.link) {
			if (reservation.data) {
				linkTollToReservation();
			} else {
				if (!reservationId) {
					return setReservationIdError(t('emptyField'));
				}
				setReservationIdError('');
				reservation.execute(reservationId);
				return null;
			}
			return null;
		}
		return null;
	};

	const onLinkInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setReservationIdError('');
		setReservationId(event.target.value);
	};

	const validateStatus = (values: OrphanTollReq) => {
		const errors = {} as FormikErrors<OrphanTollReq>;
		if (!values.reason) {
			errors.reason = t('emptyField');
		}
		if (!values.status) {
			errors.status = t('emptyField');
		}
		if (values.status && Object.keys(values.status).length === 0) {
			errors.status = t('emptyField');
		}
		return errors;
	};

	return (
		<>
			{openModal && (
				<Dialog
					open={openModal}
					title={!modalOptions.updateStatus && dialogContent.title}
					description={
						modalOptions.link ? (
							<LinkPaymentItem
								onChange={onLinkInputChange}
								reservation={reservation.data}
								reservationError={reservationIdError}
							/>
						) : (
							<UpdateStatusItem
								updateStatus={modalOptions.updateStatus}
								handleClose={handleDialogClose}
								initialValues={changeStatusInitialValues}
								validate={validateStatus}
								handleSubmit={changeTollStatus}
							/>
						)
					}
					handleClose={() => setModalOpen(false)}
					primaryButtonAction={handleDialogConfirm}
					primaryButtonText={t('confirm')}
					secondaryButtonAction={handleDialogClose}
					secondaryButtonText={t('cancel')}
					hideButtons={modalOptions.updateStatus}
				/>
			)}
			<NoBondsPage
				setSearch={setSearch}
				setPagination={setPagination}
				tolls={orphanTolls.data}
				handleListAction={handleListAction}
			/>
		</>
	);
};

export default NoBonds;
