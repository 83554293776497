import { DefaultButton } from '@Atoms';
import { formatDate } from '@Helpers';
import { DashboardContainer } from '@Molecules';
import { TableColumn, DataTable } from '@Organisms';
import { Reservation } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PendingReservationProps {
	pendingRes: Reservation[] | undefined;
	handleClick?: (action: string, id: number, typeId?: number) => void
}

const PendingReservations: FC<PendingReservationProps> = (props) => {
	const { pendingRes, handleClick } = props;
	const { t } = useTranslation('translations');
	return (
		<DashboardContainer
			title={t('pending')}
			badgeNumber={pendingRes?.length}
			hidden
		>
			<DataTable data={pendingRes || []} search={false}>
				<TableColumn
					headerText={t('reservation')}
					render={(item) => (
						<DefaultButton
							title={item.id}
							onClick={() => handleClick?.call(0, t('reservation'), item.id, item.type?.id)}
						/>
					)}
				/>
				<TableColumn headerText={t('driver')} render={(item) => item.user} />
				<TableColumn headerText={t('start')} render={(item) => formatDate(item.startDate)} />
				<TableColumn headerText={t('endDate')} render={(item) => formatDate(item.endDate)} />
			</DataTable>
		</DashboardContainer>
	);
};

export default PendingReservations;
