import {
	Autocomplete, DefaultButton, Space,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid, useTheme } from '@mui/material';
import { useDropdown } from '@Providers';
import { CommunicationLogReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RichTextEditor from 'react-rte';

interface AddCrmProps {
	initialValues: CommunicationLogReq;
	validate: (values: CommunicationLogReq) => FormikErrors<CommunicationLogReq>;
	handleSubmit: (values: CommunicationLogReq,
		formikHelpers: FormikHelpers<CommunicationLogReq>) => void
}

const AddCrmForm: FC<AddCrmProps> = (props) => {
	const { initialValues, validate, handleSubmit } = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const { subjectTypes, modalTypes, receptivities } = useDropdown();

	return (
		<PaperContainer>
			<Grid container spacing={3}>
				<Grid item sm={12} md={12}>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							setFieldValue, errors, touched, isSubmitting, values,
						}) => (
							<Form>
								<Grid container spacing={2}>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											options={subjectTypes}
											label={t('subject')}
											defaultValue={values.subjectTypeId}
											placeholder=""
											disabled={isSubmitting}
											error={touched.subjectTypeId && Boolean(errors.subjectTypeId)}
											helperText={touched.subjectTypeId && String(errors.subjectTypeId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('subjectTypeId', item)}
										/>
									</Grid>

									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											options={modalTypes}
											label={t('modal')}
											defaultValue={values.modalTypeId}
											placeholder=""
											disabled={isSubmitting}
											error={touched.modalTypeId && Boolean(errors.modalTypeId)}
											helperText={touched.modalTypeId && String(errors.modalTypeId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('modalTypeId', item)}
										/>
									</Grid>

									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											options={receptivities}
											label={t('receptivity')}
											placeholder=""
											defaultValue={values.receptivityId}
											disabled={isSubmitting}
											error={touched.receptivityId && Boolean(errors.receptivityId)}
											helperText={touched.receptivityId && String(errors.receptivityId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('receptivityId', item)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<RichTextEditor
											value={values.comment}
											onChange={(value) => {
												setFieldValue('comment', value);
											}}
											disabled={isSubmitting}
											rootStyle={{
												minHeight: 150,
												backgroundColor: theme.palette.background.paper,
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<DefaultButton
									title={t('add')}
									loading={isSubmitting}
									type="submit"
								/>
							</Form>
						)}
					</Formik>
				</Grid>
			</Grid>
		</PaperContainer>
	);
};

export default AddCrmForm;
