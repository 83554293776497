import { Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
	const { t } = useTranslation('translation');
	return (
		<Container component="main" maxWidth="xl">
			<Typography variant="h1" component="h2">
				404 -
				{' '}
				{t('notFound')}
			</Typography>
		</Container>
	);
};

export default NotFound;
