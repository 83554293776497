import { useEffectCustom } from '@Hooks';
import { useFeedback } from '@Providers';
import { authApi } from '@Requests';
import { validateEmail } from '@Validation';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ForgotPasswordPage from './ForgotPasswordPage';

const ForgotPassword = () => {
	const { t } = useTranslation('translations');
	const initialMount = useRef(true);
	const { addToast, addDialog } = useFeedback();

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		if (!initialMount.current) {
			if (!email) {
				setEmailError(t('emptyField'));
			} else if (!validateEmail(email)) {
				setEmailError(t('validEmail'));
			} else {
				setEmailError('');
			}
		}
		initialMount.current = false;
	}, [email]);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const onSubmit = () => {
		if (emailError) return null;
		setLoading(true);
		authApi.forgotPassword(email)
			.then((res) => {
				addToast({
					message: res.message,
					error: false,
				});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setLoading(false));
		return null;
	};

	return (
		<ForgotPasswordPage
			email={email}
			emailError={emailError}
			onChange={onChange}
			onSubmit={onSubmit}
			loading={loading}
		/>
	);
};

export default ForgotPassword;
