import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { reservationApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Organization, Ticket } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListTicketPage from './ListTicketPage';

const ListTickets = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({
		page: 0,
		size: 10,
	});
	const [search, setSearch] = useState('');
	const [counterId, setCounterId] = useState([] as number[]);

	const getTickets = useRequest(([paginationProps, counterProps]) => (
		reservationApi.getTickets(paginationProps, counterProps)
	));

	useEffect(() => {
		setTitle(t('tickets'));
	}, [setTitle, t]);

	const getData = () => {
		const listDataControl = {
			page: pagination.page,
			size: pagination.size,
			search,
		};
		getTickets.execute(listDataControl, counterId);
	};

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			getData();
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	const handleListAction = (action: string, ticket?: Ticket, reservationId?: number) => {
		switch (action) {
		case t('edit'):
			navigate(AppRoutes.EDIT_RESERVATION_TICKETS.formatMap(({ id: ticket?.id })));
			break;
		case t('search'):
			getData();
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
			break;
		default:
			break;
		}
	};

	const handleCounterChange = (counter: Organization[]) => {
		setCounterId(counter.reduce((ids, el) => { ids.push(el.id); return ids; }, [] as number[]));
	};

	return (
		<ListTicketPage
			tickets={getTickets.data}
			setSearch={setSearch}
			setPagination={setPagination}
			handleListAction={handleListAction}
			handleCounterChange={handleCounterChange}
		/>
	);
};

export default ListTickets;
