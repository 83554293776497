/* eslint-disable camelcase */
/* eslint-disable max-len */
// import { useAuth } from '@Providers';
import React from 'react';
import {
	Route,
	Routes,
	BrowserRouter,
	Navigate,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import {
	AccountingReport,
	ChangePassword,
	CommissionAdjustment,
	CommissionReport,
	Contract,
	CreateBanner,
	CreateCampaign,
	CreateCategory,
	CreateCharacteristics,
	CreateCounter,
	CreateFine,
	CreateMaintenance,
	CreateOrganization,
	CreateQuestion,
	CreateReservation,
	CreateTariff,
	CreateUser,
	CreateVehicle,
	TrackVehicle,
	CreateWaybill,
	Crm,
	Dashboard,
	DivergentCategories,
	EditDiscount,
	EditTicket,
	FinalizeReservation,
	FinancialPending,
	GenerateBilling,
	ListAdmins,
	ListBanners,
	ListCampaign,
	ListCategory,
	ListCharacteristics,
	ListCounter,
	ListDiscount,
	ListFine,
	ListMaintenance,
	ListOrganization,
	ListQuestion,
	ListReservation,
	ListTariffs,
	ListTickets,
	ListUsers,
	ListVehicle,
	ListWaybills,
	Login,
	Main,
	MaintenanceCost,
	Message,
	NoBonds,
	NotBilled,
	NotFound,
	PaymentCard,
	ProcessToll,
	PromoteAdmin,
	Receipt,
	RemoveFromMaintenance,
	Services,
	Setting,
	TollBooking,
	Transactions,
	UngeneratedInvoices,
	VehicleDetail,
	ListBrand,
	CreateBrand,
	ChangeReservation,
	InvoiceShow,
	CreateArticle,
	ListArticle,
	ListSocialNetwork,
	CreateSocialNetwork,
	DiscountRequest,
	ForgotPassword,
	ResetPassword,
} from '@Pages';
import { useEffectCustom } from '@Hooks';
import { useAuth } from '@Providers';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseApp } from '@Api';
import { AppRoutes } from './app-links.routes';
// import moment from 'moment';

const AppRouter = () => {
	const auth = useAuth();

	//  Variables to track inactivity
	// const [events] = useState(['click', 'load', 'scroll']);
	// const [second, setSecond] = useState(0);

	// let timeStamp;
	// const warningInactiveInterval = useRef<NodeJS.Timer>() as MutableRefObject<NodeJS.Timer>;
	// const startTimerInterval = useRef<NodeJS.Timer>() as MutableRefObject<NodeJS.Timer>;

	// const warningInactive = (timeString: string) => {
	// 	clearTimeout(startTimerInterval.current);

	// 	warningInactiveInterval.current = setInterval(() => {
	// 		const maxTime = 2; // Maximum ideal time given before logout
	// 		const popTime = 1; // remaining time (notification) left to logout.

	// 		const diff = moment.duration(moment().diff(moment(timeString)));
	// 		const minPast = diff.minutes();
	// 		const leftSecond = 60 - diff.seconds();

	// 		if (minPast === popTime) {
	// 			setSecond(leftSecond);
	// 		}

	// 		if (minPast === maxTime) {
	// 			clearInterval(warningInactiveInterval.current);
	// 			sessionStorage.removeItem('lastTimeStamp');
	// 			console.log(second);
	// 		}
	// 	}, 1000);
	// };

	// /** INACTIVE time check */
	// const timeChecker = () => {
	// 	startTimerInterval.current = setTimeout(() => {
	// 		const storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
	// 		warningInactive(storedTimeStamp || '');
	// 	}, 60000);
	// };

	// // reset interval timer
	// const resetTimer = useCallback(() => {
	// 	clearTimeout(startTimerInterval.current);
	// 	clearInterval(warningInactiveInterval.current);
	// 	if (auth.user) {
	// 		timeStamp = moment();
	// 		sessionStorage.setItem('lastTimeStamp', timeStamp.toString());
	// 	} else {
	// 		clearInterval(warningInactiveInterval.current);
	// 		sessionStorage.removeItem('lastTimeStamp');
	// 	}
	// 	timeChecker();
	// 	return () => {
	// 		clearTimeout(startTimerInterval.current);
	// 	};
	// }, [auth.user]);
	// // Life cycle hook
	// useEffect(() => {
	// 	events.forEach((event) => {
	// 		window.addEventListener(event, resetTimer);
	// 	});
	// }, [resetTimer]);

	// eslint-disable-next-line react/no-unstable-nested-components
	const RequireAuth = ({ children }: { children: JSX.Element }) => {
		const { user } = useAuth();
		const navigate = useNavigate();
		const location = useLocation();

		const analytics = getAnalytics(firebaseApp);

		useEffectCustom(() => {
			const page_path = location.pathname;
			logEvent(analytics, 'page_view', {
				items: { page_path },
			});
		}, [location.pathname]);

		useEffectCustom(() => {
			if (user) {
				if (location.pathname === '/') {
					navigate(AppRoutes.DASHBOARD);
				}
			}
		}, []);

		if (!user) {
			return <Navigate to="/login" state={{ from: location }} replace />;
		}
		return children;
	};

	return (
		<BrowserRouter>
			<Routes>
				<Route path={AppRoutes.LOGIN} element={auth.user ? <Navigate to={AppRoutes.DASHBOARD} replace /> : <Login />} />
				<Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
				<Route
					path={AppRoutes.MAIN}
					element={(
						<RequireAuth>
							<Main />
						</RequireAuth>
					)}
				>
					<Route path={AppRoutes.DASHBOARD} element={<Dashboard />} />
					<Route path={AppRoutes.CHANGE_PASSWORD} element={<ChangePassword />} />
					<Route path={AppRoutes.NEW_RESERVATION} element={<CreateReservation />} />
					<Route path={AppRoutes.crm} element={<Crm />} />
					<Route path={AppRoutes.RESERVATION_CRM} element={<Crm />} />
					<Route path={AppRoutes.USER_CRM} element={<Crm />} />
					<Route path={AppRoutes.EDIT_RESERVATION} element={<CreateReservation />} />
					<Route path={AppRoutes.FINALIZE_RESERVATION} element={<FinalizeReservation />} />
					<Route path={AppRoutes.LIST_ALL_RESERVATIONS} element={<ListReservation />} />
					<Route path={AppRoutes.LIST_CAR_RESERVATIONS} element={<ListReservation />} />
					<Route path={AppRoutes.CHANGE_RESERVATION} element={<ChangeReservation />} />
					<Route path={AppRoutes.RESERVATION_SERVICES} element={<Services />} />
					<Route path={AppRoutes.RESERVATION_TRANSACTIONS} element={<Transactions />} />
					<Route path={AppRoutes.RESERVATION_TICKETS} element={<ListTickets />} />
					<Route path={AppRoutes.EDIT_RESERVATION_TICKETS} element={<EditTicket />} />
					<Route path={AppRoutes.RESERVATION_CONTRACT} element={<Contract />} />
					<Route path={AppRoutes.LIST_ALL_TRANSPORT_GUIDES} element={<ListWaybills />} />
					<Route path={AppRoutes.ADD_NEW_TRANSPORT_GUIDE} element={<CreateWaybill />} />
					<Route path={AppRoutes.EDIT_TRANSPORT_GUIDE} element={<CreateWaybill />} />
					<Route path={AppRoutes.CREATE_USER} element={<CreateUser />} />
					<Route path={AppRoutes.EDIT_USER} element={<CreateUser />} />
					<Route path={AppRoutes.LIST_USERS} element={<ListUsers />} />
					<Route path={AppRoutes.PROMOTE_USER_TO_ADMIN} element={<PromoteAdmin />} />
					<Route path={AppRoutes.NEW_MESSAGE} element={<Message />} />
					<Route path={AppRoutes.ADD_PAYMENT_CARD} element={<PaymentCard />} />
					<Route path={AppRoutes.LIST_ADMINISTRATORS} element={<ListAdmins />} />
					<Route path={AppRoutes.ORGANIZATIONS} element={<ListOrganization />} />
					<Route path={AppRoutes.ADD_NEW_ORGANIZATION} element={<CreateOrganization />} />
					<Route path={AppRoutes.EDIT_ORGANIZATION} element={<CreateOrganization />} />
					<Route path={AppRoutes.LIST_COUNTERS} element={<ListCounter />} />
					<Route path={AppRoutes.ADD_NEW_COUNTER} element={<CreateCounter />} />
					<Route path={AppRoutes.EDIT_COUNTER} element={<CreateCounter />} />
					<Route path={AppRoutes.NEW_MAINTENANCE} element={<CreateMaintenance />} />
					<Route path={AppRoutes.EDIT_MAINTENANCE} element={<CreateMaintenance />} />
					<Route path={AppRoutes.MAINTENANCE} element={<ListMaintenance />} />
					<Route path={AppRoutes.MAINTENANCE_VEHICLE} element={<ListMaintenance />} />
					<Route path={AppRoutes.REMOVE_FROM_MAINTENANCE} element={<RemoveFromMaintenance />} />
					<Route path={AppRoutes.VEHICLES} element={<ListVehicle />} />
					<Route path={AppRoutes.TRACK_VEHICLE} element={<TrackVehicle />} />
					<Route path={AppRoutes.VEHICLE_DETAIL} element={<VehicleDetail />} />
					<Route path={AppRoutes.NEW_VEHICLE} element={<CreateVehicle />} />
					<Route path={AppRoutes.EDIT_VEHICLE} element={<CreateVehicle />} />
					<Route path={AppRoutes.VEHICLE_FINES} element={<ListFine />} />
					<Route path={AppRoutes.VEHICLE_FINES_ID} element={<ListFine />} />
					<Route path={AppRoutes.NEW_VEHICLE_FINE} element={<CreateFine />} />
					<Route path={AppRoutes.EDIT_VEHICLE_FINE} element={<CreateFine />} />
					<Route path={AppRoutes.VEHICLE_CATEGORIES} element={<ListCategory />} />
					<Route path={AppRoutes.NEW_VEHICLE_CATREGORY} element={<CreateCategory />} />
					<Route path={AppRoutes.EDIT_VEHICLE_CATEGORY} element={<CreateCategory />} />
					<Route path={AppRoutes.VEHICLE_BRANDS} element={<ListBrand />} />
					<Route path={AppRoutes.NEW_VEHICLE_BRAND} element={<CreateBrand />} />
					<Route path={AppRoutes.EDIT_VEHICLE_BRAND} element={<CreateBrand />} />
					<Route path={AppRoutes.VEHICLE_CHARACTERISTICS} element={<ListCharacteristics />} />
					<Route path={AppRoutes.NEW_VEHICLE_CHARACTERISTICS} element={<CreateCharacteristics />} />
					<Route path={AppRoutes.EDIT_VEHICLE_CHARACTERISTICS} element={<CreateCharacteristics />} />
					<Route path={AppRoutes.ADD_NEW_TERRIF} element={<CreateTariff />} />
					<Route path={AppRoutes.EDIT_TERRIF} element={<CreateTariff />} />
					<Route path={AppRoutes.LIST_ALL_TERRIFS} element={<ListTariffs />} />
					<Route path={AppRoutes.DISCOUNT_CAMPAIGN} element={<ListCampaign />} />
					<Route path={AppRoutes.NEW_DISCOUNT_CAMPAIGN} element={<CreateCampaign />} />
					<Route path={AppRoutes.EDIT_DISCOUNT_CAMPAIGN} element={<CreateCampaign />} />
					<Route path={AppRoutes.DISCOUNTS_BY_QUANTITY} element={<ListDiscount />} />
					<Route path={`${AppRoutes.EDIT_DISCOUNT_BY_QUANTITY}`} element={<EditDiscount />} />
					<Route path={AppRoutes.DISCOUNT_REQUESTS} element={<DiscountRequest />} />
					<Route path={AppRoutes.TOLLS_NO_BONDS} element={<NoBonds />} />
					<Route path={AppRoutes.TOLLS_NOT_BILLED} element={<NotBilled />} />
					<Route path={AppRoutes.TOLLS_PROCESS} element={<ProcessToll />} />
					<Route path={AppRoutes.REPORTS_DIVERGENT_CATEGORIES} element={<DivergentCategories />} />
					<Route path={AppRoutes.REPORTS_PENDING_FINANCIALS} element={<FinancialPending />} />
					<Route path={AppRoutes.REPORTS_MAINTENANCE_COST} element={<MaintenanceCost />} />
					<Route path={AppRoutes.REPORTS_TOLL_BOOKING} element={<TollBooking />} />
					<Route path={AppRoutes.REPORTS_COMMISSION} element={<CommissionReport />} />
					<Route path={AppRoutes.REPORTS_ACCOUNTING} element={<AccountingReport />} />
					<Route path={AppRoutes.REPORTS_UNGENERATED_INVOICES} element={<UngeneratedInvoices />} />
					<Route path={AppRoutes.REPORTS_COMMISSION_ADJUSTMENT} element={<CommissionAdjustment />} />
					<Route path={AppRoutes.REPORTS_TRANSACTIONS_GENERATE_BILLING} element={<GenerateBilling />} />
					<Route path={AppRoutes.LIST_ARTICLES} element={<ListArticle />} />
					<Route path={AppRoutes.CREATE_ARTICLES} element={<CreateArticle />} />
					<Route path={AppRoutes.CREATE_ARTICLES} element={<CreateArticle />} />
					<Route path={AppRoutes.EDIT_ARTICLES} element={<CreateArticle />} />
					<Route path={AppRoutes.SOCIAL_NETWORK} element={<ListSocialNetwork />} />
					<Route path={AppRoutes.EDIT_SOCIAL_NETWORK} element={<CreateSocialNetwork />} />
					<Route path={AppRoutes.CREATE_SOCIAL_NETWORK} element={<CreateSocialNetwork />} />
					<Route path={AppRoutes.FAQS} element={<ListQuestion />} />
					<Route path={AppRoutes.ADD_NEW_FAQ} element={<CreateQuestion />} />
					<Route path={AppRoutes.EDIT_FAQ} element={<CreateQuestion />} />
					<Route path={AppRoutes.APP_BANNERS} element={<ListBanners />} />
					<Route path={AppRoutes.ADD_NEW_BANNER} element={<CreateBanner />} />
					<Route path={AppRoutes.EDIT_BANNER} element={<CreateBanner />} />
					<Route path={AppRoutes.DEFINITIONS} element={<Setting />} />
					<Route path={AppRoutes.INVOICES} element={<InvoiceShow />} />
					<Route path={AppRoutes.RECEIPT} element={<Receipt />} />
				</Route>
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRouter;
