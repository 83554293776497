import { Autocomplete, Space, TextField } from '@Atoms';
import { acceptNumbers, currency } from '@Masks';
import { PaperContainer } from '@Molecules';
import { AddCircle } from '@mui/icons-material';
import {
	CircularProgress, Grid, IconButton, Typography,
} from '@mui/material';
import { useDropdown } from '@Providers';
import { DiscountItemReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EditDiscountForm {
	initialValues: DiscountItemReq;
	handleSubmit: (values: DiscountItemReq, formikHelpers: FormikHelpers<DiscountItemReq>) => void;
	validate: (values: DiscountItemReq) => FormikErrors<DiscountItemReq>;
	name: unknown
}

const DISCOUNT_VALUE_MAX_LENGTH = 7;

const EditDiscountForm: FC<EditDiscountForm> = (props) => {
	const {
		initialValues, validate, handleSubmit, name,
	} = props;
	const { t } = useTranslation('translations');
	const { vehicleCategories } = useDropdown();

	const discountType = [
		{ id: 1, name: t('percentage') },
		{ id: 2, name: t('absolute') },
	];

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Typography fontWeight="bold" color="textSecondary">{t('addDiscountItem').format(name)}</Typography>
					<Space height={1} />
					<Typography variant="h5" fontWeight="lighter">{t('newDiscount')}</Typography>
					<Space height={2} />
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values, errors, touched, setFieldValue, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<TextField
											label={t('from')}
											disabled={false}
											type="number"
											onChange={(e) => {
												if (parseInt(e.target.value, 10) > 0) {
													setFieldValue('minAmount', parseInt(e.target.value, 10).toFixed(0));
												}
											}}
											value={values.minAmount}
											id="from"
											error={touched.minAmount && Boolean(errors.minAmount)}
											helperText={touched.minAmount && errors.minAmount}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<TextField
											label={t('discount')}
											id="discount"
											type="text"
											inputProps={{ maxLength: DISCOUNT_VALUE_MAX_LENGTH }}
											value={values.discount}
											disabled={false}
											error={touched.discount && Boolean(errors.discount)}
											helperText={touched.discount && errors.discount}
											onChange={(event) => setFieldValue('discount', values.isPercentage ? acceptNumbers(event).target.value : currency(event).target.value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<Autocomplete
											label={t('type')}
											options={discountType}
											defaultValue={values.isPercentage ? discountType[0] : discountType[1]}
											placeholder=""
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name}
											onChange={(_, item) => {
												if (item?.id === 1) {
													setFieldValue('isPercentage', true);
												}
												if (item?.id === 2) {
													setFieldValue('isPercentage', false);
												}
											}}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('categor')}
											options={vehicleCategories || []}
											groupBy={(item) => item.tariff.name}
											defaultValue={values.categoryId}
											placeholder=""
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											error={touched.categoryId && Boolean(errors.categoryId)}
											helperText={touched.categoryId && String(errors.categoryId || '')}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('categoryId', item)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
										<IconButton
											type="submit"
											color="primary"
											aria-label="add discount"
											disabled={isSubmitting}
										>
											{isSubmitting ? <CircularProgress size={25} /> : <AddCircle />}
										</IconButton>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default EditDiscountForm;
