import { Space } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { discountApi } from '@Requests';
import {
	Category, DiscountItem, DiscountItemReq, ItemRelatedToDiscount,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import EditDiscountForm from './EditDiscountForm';
import ListQuantityDiscounts from './ListQuantityDiscounts';

const EditDiscount = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { state } = useLocation();
	const { id } = useParams();
	const { addToast, addDialog } = useFeedback();

	const [initialValues] = useState({
		categoryId: {} as Category,
		minAmount: 1,
		discount: '',
		isPercentage: false,
	});
	const [billableItem, setBillableItem] = useState({} as ItemRelatedToDiscount<DiscountItem[]>);
	const [deleteActivity, setDeleteActivity] = useState(false);

	useEffect(() => {
		setTitle(`${t('discountFor')} ${state}`);
	}, [setTitle, t, state]);
	const getData = () => {
		discountApi
			.getAllItemsOfDiscount(id ?? '')
			.then((res) => setBillableItem(res.data))
			.catch((err) => {
				throw err;
			});
	};

	useEffectCustom(() => {
		if (id) {
			getData();
		}
	}, []);

	const validateDiscount = (values: DiscountItemReq) => {
		const errors = {} as FormikErrors<DiscountItemReq>;
		if (Object.keys(values.categoryId).length === 0) {
			errors.categoryId = t('emptyField');
		}
		if (!values.minAmount) {
			errors.minAmount = t('emptyField');
		}
		if (!values.discount) {
			errors.discount = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (values: DiscountItemReq, formikHelpers: FormikHelpers<DiscountItemReq>) => {
		discountApi.addNewBillableItem(id ?? '', values)
			.then((res) => {
				getData();
				formikHelpers.resetForm({});
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	const handleDelete = (itemDiscountId: number, index: number) => {
		setGlobalLoading(true);
		setDeleteActivity(true);
		discountApi
			.deleteBillableDiscount(id ?? '', itemDiscountId)
			.then((res) => {
				const tempItems = { ...billableItem };
				tempItems?.items?.splice(index, 1);
				setBillableItem(tempItems);
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => {
				setDeleteActivity(false);
				setGlobalLoading(false);
			});
	};

	return (
		<>
			<EditDiscountForm
				initialValues={initialValues}
				handleSubmit={handleSubmit}
				validate={validateDiscount}
				name={state}
			/>
			<Space height={2} />
			{
				Object.keys(billableItem).length !== 0
			&& (
				<ListQuantityDiscounts
					billableItems={billableItem}
					handleDelete={handleDelete}
					loading={deleteActivity}
				/>
			)
			}
		</>
	);
};

export default EditDiscount;
