import {
	DatePicker, DefaultButton, Space, TimePicker,
} from '@Atoms';
import { padId } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { DoubleArrow, Search } from '@mui/icons-material';
import {
	Box, Divider, Grid, Typography, useTheme,
} from '@mui/material';
import { Reservation } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ReservationSearchProps {
   onReservationSearch: () => void;
   onChange: (key: string, value: Date) => void;
   reservation?: Reservation;
   loading?: boolean;
   error?: string;
   onResClick: (id: number) => void;
   date: {date: Date, time: Date};
   reservationError?: boolean
}

const ReservationSearch: FC<ReservationSearchProps> = ({
	onChange,
	onReservationSearch,
	reservation,
	loading,
	error,
	onResClick,
	date,
	reservationError,
}) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<PaperContainer title={t('reservationSearch')} dropdown>
			<Typography>{t('dateTimeText')}</Typography>
			<Space height={2} />
			<Grid container spacing={3}>
				<Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
					<DatePicker
						label={t('date')}
						value={date.date}
						error={!!error}
						onChange={(value) => onChange('date', value)}
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
					<TimePicker
						label={t('hour')}
						value={date.time}
						onChange={(value) => onChange('time', value)}
					/>
				</Grid>
			</Grid>
			<Space height={2} />
			<DefaultButton
				startIcon={<Search />}
				title={t('search')}
				onClick={onReservationSearch}
				loading={loading}
			/>
			<Space height={2} />
			<Divider />
			<Space height={2} />
			{reservation && (
				<Box sx={{
					bgcolor: theme.palette.success.main,
					p: 1,
					borderRadius: 1,
				}}
				>
					<Typography color="info" variant="caption">
						{t('dateSearchString')}
						{' '}
						{padId(reservation.id, 6)}
					</Typography>
					<DefaultButton
						startIcon={<DoubleArrow />}
						title={`${t('acess')} ${t('reservation')}`}
						color="info"
						onClick={() => onResClick(reservation.id)}
					/>
				</Box>
			)}
			{reservationError !== undefined && reservationError === true && <Typography variant="caption" color="error">{t('notUsedOnDate')}</Typography>}
			<Space height={2} />
			<Divider />
			<Space height={2} />
		</PaperContainer>
	);
};

export default ReservationSearch;
