import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import * as locales from '@mui/material/locale';
import React, {
	createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { enUS, pt } from 'date-fns/locale';

export type SupportedLocales = keyof typeof locales;

export interface LanguageContextProps {
	locale: locales.Localization;
	changeLocale: (item: SupportedLocales) => void;
	localeFns: Locale
}

const localeMap = {
	enUS,
	ptPT: pt,
};

export const LanguageContext = createContext<LanguageContextProps>({} as LanguageContextProps);

export const LanguageProvider: React.FC = ({ children }) => {
	const { i18n } = useTranslation();
	const localeKey = i18n.language.substring(0, 2) === 'en' ? 'enUS' : 'ptPT';
	const savedLocaleJson: keyof typeof localeMap = LocalStorageWrapper.get(LocalStorageKeys.LOCALE)
		?? localeKey;
	const savedLocale = locales[savedLocaleJson as SupportedLocales];
	const [locale, setLocale] = useState<locales.Localization>(savedLocale);
	const [localeFns, setLocaleFns] = useState<Locale>(localeMap[savedLocaleJson]);

	const changeLocale = useCallback((lang: SupportedLocales) => {
		i18n.changeLanguage(lang.substring(0, 2));
		const mLocale = locales[lang as SupportedLocales];
		setLocale(mLocale);
		setLocaleFns(localeMap[lang as keyof typeof localeMap]);
		LocalStorageWrapper.set(LocalStorageKeys.LOCALE, lang);
		window.location.reload();
	}, [i18n]);
	const value = useMemo(() => (
		{ locale, changeLocale, localeFns }
	), [locale, changeLocale, localeFns]);
	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default function useLanguage() {
	return useContext(LanguageContext);
}
