/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontSizes } from '@Constant';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';

interface DropdownProps<T> {
   variant?: 'text' | 'outlined' | 'contained';
   data: T[];
   label: string;
   renderItem: (item: T) => { optionValue: any; optionLabel: string };
   onChange: (value: any) => void;
   disabled?: boolean;
}

const Dropdown = <T, >(props: DropdownProps<T>) => {
	const {
		data, variant = 'contained', label, onChange, disabled, renderItem,
	} = props;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSelect = (option: any) => {
		setAnchorEl(null);
		onChange(option);
	};

	return (
		<div>
			<Button
				size="small"
				variant={variant}
				disableElevation
				disabled={disabled}
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{ textTransform: 'none' }}
				endIcon={<KeyboardArrowDown />}
			>
				{label}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{data.map((el) => {
					const { optionValue, optionLabel } = renderItem(el);
					return (
						<MenuItem
							sx={{ fontSize: FontSizes.MD }}
							value={optionValue}
							key={`dropdown-options-${optionLabel}`}
							onClick={() => {
								onSelect(optionValue);
							}}
						>
							{optionLabel}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

export default Dropdown;
