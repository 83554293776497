import {
	Administrator, ApiResponse, ListDataControl, Pagination,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getAdministrators(params: ListDataControl):
   Promise<ApiResponse<Pagination<Administrator[]>>> {
	const resp = await reqGet({
		url: Endpoint.GET_ADMINS.format(params.page, params.size, params.search),
	});

	const { data } = resp;
	return data;
}

export async function changeAdminStatus(adminId: number): Promise<ApiResponse<Administrator>> {
	const resp = await reqPost({
		url: Endpoint.CHANGE_ADMIN_STATUS.format(adminId),
		data: { adminId },
	});
	const { data } = resp;
	return data;
}
