import { useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useRequest } from '@Api';
import { useTranslation } from 'react-i18next';
import { reportApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { useNavigate } from 'react-router-dom';
import { useEffectCustom } from '@Hooks';
import { getFirstDayOfMonth } from '@Helpers';
import DivergentCategoriesPage from './DivergentCategoriesPage';

const DivergentCategories = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();

	const [dateRange, setDateRange] = useState({
		startDate: getFirstDayOfMonth(),
		endDate: new Date(),
	});
	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	const reportsData = useRequest(() => (
		reportApi.getDivergentCategories(
			dateRange.startDate,
			dateRange.endDate,
			pagination.page,
			pagination.size,
			search,
		)));

	useEffectCustom(() => {
		if (dateRange.startDate && dateRange.endDate) {
			reportsData.execute();
		}
	}, [pagination, search]);

	const handleChange = (value: Date, key: string) => {
		setDateRange({ ...dateRange, [key]: value });
	};

	const handleListAction = (action: string, id?: number) => {
		switch (action) {
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
			break;
		case t('search'):
			reportsData.execute();
			break;
		default:
			break;
		}
	};

	useEffect(() => {
		setTitle(t('divergentCategoriesBooking'));
	}, [setTitle, t]);

	return (
		<DivergentCategoriesPage
			dateValues={dateRange}
			reportData={reportsData.data}
			handleChange={handleChange}
			handleListAction={handleListAction}
			loading={reportsData.loading}
			setSearch={setSearch}
			setPagination={setPagination}
		/>
	);
};

export default DivergentCategories;
