import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TollReport, PaginationTable, Pagination } from '@Types';
import { Grid, Typography } from '@mui/material';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { TableItem, DefaultButton } from '@Atoms';
import { formatDate, padId } from '@Helpers';
import { FontSizes } from '@Constant';

interface TollBookingProps {
	data?: Pagination<TollReport[]>;
	handleReservationClick: (id: number) => void;
	setPagination: (params: PaginationTable) => void;
	setSearch: (search: string) => void
}

const TollBookingPage: FC<TollBookingProps> = (props) => {
	const {
		data, handleReservationClick, setPagination, setSearch,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<DataTable
						data={data?.data || []}
						total={data?.total}
						pagination
						onSearching={setSearch}
						onChange={(page, size) => setPagination({ page, size })}
					>
						<TableColumn
							headerText={t('survey')}
							render={(item) => (
								<TableItem
									title={item.pickupName}
									subtitle={formatDate(item.startDate)}
								/>
							)}
						/>
						<TableColumn
							headerText={t('delivery')}
							render={(item) => (
								<TableItem
									title={item.returnName}
									subtitle={formatDate(item.endDate)}
								/>
							)}
						/>
						<TableColumn headerText={t('tollQuantity')} render={(item) => item.tollsCount} />
						<TableColumn
							headerText={t('reservation')}
							render={(item) => (
								<DefaultButton
									title={padId(item.reservationId, 4)}
									onClick={() => handleReservationClick(item.reservationId)}
								/>
							)}
						/>
					</DataTable>
					{!data && <Typography sx={{ fontSize: FontSizes.XL, textAlign: 'center' }}>{t('noRecords')}</Typography>}
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default TollBookingPage;
