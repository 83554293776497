import {
	DefaultButton, Description, Image, Space, TextField, Title,
} from '@Atoms';
import { logo } from '@Images';
import {
	Box, Container, Stack, Typography,
} from '@mui/material';
import { NameAndId } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InitialValues } from '.';

interface ResetPasswordProps {
	initialValues: InitialValues;
	submit: (values: InitialValues, formikHelpers: FormikHelpers<InitialValues>) => void;
	validate: (values: InitialValues) => FormikErrors<InitialValues>;
	user: NameAndId | null;
	expired: boolean;
	success: boolean;
}

const ResetPasswordPage: FC<ResetPasswordProps> = (props) => {
	const {
		initialValues, submit, validate, user, expired, success,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Container>
			<Container maxWidth="xs">
				<Space height={5} />

				<Image src={logo} height="100%" width="100%" alt="AJ Rent Logo" />
				{success && (
					/** If User have changed password just show this message */
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Title text={t('sucess')} textAlign="center" />
						<Typography variant="caption" sx={{ textAlign: 'center', alignSelf: 'center' }}>{t('passwordChangeSuccess')}</Typography>
					</Box>
				)}

				<Space height={3} />
				{!expired ? (
					/** If User have changed Password donot show input */
					!success && (
						<>
							<Stack direction="column" alignItems="center">
								<Space height={2} />
								<Title text={user?.name ?? ''} />
								<Space height={1} />
								<Description text={t('fillPasswordFields')} />
								<Space height={2} />
							</Stack>
							<Formik initialValues={initialValues} onSubmit={submit} validate={validate}>
								{({
									isSubmitting, values, handleChange, errors,
								}) => (
									<Form>
										<TextField
											label={t('newPassword')}
											name="password"
											type="password"
											value={values.password}
											onChange={handleChange}
											error={!!errors.password}
											helperText={errors.password}
											disabled={isSubmitting}
										/>

										<Space height={2} />

										<TextField
											label={t('confirmPassword')}
											name="confirmPassword"
											type="password"
											value={values.confirmPassword}
											onChange={handleChange}
											error={!!errors.confirmPassword}
											helperText={errors.confirmPassword}
											disabled={isSubmitting}
										/>

										<Space height={2} />

										<DefaultButton
											fullWidth
											title={t('update')}
											type="submit"
											loading={isSubmitting}
										/>
									</Form>
								)}
							</Formik>
						</>
					)
				) : (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Title text={t('expiredLink')} textAlign="center" />
						<Typography variant="caption" sx={{ textAlign: 'center', alignSelf: 'center' }}>{t('expiredLinkDescription')}</Typography>
					</Box>
				)}
				<Space height={3} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						{`${process.env.REACT_APP_NAME} - ${t('copyright')} © ${new Date().getFullYear()}`}
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default ResetPasswordPage;
