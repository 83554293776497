import {
	FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import React, { FC } from 'react';

interface RadioProps {
   options: { label: string; value: string }[];
   subText: string[];
   onChange: (value: string) => void;
}

const RadioButton: FC<RadioProps> = ({ options, subText, onChange }) => (
	<FormControl component="fieldset">
		<RadioGroup aria-label="gender" defaultValue={options[0].value} name="radio-buttons-group" onChange={(_, value) => onChange(value)}>
			{options.map((el, index) => (
				<div key={`radio-${index.toString()}`}>
					<FormControlLabel value={el.value} control={<Radio />} label={el.label} />
					<Typography>{subText[index]}</Typography>
				</div>
			))}
		</RadioGroup>
	</FormControl>
);

export default RadioButton;
