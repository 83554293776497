import {
	DefaultButton, Image, ListItem, LocationListItem, Space,
} from '@Atoms';
import { FontSizes } from '@Constant';
import { PaperContainer } from '@Molecules';
import {
	Article,
	BarChart, Edit, Settings, Timeline,
} from '@mui/icons-material';
import {
	Box, Grid, Stack, Typography,
} from '@mui/material';
import { Car } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface VehicleProps {
   car?: Car;
   handleListAction: (route: string) => void;
}

const VehicleCard: FC<VehicleProps> = ({ car, handleListAction }) => {
	const { t } = useTranslation('translations');
	return (
		<PaperContainer title={t('vehicleDetail')} dropdown>
			<Stack direction="column" alignItems="center">
				<Image src={car?.image || ''} alt="vehicle" height="300px" width="100%" />
			</Stack>

			<Box sx={{
				pt: '10px',
			}}
			>
				<Typography sx={{
					fontSize: FontSizes.LG,
					fontWeight: 'bold',
				}}
				>
					{`${car?.manufactory.name || ''} ${car?.name || ''} (${car?.year || ''})`}

				</Typography>
				<LocationListItem title="Location" value={car?.office.officialName || ''} />
				<Grid container spacing={3}>
					<Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
						<ListItem title={t('enrollment')} value={car?.licensePlate || ''} />
						<ListItem title={t('chassis')} value={car?.chassis || ''} />
						<ListItem title={t('year')} value={car?.year || ''} />
					</Grid>
					<Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
						<ListItem title={t('category')} value={car?.categories[0]?.name || ''} />
						<ListItem title={t('fuel')} value={car?.fuelType.name || ''} />
						<ListItem title={t('mileage')} value={`${car?.mileage} km`} />
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item lg={4} xl={4} md={5} sm={12} xs={12}>
						<BarChart />
						<Typography sx={{
							fontSize: FontSizes.LG,
							color: 'grey',
						}}
						>
							{`${car?.rents} ${t('rental')}`}

						</Typography>
					</Grid>
					<Grid item lg={8} xl={8} md={7} sm={12} xs={12}>
						<Timeline />
						<Typography sx={{
							fontSize: FontSizes.LG,
							color: 'grey',
						}}
						>
							{`${car?.maintenances} ${t('maintenance')}`}
						</Typography>
					</Grid>
				</Grid>
				<Space height={2} />
				<Grid container spacing={3}>
					<Grid item lg={6} xl={4} md={6} sm={12} xs={12}>
						<DefaultButton
							title={t('edit')}
							onClick={() => {
								handleListAction(t('edit'));
							}}
							startIcon={<Edit />}
							fullWidth
						/>
					</Grid>
					<Grid item lg={6} xl={4} md={6} sm={12} xs={12}>
						<DefaultButton
							title={t('maintenance')}
							onClick={() => { handleListAction(t('maintenance')); }}
							startIcon={<Settings />}
							fullWidth
						/>
					</Grid>
					<Grid item lg={12} xl={4} md={12} sm={12} xs={12}>
						<DefaultButton
							title={t('fines')}
							onClick={() => {
								handleListAction(t('fines'));
							}}
							fullWidth
							startIcon={<Article />}
						/>
					</Grid>
				</Grid>
			</Box>
		</PaperContainer>
	);
};

export default VehicleCard;
