import {
	DatePicker, DefaultButton, Space, TextField,
} from '@Atoms';
import { currency } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Divider, Grid } from '@mui/material';
import { MaintenanceInfo, RemoveMaintenanceReq } from '@Types';
import {
	Formik, Form, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface RemoveMaintenanceProps {
	initialValues: RemoveMaintenanceReq;
	data?: MaintenanceInfo;
	validate: (values: RemoveMaintenanceReq) => FormikErrors<RemoveMaintenanceReq>;
	handleSubmit: (
		values: RemoveMaintenanceReq,
		formikHelpers: FormikHelpers<RemoveMaintenanceReq>
		) => void
}

const RemoveFromMaintenancePage: FC<RemoveMaintenanceProps> = (props) => {
	const {
		initialValues,
		data,
		validate,
		handleSubmit,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values,
							touched,
							setFieldValue,
							errors,
							handleChange,
							isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<TextField
											id="vehicle"
											label={t('vehicl')}
											title=""
											helperText=""
											type="text"
											disabled
											value={`${data?.carPlate} ${data?.carName}(${data?.carYear})`}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<TextField
											label={t('local')}
											id="local"
											InputLabelProps={{ shrink: true }}
											title=""
											helperText=""
											type="text"
											disabled
											value={data?.organizationName}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<DatePicker
											label={t('entry')}
											value={data?.startDate}
											disabled
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<DatePicker
											label={t('expectedExit')}
											value={data?.intendedOutDate}
											disabled
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<DatePicker
											label={t('returnDate')}
											disabled={isSubmitting}
											value={values.endDate}
											minDate={new Date(data?.startDate || '')}
											error={touched.endDate && Boolean(errors.endDate)}
											helperText={touched.endDate && String(errors.endDate || '')}
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<TextField
											id="amount"
											title=""
											disabled={isSubmitting}
											label={t('budget')}
											value={values.amount}
											error={touched.amount && Boolean(errors.amount)}
											helperText={touched.amount && errors.amount}
											type="text"
											onChange={(event) => setFieldValue('amount', currency(event).target.value)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
										<TextField
											multiline
											minRows={3}
											disabled={isSubmitting}
											label={t('comments')}
											error={touched.description && Boolean(errors.description)}
											helperText={touched.description && errors.description}
											value={values.description}
											id="description"
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<DefaultButton title={t('translations:save')} type="submit" loading={isSubmitting} />
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default RemoveFromMaintenancePage;
