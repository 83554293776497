import { formatCurrency } from '@Helpers';
import { useEffectCustom } from '@Hooks';
import { useDropdown, useFeedback, useTitleBar } from '@Providers';
import { carsApi, featureApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { CarFeatureMin, CarReq } from '@Types';
import { FormikHelpers, FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateVehiclePage from './CreateVehiclePage';

const STATUS_ACTIVE = 1;
const CONTRACT_TYPE = 2;

const CreateVehicle = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const { addDialog, addToast } = useFeedback();
	const navigate = useNavigate();

	const { setCars } = useDropdown();

	const [initialValue, setInitialValues] = useState({
		name: '',
		shortName: '',
		purchasePrice: '',
		licensePlate: '',
		licenseDate: new Date(),
		chassis: '',
		mileage: 0,
		year: 0,
		firstInspection: 0,
		inspectionFrequency: 0,
		fuelLevel: 8,
	} as CarReq);
	const [featureWarning, setFeatureWarning] = useState({ vehicle: '', contract: '' });

	useEffect(() => {
		if (id) {
			setTitle(`${t('edit')} ${t('vehicle')}`);
		} else {
			setTitle(`${t('new')} ${t('vehicle')}`);
			setFeatureWarning({
				vehicle: t('selectionInstruct'),
				contract: t('contractInstruct'),
			});
		}
	}, [setTitle, id, t]);

	const getFeatures = async () => {
		const listDataControl = {
			page: 1,
			size: 10000,
			search: '',
		};
		const mInputs = [] as CarFeatureMin[];
		const mVehicleChecks = [] as CarFeatureMin[];
		const mContractChecks = [] as CarFeatureMin[];
		const { data } = await featureApi.getFeatures(listDataControl);

		data.data.forEach((element) => {
			/** CHECK IF THE FEATURE IS MARKED ACTIVE AND ITS OF TYPE QUANTITATIVE */
			if (!element.isBoolean && element.status.id === STATUS_ACTIVE) {
				mInputs.push({
					name: element.name,
					highlight: false,
					value: 0,
					featureId: element.id,
				} as CarFeatureMin);
			}
			/** CHECK IF THE FEATURE IS MARKED ACTIVE AND ITS OF TYPE NOT QUANTITAVE */
			if (element.isBoolean && element.status.id === STATUS_ACTIVE) {
				/** CHECK IF THE FEATURE IS MARKED ACTIVE AND ITS OF TYPE CONTRACT FEATURE or Vehicle */
				if (element.type.id === CONTRACT_TYPE) {
					mContractChecks.push({
						name: element.name,
						value: 0,
						highlight: false,
						featureId: element.id,
					});
				} else {
					mVehicleChecks.push({
						name: element.name,
						value: 0,
						highlight: false,
						featureId: element.id,
					});
				}
			}
		});

		return { mInputs, mVehicleChecks, mContractChecks };
	};

	const getVehicle = async () => {
		carsApi.getCarByID(id)
			.then(async ({ data }) => {
				const listDataControl = {
					page: 1,
					size: 10000,
					search: '',
				};
				const mInputs = [] as CarFeatureMin[];
				const mVehicleChecks = [] as CarFeatureMin[];
				const mContractChecks = [] as CarFeatureMin[];
				const features = await featureApi.getFeatures(listDataControl);
				features.data.data.forEach((element) => {
					/** CHECK IF THE FEATURE IS MARKED ACTIVE AND ITS OF TYPE QUANTITATIVE */
					if (!element.isBoolean && element.status.id === STATUS_ACTIVE) {
						const foundFeature = data.features.find((el) => el.featureId === element.id);
						if (foundFeature) {
							mInputs.push({
								name: foundFeature.name,
								highlight: foundFeature.highlight,
								value: foundFeature.value,
								featureId: element.id,
							} as CarFeatureMin);
						} else {
							mInputs.push({
								name: element.name,
								highlight: false,
								value: 0,
								featureId: element.id,
							} as CarFeatureMin);
						}
					}
					/** CHECK IF THE FEATURE IS MARKED ACTIVE AND ITS OF TYPE NOT QUANTITAVE */
					if (element.isBoolean && element.status.id === STATUS_ACTIVE) {
						/** CHECK IF THE FEATURE IS MARKED ACTIVE AND ITS OF TYPE CONTRACT FEATURE or Vehicle */
						if (element.type.id === CONTRACT_TYPE) {
							const foundFeature = data.features.find((el) => el.featureId === element.id);
							if (foundFeature) {
								mContractChecks.push({
									name: foundFeature.name,
									highlight: foundFeature.highlight,
									value: foundFeature.value,
									featureId: element.id,
								} as CarFeatureMin);
							} else {
								mContractChecks.push({
									name: element.name,
									value: 0,
									highlight: false,
									featureId: element.id,
								});
							}
						} else {
							const foundFeature = data.features.find((el) => el.featureId === element.id);
							if (foundFeature) {
								mVehicleChecks.push({
									name: foundFeature.name,
									highlight: foundFeature.highlight,
									value: foundFeature.value,
									featureId: element.id,
								} as CarFeatureMin);
							} else {
								mVehicleChecks.push({
									name: element.name,
									value: 0,
									highlight: false,
									featureId: element.id,
								});
							}
						}
					}
				});
				setInitialValues({
					fuelTypeId: data.fuelType,
					fuelLevel: data.fuelLevel || 0,
					licenseDate: new Date(data.licenseDate),
					cubicCapacity: String(data.cubicCapacity),
					firstInspection: data.firstInspection,
					inspectionFrequency: data.inspectionFrequency,
					colorId: data.color,
					supplierId: data.supplier,
					purchasePrice: formatCurrency(data.purchasePrice),
					shortName: data.shortName,
					image: {} as File,
					imageLink: data.image,
					coverImage: {} as File,
					coverImageLink: data.coverImage,
					officeId: data.office,
					licensePlate: data.licensePlate,
					carTypeId: data.carType,
					fleetId: data.fleet,
					year: data.year,
					mileage: data.mileage,
					chassis: data.chassis,
					brandId: data.manufactory,
					name: data.name,
					categories: data.categories,
					featureInputs: mInputs,
					vehicleCheckboxes: mVehicleChecks,
					contractCheckboxes: mContractChecks,
				});
			});
	};

	const setInitialFormValues = async () => {
		const { mInputs, mContractChecks, mVehicleChecks } = await getFeatures();
		setInitialValues({
			...initialValue,
			featureInputs: mInputs,
			vehicleCheckboxes: mVehicleChecks,
			contractCheckboxes: mContractChecks,
		});
	};

	useEffectCustom(() => {
		if (id) {
			getVehicle();
		} else {
			setInitialFormValues();
		}
	}, []);

	const handleSubmit = (values: CarReq, formikHelpers: FormikHelpers<CarReq>) => {
		if (id) {
			carsApi.updateCar(values, id)
				.then((res) => {
					addToast({
						message: res.message,
						error: false,
					});
					navigate(AppRoutes.VEHICLES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			carsApi.saveCar(values)
				.then((res) => {
					addToast({
						message: res.message,
						error: false,
					});
					setCars((prev) => {
						const mPrev = prev;
						mPrev?.data.push(res.data);
						return mPrev;
					});
					navigate(AppRoutes.VEHICLES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const validate = (values: CarReq) => {
		const errors = {} as FormikErrors<CarReq>;
		if (!values.brandId) {
			errors.brandId = t('emptyField');
		}
		if (values.brandId && Object.keys(values.brandId).length === 0) {
			errors.brandId = t('emptyField');
		}
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.shortName) {
			errors.shortName = t('emptyField');
		}
		if (!values.colorId) {
			errors.colorId = t('emptyField');
		}
		if (values.colorId && Object.keys(values.colorId).length === 0) {
			errors.colorId = t('emptyField');
		}
		if (!values.carTypeId) {
			errors.carTypeId = t('emptyField');
		}
		if (values.carTypeId && Object.keys(values.carTypeId).length === 0) {
			errors.carTypeId = t('emptyField');
		}
		if (!values.categories) {
			errors.categories = t('emptyField');
		}
		if (values.categories && values.categories.length === 0) {
			errors.categories = t('emptyField');
		}
		if (!values.officeId) {
			errors.officeId = t('emptyField');
		}
		if (values.officeId && Object.keys(values.officeId).length === 0) {
			errors.officeId = t('emptyField');
		}
		if (!values.supplierId) {
			errors.supplierId = t('emptyField');
		}
		if (values.supplierId && Object.keys(values.supplierId).length === 0) {
			errors.supplierId = t('emptyField');
		}
		if (!values.purchasePrice) {
			errors.purchasePrice = t('emptyField');
		}
		if (!values.fleetId) {
			errors.fleetId = t('emptyField');
		}
		if (values.fleetId && Object.keys(values.fleetId).length === 0) {
			errors.fleetId = t('emptyField');
		}
		if (!values.fuelTypeId) {
			errors.fuelTypeId = t('emptyField');
		}
		if (values.fuelTypeId && Object.keys(values.fuelTypeId).length === 0) {
			errors.fuelTypeId = t('emptyField');
		}
		if (!values.licensePlate) {
			errors.licensePlate = t('emptyField');
		}
		if (!values.licenseDate) {
			errors.licenseDate = t('emptyField');
		}
		if (!values.chassis) {
			errors.chassis = t('emptyField');
		}
		if (values.mileage === null || values.mileage === undefined) {
			errors.mileage = t('emptyField');
		}
		if (!values.year) {
			errors.year = t('emptyField');
		}
		if (!values.cubicCapacity) {
			errors.cubicCapacity = t('emptyField');
		}
		if (values.firstInspection === null || values.firstInspection === undefined) {
			errors.firstInspection = t('emptyField');
		}
		if (!values.inspectionFrequency) {
			errors.inspectionFrequency = t('emptyField');
		}
		const vehCheckLength = values.vehicleCheckboxes.reduce((sum, element) => {
			let mSum = sum;
			if (element.highlight) {
				mSum += 1;
			}
			return mSum;
		}, 0);

		if (vehCheckLength === 0) {
			addDialog({
				message: t('featureLessFour'),
				error: true,
			});
		}
		if (vehCheckLength > 4) {
			addDialog({
				title: t('featureGreaterThanFourTitle'),
				message: t('featureGreaterThanFourMessage'),
				error: true,
			});
		}
		if (vehCheckLength !== 4) {
			errors.vehicleCheckboxes = t('selectionInstruct');
		} else if (vehCheckLength >= 4) {
			setFeatureWarning(() => ({ ...featureWarning, vehicle: '' }));
		}
		const conCheckLength = values.contractCheckboxes.reduce((sum, element) => {
			let mSum = sum;
			if (element.highlight) {
				mSum += 1;
			}
			return mSum;
		}, 0);
		if (conCheckLength < 1) {
			errors.contractCheckboxes = t('contractInstruct');
		} else {
			setFeatureWarning({ ...featureWarning, contract: '' });
		}
		return errors;
	};

	return (
		<CreateVehiclePage
			initialValues={initialValue}
			handleSubmit={handleSubmit}
			validate={validate}
			warning={featureWarning}
		/>
	);
};

export default CreateVehicle;
