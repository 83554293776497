/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import {
	Edit,
	Menu,
	Loop,
	People,
	AccountBalance,
	Store,
	DriveEta,
	Build,
	LocalAtm,
	Euro,
	Toll,
	Assessment,
	LiveHelp,
	ViewCarousel,
	MenuBook,
	AddCircle,
	List,
	AccountCircle,
	ArrowRight,
	ConfirmationNumber,
	FormatListBulleted,
	ListAlt,
	CreditCard,
	Send,
	AllInclusive,
	Campaign,
	ConnectWithoutContact,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
	Dashboard,
} from '@Pages';
import { AppRoutes } from '@Routes';
import { useSetting } from '@Providers';
import { useTheme } from '@mui/material';

export interface ROUTES_TYPES {
   route: string;
   icon: any;
   component: any;
   link: string;
   children: { title: string; icon: ReactNode; route: string }[] | null;
   active: boolean;
}

const MAIN_COUNTER = process.env.REACT_APP_MAIN_COUNTER;

export const useSidebarRoutes = (): {
	sidebarRoutes: ROUTES_TYPES[], setSidebarRoutes: Dispatch<SetStateAction<ROUTES_TYPES[]>>} => {
	const { t, i18n } = useTranslation(['translations']);
	const { counter } = useSetting();
	const theme = useTheme();

	const [sidebarRoutes, setSidebarRoutes] = useState([] as ROUTES_TYPES[]);

	const iconStyle = {
		color: theme.palette.border.darker,
		fontSize: 15,
		padding: 0,
	};

	const setRoutes = () => {
		if (counter.toString() === (MAIN_COUNTER)) {
			setSidebarRoutes([
				{
					route: t('dashboard'),
					icon: <Menu style={iconStyle} />,
					link: AppRoutes.DASHBOARD,
					component: Dashboard,
					children: null,
					active: false,
				},
				{
					route: t('reservation'),
					icon: <Edit style={iconStyle} />,
					link: '/reservation',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.NEW_RESERVATION,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.LIST_ALL_RESERVATIONS,
						},
						{
							title: t('tickets'),
							icon: <ConfirmationNumber style={iconStyle} />,
							route: AppRoutes.RESERVATION_TICKETS,
						},
					],
					active: false,
				},
				{
					route: t('wayBills'),
					icon: <Loop style={iconStyle} />,
					link: '/waybills',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.ADD_NEW_TRANSPORT_GUIDE,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.LIST_ALL_TRANSPORT_GUIDES,
						},
					],
					active: false,
				},
				{
					route: t('users'),
					icon: <People style={iconStyle} />,
					link: '/users',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.CREATE_USER,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: '/users/list',
						},
						{
							title: t('administrators'),
							icon: <AccountCircle style={iconStyle} />,
							route: AppRoutes.LIST_ADMINISTRATORS,
						},
					],
					active: false,
				},
				{
					route: t('organizations'),
					icon: <AccountBalance style={iconStyle} />,
					link: '/organizations',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.ADD_NEW_ORGANIZATION,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.ORGANIZATIONS,
						},
					],
					active: false,
				},
				{
					route: t('counters'),
					icon: <Store style={iconStyle} />,
					link: '/counters',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.ADD_NEW_COUNTER,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.LIST_COUNTERS,
						},
					],
					active: false,
				},
				{
					route: t('maintenance'),
					icon: <Build style={iconStyle} />,
					link: '/maintenance',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.NEW_MAINTENANCE,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.MAINTENANCE,
						},
					],
					active: false,
				},
				{
					route: t('vehicleManagement'),
					icon: <DriveEta style={iconStyle} />,
					link: '/vehiclemanagment',
					component: Dashboard,
					children: [
						{
							title: t('vehicles'),
							icon: <ArrowRight style={iconStyle} />,
							route: AppRoutes.VEHICLES,
						},
						{
							title: t('fines'),
							icon: <ArrowRight style={iconStyle} />,
							route: AppRoutes.VEHICLE_FINES,
						},
						{
							title: t('categories'),
							icon: <ArrowRight style={iconStyle} />,
							route: AppRoutes.VEHICLE_CATEGORIES,
						},
						{
							title: t('brands'),
							icon: <ArrowRight style={iconStyle} />,
							route: AppRoutes.VEHICLE_BRANDS,
						},
						{
							title: t('characteristics'),
							icon: <ArrowRight style={iconStyle} />,
							route: AppRoutes.VEHICLE_CHARACTERISTICS,
						},
					],
					active: false,
				},
				{
					route: t('teriffs'),
					icon: <LocalAtm style={iconStyle} />,
					link: '/tarrifs',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.ADD_NEW_TERRIF,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.LIST_ALL_TERRIFS,
						},
					],
					active: false,
				},
				{
					route: t('discounts'),
					icon: <Euro style={iconStyle} />,
					link: '/discounts',
					component: Dashboard,
					children: [
						{
							title: t('campaign'),
							icon: <ArrowRight style={iconStyle} />,
							route: '/discounts/campaign',
						},
						{
							title: t('byQuantity'),
							icon: <ArrowRight style={iconStyle} />,
							route: '/discounts/byquantity',
						},
						{
							title: t('requested'),
							icon: <ArrowRight style={iconStyle} />,
							route: '/discounts/requested',
						},
					],
					active: false,
				},
				{
					route: t('tolls'),
					icon: <Toll style={iconStyle} />,
					link: '/tolls',
					component: Dashboard,
					children: [
						{
							title: t('noBonds'),
							icon: <AllInclusive style={iconStyle} />,
							route: '/tolls/nobonds',
						},
						{
							title: t('notBilled'),
							icon: <CreditCard style={iconStyle} />,
							route: '/tolls/notbilled',
						},
						{
							title: t('process'),
							icon: <Send style={iconStyle} />,
							route: '/tolls/process',
						},
					],
					active: false,
				},
				{
					route: t('reports'),
					icon: <Assessment style={iconStyle} />,
					link: '/reports',
					component: Dashboard,
					children: [
						{
							title: t('divergentCategories'),
							icon: <ListAlt style={iconStyle} />,
							route: AppRoutes.REPORTS_DIVERGENT_CATEGORIES,
						},
						{
							title: t('pendingFinancials'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/pending-financials',
						},
						{
							title: t('maintenanceCosts'),
							icon: <FormatListBulleted style={iconStyle} />,
							route: '/reports/maintenance-cost',
						},
						{
							title: t('tollBooking'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/toll-bookings',
						},
						{
							title: t('commissions'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/commissions',
						},
						{
							title: t('accounting'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/accounting',
						},
						{
							title: t('ungeneratedInvoices'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/ungenerated-invoices',
						},
						{
							title: t('commissionAdjustments'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/commission-adjustment',
						},
					],
					active: false,
				},
				{
					route: t('articles'),
					icon: <Campaign style={iconStyle} />,
					link: '/article',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.CREATE_ARTICLES,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.LIST_ARTICLES,
						},
					],
					active: false,
				},
				{
					route: t('socialNetworks'),
					link: '/social-network',
					active: false,
					icon: <ConnectWithoutContact style={iconStyle} />,
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.CREATE_SOCIAL_NETWORK,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.SOCIAL_NETWORK,
						},
					],
				},
				{
					route: t('faqs'),
					icon: <LiveHelp style={iconStyle} />,
					link: '/faqs',
					component: Dashboard,
					children: null,
					active: false,
				},
				{
					route: t('appBanners'),
					icon: <ViewCarousel style={iconStyle} />,
					link: '/app-banners',
					component: Dashboard,
					children: null,
					active: false,
				},
				{
					route: t('definitions'),
					icon: <MenuBook style={iconStyle} />,
					link: '/definitions',
					component: Dashboard,
					children: null,
					active: false,
				},
			]);
		} else {
			setSidebarRoutes([
				{
					route: t('dashboard'),
					icon: <Menu style={iconStyle} />,
					link: '/dashboard',
					component: Dashboard,
					children: null,
					active: false,
				},
				{
					route: t('reservation'),
					icon: <Edit style={iconStyle} />,
					link: '/reservation',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.NEW_RESERVATION,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.LIST_ALL_RESERVATIONS,
						},
						{
							title: t('tickets'),
							icon: <ConfirmationNumber style={iconStyle} />,
							route: AppRoutes.RESERVATION_TICKETS,
						},
					],
					active: false,
				},
				{
					route: t('wayBills'),
					icon: <Loop style={iconStyle} />,
					link: '/waybills',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.ADD_NEW_TRANSPORT_GUIDE,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: AppRoutes.LIST_ALL_TRANSPORT_GUIDES,
						},
					],
					active: false,
				},
				{
					route: t('users'),
					icon: <People style={iconStyle} />,
					link: '/users',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.CREATE_USER,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: '/users/list',
						},
						{
							title: t('administrators'),
							icon: <AccountCircle style={iconStyle} />,
							route: AppRoutes.LIST_ADMINISTRATORS,
						},
					],
					active: false,
				},
				{
					route: t('organizations'),
					icon: <AccountBalance style={iconStyle} />,
					link: '/organizations',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.ADD_NEW_ORGANIZATION,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: '/organizations/list',
						},
					],
					active: false,
				},
				{
					route: t('counters'),
					icon: <Store style={iconStyle} />,
					link: '/counters',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: AppRoutes.ADD_NEW_COUNTER,
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: '/counters/listing',
						},
					],
					active: false,
				},
				{
					route: t('maintenance'),
					icon: <Build style={iconStyle} />,
					link: '/maintenance',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: '/maintenance/form',
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: '/maintenance/list',
						},
						// { title: "Tickets", icon: <Label style={iconStyle} /> },
					],
					active: false,
				},
				{
					route: t('reports'),
					icon: <Assessment style={iconStyle} />,
					link: '/reports',
					component: Dashboard,
					children: [
						{
							title: t('divergentCategories'),
							icon: <ListAlt style={iconStyle} />,
							route: AppRoutes.REPORTS_DIVERGENT_CATEGORIES,
						},
						{
							title: t('pendingFinancials'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/pending-financials',
						},
						{
							title: t('maintenanceCosts'),
							icon: <FormatListBulleted style={iconStyle} />,
							route: '/reports/maintenance-cost',
						},
						{
							title: t('tollBooking'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/toll-bookings',
						},
						{
							title: t('commissions'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/commissions',
						},
						{
							title: t('accounting'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/accounting',
						},
						{
							title: t('ungeneratedInvoices'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/ungenerated-invoices',
						},
						{
							title: t('commissionAdjustments'),
							icon: <ListAlt style={iconStyle} />,
							route: '/reports/commission-adjustment',
						},
					],
					active: false,
				},
				{
					route: t('articles'),
					icon: <Campaign style={iconStyle} />,
					link: '/article',
					component: Dashboard,
					children: [
						{
							title: t('new'),
							icon: <AddCircle style={iconStyle} />,
							route: '/article/create',
						},
						{
							title: t('listAll'),
							icon: <List style={iconStyle} />,
							route: '/article/listall',
						},
					],
					active: false,
				},
			]);
		}
	};

	useEffect(() => {
		setRoutes();
	}, [counter]);

	useEffect(() => {
		setRoutes();
	}, [i18n.language]);

	return { sidebarRoutes, setSidebarRoutes };
};
