import { STATUS_ID } from '@Constant';
import { useFeedback, useTitleBar } from '@Providers';
import { tollApi, userApi } from '@Requests';
import {
	LegacyTolls, LegacyTollsReq, NameAndId, Pagination, ProcessTollReq, User,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProcessTollForm from './ProcessTollForm';
import ProcessTollPage from './ProcessTollPage';
import ProcessTollSearchForm from './ProcessTollSearchForm';

const ProcessToll = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast } = useFeedback();

	const [error, setError] = useState('');
	const [initialValues, setInitialValues] = useState({
		registration: '',
		deviceNo: '',
		startDate: new Date(),
		endDate: new Date(),
	});
	const [processInitial] = useState({
		StatusId: {} as NameAndId,
		DriverId: {} as User,
	});
	const [transactionId, setTransactionId] = useState([] as number[]);
	const [users, setUsers] = useState([] as User[]);
	const [userLoading, setUserLoading] = useState(false);
	const [legacyTolls, setLegacyTolls] = useState<Pagination<LegacyTolls[]>>();

	useEffect(() => {
		setTitle(`${t('legacyContracts')} (${t('gimpsoft')})`);
	}, [setTitle, t]);

	const searchLegacyTolls = (
		values: LegacyTollsReq,
		formikHelpers?: FormikHelpers<LegacyTollsReq>,
	) => {
		tollApi.legacyTolls(values)
			.then((res) => setLegacyTolls(res.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => formikHelpers?.setSubmitting(false));
		setInitialValues(values);
	};

	const onCheckClick = (cod: number) => {
		const temp: number[] = [...transactionId];
		setError('');
		if (temp.includes(cod)) {
			temp.splice(temp.indexOf(cod), 1);
		} else {
			temp.push(cod);
		}
		setTransactionId(temp);
	};

	const processToll = (
		values: ProcessTollReq,
		formikHelpers: FormikHelpers<ProcessTollReq>,
	) => {
		if (transactionId.length === 0) {
			formikHelpers.setSubmitting(false);
			setError(t('selectOne'));
		} else {
			tollApi
				.processToll(values, transactionId)
				.then(() => {
					searchLegacyTolls(initialValues);
					setTransactionId([]);
					setUsers([]);
					formikHelpers.resetForm({});
				})
				.catch(({ response }) => {
					addToast({ message: response?.data.message, error: true });
				})
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const getUserBySearch = (query: string) => {
		if (!query) { return null; }
		const listDataControl = {
			page: 0,
			size: 100000,
			search: query,
		};
		setUserLoading(true);
		userApi.getAllUsers(listDataControl, STATUS_ID.ACTIVE)
			.then((res) => setUsers(res.data.data))
			.finally(() => setUserLoading(false));
		return null;
	};

	const validateProcess = (values: ProcessTollReq) => {
		const errors = {} as FormikErrors<ProcessTollReq>;
		if (Object.keys(values.DriverId).length === 0) {
			errors.DriverId = t('emptyField');
		}
		if (Object.keys(values.StatusId).length === 0) {
			errors.StatusId = t('emptyField');
		}
		return errors;
	};

	return (
		<>
			<ProcessTollSearchForm
				initialValues={initialValues}
				handleSubmit={searchLegacyTolls}
			/>
			<ProcessTollPage
				tolls={legacyTolls}
				transactionId={transactionId}
				onCheckClick={onCheckClick}
				error={error}
			/>
			<ProcessTollForm
				initialValues={processInitial}
				handleSubmit={processToll}
				validate={validateProcess}
				getDropdownUsers={getUserBySearch}
				users={users}
				usersLoading={userLoading}
			/>
		</>
	);
};

export default ProcessToll;
