import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { featureApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { FeatureType } from '@Types';
import { CarFeatureReq } from 'data/models/car-feature.model';
import { FormikHelpers, FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NewCharacteristicPage from './NewCharacteristicPage';

const CreateCharacteristics = () => {
	const { id } = useParams<{ id: string }>();
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({
		name: '',
		shortName: '',
		typeId: {} as FeatureType,
		isBoolean: true,
	});
	const [isEdit, setEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('characteristic')} ${t('detail')}`);
		} else {
			setTitle(`${t('new')} ${t('characteristic')}`);
			setEdit(true);
		}
	}, [setTitle, id, t]);

	useEffectCustom(() => {
		let unsubscribe = false;

		if (id && !unsubscribe) {
			featureApi.getFeatureById(id).then(({ data }) => setInitialValues({
				name: data.name,
				shortName: data.shortName,
				typeId: data.type,
				isBoolean: data.isBoolean,
			}));
		}

		return () => {
			unsubscribe = false;
		};
	}, []);

	const validate = (values: CarFeatureReq) => {
		const errors = {} as FormikErrors<CarFeatureReq>;
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.shortName) {
			errors.shortName = t('emptyField');
		}
		if (!values.typeId) {
			errors.typeId = t('emptyField');
		}
		if (values.typeId && Object.keys(values.typeId).length === 0) {
			errors.typeId = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (value: CarFeatureReq, formikHelpers: FormikHelpers<CarFeatureReq>) => {
		if (id) {
			featureApi.updateFeature(value, id ?? '')
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_CHARACTERISTICS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			featureApi.newFeature(value)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_CHARACTERISTICS);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setEdit(true);
		setTitle(`${t('edit')} ${t('characteristic')}`);
	};

	return (
		<NewCharacteristicPage
			initialValues={initialValues}
			validate={validate}
			handleSubmit={handleSubmit}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
		/>
	);
};

export default CreateCharacteristics;
