import React, { useEffect, useState } from 'react';
import { categoryApi } from '@Requests';
import { useRequest } from '@Api';
import { Category } from '@Types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import CategoriesPage from './CategoriesPage';

const ListCategory = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	const category = useRequest(([params]) => categoryApi.getCategories(params));
	const changeCategoryStatus = useRequest(([params]) => categoryApi.updateCategoryStatus(params));

	useEffect(() => {
		setTitle(t('categories'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: pagination.page, size: pagination.size, search,
			};
			category.execute(listDataControl);
		};
		getData();
	}, [pagination, search]);

	useEffectCustom(() => {
		const { data } = changeCategoryStatus;
		if (data) {
			category.setData((mPrev) => {
				const prev = mPrev;
				const index = prev?.data.findIndex((el) => el.id === changeCategoryStatus?.data?.id);
				if (prev && index !== undefined) {
					prev.data[index] = data;
				}
				return prev;
			});
		}
	}, [changeCategoryStatus.data]);

	const handleDropdownClick = (action: string, item?: Category) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.NEW_VEHICLE_CATREGORY);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_VEHICLE_CATEGORY.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeCategoryStatus.execute(item?.id);
			break;
		default:
			break;
		}
	};

	return (
		<CategoriesPage
			categories={category.data}
			setSearch={setSearch}
			setPagination={setPagination}
			handleListAction={handleDropdownClick}
		/>
	);
};

export default ListCategory;
