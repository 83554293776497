// import ApiResponse from '../../../models/api-response.model';
// import User from '../../../models/user.model';
// import { API_ROUTES } from '../../apiRoutes';
// import { API_METHODS } from '../../methods';

import {
	ApiResponse, LoginReq, NameAndId, PasswordReq, User,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqPost } from '../request.api';

export const loginUser = async (params: LoginReq): Promise<ApiResponse<User>> => {
	const { email, password, deviceUniqueId } = params;
	const deviceTypeId = 3;
	const platformId = 3;
	const resp = await reqPost({
		url: Endpoint.LOGIN,
		data: {
			email, password, deviceTypeId, platformId, deviceUniqueId,
		},
	});
	const data: ApiResponse<User> = await resp.data;
	data.statusCode = resp.status;
	return data;
};

export const changePassword = async (password: PasswordReq): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.CHANGE_PASSWORD,
		data: {
			currentPassword: password.currentPassword,
			newPassword: password.newPassword,
		},
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const refreshCurrentToken = async (refreshToken: string): Promise<ApiResponse<User>> => {
	const resp = await reqPost({
		url: Endpoint.REFRESH_TOKEN,
		data: { refreshToken },
	});
	const data: ApiResponse<User> = await resp.data;
	if (resp.status !== 200) {
		throw new Error(data.message);
	}
	return data;
};

// export const logoutUser = async (): Promise<ApiResponse<void>> => {
// 	const resp = await request({
// 		method: API_METHODS.POST,
// 		url: API_ROUTES.logout,
// 	});

// 	if (resp.status !== 200) {
// 		throw new Error('Something went wrong');
// 	}
// 	const data: ApiResponse<void> = await resp.data;
// 	if (resp.status !== 200) {
// 		throw new Error(data.message);
// 	}
// 	return data;
// };

export const forgotPassword = async (email: string): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.FORGOT_PASSWORD,
		data: { email },
	});
	const { data } = resp;
	return data;
};

export const checkChangePassword = async (token: string): Promise<ApiResponse<NameAndId>> => {
	const resp = await reqPost({
		url: Endpoint.CHECK_CHANGE_PASSWORD,
		data: { token },
	});
	const { data } = resp;
	return data;
};

export const resetPassword = async (param: {newPassword: string, token: string}):
	Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.RESET_FORGOT_PASSWORD,
		data: { ...param },
	});
	const { data } = resp;
	return data;
};
