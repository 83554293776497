import { DefaultButton } from '@Atoms';
import { RESERVATION_STATUS } from '@Constant';
import { PaperContainer } from '@Molecules';
import {
	AddCircle,
	Chat,
	Check,
	Close,
	CreditCard,
	DirectionsCar,
	Edit,
	ListAlt,
	Print,
	SettingsApplications,
	Toll,
} from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { Status } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ReservationButtonsProps {
	addNew: boolean;
	reservationStatus?: Status;
	loading: boolean;
	handleButtonAction: (action: string) => void
}

const ReservationButtons: FC<ReservationButtonsProps> = (props) => {
	const {
		addNew, reservationStatus, loading, handleButtonAction,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					{!loading && (
						<>
							{addNew && (
								<Stack direction="row">
									<DefaultButton
										title={t('createNewUser')}
										startIcon={<AddCircle />}
										onClick={() => handleButtonAction(t('createNewUser'))}
									/>
									<DefaultButton
										title={t('createNewCompany')}
										startIcon={<AddCircle />}
										sx={{ ml: 1 }}
										onClick={() => handleButtonAction(t('createNewCompany'))}
									/>
								</Stack>
							)}
							{!addNew && (
								<>
									{reservationStatus?.id === RESERVATION_STATUS.CONFIRMED && (
										<DefaultButton
											title={t('proof')}
											onClick={() => {
												handleButtonAction(t('proof'));
											}}
											startIcon={<Print />}
										/>
									)}
									{(
										reservationStatus?.id === RESERVATION_STATUS.STARTED
							|| reservationStatus?.id === RESERVATION_STATUS.CLOSED
									) && (
										<DefaultButton
											title={t('contract')}
											onClick={() => {
												handleButtonAction(t('contract'));
											}}
											startIcon={<ListAlt />}
										/>
									)}
									{reservationStatus?.id === RESERVATION_STATUS.PENDING && (
										<>
											<DefaultButton
												title={t('approveRequest')}
												onClick={() => {
													handleButtonAction(t('approveRequest'));
												}}
												startIcon={<Check />}
											/>
											<DefaultButton
												title={t('cancelReservation')}
												color="error"
												onClick={() => {
													handleButtonAction(t('cancelReservation'));
												}}
												sx={{ ml: 1 }}
												startIcon={<Close />}
											/>
										</>
									)}
									{reservationStatus?.id !== RESERVATION_STATUS.PENDING && (
										<>
											<DefaultButton
												title={t('services')}
												onClick={() => {
													handleButtonAction(t('services'));
												}}
												sx={{ ml: 1 }}
												startIcon={<SettingsApplications />}
											/>
											<DefaultButton
												title={t('transactions')}
												onClick={() => {
													handleButtonAction(t('transactions'));
												}}
												sx={{ ml: 1 }}
												startIcon={<CreditCard />}
											/>
											{reservationStatus?.id !== RESERVATION_STATUS.PENDING && (
												<>
													<DefaultButton
														title={t('tolls')}
														onClick={() => {
															handleButtonAction(t('tolls'));
														}}
														sx={{ ml: 1 }}
														startIcon={<Toll />}
													/>
													<DefaultButton
														title={t('vehicleHistory')}
														onClick={() => {
															handleButtonAction(t('vehicleHistory'));
														}}
														sx={{ ml: 1 }}
														startIcon={<DirectionsCar />}
													/>

												</>
											)}
										</>
									)}
									{reservationStatus?.id === RESERVATION_STATUS.CLOSED && (
										<DefaultButton
											title={t('editReservation')}
											color="success"
											onClick={() => {
												handleButtonAction(t('editReservation'));
											}}
											sx={{ ml: 1 }}
											startIcon={<Edit />}
										/>
									)}
									{!addNew && (
										<DefaultButton
											title={t('crm')}
											onClick={() => {
												handleButtonAction(t('crm'));
											}}
											sx={{ ml: 1 }}
											startIcon={<Chat />}
										/>
									)}
								</>
							)}
						</>
					)}
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ReservationButtons;
