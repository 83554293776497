import {
	Autocomplete,
	DatePicker,
	DefaultButton,
	CarItem,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceReportReq } from '@Types';
import { useDropdown } from '@Providers';

interface MaintenanceCostFormProps {
	initialValues: MaintenanceReportReq;
	validate: (values: MaintenanceReportReq) => FormikErrors<MaintenanceReportReq>
	handleSubmit: (
		values: MaintenanceReportReq,
		formikHelpers: FormikHelpers<MaintenanceReportReq>
		) => void
}

const MaintenanceCostForm: FC<MaintenanceCostFormProps> = (props) => {
	const { initialValues, validate, handleSubmit } = props;
	const { t } = useTranslation('translations');
	const { cars } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						validateOnChange={false}
						validateOnMount={false}
						onSubmit={handleSubmit}
					>
						{({
							errors, values, setFieldValue, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('vehicle')}
											placeholder=""
											options={cars || []}
											defaultValue={values.CarId}
											error={!!errors.CarId}
											helperText={String(errors.CarId || '')}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													<CarItem car={item} hideName />
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => `${item?.name || ''} ${item.licensePlate || ''}`}
											onChange={(_, item) => setFieldValue('CarId', item)}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<DatePicker
											label={t('startDate')}
											value={values.StartDate}
											disabled={isSubmitting}
											error={!!errors.StartDate}
											helperText={String(errors.StartDate || '')}
											onChange={(value) => {
												setFieldValue('StartDate', value);
											}}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<DatePicker
											label={t('endDate')}
											value={values.EndDate}
											error={!!errors.EndDate}
											helperText={String(errors.EndDate || '')}
											disabled={isSubmitting}
											onChange={(value) => {
												setFieldValue('EndDate', value);
											}}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
										<DefaultButton type="submit" title={t('search')} startIcon={<Search />} loading={isSubmitting} />
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default MaintenanceCostForm;
