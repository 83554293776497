/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ListDataControl,
	ApiResponse,
	Car,
	CarRecReserva,
	CarFreePeriods,
	Pagination,
	Reservation,
	Category,
	CarColor,
	NameAndId,
	CarReq,
	CarRevision,
} from '@Types';
import { normalizeCurrency } from '@Masks';
import { encodeDateTime } from '@Helpers';
import { CarMin } from 'data/models/car.model';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './upload.service';

export const getCars = async (params: ListDataControl, statusId?: number):
   Promise<ApiResponse<Pagination<Car[]>>> => {
	const resp = await reqGet({
		url: Endpoint.CARS.format(params.page, params.size, params.search, statusId || ''),
	});

	if (resp.status !== 200) {
		throw new Error('Something went wrong');
	}
	const { data } = resp;
	return data;
};

export const getCarAvailableForReservation = async (
	categoryId: number,
	startDate: Date,
	endDate: Date,
):
	Promise<ApiResponse<CarMin[]>> => {
	const dataToUpload = {
		categoryId,
		startDate: startDate.toISOString(),
		endDate: endDate.toISOString(),
	};
	const resp = await reqPost({
		url: Endpoint.CARS_FOR_RESERVATIONS,
		data: { ...dataToUpload },
	});
	const { data } = resp;
	const {
		errorType,
		message,
		title,
		statusCode,
	} = data;
	const inCategory = data.data.inCategory?.reduce((newArr: CarMin[], el: CarMin) => {
		const mNewArr = newArr;
		mNewArr.push({ ...el, categoryType: true });
		return mNewArr;
	}, [] as CarMin[]);
	const outCategory = data.data.outCategory?.reduce((newArr: CarMin[], el: CarMin) => {
		const mNewArr = newArr;
		mNewArr.push({ ...el, categoryType: false });
		return mNewArr;
	}, [] as CarMin[]);
	const groupedData = {
		errorType,
		message,
		title,
		statusCode,
		data: inCategory.concat(outCategory),
	};
	return groupedData;
};

export const saveCar = async (car: CarReq): Promise<ApiResponse<Car>> => {
	let downlink = '';
	let coverDownlink = '';
	const Mfeatures: any = [];
	/** Add all the quantitative features */
	car.featureInputs.forEach((el) => {
		if (el.value) {
			Mfeatures.push({
				value: el.value,
				highlight: el.highlight,
				featureId: el.featureId,
			});
		}
	});
	/** Add all vehicle features selected from check boxes */
	car.vehicleCheckboxes.forEach((el) => {
		if (el.value || el.highlight) {
			Mfeatures.push({
				value: el.value,
				highlight: el.highlight,
				featureId: el.featureId,
			});
		}
	});
	/** Add all contract features selected from checkboxes */
	car.contractCheckboxes.forEach((el) => {
		if (el.value || el.highlight) {
			Mfeatures.push({
				value: el.value,
				highlight: el.highlight,
				featureId: el.featureId,
			});
		}
	});
	if (car.image?.size || car.coverImage?.size) {
		const image = new FormData();
		const coverImage = new FormData();
		image.append('file', car.image);
		coverImage.append('file', car.coverImage);
		if (car.coverImage?.size) {
			const res = await uploadFile(coverImage);
			coverDownlink = res.data.file;
		}
		if (car.image?.size) {
			const resp = await uploadFile(image);
			downlink = resp.data.file;
		}
	}
	const dataToUpload = {
		fuelTypeId: car.fuelTypeId.id,
		fuelLevel: car.fuelLevel,
		licenseDate: car.licenseDate.toISOString(),
		cubicCapacity: car.cubicCapacity,
		firstInspection: car.firstInspection,
		inspectionFrequency: car.inspectionFrequency,
		colorId: car.colorId.id,
		supplierId: car.supplierId.id,
		purchasePrice: normalizeCurrency(car.purchasePrice),
		shortName: car.shortName,
		image: downlink,
		coverImage: coverDownlink,
		officeId: car.officeId.id,
		licensePlate: car.licensePlate,
		carTypeId: car.carTypeId.id, // Car type is always 2 for now
		fleetId: car.fleetId.id,
		year: car.year,
		mileage: car.mileage,
		chassis: car.chassis,
		brandId: car.brandId.id,
		name: car.name,
		categories: car.categories.reduce((ids, el) => {
			ids.push(el.id);
			return ids;
		}, [] as number[]),
		features: Mfeatures,
	};

	const resp = await reqPost({
		url: Endpoint.CAR,
		data: { ...dataToUpload },
	});
	const data: ApiResponse<Car> = await resp.data;
	return data;
};

export const getCarByID = async (id?: string | number): Promise<ApiResponse<Car>> => {
	const resp = await reqGet({
		url: Endpoint.CAR_BY_ID.format(id),
	});
	const data: ApiResponse<Car> = await resp.data;
	return data;
};

export const getCarRevisions = async (id?: string): Promise<ApiResponse<CarRevision>> => {
	const resp = await reqGet({
		url: Endpoint.CHECK_REVISION.format(id),
	});
	const { data } = resp;
	return data;
};

export const freePeriods = async (id?: string): Promise<ApiResponse<CarFreePeriods[]>> => {
	const resp = await reqGet({
		url: Endpoint.FREE_CAR_PERIODS.format(id),
	});
	const data: ApiResponse<CarFreePeriods[]> = await resp.data;
	return data;
};

export const updateCar = async (car: CarReq, id: string): Promise<ApiResponse<void>> => {
	let downlink = car.imageLink;
	let coverDownlink = car.coverImageLink;
	const Mfeatures: any = [];
	/** Add all the quantitative features */
	car.featureInputs.forEach((el) => {
		if (el.value) {
			Mfeatures.push({
				value: el.value,
				highlight: el.highlight,
				featureId: el.featureId,
			});
		}
	});
	/** Add all vehicle features selected from check boxes */
	car.vehicleCheckboxes.forEach((el) => {
		if (el.value || el.highlight) {
			Mfeatures.push({
				value: el.value,
				highlight: el.highlight,
				featureId: el.featureId,
			});
		}
	});
	/** Add all contract features selected from checkboxes */
	car.contractCheckboxes.forEach((el) => {
		if (el.value || el.highlight) {
			Mfeatures.push({
				value: el.value,
				highlight: el.highlight,
				featureId: el.featureId,
			});
		}
	});
	if (car.image?.size || car.coverImage?.size) {
		const image = new FormData();
		const coverImage = new FormData();
		image.append('file', car.image);
		coverImage.append('file', car.coverImage);
		if (car.coverImage?.size) {
			const res = await uploadFile(coverImage);
			coverDownlink = res.data.file;
		}
		if (car.image?.size) {
			const resp = await uploadFile(image);
			downlink = resp.data.file;
		}
	}
	const dataToUpload = {
		fuelTypeId: car.fuelTypeId.id,
		fuelLevel: car.fuelLevel,
		licenseDate: car.licenseDate.toISOString(),
		cubicCapacity: car.cubicCapacity,
		firstInspection: car.firstInspection,
		inspectionFrequency: car.inspectionFrequency,
		colorId: car.colorId.id,
		supplierId: car.supplierId.id,
		purchasePrice: normalizeCurrency(car.purchasePrice),
		shortName: car.shortName,
		image: downlink,
		coverImage: coverDownlink,
		officeId: car.officeId.id,
		licensePlate: car.licensePlate,
		carTypeId: car.carTypeId.id, // Car type is always 2 for now
		fleetId: car.fleetId.id,
		year: car.year,
		mileage: car.mileage,
		chassis: car.chassis,
		brandId: car.brandId.id,
		name: car.name,
		categories: car.categories.reduce((ids, el) => {
			ids.push(el.id);
			return ids;
		}, [] as number[]),
		features: Mfeatures,
	};

	const resp = await reqPost({
		url: Endpoint.CAR_BY_ID.format(id),
		data: { ...dataToUpload },
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const changeCarStatus = async (carId: number): Promise<ApiResponse<Car>> => {
	const resp = await reqPost({
		url: Endpoint.CAR_STATUS.format(carId),
	});
	const data = resp.data as ApiResponse<Car>;
	data.statusCode = resp.status;
	return data;
};

export const getCarRecentReservations = async (carId?: string):
   Promise<ApiResponse<CarRecReserva[]>> => {
	const resp = await reqGet({
		url: Endpoint.RECENT_CAR_RESERVATIONS.format(carId),
	});
	const data = resp.data as ApiResponse<CarRecReserva[]>;
	return data;
};

export const carReservationByDate = async (date: { date: Date; time: Date }, carId: string):
   Promise<ApiResponse<Reservation[]>> => {
	const finalDate = encodeDateTime(date.date, date.time);
	const resp = await reqPost({
		url: Endpoint.CAR_RESERVA_BY_DATE.format(carId),
		data: { date: finalDate },
	});
	const data = resp.data as ApiResponse<Reservation[]>;
	return data;
};

export async function getVehicleCategories(tariffId?: number):
	Promise<ApiResponse<Pagination<Category[]>>> {
	const resp = await reqGet({
		url: Endpoint.CAR_CATEGORIES.format(1, 10000, '', tariffId),
	});

	const data: ApiResponse<Pagination<Category[]>> = await resp.data;
	data.data.data = data.data.data.sort((a, b) => a.tariff.name.localeCompare(b.tariff.name));
	return data;
}

export const carColors = async (): Promise<ApiResponse<CarColor[]>> => {
	const resp = await reqGet({
		url: Endpoint.CAR_COLOR,
	});
	const { data } = resp;
	return data;
};

export const carFleets = async (): Promise<ApiResponse<NameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.CAR_FLEET,
	});
	const { data } = resp;
	return data;
};

export const fuelTypes = async (): Promise<ApiResponse<NameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.CAR_FUEL,
	});
	const { data } = resp;
	return data;
};
