import React, { useEffect, useState } from 'react';
import { socialNetworkApi } from '@Requests';
import { ISocialNetworkReq, ISocialNetworkType } from '@Types';
import { useFeedback, useTitleBar } from '@Providers';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffectCustom } from '@Hooks';
import { FormikErrors, FormikHelpers } from 'formik';
import CreateSocialNetworkPage from './CreateSocialNetworkPage';

const FACEBOOK_TYPE = 1;

const CreateSocialNetwork = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [socialNetworkTypes, setSocialNetworkType] = useState([] as ISocialNetworkType[]);

	const [initialValues, setInitialValues] = useState({
		userName: '',
		pageId: '',
		typeId: {} as ISocialNetworkType,
	});

	useEffect(() => {
		if (id) {
			setTitle(`${t('edit')} ${t('socialNetwork')}`);
		} else {
			setTitle(`${t('new')} ${t('socialNetwork')}`);
		}
	}, [t, setTitle, id]);

	const networkTypes = async () => {
		const { data } = await socialNetworkApi.getSocialNetworkTypes();
		return data;
	};

	const getData = async () => {
		const mSocialNetworkTypes = await networkTypes();
		setSocialNetworkType(mSocialNetworkTypes);
		socialNetworkApi.getSocialNetworkById(id ?? '')
			.then(({ data }) => setInitialValues({
				typeId: data.type,
				userName: data.userName,
				pageId: data.pageId,
			}))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}));
	};

	const getTypes = async () => {
		const mSocialNetworkTypes = await networkTypes();
		setSocialNetworkType(mSocialNetworkTypes);
	};

	useEffectCustom(() => {
		if (id) {
			getData();
		} else {
			getTypes();
		}
	}, []);

	const validate = (values: ISocialNetworkReq) => {
		const errors = {} as FormikErrors<ISocialNetworkReq>;
		if (!values.userName) {
			errors.userName = t('emptyField');
		}
		if (!values.typeId) {
			errors.typeId = t('emptyField');
		}
		if (values.typeId && Object.keys(values.typeId).length === 0) {
			errors.typeId = t('emptyField');
		}
		if (values.typeId && values.typeId.id !== FACEBOOK_TYPE) {
			if (!values.pageId) {
				errors.pageId = t('emptyField');
			}
		}
		return errors;
	};

	const handleSubmit = (
		values: ISocialNetworkReq,
		formikHelpers: FormikHelpers<ISocialNetworkReq>,
	) => {
		if (id) {
			socialNetworkApi.editSocialNetwork(values, id ?? '')
				.then((res) => {
					addToast({ message: res.title, error: false });
					navigate(-1);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			socialNetworkApi.createSocialNetwork(values)
				.then((res) => {
					addToast({ message: res.title, error: false });
					navigate(-1);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};
	return (
		<CreateSocialNetworkPage
			initialValues={initialValues}
			validate={validate}
			socialNetworkTypes={socialNetworkTypes}
			handleSubmit={handleSubmit}
		/>
	);
};

export default CreateSocialNetwork;
