import { AddCircle } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
	CarItem,
	DefaultButton,
	Dropdown,
	StatusLabel,
	TableItem,
	FinancialButton,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
// import { formatDate } from '@Helpers';
import { Pagination, PaginationTable, Reservation } from '@Types';
import { formatDate } from '@Helpers';

interface ListReservationProps {
   setSearch: (searchQuery: string) => void;
   setPagination: (props: PaginationTable) => void;
   handleListAction: (action: string, item?: Reservation) => void;
   reservations?: Pagination<Reservation[]>;
   getDropdownOptions: (item: Reservation) => string[]
}

const ListReservationPage: FC<ListReservationProps> = (props) => {
	const {
		setSearch, setPagination, handleListAction, reservations, getDropdownOptions,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
					startIcon={<AddCircle />}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={reservations?.data || []}
						onSearching={setSearch}
						onChange={(page, size) => setPagination({ page, size })}
						pagination
						total={reservations?.total}
					>
						<TableColumn
							headerText="ID"
							render={(item) => (
								<>
									<div>{`R: ${item.id}`}</div>
									<div>{item.dossier && `D:#${item.dossier}`}</div>
									<div>{item.contractId && `C:#${item.contractId}`}</div>
								</>
							)}
						/>
						<TableColumn
							headerText={t('client')}
							render={(item) => (
								<>
									<div>{item.client?.name}</div>
									<div>{item.organization?.officialName}</div>
								</>
							)}
						/>
						<TableColumn
							headerText={t('vehicl')}
							render={(item) => item.car && <CarItem car={item.car} />}
						/>
						<TableColumn
							headerText={t('categor')}
							render={(item) => (
								<TableItem
									title={item.category?.name}
									subtitle={item.category?.cta}
								/>
							)}
						/>
						<TableColumn
							headerText={t('duration')}
							render={(item) => (
								<TableItem
									title={formatDate(item.startDate)}
									subtitle={formatDate(item.endDate)}
								/>
							)}
						/>
						<TableColumn
							headerText={t('store')}
							render={(item) => (
								<TableItem
									title={item.pickupOrganization?.officialName}
									subtitle={item.returnOrganization?.officialName}
									icon
								/>
							)}
						/>
						<TableColumn
							headerText={t('status')}
							render={(item) => (
								<>
									<StatusLabel status={item.status} />
									{item.hasUnpaidBill && (
										<FinancialButton onClick={() => handleListAction(t('transactions'), item)} />
									)}
								</>
							)}
						/>
						<TableColumn
							headerText={t('details')}
							render={(item) => (
								<Dropdown
									data={getDropdownOptions(item)}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(action) => handleListAction(action, item)}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListReservationPage;
