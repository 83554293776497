import moment from 'moment';

export const encodeDate = (date: Date) => date.toISOString();

export const formatDate = (date: string | undefined) => {
	if (!date) return '';
	const mDate = moment(date).format('DD/MM/YYYY HH:mm');
	return mDate;
};

export const encodeDateTime = (date: Date, time: Date) => {
	const dateString = moment(date).format('MM/DD/YYYY');
	const timeString = moment(time).format('HH:mm');

	const finalDate = new Date(`${dateString} ${timeString}`);
	return finalDate.toISOString();
};

export const formatDateOnly = (date: string | Date | null | undefined) => {
	if (!date) {
		return '';
	}
	return moment(date).format('DD-MM-YYYY');
};
export const formatTimeOnly = (date: string | Date | null | undefined) => {
	if (!date) {
		return '';
	}
	return moment(date).format('HH:mm');
};

export const getMinBirthDate = () => {
	const date = new Date();
	date.setFullYear(date.getFullYear() - 18);
	return date;
};

export const getFirstDayOfMonth = () => {
	const date = new Date();
	date.setDate(1);
	return date;
};

export const getOneMoreDay = (date: Date) => date.setDate(date.getDate() + 1);

export const getToday = () => {
	const today = new Date().getTime();
	return moment(today).format('YYYY-MM-DD');
};

export const getTomorrow = () => new Date(new Date().setDate(new Date().getDate() + 1));

export const getDayFromDate = (date: Date) => {
	let day = '';
	const selectedDate = moment(date);
	const today = moment(new Date());
	const result = Math.round(today.diff(selectedDate, 'days', true));
	if (result === 0) day = 'today';
	if (result === 1) day = 'yesterday';
	if (result === -1) day = 'tomorrow';
	if (result > 1 || result < -1) day = formatDateOnly(date);

	return day;
};
