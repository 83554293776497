export const ESTABLISHMENT_LIST = [
	{ name: '0', id: 0 },
	{ name: '1', id: 1 },
	{ name: '2', id: 2 },
	{ name: '3', id: 3 },
	{ name: '4', id: 4 },
	{ name: '5', id: 5 },
	{ name: '6', id: 6 },
	{ name: '7', id: 7 },
	{ name: '8', id: 8 },
	{ name: '9', id: 9 },
	{ name: '10', id: 10 },
];
