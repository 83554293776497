import { ApiResponse, UploadResponse } from '@Types';
import Endpoint from '../endpoints.api';
import { reqPost } from '../request.api';

export const uploadFile = async (
	fileData: FormData,
): Promise<ApiResponse<UploadResponse>> => {
	const resp = await reqPost({
		url: Endpoint.UPLOAD,
		data: fileData,
	});
	if (resp.status !== 200) {
		throw new Error('');
	}
	const { data } = resp;
	return data;
};
