import { Box } from '@mui/material';
import React, { FC } from 'react';

interface PdfViewerProps {
	source?: string
}

const PdfViewer: FC<PdfViewerProps> = (props) => {
	const { source } = props;
	return (
		<Box style={{ height: '100%' }}>
			{source && (
				// <embed
				// 	src={`data:application/pdf;base64,${source}`}
				// 	type="application/pdf;base64,base64encodedpdf"
				// 	width="100%"
				// 	frameborder="0"
				// 	height="600px"
				// />
				<iframe title="iframe" id="iframe" src={`data:application/pdf;base64,${source}`} frameBorder="0" width="100%" height="600px" />
			)}
		</Box>
	);
};

export default PdfViewer;
