import { encodeDateTime } from '@Helpers';
import { normalizeCurrency } from '@Masks';
import {
	ApiResponse, CarFine, CarFineReq, ListDataControl, Pagination,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './upload.service';

export const getFines = async (carId: number, listDataControl: ListDataControl):
	Promise<ApiResponse<Pagination<CarFine[]>>> => {
	const { page, size, search } = listDataControl;
	const resp = await reqGet({
		url: Endpoint.FINE_BY_CARID.format(carId, page, size, search),
	});
	const data: ApiResponse<Pagination<CarFine[]>> = await resp.data;
	return data;
};

export const saveCarFine = async (
	carFine: CarFineReq,
): Promise<ApiResponse<void>> => {
	let downlink = '';
	if (carFine.file?.size) {
		const image = new FormData();
		image.append('file', carFine.file);
		const res = await uploadFile(image);
		downlink = res.data.file;
	}
	const dataToUpload = {
		carId: carFine.carId.id,
		amount: normalizeCurrency(carFine.amount),
		note: carFine.note,
		date: encodeDateTime(carFine.date, carFine.time),
		local: carFine.local,
		file: downlink,
		fileNote: carFine.fileNote,
	};
	const resp = await reqPost({
		url: Endpoint.FINES,
		data: dataToUpload,
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const getCarFineByID = async (id: string): Promise<ApiResponse<CarFine>> => {
	const resp = await reqGet({
		url: Endpoint.FINE_BY_ID.format(id),
		data: {},
	});
	const data: ApiResponse<CarFine> = await resp.data;
	return data;
};

export const updateCarFine = async (
	carFine: CarFineReq,
	id: string,
): Promise<ApiResponse<void>> => {
	let downlink = '';
	if (carFine.file?.size) {
		const image = new FormData();
		image.append('file', carFine.file);
		const res = await uploadFile(image);
		downlink = res.data.file;
	}
	const dataToUpload = {
		carId: carFine.carId.id,
		amount: normalizeCurrency(carFine.amount),
		note: carFine.note,
		date: encodeDateTime(carFine.date, carFine.time),
		local: carFine.local,
		file: downlink,
		fileNote: carFine.fileNote,
		carFineId: id,
	};
	const resp = await reqPost({
		url: Endpoint.FINE_BY_ID.format(id),
		data: dataToUpload,
	});
	const data: ApiResponse<void> = await resp.data;

	return data;
};
