import { useRequest } from '@Api';
import { Space } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { reportApi } from '@Requests';
import { AppRoutes } from '@Routes';
import {
	Organization, NameAndId, InvoiceReportReq, Invoice,
} from '@Types';
import { FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountingForm from './AccountingForm';
import AccountingPage from './AccountingPage';

const CREDIT_NOTE = 'NC';

const AccountingReport = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const [initialValues] = useState({
		startDate: new Date(),
		endDate: new Date(),
		reservation: '',
		Pickups: [] as Organization[],
		documentType: {} as NameAndId,
		billNumber: '',
		year: '',
		series: '',
		nifId: '',
	});
	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	const accountingReports = useRequest(([param1, param2]) => reportApi.getInvoices(param1, param2));

	useEffect(() => {
		setTitle(t('invoices'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			accountingReports.execute(listDataControl);
		};
		getData();
	}, [pagination, search]);

	const handleListAction = (action: string, item: number | Invoice) => {
		switch (action) {
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: item }));
			break;
		case t('invoice'):
			if (typeof item !== 'number') {
				if (item.fullIdentifier?.startsWith(CREDIT_NOTE)) {
					navigate(AppRoutes.RECEIPT.formatMap(({ id: item.id })));
				} else {
					navigate(AppRoutes.INVOICES.formatMap(({
						invoiceId: item.id,
						reservationId: item.reservationId,
					})));
				}
			}
			break;

		default:
			break;
		}
	};

	const handleSubmit = (
		values: InvoiceReportReq,
		formikHelpers: FormikHelpers<InvoiceReportReq>,
	) => {
		setGlobalLoading(true);
		const listDataControl = {
			page: pagination.page,
			size: pagination.size,
			search,
		};
		reportApi.getInvoices(listDataControl, values)
			.then((res) => accountingReports.setData(res.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => formikHelpers.setSubmitting(false));
		setGlobalLoading(false);
	};

	return (
		<>
			<AccountingForm
				initialValues={initialValues}
				handleSubmit={handleSubmit}
			/>
			<Space height={3} />
			<AccountingPage
				invoiceReport={accountingReports.data}
				setPagination={setPagination}
				setSearch={setSearch}
				handleListAction={handleListAction}
			/>
		</>
	);
};

export default AccountingReport;
