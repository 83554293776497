/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-boolean-value */
import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import { invoiceApi, serviceApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Invoice,
	InvoiceCostumer,
	InvoiceItem, NameAndId, Receipt, ReceiptReq,
} from '@Types';
import { formatCurrency } from '@Helpers';
import CancelInvoiceItem from './CancelInvoiceItem';
import CreditNoteCard from './CreditNoteCard';
import EmailCard from './EmailCard';
import InvoicePage from './InvoicePage';
import ReceiptCard from './ReceiptCard';
import CreditNoteModalItem from './CreditNoteModalItem';
import ReceiptModalItem from './ReceiptModalItem';

enum MODAL_TYPE {
	CANCEL = 1,
	CREDIT_NOTE = 2,
	RECEIPT = 3
}

const INVOICE_TYPE_RECEIPT = 2;

const InvoiceShow = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const navigate = useNavigate();
	const { invoiceId, reservationId } = useParams();
	const { addToast, addDialog } = useFeedback();
	const abortCtrl = new AbortController();

	const [invoice, setInvoice] = useState<InvoiceCostumer>();
	const [creditMemo, setCreditMemo] = useState<Invoice[]>([]);
	const [receipts, setReceipts] = useState<Receipt[]>([]);
	const [{
		receiptTotal,
		totalRefunded,
		pendingPayment,
	}, setPayments] = useState({ receiptTotal: 0, totalRefunded: 0, pendingPayment: 0 });

	const invoiceItems = useRequest(([params]) => (
		serviceApi.getInvoiceItems(params)));

	const [email, setEmail] = useState({ email: '', loading: false, error: '' });

	const [showModal, setShowModal] = useState({ type: 0, open: false });
	const [reason, setReason] = useState({ reason: '', type: 'canceling' });
	const [invoiceItemsLocal, setInvoiceItemsLocal] = useState<InvoiceItem[]>([] as InvoiceItem[]);
	const [creditMemoError, setCreditMemoError] = useState('');
	const [createReceiptInitialValues, setReceiptInitialValue] = useState({
		paymentMethodId: {} as NameAndId,
		accountId: {} as NameAndId,
		value: '',
		date: new Date(),
		note: '',
	});
	const [importReceiptLoading, setImportReceiptLoading] = useState(false);

	useEffect(() => {
		setTitle(`${t('invoice')} # ${invoiceId}`);
	}, [setTitle, t, invoiceId]);

	const getInvoices = async () => {
		const { data } = await invoiceApi.getInvoiceById(invoiceId ?? '', abortCtrl.signal);
		return data;
	};

	const getReceipts = async () => {
		const { data } = await invoiceApi.getReceipt(invoiceId ?? '', abortCtrl.signal);
		return data;
	};

	const getCreditMemos = async () => {
		const { data } = await invoiceApi.getCreditMemo(invoiceId ?? '', abortCtrl.signal);
		return data;
	};

	const getData = async () => {
		invoiceItems.execute(invoiceId);
		setGlobalLoading(true);
		const mInvoices = await getInvoices();
		const mReceipts = await getReceipts();
		const mCreditMemo = await getCreditMemos();

		// Calculate Respective totals from requests
		const mReceiptTotal = mReceipts?.reduce((sum, el) => {
			let mSum = sum;
			mSum += el.amount;
			return mSum;
		}, 0);

		const mTotalRefunded = mCreditMemo?.reduce((sum, el) => {
			let mSum = sum;
			mSum += el.amount;
			return mSum;
		}, 0);

		const mPendingPayment = (mInvoices?.invoice?.amount || 0) - (
			Math.abs(mTotalRefunded) + mReceiptTotal);

		setPayments({
			pendingPayment: mPendingPayment,
			totalRefunded: mTotalRefunded,
			receiptTotal: mReceiptTotal,
		});
		setInvoice(mInvoices);
		setReceipts(mReceipts);
		setCreditMemo(mCreditMemo);
		setGlobalLoading(false);
	};

	useEffectCustom(() => {
		getData();
		return () => {
			abortCtrl.abort();
		};
	}, []);

	const handleListAction = (action: string) => {
		switch (action) {
		case t('backButton'):
			navigate(-1);
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
			break;
		case t('cancelInvoice'):
			setShowModal({ type: MODAL_TYPE.CANCEL, open: true });
			break;
		default:
			break;
		}
	};

	const handleCreateReceipt = () => {
		const mPendingPayment = pendingPayment < 0 ? '' : formatCurrency(pendingPayment);
		setReceiptInitialValue({ ...createReceiptInitialValues, value: mPendingPayment });
		setShowModal({ type: MODAL_TYPE.RECEIPT, open: true });
	};

	const generateInvoice = (item: Receipt) => {
		setGlobalLoading(true);
		invoiceApi.generateInvoice(item.transactionId, 'FR')
			.then((res) => {
				navigate(AppRoutes.RECEIPT.formatMap({ id: res.data?.invoiceId }));
				addToast({
					message: res.message,
					error: false,
				});
			})
			.catch(({ response }) => addDialog({
				title: response.data?.title,
				message: response.data?.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	const handleReceiptAction = (action: string, item: Receipt) => {
		switch (action) {
		case t('generate'):
			generateInvoice(item);
			break;
		case t('openInvoice'):
			navigate(AppRoutes.RECEIPT.formatMap({ id: item.id }));
			break;
		default:
			break;
		}
	};

	const handleCreateCreditNote = () => {
		const { data } = invoiceItems;
		if (data && data.length > 0) {
			const mInvoiceItems = [] as InvoiceItem[];
			data.forEach((val) => mInvoiceItems.push({ ...val }));
			setInvoiceItemsLocal(mInvoiceItems);
		}
		setShowModal({ type: MODAL_TYPE.CREDIT_NOTE, open: true });
	};

	const handleSendEmail = () => {
		if (!email.email) {
			return setEmail({ ...email, error: t('noEmail') });
		}
		setEmail({ ...email, loading: true });
		invoiceApi.sendInvoiceByEmail(invoiceId ?? '', email.email, abortCtrl.signal)
			.then((res) => addToast({
				message: res.message,
				error: false,
			}))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setEmail({ ...email, loading: false }));
		return null;
	};

	const handleEmailChange = (value?: string) => {
		setEmail({ ...email, email: value || '', error: '' });
	};

	const handleCloseModal = () => {
		setShowModal({
			open: false,
			type: 0,
		});
	};

	const handleCancelInvoice = (
		values: {reason: string},
		formikHelpers: FormikHelpers<{reason: string}>,
	) => {
		invoiceApi.cancelInvoice(invoiceId ?? '', values.reason, abortCtrl.signal)
			.then((res) => {
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => {
				addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				});
			})
			.finally(() => {
				handleCloseModal();
				formikHelpers.setSubmitting(false);
			});
	};

	const onItemRemove = (item: InvoiceItem) => {
		setInvoiceItemsLocal((prev) => prev?.filter((el) => el.itemId !== item.itemId));
	};

	const createCreditNote = () => {
		if (reason.reason.length < 5) return setCreditMemoError(t('reasonLengthError'));
		setCreditMemoError('');
		setGlobalLoading(true);
		const data = { ...reason, items: invoiceItemsLocal };
		handleCloseModal();
		invoiceApi.refundInvoice(data, invoiceId || '', abortCtrl.signal)
			.then((res) => {
				setReason({ reason: '', type: 'canceling' });
				navigate(AppRoutes.RECEIPT.formatMap({ id: res.data.invoiceId }));
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
		return null;
	};

	const handleCreditNoteAction = (action: string) => {
		switch (action) {
		case t('confirm'):
			createCreditNote();
			break;
		case t('refresh'):
			{
				const { data } = invoiceItems;
				if (data && data.length > 0) {
					const mInvoiceItems = [] as InvoiceItem[];
					data.forEach((val) => mInvoiceItems.push({ ...val }));
					setInvoiceItemsLocal(mInvoiceItems);
				}
			}
			break;
		case t('cancel'):
			handleCloseModal();
			break;

		default:
			break;
		}
	};

	const handleCreditNoteChange = (key: string, value: string) => {
		setReason({ ...reason, [key]: value });
	};

	const handleCreditNoteCountChange = (
		item: InvoiceItem,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const index = invoiceItemsLocal?.findIndex((el) => el.itemId === item.itemId);
		if (index !== undefined) {
			setInvoiceItemsLocal((prev) => {
				if (!prev) return [];
				const mPrev = [...prev];
				if (mPrev) {
					const oldItem = invoiceItems.data?.find((el) => el.itemId === item.itemId);
					if (oldItem && oldItem.quantity >= parseInt(e.target.value, 10)) {
						mPrev[index].quantity = parseInt(e.target.value, 10);
					}
				}
				return mPrev;
			});
		}
	};

	const validateReceiptForm = (values: ReceiptReq) => {
		const errors = {} as FormikErrors<ReceiptReq>;
		if (!values.date) {
			errors.date = t('emptyField');
		}
		if (!values.accountId) {
			errors.accountId = t('emptyField');
		}
		if (values.accountId && Object.keys(values.accountId).length === 0) {
			errors.accountId = t('emptyField');
		}
		if (!values.paymentMethodId) {
			errors.paymentMethodId = t('emptyField');
		}
		if (values.paymentMethodId && Object.keys(values.paymentMethodId).length === 0) {
			errors.paymentMethodId = t('emptyField');
		}
		if (!values.value) {
			errors.value = t('emptyField');
		}
		return errors;
	};

	const createInvoice = (values: ReceiptReq, formikHelpers: FormikHelpers<ReceiptReq>) => {
		invoiceApi.createReceipt(invoiceId ?? '', values, abortCtrl.signal)
			.then((res) => {
				navigate(AppRoutes.RECEIPT.formatMap({ id: res.data.invoiceId }));
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => {
				formikHelpers.setSubmitting(false);
				formikHelpers.resetForm({});
				handleCloseModal();
			});
	};

	const generateCreditMemo = (item: Invoice) => { // REMOVE REPITITION PUT IN ONE BLOCK
		setGlobalLoading(true);
		if (item.transactionIds && item.transactionIds.length > 0) {
			invoiceApi.generateInvoice(item.transactionIds[0], 'FR')
				.then((res) => {
					navigate(AppRoutes.RECEIPT.formatMap({ id: res.data?.invoiceId }));
					addToast({
						message: res.message,
						error: false,
					});
				})
				.catch(({ response }) => addDialog({
					title: response.data?.title,
					message: response.data?.message,
					error: true,
				}))
				.finally(() => setGlobalLoading(false));
		}
	};

	const handleCreditNoteTableAction = (action: string, item: Invoice) => {
		switch (action) {
		case t('access'):
			navigate(AppRoutes.RECEIPT.formatMap({ id: item.id }));
			break;
		case t('generate'):
			generateCreditMemo(item);
			break;
		default:
			break;
		}
	};

	const handleImportClick = () => {
		setImportReceiptLoading(true);
		invoiceApi.importReceipt(invoiceId ?? '')
			.then(() => getData())
			.catch((err) => addDialog({
				title: err.response.data.title,
				message: err.response.data.message,
				error: true,
			}))
			.finally(() => setImportReceiptLoading(false));
	};

	return (
		<>
			{showModal.open && (
				<Dialog
					open={showModal.open}
					handleClose={handleCloseModal}
					description={(
						<>
							{showModal.type === MODAL_TYPE.CANCEL && (
								<CancelInvoiceItem
									onSubmit={handleCancelInvoice}
									close={handleCloseModal}
								/>
							)}
							{showModal.type === MODAL_TYPE.CREDIT_NOTE && (
								<CreditNoteModalItem
									handleAction={handleCreditNoteAction}
									items={{
										items: invoiceItemsLocal,
										total: invoiceItemsLocal?.reduce((total, element) => {
											let mTotal = total;
											mTotal += (
												((element.itemValue
												* element.quantity)
												- element.quantityDiscount) * (element.itemTax / 100 + 1)
											);
											return mTotal;
										}, 0),
									}}
									onRemove={onItemRemove}
									onChange={handleCreditNoteChange}
									handleCreditNoteCountChange={handleCreditNoteCountChange}
									error={creditMemoError}
								/>
							)}
							{showModal.type === MODAL_TYPE.RECEIPT && (
								<ReceiptModalItem
									initialValues={createReceiptInitialValues}
									validate={validateReceiptForm}
									handleSubmit={createInvoice}
									handleClose={handleCloseModal}
									total={pendingPayment}
								/>
							)}
						</>
					)}
					hideButtons={true}
				/>
			)}
			<InvoicePage
				handleListAction={handleListAction}
				invoice={invoice}
				showCancel={creditMemo?.length === 0 && receipts?.length === 0}
				pendingPayment={pendingPayment}
				receiptComponent={(
					invoice?.invoice?.type?.id === INVOICE_TYPE_RECEIPT && (
						<ReceiptCard
							handleCreateClick={handleCreateReceipt}
							receipts={receipts}
							total={receiptTotal}
							handleReceiptAction={handleReceiptAction}
							handleImportClick={handleImportClick}
							importLoading={importReceiptLoading}
						/>
					)
				)}
				creditNoteComponent={(
					<CreditNoteCard
						handleCreateClick={handleCreateCreditNote}
						creditMemo={creditMemo}
						total={Math.abs(totalRefunded)}
						handleCreditNoteAction={handleCreditNoteTableAction}
					/>
				)}
				emailComponent={(
					<EmailCard
						handleSendClick={handleSendEmail}
						users={invoice?.customers}
						values={email}
						handleChange={handleEmailChange}
					/>
				)}
			/>
		</>
	);
};

export default InvoiceShow;
