import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeedback, useTitleBar } from '@Providers';
import { MessageReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import { userApi } from '@Requests';
import { useParams } from 'react-router-dom';
import { useEffectCustom } from '@Hooks';
import SendMessagePage from './SendMessagePage';

const Message = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { id } = useParams();
	const { addToast, addDialog } = useFeedback();

	const [initialValues] = useState({
		title: '',
		message: '',
		pushNotification: false,
		email: false,
		sms: false,
	});
	const [userName, setUserName] = useState('');

	useEffectCustom(() => {
		const getData = () => {
			setGlobalLoading(true);
			userApi.getUserById(id ?? '')
				.then((res) => {
					setTitle(res.data.name);
					setUserName(res.data.name);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => setGlobalLoading(false));
		};
		getData();
	}, [id, setTitle]);

	const validate = (values: MessageReq) => {
		const errors = {} as FormikErrors<MessageReq>;
		if (!values.title) {
			errors.title = t('emptyField');
		}
		if (!values.message) {
			errors.message = t('emptyField');
		}
		if (!values.pushNotification && !values.email && !values.sms) {
			errors.pushNotification = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (values: MessageReq, formikHelpers: FormikHelpers<MessageReq>) => {
		userApi.sendMessageToUser(values, id ?? '')
			.then((res) => {
				addToast({ message: res.message, error: false });
				formikHelpers.resetForm({});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	return (
		<SendMessagePage
			initialValues={initialValues}
			user={userName}
			validateForm={validate}
			submitForm={handleSubmit}
		/>
	);
};

export default Message;
