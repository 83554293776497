import { FontSizes } from '@Constant';
import { CreditCard } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { User } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UserDropdownProps {
   user: User;
}

const UserDropdownItem: FC<UserDropdownProps> = ({ user }) => {
	const { t } = useTranslation('translations');
	return (
		<Box>
			<Box>{user.name}</Box>
			<Stack direction="row" alignItems="center" sx={{ fontSize: FontSizes.SM }}>
				{user.taxId}
				{' '}
				{user.hasCard && (
					<Stack direction="row" alignItems="center" sx={{ fontSize: FontSizes.SM }}>
						/
						{' '}
						{t('hasCard')}
						{' '}
						<CreditCard sx={{ fontSize: FontSizes.MD, ml: '3px' }} />
					</Stack>
				)}
			</Stack>
		</Box>
	);
};

export default UserDropdownItem;
