import {
	CheckBox, DatePicker, DefaultButton, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid, Stack, Typography } from '@mui/material';
import { PendingFinancialsReq } from '@Types';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PendingFinancialFormProps {
	initialValues: PendingFinancialsReq;
	submit: (values: PendingFinancialsReq, formikHelpers: FormikHelpers<PendingFinancialsReq>) => void
}

const PendingFinancialForm: FC<PendingFinancialFormProps> = (props) => {
	const { initialValues, submit } = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						onSubmit={submit}
					>
						{({
							values,
							handleChange,
							setFieldValue,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<TextField
											label={t('name')}
											helperText=""
											disabled={false}
											id="name"
											type="text"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<DatePicker
											label={t('startDate')}
											helperText=""
											value={values.startDate}
											disabled={false}
											onChange={(value) => setFieldValue('startDate', value)}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
										<DatePicker
											label={t('endDate')}
											helperText=""
											value={values.endDate}
											disabled={false}
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
										<DefaultButton title={t('search')} type="submit" />
									</Grid>
								</Grid>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Stack direction="row">
											<CheckBox
												title={t('translations:company')}
												checked={values.company}
												setChecked={() => {
													setFieldValue('company', !values.company);
												}}
											/>
											<CheckBox
												title={t('translations:individual')}
												checked={values.indivisual}
												setChecked={() => {
													setFieldValue('indivisual', !values.indivisual);
												}}
											/>
										</Stack>
									</Grid>
								</Grid>
								<Typography variant="caption">
									*
									{' '}
									{t('toDisplayAll')}
									,
									{' '}
									{t('checkOnlyThe')}
									{' '}
									<b>{t('company')}</b>
									{' '}
									{t('and')}
									{' '}
									<b>{t('individual')}</b>
								</Typography>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default PendingFinancialForm;
