/* eslint-disable max-len */
/* eslint-disable radix */
import {
	Autocomplete, DefaultButton, Space, TextField,
} from '@Atoms';
import { currency, putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { AddCircle, ArrowForward } from '@mui/icons-material';
import {
	Box, Grid, Stack, Typography,
} from '@mui/material';
import { useDropdown } from '@Providers';
import { DiscountBillable, NameAndId, ServicesReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ServicesFormProps {
	initialValues: ServicesReq;
	discounts: DiscountBillable[];
	validate: (values: ServicesReq) => FormikErrors<ServicesReq>;
	handleSubmit: (values: ServicesReq, formikHelpers: FormikHelpers<ServicesReq>) => void;
	onReqDiscountClick: () => void;
	onTransactionsClick: () => void;
	onItemSelect: (id: number) => void;
	getBillableItems: (item: NameAndId | null) => void;
	billableItems?: DiscountBillable[];
	itemError: string
}

const ServicesForm: FC<ServicesFormProps> = (props) => {
	const {
		initialValues,
		discounts,
		validate,
		handleSubmit,
		onReqDiscountClick,
		onTransactionsClick,
		onItemSelect,
		getBillableItems,
		billableItems,
		itemError,
	} = props;
	const { t } = useTranslation('translations');
	const { discountTypes } = useDropdown();
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							setFieldValue, values, touched, errors, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('type')}
											placeholder=""
											disabled={isSubmitting}
											options={discountTypes || []}
											getOptionLabel={(item) => item.name || ''}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													<div>
														<i className={`fa ${item.icon}`} />
														<Box sx={{ ml: 1 }} component="span">{item.name}</Box>
													</div>
												</li>
											)}
											defaultValue={values.type}
											onChange={(_, item) => {
												setFieldValue('type', item);
												getBillableItems(item);
											}}
										/>
									</Grid>
									<Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('item')}
											placeholder=""
											disabled={isSubmitting}
											options={billableItems || []}
											getOptionLabel={(item) => item.name || ''}
											error={touched.billableItemId && Boolean(errors.billableItemId)}
											helperText={touched.billableItemId && String(errors.billableItemId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.name}
												</li>
											)}
											defaultValue={values.billableItemId}
											onChange={(_, item) => {
												setFieldValue('billableItemId', item);
												setFieldValue('value', putDecimalPoints(item?.value));
												if (item?.id) {
													onItemSelect(item?.id);
												}
											}}
										/>
									</Grid>
									<Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
										<TextField
											label={t('quantity')}
											title=""
											value={values.quantity}
											type="number"
											error={touched.quantity && Boolean(errors.quantity)}
											helperText={touched.quantity && errors.quantity}
											disabled={isSubmitting}
											id="quantity"
											onChange={(e) => {
												e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
												setFieldValue('quantity', parseInt(e.target.value));
											}}
										/>
									</Grid>
									<Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
										<TextField
											title=""
											label={`${t('unitary')} (${t('withOut')} ${t('vat')})`}
											type="text"
											disabled={values.type?.id === 1 || isSubmitting}
											id="value"
											value={values.value}
											error={touched.value && Boolean(errors.value)}
											helperText={touched.value && errors.value}
											onChange={(event) => setFieldValue('value', currency(event).target.value)}
										/>
									</Grid>
									<Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('discount')}
											placeholder=""
											disabled={isSubmitting}
											options={discounts}
											defaultValue={values.discountRequestId}
											getOptionLabel={(item) => item.note || ''}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.note}
												</li>
											)}
											onChange={(_, item) => setFieldValue('discountRequestId', item)}
										/>
									</Grid>
									<Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
										<DefaultButton
											size="medium"
											title={t('requestDiscount')}
											disabled={isSubmitting}
											onClick={onReqDiscountClick}
										/>
										<Space />
										<Typography color="error" variant="caption">{itemError}</Typography>
									</Grid>
								</Grid>
								<Space height={3} />
								<Stack direction="row" justifyContent="space-between">
									<DefaultButton
										title={t('addService')}
										type="submit"
										loading={isSubmitting}
										startIcon={<AddCircle />}
									/>
									<DefaultButton
										title={t('goToTransactions')}
										onClick={() => {
											onTransactionsClick();
										}}
										startIcon={<ArrowForward />}
										variant="text"
										disabled={isSubmitting}
									/>
								</Stack>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ServicesForm;
