/* eslint-disable react/no-unstable-nested-components */
import { useTitleBar, useAuth, useFeedback } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Header, RequestDiscountDailog } from '@Organisms';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequest } from '@Api';
import {
	discountApi, reservationApi, serviceApi, transactionApi, invoiceApi,
} from '@Requests';
import { useEffectCustom } from '@Hooks';
import { AppRoutes } from '@Routes';
import { DefaultSelect, Space } from '@Atoms';
import {
	Transaction,
	TransactionReq,
	NameAndId,
	User,
	RequestDiscountReq,
	Item,
	DiscountBillable,
	ApplicableCampaign,
	Service,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import { INVOICE_TYPE } from '@Constant';
import TransactionsListing from './TransactionsListing';
import BillingItem from './BillingItem';

const CREDIT_NOTES_TYPE_ID = 4;
const PENDING_TRANSACTION = 1;

const INVOICE_TYPES = {
	COMMON: 'FT',
	RECEIPT: 'FR',
};

const Transactions = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const navigate = useNavigate();
	const { reservationId } = useParams();
	const { user } = useAuth();
	const { addDialog, addToast } = useFeedback();

	const invoiceTypes = [
		{ value: 'FR', label: t('receiptInvoice') },
		{ value: 'FT', label: t('commonInvoice') },
	];

	const reservation = useRequest(([params]) => reservationApi.getReservationById(params));
	const transactions = useRequest((params) => reservationApi.getReservationTransactions(params));
	const reservationItems = useRequest(([params]) => serviceApi.unbilledReservationItems(params));
	const reservationDiscounts = useRequest(([params]) => (
		discountApi.getDiscountsByReservation(params, 1)));

	const reservationCostumers = useRequest(([params]) => (
		reservationApi.getReservationCustomers(params)));

	const [initialValues] = useState<TransactionReq>({
		dueDate: new Date(),
		paymentMethodId: {} as NameAndId,
		clientId: {} as User,
		accountId: {} as NameAndId,
		clientType: {} as NameAndId,
	});
	const [reqDiscountValues, setReqDiscountValues] = useState({
		discount: '',
		isPercent: false,
		note: '',
		itemId: [] as Item[],
	});
	const [total, setTotal] = useState(0);
	const [tax, setTax] = useState(0);
	const [invoiceModal, setInvoiceModal] = useState(false);
	const [transactionId, setTransactionId] = useState<number | undefined>();
	const [invoiceType, setInvoiceType] = useState('FR');
	const [loadingId, setLoadingId] = useState(0);
	const [reqDiscountDialog, setReqDiscountDialog] = useState(false);
	const [applicableCampaign, setApplicableCampaign] = useState<ApplicableCampaign>({
		show: false,
		item: null,
		cancel: false,
	});
	const [applyCampaign, setApplyCampaign] = useState(false);

	useEffect(() => {
		setTitle(t('transactions'));
	}, [setTitle, t]);

	const calculateTotalWithTax = (data: Service[], discount: number) => {
		// const calculateTax = data.reduce((sum, el) => {
		// 	let mTotal = sum;
		// 	mTotal += ((
		// 		((
		// 			el.itemValue - el.discount
		// 		) * el.quantity) - el.quantityDiscount) * (el.itemTax / 100));
		// 	return mTotal;
		// }, 0 as number);
		const calculateSum = data.reduce((a, el) => (
			(((el.itemValue - el.discount) * el.quantity) - el.quantityDiscount) + a), 0) - discount;
		setTotal(calculateSum);
		setTax(calculateSum * 0.23);
	};

	const calculateValues = (discount?: number) => {
		const { data } = reservationItems;
		if (data) {
			calculateTotalWithTax(data, discount ?? 0);
			const mApplicableCampaign = data.find((resItem) => (
				reservation.data?.campaign?.itemId === resItem?.item?.itemId));
			if (mApplicableCampaign) {
				setApplicableCampaign({ show: true, item: mApplicableCampaign, cancel: false });
			} else {
				setApplicableCampaign({ show: false, item: null, cancel: false });
				setApplyCampaign(false);
			}
		}
	};

	useEffectCustom(() => {
		calculateValues();
	}, [reservationItems.data, reservation.data]);

	const calculateTotal = (item: DiscountBillable | null) => {
		if (!item) {
			return calculateValues();
		}
		const foundItem = reservationItems.data?.filter((
			(el) => item.billableItemsIds.find((it) => it === el.item.itemId)
		));
		if (foundItem && foundItem.length > 0) {
			const totalValue = foundItem.reduce((sum, el) => {
				let mSum = sum;
				mSum += (el.itemValue - el.discount - el.quantity) * el.quantity;
				return mSum;
			}, 0);
			const discount = item.isPercentage ? totalValue * (item.amount / 100) : item.amount;
			calculateValues(discount);
		} else {
			calculateValues();
		}
		return null;
	};

	useEffectCustom(() => {
		if (reservation.data) {
			if (!reservation.data.hasContract) {
				addDialog({
					title: t('noContract'),
					message: t('noContractMessage'),
					error: true,
				});
			}
		}
	}, [reservation.data]);

	const getData = () => {
		reservation.execute(reservationId);
		transactions.execute(reservationId);
		reservationCostumers.execute(reservationId);
		reservationDiscounts.execute(reservationId);
		reservationItems.execute(reservationId);
	};

	useEffectCustom(() => {
		if (reservationId) { getData(); }
	}, [reservationId]);

	const handleBackPress = () => {
		navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
	};

	const validateTransaction = (values: TransactionReq) => {
		const errors = {} as FormikErrors<TransactionReq>;
		if (!values.clientId) {
			errors.clientId = t('emptyField');
		}
		if (values.clientId && Object.keys(values.clientId).length === 0) {
			errors.clientId = t('emptyField');
		}
		if (!values.paymentMethodId) {
			errors.paymentMethodId = t('emptyField');
		}
		if (values.paymentMethodId && Object.keys(values.paymentMethodId).length === 0) {
			errors.paymentMethodId = t('emptyField');
		}
		if (!values.accountId) {
			errors.accountId = t('emptyField');
		}
		if (values.accountId && Object.keys(values.accountId).length === 0) {
			errors.accountId = t('emptyField');
		}
		if (!values.dueDate) {
			errors.dueDate = t('emptyField');
		}
		return errors;
	};

	const removeItem = (itemId?: number) => {
		if (itemId) {
			reservationItems.setData((prev) => prev?.filter((el) => el.id !== itemId));
		}
	};

	const generateInvoice = (type: string, transactId?: number) => {
		setLoadingId(transactId ?? 0);
		setGlobalLoading(true);
		invoiceApi.generateInvoice(transactId, type)
			.then(({ data }) => {
				// TODO If RECEIPT NAVIGATE TO CREDIT NOTE
				if (invoiceType === INVOICE_TYPES.COMMON) {
					navigate(AppRoutes.INVOICES.formatMap({ invoiceId: data.invoiceId, reservationId }));
				} else {
					// navigate(AppRoutes.RECEIPT.formatMap({ id: data.invoiceId }));
					navigate(AppRoutes.INVOICES.formatMap({ invoiceId: data.invoiceId, reservationId }));
				}
			})
			.catch(({ response }) => {
				addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				});
				transactions.execute(reservationId);
			})
			.finally(() => {
				setGlobalLoading(false);
				setLoadingId(0);
			});
	};

	const onReqDiscountClick = () => {
		if (!reservationItems.data) {
			return null;
		}
		setReqDiscountValues({
			...reqDiscountValues,
			itemId: reservationItems.data?.reduce((newItems, element, index) => {
				const mItems = newItems;
				mItems.push({ ...element.item, checked: false, id: index });
				return mItems;
			}, [] as Item[]),
		});
		setReqDiscountDialog(true);
		return null;
	};

	const handleAction = (action: string, id?: number) => {
		switch (action) {
		case t('remove'):
			removeItem(id);
			break;
		case t('goToServices'):
			navigate(AppRoutes.RESERVATION_SERVICES.formatMap({ reservationId }));
			break;
		case t('refresh'):
			reservationItems.execute(reservationId);
			break;
		case t('requestDiscount'):
			onReqDiscountClick();
			break;
		default:
			break;
		}
	};

	const createNewTransaction = (
		values: TransactionReq,
		formikHelpers: FormikHelpers<TransactionReq>,
	) => {
		transactionApi.addTransaction(
			reservationId ?? '',
			values,
			reservationItems.data,
			user?.id,
			applyCampaign,
		)
			.then(() => {
				transactions.execute(reservationId);
				reservationItems.execute(reservationId);
				formikHelpers.resetForm({});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	const handleInvoiceAction = (action: string, item: Transaction) => {
		switch (action) {
		case t('generateInvoice'):
			if (item?.status.id === PENDING_TRANSACTION) {
				generateInvoice(INVOICE_TYPE.RECEIPT_INVOICE, item?.id);
				break;
			}
			if (item && new Date() === new Date(item?.dueDate)) {
				generateInvoice(INVOICE_TYPE.COMMON_INVOICE, item?.id);
				break;
			} else {
				setInvoiceModal(true);
				setTransactionId(item?.id);
				break;
			}
			break;
		case t('openInvoice'):
			if (item?.type?.id === CREDIT_NOTES_TYPE_ID) {
				navigate(AppRoutes.RECEIPT.formatMap({ id: item?.invoice?.id }));
			} else {
				navigate(AppRoutes.INVOICES.formatMap({ invoiceId: item?.invoice?.id, reservationId }));
			}
			break;
		default:
			break;
		}
	};

	const closeInvoiceTypeDialog = () => {
		setInvoiceModal(false);
	};

	const onConfirmClick = () => {
		if (transactionId) {
			setInvoiceModal(false);
			generateInvoice(invoiceType, transactionId);
		}
	};

	const closeDialog = () => {
		setReqDiscountDialog(false);
		setReqDiscountValues({
			...reqDiscountValues,
			itemId: [] as Item[],
			note: '',
		});
	};

	const validateReqDisForm = (values: RequestDiscountReq) => {
		const errors = {} as FormikErrors<RequestDiscountReq>;
		if (!values.discount) {
			errors.discount = t('emptyField');
		}
		if (!values.note) {
			errors.note = t('emptyField');
		}
		return errors;
	};

	const reqDiscount = (
		values: RequestDiscountReq,
		formikHelper: FormikHelpers<RequestDiscountReq>,
	) => {
		setReqDiscountDialog(false);
		setGlobalLoading(true);
		discountApi.requestDiscount({
			...values,
			itemId: values.itemId.reduce((numberList, element) => {
				const mNumberList = numberList;
				if (element.checked) {
					mNumberList.push(element.itemId);
				}
				return mNumberList;
			}, [] as number[]),
			typeId: 1,
			reservationId,
		}).then((res) => {
			reservationDiscounts.execute(reservationId);
			addToast({ message: res.message, error: false });
			formikHelper.resetForm({});
		})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	const calculateTotalWithCampaign = () => {
		const { data } = reservationItems;
		if (data) {
			const calculateTax = data.reduce((sum, el) => {
				let mTotal = sum;
				mTotal += (el.itemValue - el.discount) * el.quantity * (el.itemTax / 100);
				return mTotal;
			}, 0 as number);
			const calculateSum = data.reduce((a, el) => (
				(el.itemValue - el.discount) * el.quantity + a), 0);
			setTotal(calculateSum);
			setTax(calculateTax);
		}
	};

	const handleApplyCampaign = () => {
		if (applicableCampaign.cancel) {
			setApplyCampaign(false);
			setApplicableCampaign({ ...applicableCampaign, cancel: false });
		} else {
			setApplyCampaign(true);
			setApplicableCampaign({ ...applicableCampaign, cancel: true });
			calculateTotalWithCampaign();
		}
	};

	return (
		<>
			<RequestDiscountDailog
				initialValues={reqDiscountValues}
				open={reqDiscountDialog}
				validate={validateReqDisForm}
				handleClose={closeDialog}
				data={reservationItems.data}
				handleSubmit={reqDiscount}
			/>
			<Dialog
				open={invoiceModal}
				handleClose={closeInvoiceTypeDialog}
				title={t('whatInvoiceType')}
				description={(
					<DefaultSelect
						options={invoiceTypes}
						label=""
						renderItem={(item) => ({ optionLabel: item.label, optionValue: item.value })}
						value={invoiceType}
						onChange={(event) => setInvoiceType(event.target.value)}
					/>
				)}
				primaryButtonAction={onConfirmClick}
				primaryButtonText="OK"
				secondaryButtonText={t('cancel')}
				secondaryButtonAction={closeInvoiceTypeDialog}
			/>
			<Header onBackPress={handleBackPress} reservation={reservation.data} />
			<Space height={3} />
			<BillingItem
				data={reservationItems.data || []}
				total={total}
				tax={tax}
				validate={validateTransaction}
				initialValues={initialValues}
				handleSubmit={createNewTransaction}
				customers={reservationCostumers.data}
				discounts={reservationDiscounts.data?.data}
				handleAction={handleAction}
				calculateTotal={calculateTotal}
				reservation={reservation.data}
				applicableCampaign={applicableCampaign}
				handleApplyDiscount={handleApplyCampaign}
			/>
			<Space height={3} />
			<TransactionsListing
				data={transactions.data}
				handleInvoiceAction={handleInvoiceAction}
				loadingItemId={loadingId}
			/>
		</>
	);
};

export default Transactions;
