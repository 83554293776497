import { DROPDOWN_ACTION } from '@Constant';
import { validateNif } from '@Helpers';
import { useEffectCustom } from '@Hooks';
import {
	phoneString, taxIdString, postCodeString, replaceDecimal, replaceComma, normalizePhone,
} from '@Masks';
import { useFeedback, useTitleBar } from '@Providers';
import { organizationApi, placesApi } from '@Requests';
import { AppRoutes } from '@Routes';
import {
	City, Country, OrganizationReq, State,
} from '@Types';
import { validateEmail } from '@Validation';
import { SetFieldValue } from 'components/pages/users/create/CreateUserPage';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateOrganizationPage from './CreateOrganizationPage';

const NIF_LENGTH = 10;

const CreateOrganization = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const navigate = useNavigate();
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast, addDialog } = useFeedback();

	const [initialValues, setInitialValues] = useState({
		taxId: '',
		phone: '',
		phoneCountryId: {} as Country,
		email: '',
		address: '',
		addressComplement: '',
		postalCode: '',
		neighborhood: '',
		fantasyName: '',
		officialName: '',
		cityId: {} as City,
		stateId: {} as State,
		countryId: {} as Country,
		establishmentNumber: 0,
		latitude: '',
		longitude: '',
		externalId: '',
	});

	const [countries, setCountries] = useState([] as Country[]);
	const [cities, setCities] = useState([] as City[]);
	const [states, setStates] = useState([] as State[]);
	const [selectedPlaces] = useState({
		country: 1,
		state: 1,
	});
	const [isEdit, setEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('organization')} ${t('details')}`);
		} else {
			setTitle(`${t('new')} ${t('organization')}`);
			setInitialValues({
				taxId: '',
				phone: '',
				phoneCountryId: {} as Country,
				email: '',
				address: '',
				addressComplement: '',
				postalCode: '',
				neighborhood: '',
				fantasyName: '',
				officialName: '',
				cityId: {} as City,
				stateId: {} as State,
				countryId: {} as Country,
				establishmentNumber: 0,
				latitude: '',
				longitude: '',
				externalId: '',
			});
			setEdit(true);
		}
	}, [setTitle, t, id]);

	const getDropdowns = async () => {
		const mCountries = await placesApi.getCountries();
		const mStates = await placesApi.getStates(selectedPlaces.country);
		setCountries(mCountries.data.data);
		setStates(mStates.data);
		setInitialValues({
			...initialValues,
			countryId: mCountries.data.data[0],
		});
	};

	const getData = () => {
		setGlobalLoading(true);
		organizationApi.getOrganizationById(id ?? '').then(async ({ data }) => {
			const mCountries = await placesApi.getCountries();
			const mStates = await placesApi.getStates(selectedPlaces.country);
			const mCities = await placesApi.getCities(selectedPlaces.state);
			setCountries(mCountries.data.data);
			setStates(mStates.data);
			setCities(mCities.data);
			setInitialValues({
				taxId: taxIdString(data.taxId),
				phone: phoneString(data.phone),
				phoneCountryId: data.phoneCountry,
				email: data.email,
				address: data.address,
				addressComplement: data.addressComplement,
				postalCode: postCodeString(data.postalCode),
				neighborhood: data.neighborhood,
				fantasyName: data.fantasyName,
				officialName: data.officialName,
				cityId: data.city,
				stateId: data.state,
				countryId: data.country,
				establishmentNumber: data.establishmentNumber,
				latitude: replaceDecimal(data.latitude),
				longitude: replaceDecimal(data.longitude),
				externalId: data.externalId,
			});
		});
		setGlobalLoading(false);
	};

	useEffectCustom(() => {
		if (id) {
			getData();
		} else {
			getDropdowns();
		}
	}, []);

	const handleCardClick = () => {
		navigate(AppRoutes.ADD_PAYMENT_CARD.formatMap({ clientId: id, typeId: 2 }));
	};

	const validate = (values: OrganizationReq) => {
		const errors = {} as FormikErrors<OrganizationReq>;
		const lat = replaceComma(values.latitude);
		const long = replaceComma(values.longitude);
		if (!values.countryId) {
			errors.countryId = t('emptyField');
		}
		if (values.countryId && Object.keys(values.countryId).length === 0) {
			errors.countryId = t('emptyField');
		}
		if (!values.phoneCountryId) {
			errors.phoneCountryId = t('emptyField');
		}
		if (values.phoneCountryId && Object.keys(values.phoneCountryId).length === 0) {
			errors.phoneCountryId = t('emptyField');
		}
		if (values.countryId.id === 1) {
			if (!values.stateId) {
				errors.stateId = t('emptyField');
			}
			if (values.stateId && Object.keys(values.stateId).length === 0) {
				errors.stateId = t('emptyField');
			}
		}
		if (values.countryId.id === 1) {
			if (!values.cityId) {
				errors.cityId = t('emptyField');
			}
			if (values.cityId && Object.keys(values.cityId).length === 0) {
				errors.cityId = t('emptyField');
			}
		}
		if (!values.taxId) {
			errors.taxId = t('emptyField');
		}
		if (values.countryId.id === 1) {
			if (values.taxId.length < 10) {
				errors.taxId = t('emptyField');
			}
			if (values.postalCode.length < 8) {
				errors.postalCode = t('invalidValue').format(t('postalCode'));
			}
		}
		if (!values.phone) {
			errors.phone = t('emptyField');
		}
		if (values.phoneCountryId.id === 1) {
			if (normalizePhone(values.phone).length !== 9) {
				errors.phone = t('invalidValue').format(t('phone'));
			}
		}
		if (!values.email) {
			errors.email = t('emptyField');
		} else if (!validateEmail(values.email)) {
			errors.email = t('validEmail');
		}
		if (!values.address) {
			errors.address = t('emptyField');
		}
		if (!values.postalCode) {
			errors.postalCode = t('emptyField');
		}
		if (!values.neighborhood) {
			errors.neighborhood = t('emptyField');
		}
		if (!values.fantasyName) {
			errors.fantasyName = t('emptyField');
		}
		if (!values.officialName) {
			errors.officialName = t('emptyField');
		}
		if (!values.latitude) {
			errors.latitude = t('emptyField');
		}
		if (!values.longitude) {
			errors.longitude = t('emptyField');
		}
		if (values.latitude) {
			if (values.latitude.length < 6) {
				errors.latitude = t('latitudeLengthError');
			}
		}
		if (lat > 90) {
			errors.latitude = `${t('latitudeGreaterError')}90`;
		}
		if (!values.longitude) {
			errors.longitude = t('emptyField');
		}
		if (values.longitude) {
			if (values.longitude.length < 6) {
				errors.longitude = t('latitudeLengthError');
			}
		}
		if (long > 180) {
			errors.longitude = `${t('latitudeGreaterError')}180`;
		}

		return errors;
	};

	const getDropdownById = async (action: string, dropdownId: number) => {
		switch (action) {
		case DROPDOWN_ACTION.COUNTRY:
		{
			const mStates = await placesApi.getStates(dropdownId);
			setStates(mStates.data);
			if (dropdownId !== 1) {
				setCities([]);
			}
			break;
		}
		case DROPDOWN_ACTION.STATE:
		{
			const mCities = await placesApi.getCities(dropdownId);
			setCities(mCities.data);
			break;
		}
		default:
			break;
		}
	};

	const handleSubmit = (values: OrganizationReq, formikHelpers: FormikHelpers<OrganizationReq>) => {
		if (id) {
			organizationApi.updateOrganization(values, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.ORGANIZATIONS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			organizationApi.addNewOrganization(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.ORGANIZATIONS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const checkNif = async (
		value: string,
		setFieldValue: SetFieldValue,
	) => {
		if (value.length === NIF_LENGTH) {
			if (parseInt(value.charAt(0), 10) < 5) {
				addDialog({ title: t('invalidNif'), message: t('organizationNifError'), error: true });
				setFieldValue('taxId', '');
				return null;
			}
			const isCorrect = validateNif(value);
			if (!isCorrect) {
				setFieldValue('taxId', '');
				addDialog({ title: t('invalidNif'), message: t('invalidNifMessage'), error: true });
			}
		}
		return null;
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('organization')}`);
		setEdit(true);
	};

	return (
		<CreateOrganizationPage
			initialValues={initialValues}
			id={!!id}
			handleCardClick={handleCardClick}
			countries={countries}
			states={states}
			cities={cities}
			requestById={getDropdownById}
			validate={validate}
			handleSubmit={handleSubmit}
			validateNif={checkNif}
			handleEditClick={handleEditClick}
			isEdit={isEdit}
		/>
	);
};

export default CreateOrganization;
