import {
	Title, Autocomplete, Space, TextField, DefaultButton,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ReservationReq, Organization, User, Reservation,
} from '@Types';
import { Formik, Form, FormikHelpers } from 'formik';

interface ChangeReservationPage {
	initialValues: ReservationReq;
	handleSubmit: (values: ReservationReq, formikHelpers: FormikHelpers<ReservationReq>) => void;
	companies: Organization[];
	users: User[];
	getDropdown: (action: string, search: string) => void;
	name: string;
	reservation: Reservation
}

const ChangeReservationPage: FC<ChangeReservationPage> = (props) => {
	const {
		initialValues, handleSubmit, companies, users, getDropdown, name, reservation,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Title text={`${t('responsible')}: ${name}`} />
					<Space height={2} />
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
					>
						{({
							values, setFieldValue, handleChange, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									{!reservation.organization && (
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<Autocomplete
												options={companies}
												label={t('company')}
												onInputChange={(value) => getDropdown(t('company'), value)}
												placeholder=""
												disabled={isSubmitting}
												defaultValue={values.organizationId}
												getOptionLabel={(item) => item.officialName}
												renderOption={(inputprops, item) => (
													<li {...inputprops} key={item.id}>
														{item.officialName}
													</li>
												)}
												onChange={(_, item) => {
													setFieldValue('organizationId', item);
												}}
											/>
										</Grid>
									)}
									{!reservation.additionalDriver && (
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<Autocomplete
												options={users}
												label={t('secondDriver')}
												onInputChange={(value) => getDropdown(t('secondDriver'), value)}
												placeholder=""
												disabled={isSubmitting}
												defaultValue={values.additionalDriverId}
												getOptionLabel={(item) => item.name}
												renderOption={(inputprops, item) => (
													<li {...inputprops} key={item.id}>
														{item.name}
														{' '}
														-
														{' '}
														{item.taxId}
													</li>
												)}
												onChange={(_, item) => {
													setFieldValue('additionalDriverId', item);
												}}
											/>
										</Grid>
									)}
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									{!reservation.notes && (
										<Grid item sm={12} md={12}>
											<TextField
												label={t('notes')}
												multiline
												disabled={isSubmitting}
												minRows={3}
												value={values.note}
												id="note"
												onChange={handleChange}
											/>
										</Grid>
									)}
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={12} md={12}>
										<DefaultButton
											title={t('save')}
											type="submit"
											loading={isSubmitting}
										/>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ChangeReservationPage;
