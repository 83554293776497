/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import React from 'react';
import * as yup from 'yup';
import { Country } from '@Types';

enum DOCUMENT_TYPES {
	CITIZEN = 1,
	PASSPORT = 2,
	LICENSE = 3
}

const ISSUE_DATE_KEY = 'issueDate';

const validate = (context: any) => {
	if (!context) {
		return false;
	}
	if (context && Object.keys(context).length === 0) {
		return false;
	}
	return true;
};

const validateDocumentField = (docObject: any, documents: any, currentField: any) => {
	/** CHECK IF THE FIELD HAS NO VALUE */
	if (!docObject[currentField]) {
		return false;
	}
	/** CHECK IF THE INPUT IS OF TYPE OBJECT (DROPDOWN) */
	if (typeof docObject[currentField] === 'object' && currentField === 'countryId') {
		if (Object.keys(docObject[currentField]).length > 0) {
			return true;
		}
		return false;
	}
	/** CHECK IF THE FIELD HAS A VALUE */
	if (docObject[currentField]) {
		return true;
	}
	return false;
};

export const validateAtleastOne = (context: yup.TestContext<any>) => {
	const data: any = context;
	const { documents } = data.from[1].value;
	const docObject = data.parent;
	const currentField = data.path.split('.')[1];

	if (docObject.typeId === DOCUMENT_TYPES.LICENSE) {
		return validateDocumentField(docObject, documents, currentField);
	}
	if (docObject.typeId === DOCUMENT_TYPES.CITIZEN && !documents[1]?.value) {
		if (documents[0]?.countryId?.id === 1 && currentField === ISSUE_DATE_KEY) {
			return true;
		}
		return validateDocumentField(docObject, documents, currentField);
	}
	if (docObject.typeId === DOCUMENT_TYPES.PASSPORT && !documents[0]?.value) {
		if (!documents[1]?.value) {
			return true;
		}
		return validateDocumentField(docObject, documents, currentField);
	}
	return true;
};

export const useValidation = () => {
	const { t } = useTranslation('translations');
	const userSchema = yup.object({
		nationalityId: yup.object().typeError(t('emptyField')).test('', t('emptyField'), function () {
			return validate(this.parent.nationalityId);
		}),
		name: yup.string().min(1).required(t('emptyField')),
		birthdate: yup.string().min(10).required(t('emptyField')),
		phoneCountryId: yup.object().typeError('').test('', t('emptyField'), function () {
			return validate(this.parent.phoneCountryId);
		}),
		phone: yup.string().min(9).required(t('emptyField')),
		email: yup.string().email(t('validEmail')).required(t('emptyField')),
		countryId: yup.object().typeError(t('emptyField')).test('', t('emptyField'), function () {
			return validate(this.parent.countryId);
		}),
		stateId: yup.object().nullable().when('countryId', {
			is: (countryId: Country) => countryId && countryId?.id === 1,
			then: yup.object().nullable().test('', t('emptyField'), function () {
				return validate(this.parent.stateId);
			}),
		}),
		cityId: yup.object().nullable().when('countryId', {
			is: (countryId: Country) => countryId && countryId?.id === 1,
			then: yup.object().nullable().test('', t('emptyField'), function () {
				return validate(this.parent.cityId);
			}),
		}),
		alternateCityName: yup.string().nullable().when('countryId', {
			is: (countryId: Country) => countryId && countryId?.id !== 1,
			then: yup.string().nullable().min(1, t('emptyField')).required(t('emptyField')),
		}),
		address: yup.string().min(3).required(t('emptyField')),
		neighborhood: yup.string().min(3).required(t('emptyField')),
		postalCode: yup.string().when('countryId', {
			is: (countryId: Country) => countryId && countryId?.id === 1,
			then: yup.string().length(8, t('postCodeIncomplete')).required(t('emptyField')),
		}).required(t('emptyField')),
		taxId: yup.string().when('countryId', {
			is: (countryId: Country) => (
				countryId && countryId?.id === 1
			),
			then: yup.string().length(10, t('taxIdIncomplete')).required(t('emptyField')),
		}),
		disableRequireDocs: yup.boolean(),
		documents: yup.array().when('disableRequireDocs', {
			is: false,
			then: yup.array(
				yup.object().shape({
					countryId: yup.mixed().typeError('').test('', t('emptyField'), function () {
						return validateAtleastOne(this);
					}),
					value: yup.string().test('', t('emptyField'), function () {
						return validateAtleastOne(this);
					}),
					expirationDate: yup.mixed().test('', t('emptyField'), function () {
						return validateAtleastOne(this);
					}),
					issueDate: yup.mixed().test('', t('emptyField'), function () {
						return validateAtleastOne(this);
					}),
				}),
			),
		}),
	});

	return { userSchema };
};
