import {
	DefaultButton, Image, Space, TextField,
} from '@Atoms';
import { logo } from '@Images';
import { Box, Container, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ForgotPasswordProps {
	email: string;
	emailError: string;
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	onSubmit: () => void;
	loading: boolean
}

const ForgotPasswordPage: FC<ForgotPasswordProps> = (props) => {
	const {
		email, emailError, onChange, onSubmit, loading,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Container>
			<Container maxWidth="xs">
				<Space height={5} />

				<Image src={logo} height="100%" width="100%" alt="AJ Rent Logo" />

				<Space height={3} />

				<form onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
				>
					<TextField
						label={t('email')}
						name="email"
						value={email}
						onChange={(e) => onChange(e)}
						error={!!emailError}
						helperText={emailError}
					/>

					<Space height={2} />

					<DefaultButton
						fullWidth
						title={t('passwordResetLink')}
						type="submit"
						loading={loading}
					/>
				</form>
				<Space height={3} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						{`${process.env.REACT_APP_NAME} - ${t('copyright')} © ${new Date().getFullYear()}`}
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default ForgotPasswordPage;
