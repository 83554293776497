import { DefaultButton, TableItem } from '@Atoms';
import { formatDate, padId } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Invoice, Pagination, PaginationTable } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AccountingProps {
   invoiceReport?: Pagination<Invoice[]>;
   setPagination: (params: PaginationTable)=>void;
   setSearch: (searchQuery: string) => void;
   handleListAction: (action: string, item: number | Invoice) => void
}

const AccountingPage: FC<AccountingProps> = (props) => {
	const {
		invoiceReport, setPagination, setSearch, handleListAction,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<DataTable
						data={invoiceReport?.data || []}
						total={invoiceReport?.total}
						pagination
						onChange={(page, size) => setPagination({ page, size })}
						onSearching={(e) => setSearch(e)}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('coloborator')} render={(item) => item.salespeople} />
						<TableColumn
							headerOptions={{
								render: () => <TableItem title={t('date')} subtitle={t('expiration')} />,
							}}
							render={(item) => (
								<TableItem
									title={formatDate(item.registration)}
									subtitle={formatDate(item.dueDate)}
								/>
							)}
						/>
						<TableColumn
							headerOptions={{
								render: () => (
									<TableItem
										title={t('reservation')}
										subtitle={t('client')}
									/>
								),
							}}
							render={(item) => (
								<TableItem
									title={padId(item.reservationId, 6)}
									subtitle={item.clientTaxId}
									link
									color="blue"
									onClick={() => handleListAction(t('reservation'), item.reservationId)}
								/>
							)}
						/>
						<TableColumn
							headerOptions={{
								render: () => <TableItem title={t('value')} subtitle={t('discount')} />,
							}}
							render={(item) => (
								<TableItem
									title={putDecimalPoints(item.amount)}
									subtitle={putDecimalPoints(item.discount)}
								/>
							)}
						/>
						<TableColumn
							headerOptions={{
								render: () => (
									<TableItem
										title={t('paymentMethod')}
										subtitle={t('account')}
									/>
								),
							}}
							render={(item) => (
								<TableItem
									title={item.paymentMethod?.name}
									subtitle={item.accountName}
								/>
							)}
						/>
						<TableColumn
							headerOptions={{
								render: () => <TableItem title={t('serialName')} subtitle={t('invoiceType')} />,
							}}
							render={(item) => (
								<TableItem
									title={item.serialName}
									subtitle={item.invoiceType?.name}
								/>
							)}
						/>
						<TableColumn
							headerOptions={{
								render: () => <TableItem title={t('invoice')} subtitle={' '} />,
							}}
							render={(item) => item.fullIdentifier && (
								<DefaultButton
									title={item.fullIdentifier}
									onClick={() => {
										handleListAction(t('invoice'), item);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default AccountingPage;
