import {
	Autocomplete,
	CarItem,
	CheckBox,
	DatePicker,
	DefaultButton,
	Description,
	Space,
	TextField,
	TimePicker,
} from '@Atoms';
import { currency, putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import {
	Alert,
	Chip, Divider, Grid, Slider, Stack, Typography,
} from '@mui/material';
import { useDropdown } from '@Providers';
import {
	Campaign,
	Category,
	Organization,
	ReservationReq,
	User,
	ReservationConflictReq,
	CarMin,
	DiscountBillable,
	Tariff,
} from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatPercentage } from '@Helpers';
import { Block, Flag, Save } from '@mui/icons-material';
import { USER_FIELD } from '@Constant';
import { Dialog } from '@Organisms';
import EditLabel from './EditLabel';
import TransferTariffItem from './TransferTariffItem';

interface DisableOptions {
	edit: boolean;
	disableAll: boolean;
	disablePickupDate: boolean;
	startButton: boolean;
	showButtons: boolean;
}

export type SetSubmitting = (isSubmitting: boolean) => void;
export type SetFieldError = (field: string, message: string | undefined) => void;
export type SetFieldValue = (field: string, value: User | [] | DiscountBillable[] | null) => void
export type SetFieldTouched = (
	field: string,
	isTouched?: boolean | undefined,
	shouldValidate?: boolean | undefined) => void;

type ReservationCost = {
	days: number,
	hours: number,
	total: number
}

interface CreateReservationProps {
	initialValues: ReservationReq;
	companies?: Organization[];
	responsibles?: User[];
	mainDrivers?: User[];
	secondDrivers?: User[];
	categories?: Category[];
	cars?: CarMin[],
	campaigns?: Campaign[];
	getDropdown: (
		dropdownType: string,
		query?: string,
		itemId?: number,
		startData?: Date,
		endDate?: Date) => void;
	onSubmit: (values: ReservationReq, formikHelpers: FormikHelpers<ReservationReq>) => void;
	saveDossier: (value: string) => void;
	handleAction: (action: string, id?: number) => void;
	validate: (values: ReservationReq) => FormikErrors<ReservationReq>;
	disableOptions: DisableOptions;
	startReservation: (
		values: ReservationReq,
		setSubmitting: SetSubmitting) => void;
	getReservationConflict: (params: ReservationConflictReq) => void;
	reservationCost: ReservationCost;
	calculateCost: (params: ReservationReq) => void;
	checkUserData: (user: User, fieldName: string, setFieldValue: SetFieldValue) => void;
	selectedCar?: CarMin | null;
	billableItems?: DiscountBillable[];
	checkViaVerdeAllowed: (values: ReservationReq, setFieldValue: SetFieldValue) => void;
	isViaVerdeSelected: boolean;
	tariffs?: Tariff[];
	getTariffs: (organizationId?: number | null) => void
}

const CreateReservationPage: FC<CreateReservationProps> = (props) => {
	const {
		initialValues,
		companies,
		responsibles,
		mainDrivers,
		secondDrivers,
		categories,
		cars,
		campaigns,
		getDropdown,
		onSubmit,
		saveDossier,
		handleAction,
		validate,
		disableOptions,
		startReservation,
		getReservationConflict,
		reservationCost,
		calculateCost,
		checkUserData,
		selectedCar,
		billableItems,
		checkViaVerdeAllowed,
		isViaVerdeSelected,
		tariffs,
		getTariffs,
	} = props;
	const { t } = useTranslation('translations');
	const {
		counters, paymentMethods, resStatus,
	} = useDropdown();
	const [openMileage, setOpenMileage] = useState(false);

	const handleOpen = () => { setOpenMileage(true); };
	const handleSave = (
		mileage: number,
		fuelLevel: number,
		setFieldError: (field: string, error: string) => void,
	) => {
		if (mileage === null || mileage === 0 || fuelLevel === null) {
			return setFieldError('mileage', t('emptyField'));
		}
		return setOpenMileage(false);
	};

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						onSubmit={onSubmit}
						enableReinitialize
						validate={validate}
						validateOnChange={false}
					>
						{({
							values,
							setFieldValue,
							errors,
							handleChange,
							isSubmitting,
							setSubmitting,
							setFieldError,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<EditLabel
											title={t('company')}
											onClick={() => {
												if (values.organizationId?.id) {
													handleAction(t('company'), values.organizationId.id);
												}
											}}
										/>
										<Space height={1} />
										<Autocomplete
											label={t('company')}
											placeholder=""
											noOptionsText={!companies ? t('typeSomething') : t('noOptions')}
											defaultValue={values.organizationId}
											disabled={disableOptions?.disableAll || isSubmitting}
											onInputChange={(text) => {
												getDropdown(t('company'), text);
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											options={companies || []}
											getOptionLabel={(item) => item.officialName || ''}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('organizationId', item);
												getTariffs(item?.id);
												checkViaVerdeAllowed({ ...values, organizationId: item }, setFieldValue);
											}}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<EditLabel
											title={t('user')}
											onClick={() => {
												if (values.userId?.id) {
													handleAction(t('user'), values.userId.id);
												}
											}}
										/>
										<Space height={1} />
										<Autocomplete
											label={t('responsible')}
											placeholder=""
											noOptionsText={!responsibles ? t('typeSomething') : t('noOptions')}
											disabled={disableOptions?.disableAll || isSubmitting}
											onInputChange={(text) => {
												getDropdown(t('responsible'), text);
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={Boolean(errors.userId)}
											helperText={String(errors.userId || '')}
											options={responsibles || []}
											defaultValue={values.userId}
											getOptionLabel={(item) => `${item.name || ''} - ${item.taxId || ''}`}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{`${item.name || ''} - ${item.taxId || ''}`}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('userId', item, false);
												checkViaVerdeAllowed({ ...values, userId: item }, setFieldValue);
												if (item) {
													checkUserData(item, USER_FIELD.USER, setFieldValue);
												}
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<EditLabel
											title={t('mainDriver')}
											onClick={() => {
												if (values.mainDriverId?.id) {
													handleAction(t('user'), values.mainDriverId.id);
												}
											}}
										/>
										<Space height={1} />
										<Autocomplete
											label={t('mainDriver')}
											placeholder=""
											noOptionsText={!mainDrivers ? t('typeSomething') : t('noOptions')}
											disabled={disableOptions?.disableAll || isSubmitting}
											defaultValue={values.mainDriverId}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											onInputChange={(text) => {
												getDropdown(t('mainDriver'), text);
											}}
											error={Boolean(errors.mainDriverId)}
											helperText={String(errors.mainDriverId || '')}
											options={mainDrivers || []}
											getOptionLabel={(item) => `${item.name || ''} - ${item.taxId || ''}`}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{`${item.name} - ${item.taxId || ''}`}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('mainDriverId', item, false);
												if (item) {
													checkUserData(item, USER_FIELD.DRIVER, setFieldValue);
												}
											}}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<EditLabel
											title={t('secondDriver')}
											onClick={() => {
												if (values.additionalDriverId?.id) {
													handleAction(t('user'), values.additionalDriverId.id);
												}
											}}
										/>
										<Space height={1} />
										<Autocomplete
											label={t('secondDriver')}
											placeholder=""
											defaultValue={values.additionalDriverId}
											noOptionsText={!secondDrivers ? t('typeSomething') : t('noOptions')}
											disabled={disableOptions?.disableAll || isSubmitting}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											onInputChange={(text) => {
												getDropdown(t('secondDriver'), text);
											}}
											options={secondDrivers || []}
											getOptionLabel={(item) => `${item.name || ''} - ${item.taxId || ''}`}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{`${item.name} - ${item.taxId}`}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('additionalDriverId', item, false);
												if (item) {
													checkUserData(item, USER_FIELD.SECOND_DRIVER, setFieldValue);
												}
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={counters || []}
											defaultValue={values.pickupOrganizationId}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											label={t('pickupIn')}
											placeholder=""
											disableClearable
											disabled={disableOptions?.disableAll || isSubmitting}
											getOptionLabel={(item) => `${item.fantasyName || ''} - ${item.address || ''}`}
											error={Boolean(errors.pickupOrganizationId)}
											helperText={String(errors.pickupOrganizationId || '')}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.fantasyName}
													{' '}
													-
													{' '}
													{item.address}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('pickupOrganizationId', item);
												if (item) {
													calculateCost({ ...values, pickupOrganizationId: item });
												}
											}}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Grid container spacing={3}>
											<Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
												<DatePicker
													disabled={disableOptions?.disablePickupDate || isSubmitting}
													label={t('pickupDate')}
													error={Boolean(errors.startDate)}
													helperText={String(errors.startDate || '')}
													value={values.startDate}
													onChange={(value) => {
														setFieldValue('startDate', value);
														calculateCost({ ...values, startDate: value });
													}}
												/>
											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TimePicker
													disabled={disableOptions?.disablePickupDate || isSubmitting}
													label={t('hour')}
													error={Boolean(errors.startTime)}
													helperText={String(errors.startTime || '')}
													value={values.startTime}
													onChange={(value) => {
														setFieldValue('startTime', value);
														calculateCost({ ...values, startTime: value });
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Space height={2} />
								{
									values?.pickupOrganizationId?.id !== values?.returnOrganizationId?.id && (
										<>
											<TransferTariffItem />
											<Space height={1} />
										</>
									)
								}
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('deliverIn')}
											placeholder=""
											disabled={disableOptions?.disableAll || isSubmitting}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={Boolean(errors.returnOrganizationId)}
											helperText={String(errors.returnOrganizationId || '')}
											defaultValue={values.returnOrganizationId}
											disableClearable
											options={counters || []}
											getOptionLabel={(item) => `${item.fantasyName || ''} - ${item.address || ''}`}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.fantasyName}
													{' '}
													-
													{' '}
													{item.address}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('returnOrganizationId', item);
												if (item) {
													calculateCost({ ...values, returnOrganizationId: item });
												}
											}}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Grid container spacing={3}>
											<Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
												<DatePicker
													disabled={disableOptions?.disableAll || isSubmitting}
													label={t('deliveryDate')}
													value={values.endDate}
													minDate={values.startDate}
													error={Boolean(errors.endDate)}
													helperText={String(errors.endDate || '')}
													onChange={(value) => {
														setFieldValue('endDate', value);
														calculateCost({ ...values, endDate: value });
													}}
												/>
											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TimePicker
													disabled={disableOptions?.disableAll || isSubmitting}
													label={t('hour')}
													error={Boolean(errors.endTime)}
													helperText={String(errors.endTime || '')}
													value={values.endTime}
													onChange={(value) => {
														setFieldValue('endTime', value);
														calculateCost({ ...values, endTime: value });
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('teriff')}
											placeholder=""
											isOptionEqualToValue={(option, value) => option.id === value.id}
											disabled={disableOptions?.disableAll || isSubmitting}
											defaultValue={values.groupId}
											options={tariffs || []}
											getOptionLabel={(item) => item.name || ''}
											error={Boolean(errors.groupId)}
											helperText={String(errors.groupId || '')}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											onChange={(_, item) => {
												getDropdown(t('category'), '', item?.id);
												setFieldValue('groupId', item);
												if (item) {
													calculateCost({ ...values, groupId: item });
												}
											}}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('category')}
											placeholder=""
											defaultValue={values.categoryId}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											disabled={disableOptions?.disableAll || isSubmitting}
											options={categories || []}
											error={Boolean(errors.categoryId)}
											helperText={String(errors.categoryId || '')}
											getOptionLabel={(item) => `${item.cta || ''} - ${item.name || ''}`}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{`${item.name} - ${item.cta}`}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('categoryId', item);
												setFieldValue('carId', null);
												getDropdown(t('vehicle'), '', item?.id, values.startDate, values.endDate);
												getDropdown(t('campaign'), '', item?.id);
												if (item) {
													calculateCost({ ...values, categoryId: item });
												}
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('vehicle')}
											placeholder=""
											defaultValue={values.carId}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={Boolean(errors.carId)}
											helperText={String(errors.carId || '')}
											disabled={disableOptions?.disableAll || isSubmitting}
											options={cars || []}
											groupBy={(option) => (option.categoryType ? t('selectedCategory') : t('otherCategory'))}
											getOptionLabel={(item) => `${item.name || ''}(${item.licensePlate || ''})`}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													<CarItem car={item} hideName />
													{item.name}
												</li>
											)}
											onChange={(_, item) => {
												setFieldValue('carId', item);
												if (disableOptions?.edit && item) {
													getReservationConflict({ // Check the new car's availability
														carId: item.id,
														startDate: values.startDate,
														startTime: values.startTime,
														endDate: values.endDate,
														endTime: values.endTime,
														setFieldValue,
													});
													if (item) {
														calculateCost({ ...values, carId: item });
													}
													if (selectedCar && selectedCar.id !== item.id) {
														handleOpen();
														setFieldValue('carChanged', true);
														setFieldValue('fuelLevel', 8);
													}
												}
											}}
										/>

										{disableOptions?.showButtons && selectedCar && values.carId && values.carId?.id
										!== selectedCar?.id && (
											<Dialog
												title={t('fuelAndMileageTitle')}
												open={openMileage}
												handleClose={() => {
													handleSave(values.mileage, values.fuelLevel, setFieldError);
												}}
												primaryButtonText={t('save')}
												primaryButtonAction={() => {
													handleSave(values.mileage, values.fuelLevel, setFieldError);
												}}
												description={(
													<Stack spacing={2}>
														<Space height={2} />
														<TextField
															label={t('mileage')}
															disabled={false}
															value={values.mileage}
															id="mileage"
															type="number"
															onChange={handleChange}
														/>
														<Description text={t('fuelLevel')} />
														<Slider
															aria-label="fuel-level"
															defaultValue={values.fuelLevel}
															valueLabelDisplay="auto"
															step={1}
															marks
															min={1}
															max={8}
															onChange={(_, value) => {
																setFieldValue('fuelLevel', value);
															}}
														/>
														<Typography variant="caption" color="error">{errors.mileage}</Typography>
													</Stack>
												)}
											/>
										)}
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										{disableOptions.edit && (
											<>
												<Stack direction="row" flexWrap="wrap">
													{values?.optionals?.map((el, index) => (
														<Chip
															label={`${el.name} - ${formatCurrency(parseInt(el.value, 10))}`}
															key={String(`${el}-${index}`)}
															sx={{ mb: 1, mr: 1 }}
														/>
													))}
												</Stack>
												<DefaultButton
													color="info"
													title={t('change')}
													onClick={() => {
														handleAction(t('change'));
													}}
												/>
											</>
										)}
										{!disableOptions.edit && (
											<Autocomplete
												label={t('optionals')}
												multiple
												placeholder=""
												disabled={disableOptions?.disableAll || isSubmitting}
												defaultValue={values.optionals}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												options={billableItems || []}
												getOptionLabel={(item) => item.name || ''}
												renderOption={(inputprops, item) => (
													<li {...inputprops} key={item.id}>
														{`${item.name || ''} - ${putDecimalPoints(item.value) || ''}`}
													</li>
												)}
												onChange={(_, item) => {
													setFieldValue('optionals', item);
													checkViaVerdeAllowed({ ...values, optionals: item }, setFieldValue);
													if (item) {
														calculateCost({ ...values, optionals: item });
													}
												}}
											/>
										)}
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<Autocomplete
											label={t('frunchiasePaymentMethod')}
											placeholder=""
											defaultValue={values.paymentMethodId}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											disabled={disableOptions?.disableAll || isSubmitting}
											options={paymentMethods}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('paymentMethodId', item)}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
										<TextField
											disabled={disableOptions?.disableAll || isSubmitting}
											label={t('frunchaiseValue')}
											title=""
											id="securityDeposit"
											type="text"
											helperText=""
											value={values.securityDeposit}
											onChange={(value) => setFieldValue('securityDeposit', currency(value).target.value)}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Stack direction="row">
											<TextField
												disabled={false}
												label={t('reference')}
												title=""
												id="dossier"
												type="text"
												helperText=""
												InputLabelProps={{ shrink: true }}
												value={values.dossier}
												onChange={handleChange}
											/>

											{disableOptions.edit && (
												<DefaultButton
													color="info"
													title={t('record')}
													onClick={() => {
														saveDossier(values.dossier);
													}}
												/>
											)}

										</Stack>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<CheckBox
									disabled={disableOptions?.disableAll || isSubmitting}
									title={`${t('chargeHour')}`}
									checked={values.chargeOvertime}
									setChecked={() => {
										setFieldValue('chargeOvertime', !values.chargeOvertime);
										calculateCost({ ...values, chargeOvertime: !values.chargeOvertime });
									}}
								/>
								<div>
									<Autocomplete
										label={t('campaign')}
										placeholder=""
										disabled={disableOptions?.disableAll || isSubmitting}
										options={campaigns || []}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderOption={(inputprops, item) => (
											<li {...inputprops} key={item.id}>
												{item.name}
												{' '}
												-
												{' '}
												{item.isPercentage
													? `${formatPercentage(item.discount)}`
													: `${formatCurrency(item.discount)} €`}
											</li>
										)}
										getOptionLabel={(item) => `${item.name || ''} - ${item.isPercentage
											? `${formatPercentage(item.discount)}`
											: `${formatCurrency(item.discount)} €`}`}
										onChange={(_, item) => {
											setFieldValue('campaignId', item?.id);
											calculateCost({ ...values, campaignId: item });
										}}
									/>
								</div>
								<Space height={2} />
								<div>
									<TextField
										label={t('observation')}
										disabled={disableOptions?.disableAll || isSubmitting}
										multiline
										minRows={3}
										id="note"
										InputLabelProps={{ shrink: true }}
										value={values.notes}
										onChange={(event) => setFieldValue('notes', event.target.value, false)}
									/>
								</div>
								{!disableOptions.edit && <Space height={2} />}
								{!disableOptions.edit && (
									<Alert severity="success" icon={false}>
										<Typography variant="h5">
											{t('duration')}
											:
											{' '}
											{reservationCost.days}
											{' '}
											{t('days')}
											{' '}
											{t('and')}
											{' '}
											{reservationCost.hours}
											{' '}
											{t('hours')}
											{' '}
											(
											{t('subTotal')}
											: €
											{' '}
											{reservationCost.total.toFixed(2)}
											)
										</Typography>
										<Typography variant="h5">
											{t('vat')}
											: €
											{' '}
											{(reservationCost.total * 0.23).toFixed(2)}
										</Typography>
										<Typography variant="h5">
											{t('total')}
											{' '}
											{t('with')}
											{' '}
											{t('vat')}
											: €
											{' '}
											{(reservationCost.total * 0.23 + reservationCost.total).toFixed(2)}
										</Typography>
									</Alert>
								)}
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{disableOptions?.edit && (
									<Typography variant="caption">
										{t('saleman')}
										:
										{' '}
										{values.sellerUser?.name}
									</Typography>
								)}
								{!disableOptions.edit && (
									<Grid container spacing={3}>
										<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
											<Autocomplete
												label={t('status')}
												placeholder=""
												disabled={disableOptions?.disableAll || isSubmitting}
												defaultValue={values.statusId}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												error={Boolean(errors.statusId)}
												helperText={String(errors.statusId || '')}
												options={resStatus || []}
												getOptionLabel={(item) => item.name || ''}
												renderOption={(inputprops, item) => (
													<li {...inputprops} key={item.id}>
														{item.name}
													</li>
												)}
												onChange={(_, item) => setFieldValue('statusId', item)}
											/>
										</Grid>
									</Grid>
								)}
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{!disableOptions.edit && (
									<div>
										<DefaultButton
											title={`${t('save')}`}
											type="submit"
											loading={isSubmitting}
											sx={{ mr: 1 }}
										/>
										{!isViaVerdeSelected && (
											<Typography variant="caption" color="secondary">
												{t('warning')}
												:
												{' '}
												{t('reservationWithOutGreenway')}
											</Typography>
										)}
									</div>
								)}
								{disableOptions?.showButtons && (
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										<span>
											<DefaultButton
												title={t('saveChanges')}
												type="submit"
												loading={isSubmitting}
												sx={{ mr: 1 }}
												startIcon={<Save />}
											/>
											{disableOptions?.startButton ? (
												<DefaultButton
													color="info"
													startIcon={<Flag />}
													title={t('startRental')}
													loading={isSubmitting}
													onClick={() => {
														startReservation(
															values,
															setSubmitting,
														);
													}}
												/>
											) : (
												<DefaultButton
													color="info"
													startIcon={<Flag />}
													disabled={isSubmitting}
													title={t('finalizeRental')}
													onClick={() => {
														handleAction(t('finalizeRental'));
													}}
												/>
											)}
										</span>
										<DefaultButton
											color="error"
											disabled={isSubmitting}
											startIcon={<Block />}
											title={t('cancelReservation')}
											onClick={() => handleAction(t('cancelReservation'))}
										/>
									</Stack>
								)}

							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateReservationPage;
