import { Space } from '@Atoms';
import { CRM_ACTIONS } from '@Constant';
import { useEffectCustom } from '@Hooks';
import { Typography } from '@mui/material';
import { useFeedback, useTitleBar } from '@Providers';
import { commLogsApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { CommunicationLog, CommunicationLogReq, NameAndId } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import AddCrmForm from './AddCrmForm';
import CrmButtons from './CrmButtons';
import ListCRMPage from './ListCRMPage';

const Crm = () => {
	const { reservationId, userId } = useParams();
	const { setTitle, setGlobalLoading } = useTitleBar();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { addDialog, addToast } = useFeedback();

	const [comLogs, setComLogs] = useState([] as CommunicationLog[]);

	const [initialValues] = useState({
		comment: RichTextEditor.createEmptyValue(),
		subjectTypeId: {} as NameAndId,
		modalTypeId: {} as NameAndId,
		receptivityId: {} as NameAndId,
	});

	useEffect(() => {
		setTitle(
			<div>
				CRM
				{reservationId && (
					<div>
						<Typography>
							{t('reservation')}
							{' '}
							#
							{reservationId}
						</Typography>
					</div>
				)}
				{userId && (
					<div>
						<Typography>
							{t('user')}
							{' '}
							#
							{userId}
						</Typography>
					</div>
				)}
			</div>,
		);
	}, [setTitle, reservationId, userId, t]);

	const getData = () => {
		setGlobalLoading(true);
		commLogsApi.getCommLogs(reservationId, userId)
			.then(({ data }) => setComLogs(data))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	useEffectCustom(() => {
		if (userId || reservationId) {
			getData();
		}
	}, [reservationId, userId]);

	const onButtonClick = (action: string) => {
		switch (action) {
		case CRM_ACTIONS.RESERVATION_DETAIL:
			if (reservationId) {
				navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
			}
			break;
		case CRM_ACTIONS.USER_DETAIL:
			if (userId) {
				navigate(AppRoutes.EDIT_USER.formatMap({ id: userId }));
			}
			break;
		case CRM_ACTIONS.LIST_ALL:
			navigate(AppRoutes.USER_CRM.formatMap({ userId }));
			break;

		default:
			break;
		}
	};

	const handleReservationClick = (id: number) => {
		navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
	};

	const validate = (values: CommunicationLogReq) => {
		const errors = {} as FormikErrors<CommunicationLogReq>;
		if (!values.comment) {
			errors.comment = t('emptyField');
		}
		if (!values.subjectTypeId) {
			errors.subjectTypeId = t('emptyField');
		}
		if (values.subjectTypeId && Object.keys(values.subjectTypeId).length === 0) {
			errors.subjectTypeId = t('emptyField');
		}
		if (!values.modalTypeId) {
			errors.modalTypeId = t('emptyField');
		}
		if (values.modalTypeId && Object.keys(values.modalTypeId).length === 0) {
			errors.modalTypeId = t('emptyField');
		}
		if (!values.receptivityId) {
			errors.receptivityId = t('emptyField');
		}
		if (values.receptivityId && Object.keys(values.receptivityId).length === 0) {
			errors.receptivityId = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (
		values: CommunicationLogReq,
		formikHelpers: FormikHelpers<CommunicationLogReq>,
	) => {
		commLogsApi.addNewCommLog({ ...values, userId, reservationId })
			.then((res) => {
				addToast({ message: res.message, error: false });
				formikHelpers.resetForm({});
				setComLogs((prev) => {
					const mPrev = prev;
					mPrev.push(res.data);
					return mPrev;
				});
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	return (
		<div>
			<CrmButtons
				user={!!userId}
				reservation={!!reservationId}
				onClick={onButtonClick}
			/>
			<Space height={3} />
			<AddCrmForm
				initialValues={initialValues}
				validate={validate}
				handleSubmit={handleSubmit}
			/>
			<Space height={3} />
			<ListCRMPage
				commLogs={comLogs}
				handleReservationClick={handleReservationClick}
			/>
		</div>
	);
};

export default Crm;
