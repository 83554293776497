import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { questionApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { QuestionReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NewQuestionPage from './NewQuestionPage';

const CreateQuestion = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({
		question: '',
		answer: '',
		languageId: 0,
	});

	useEffect(() => {
		setTitle(`${id ? t('edit') : t('new')} ${t('question')}`);
	}, [setTitle, t, id]);

	useEffectCustom(() => {
		let unmounted = false;
		if (!unmounted && id) {
			questionApi.getQuestionById(id)
				.then(({ data }) => setInitialValues({
					question: data.question,
					answer: data.answer,
					languageId: data.language.id,
				}))
				.catch(({ response }) => addToast({ message: response.data.message, error: true }));
		}
		return () => {
			unmounted = true;
		};
	}, []);

	const validate = (values: QuestionReq) => {
		const errors = {} as FormikErrors<QuestionReq>;
		if (!values.question) {
			errors.question = t('emptyField');
		}
		if (!values.answer) {
			errors.answer = t('emptyField');
		}
		if (!values.languageId) {
			errors.languageId = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (value: QuestionReq, formikHelpers: FormikHelpers<QuestionReq>) => {
		if (id) {
			questionApi.updateQuestion(value, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.FAQS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: false,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			questionApi.addNewQuestion(value)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.FAQS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: false,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	return (
		<NewQuestionPage
			initialValues={initialValues}
			edit={!!id}
			handleSubmit={handleSubmit}
			validate={validate}
		/>
	);
};

export default CreateQuestion;
