import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { bannerApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { BannerReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateBannerPage from './CreateBannerPage';

const CreateBanner = () => {
	const { id } = useParams<{ id: string }>();
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({
		name: '',
		file: {} as File,
		fileLink: '',
	} as BannerReq);

	useEffect(() => {
		if (id) {
			setTitle(`${t('edit')} ${t('banner')}`);
		} else {
			setTitle(`${t('new')} ${t('banner')}`);
		}
	}, [id, setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getBanner = () => {
			if (id) {
				bannerApi.getBannerByID(id, abortCtrl.signal)
					.then(({ data }) => setInitialValues({
						name: data.name,
						fileLink: data.file,
						file: {} as File,
					}))
					.catch(({ response }) => addToast({ message: response.data.message, error: true }));
			}
		};
		getBanner();
		return () => {
			abortCtrl.abort();
		};
	}, []);

	const validate = (values: BannerReq) => {
		const errors = {} as FormikErrors<BannerReq>;
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.fileLink) {
			errors.file = t('emptyField');
		}
		return errors;
	};

	const submit = (values: BannerReq, formikHelpers: FormikHelpers<BannerReq>) => {
		if (id) {
			bannerApi.updateBanner(values, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.APP_BANNERS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			bannerApi.saveBanner(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.APP_BANNERS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	return (
		<CreateBannerPage
			initialValues={initialValues}
			validate={validate}
			submit={submit}
		/>
	);
};

export default CreateBanner;
