import { SxProps, Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

interface TextProps extends TypographyProps {
	children: string;
	maxLines?: number;
	sx?: SxProps
}

const Text: FC<TextProps> = (props) => {
	const { children, maxLines, sx } = props;
	return (
		<Typography sx={{
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			lineClamp: maxLines,
			WebkitLineClamp: maxLines,
			display: '-webkit-box',
			WebkitBoxOrient: 'vertical',
			...sx,
			fontSize: '0.875rem',
		}}
		>
			{children}
		</Typography>
	);
};

export default Text;
