import { Autocomplete, DefaultButton, Description } from '@Atoms';
import { Mail } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { User } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EmailCardProps {
	handleSendClick: () => void;
	users?: User[];
	values: {email: string, error: string, loading: boolean};
	handleChange: (email?: string) => void
}

const EmailCard: FC<EmailCardProps> = (props) => {
	const {
		handleSendClick, users, values, handleChange,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<Box
			sx={{
				height: '150px',
				border: 1,
				pl: '20px',
				pr: '20px',
				borderColor: theme.palette.divider,
				borderStyle: 'solid',
				borderRadius: '7px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<Description text={t('sendToUserEmail')} />
			<Autocomplete
				options={users || []}
				renderOption={(inputprops, item) => (
					<li {...inputprops} key={item.id}>
						{item.name}
					</li>
				)}
				getOptionLabel={(item) => item.name}
				label=""
				error={!!values.error}
				helperText={values.error}
				placeholder=""
				onChange={(_, item) => {
					handleChange(item?.email);
				}}
			/>
			<DefaultButton
				title={t('emailButtonTitle')}
				onClick={handleSendClick}
				startIcon={<Mail />}
				loading={values.loading}
			/>
		</Box>
	);
};

export default EmailCard;
