export enum CACHE_KEY {
	COUNTERS= 'COUNTERS',
	GROUPTYPES='GROUP_TYPES',
	CARS='CARS',
	TARIFFS='TARIFFS',
	COMPANIES='COMPANIES',
	TOLL_STATUS='TOLL_STATUS',
	DISCOUNT_TYPES='DISCOUNT_TYPES',
	VEHICLE_CATEGORIES='VEHICLE_CATEGORIES',
	BILLABLE_ITEMS='BILLABLE_ITEMS',
	FEATURE_TYPES='FEATURE_TYPES',
	MAINTENANCE_TYPES='MAINTENANCE_TYPES',
	COUNTRIES='COUNTRIES',
	STATUS='STATUS',
	ACCOUNT='ACCOUNT',
	PAYMENT_METHODS='PAYMENT_METHODS',
	DOCUMENT_TYPES='DOCUMENT_TYPES',
	BRANDS='BRANDS',
	COLORS='COLORS',
	FLEETS='FLEETS',
	FUELS='FUELS',
}
