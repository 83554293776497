import {
	ApiResponse, City, Country, Pagination, State,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

export const getCountries = async (): Promise<ApiResponse<Pagination<Country[]>>> => {
	const resp = await reqGet({
		url: Endpoint.COUNTRIES.format(1, 1000),
	});
	const { data } = resp;
	return data;
};

export const getStates = async (id: number | undefined): Promise<ApiResponse<State[]>> => {
	const resp = await reqGet({
		url: Endpoint.STATES_BY_COUTRY_ID.format(id),
	});
	const { data } = resp;
	const mData = { ...data, data: data.data.data };
	return mData;
};

export const getCities = async (id: number | undefined): Promise<ApiResponse<City[]>> => {
	const resp = await reqGet({
		url: Endpoint.CITIES_BY_STATE_ID.format(id),
	});

	const { data } = resp;
	const mData = { ...data, data: data.data.data };
	return mData;
};
