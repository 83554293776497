import { Space } from '@Atoms';
import { Build } from '@mui/icons-material';
import { Alert, Typography } from '@mui/material';
import { CarRevision } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface MaintenanceAlertProps {
	revision?: CarRevision
}

const MaintenanceAlert: FC<MaintenanceAlertProps> = (props) => {
	const { revision } = props;
	const { t } = useTranslation('translations');
	if (revision === undefined) {
		return <div />;
	}
	if (revision === null) {
		return <Alert color="success" icon={<Build fontSize="inherit" />}>Revisões ok</Alert>;
	} return (
		<Alert severity="info" icon={<Build fontSize="inherit" />}>
			<Typography variant="caption" fontWeight="bold">
				{t('inspectionCarriedOut')}
				{' '}
				{revision.lastInspection}
			</Typography>
			<Space height={1} />
			<Typography variant="caption">
				{t('nextInspection')}
				{' '}
				{revision.nextInspection}
			</Typography>
		</Alert>
	);
};

export default MaintenanceAlert;
