import { DefaultButton, Space } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Typography, Box, Grid } from '@mui/material';
import { Reservation } from '@Types';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
	reservation?: Reservation;
	onBackPress: () => void
}
const Header: FC<HeaderProps> = (props) => {
	const { reservation, onBackPress } = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Box>
						<DefaultButton
							title={`${t('reservation')} ${reservation?.id}`}
							onClick={onBackPress}
						/>
						<Space height={1} />
						<Typography variant="caption" color="primary">
							<b>
								{t('period')}
								:
							</b>
							{` ${moment(reservation?.startDate).format('DD/MM/YYYY HH:mm')} ${t('to')} ${moment(reservation?.endDate).format('DD/MM/YYYY HH:mm')} `}
						</Typography>
						<Typography variant="caption" color="primary">
							<b>
								{t('mainDriver')}
								:
								{' '}
							</b>
							{' '}
							{reservation?.mainDriver?.name}
						</Typography>
					</Box>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default Header;
