import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { tollApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { NotBilledToll } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NotBilledPage from './NotBilledPage';

const NotBilled = () => {
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	const noBilledTolls = useRequest(([params]) => tollApi.getUnbilledTolls(params));

	useEffect(() => {
		setTitle(t('tolls'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			noBilledTolls.execute(listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	const handleReservationClick = (item: NotBilledToll) => {
		navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: item.reservationId }));
	};

	return (
		<NotBilledPage
			tolls={noBilledTolls.data}
			onReservationClick={handleReservationClick}
			setPagination={setPagination}
			setSearch={setSearch}
		/>
	);
};

export default NotBilled;
