import { StatusLabel } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { CarRecReserva } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import VehicleReservationItem from './VehicleReservationItem';

interface RecentBookingProps {
  reservations?: CarRecReserva[];
  handleListItem: (action: string, id?: number) => void;
}

const VehicleBookings: FC<RecentBookingProps> = ({
	reservations,
	handleListItem,
}) => {
	const { t } = useTranslation('translations');

	return (
		<PaperContainer
			title={t('latestBooking')}
			seeAll
			dropdown
			handleClick={() => handleListItem(t('latestBooking'))}
		>
			{
				(reservations && reservations?.length > 0) && (
					<DataTable data={reservations || []} search={false}>
						<TableColumn
							align="left"
							render={(item) => (
								<VehicleReservationItem reservation={item} handleListAction={handleListItem} />
							)}
						/>
						<TableColumn align="right" render={(item) => <StatusLabel status={item.status} justify="flex-end" />} />
					</DataTable>
				)
			}
		</PaperContainer>
	);
};

export default VehicleBookings;
