import { Grid } from '@mui/material';
import { Pagination, PaginationTable, Waybill } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaperContainer } from '@Molecules';
import {
	DefaultButton, TableItem, StatusLabel, Dropdown, CarItem,
} from '@Atoms';
import { DataTable, TableColumn } from '@Organisms';
import { AddCircle } from '@mui/icons-material';
import { formatDateOnly } from '@Helpers';

interface ListWaybillProps {
   handleListAction: (action: string, item?: Waybill) => void;
   setPagination: (params: PaginationTable) => void;
   setSearch: (searchQuery: string) => void;
   waybills?: Pagination<Waybill[]>
}

const ListWaybillPage:FC<ListWaybillProps> = (props) => {
	const {
		handleListAction, setPagination, setSearch, waybills,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
					startIcon={<AddCircle />}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={waybills?.data || []}
						onSearching={setSearch}
						pagination
						onChange={(page, size) => setPagination({ page, size })}
						total={waybills?.total}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn
							headerText={`${t('responsible')}/${t('driver')}`}
							render={(item) => (
								<TableItem
									title={item.user?.name}
									subtitle={item.mainDriver?.name}
								/>
							)}
						/>
						<TableColumn headerText={t('vehicl')} render={(item) => item.car && <CarItem car={item.car} />} />
						<TableColumn headerText={t('duration')} render={(item) => `${item.duration} ${t('days')}`} />
						<TableColumn
							headerText="Info"
							render={(item) => (
								<TableItem
									title={formatDateOnly(item.startDate)}
									subtitle={formatDateOnly(item.endDate)}
									icon
								/>
							)}
						/>
						<TableColumn
							headerText={t('store')}
							render={(item) => (
								<TableItem
									title={item.pickupCounter?.officialName}
									subtitle={item.returnCounter?.officialName}
									icon
								/>
							)}
						/>
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('details')}
							render={(item) => (
								<Dropdown
									data={[t('detail'), t('print')]}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(action) => {
										handleListAction(action, item);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListWaybillPage;
