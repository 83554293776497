/* eslint-disable @typescript-eslint/no-explicit-any */
import { encodeDate, encodeDateTime } from '@Helpers';
import { normalizeCurrency } from '@Masks';
import {
	ApiResponse,
	Pagination,
	Reservation,
	Transaction,
	CarHistory,
	ReservationConflict,
	User,
	Ticket,
	TransferTariff,
	ListDataControl,
	NameAndId,
	ReservationReq,
	Contract,
	FindResConflictReq,
	FindResConflict,
	Status,
	FinalizeReservationReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './upload.service';

export async function getAllReservations(params: ListDataControl, carId?: string):
   Promise<ApiResponse<Pagination<Reservation[]>>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATIONS.format(params.page, params.size, params.search, carId || ''),
	});
	const { data } = resp;
	return data;
}

export async function addNewReservation(payload: ReservationReq):
   Promise<ApiResponse<void>> {
	const dataToUpload = {
		disableRequireDocs: false,
		organizationId: payload.organizationId?.id,
		userId: payload.userId?.id,
		mainDriverId: payload.mainDriverId?.id,
		additionalDriverId: payload.additionalDriverId?.id,
		pickupOrganizationId: payload.pickupOrganizationId?.id,
		returnOrganizationId: payload.returnOrganizationId?.id,
		startDate: encodeDateTime(payload.startDate, payload.startTime),
		endDate: encodeDateTime(payload.endDate, payload.endTime),
		groupId: payload.groupId?.id,
		categoryId: payload.categoryId?.id,
		carId: payload.carId?.id,
		statusId: payload.statusId?.id,
		securityDeposit: normalizeCurrency(payload.securityDeposit),
		paymentMethodId: payload.paymentMethodId?.id,
		chargeOvertime: payload.chargeOvertime,
		campaignId: payload.campaignId?.id,
		notes: payload.notes,
		optionals: payload.optionals?.reduce((optionalIds, element) => {
			const ids = optionalIds;
			ids.push(element.id);
			return ids;
		}, [] as number[]),
	};
	const resp = await reqPost({
		url: Endpoint.RESERVATION,
		data: { ...dataToUpload },
	});
	const { data } = resp;
	return data;
}

export async function addPendingItems(reservationId?: string): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.PENDING_ITEMS.format(reservationId),
	});
	const { data } = resp;
	return data;
}

export async function updateReservationById(reservationId: string, payload: ReservationReq):
   Promise<ApiResponse<void>> {
	const mPayload = {
		disableRequireDocs: false,
		organizationId: payload.organizationId?.id || null,
		userId: payload.userId?.id,
		mainDriverId: payload.mainDriverId?.id,
		additionalDriverId: payload.additionalDriverId?.id,
		pickupOrganizationId: payload.pickupOrganizationId?.id,
		returnOrganizationId: payload.returnOrganizationId?.id,
		startDate: encodeDateTime(payload.startDate, payload.startTime),
		endDate: encodeDateTime(payload.endDate, payload.endTime),
		groupId: payload.groupId?.id,
		categoryId: payload.categoryId?.id,
		carId: payload.carId?.id,
		securityDeposit: normalizeCurrency(payload.securityDeposit),
		paymentMethodId: payload.paymentMethodId?.id,
		chargeOvertime: payload.chargeOvertime,
		campaignId: payload.campaignId?.id,
		notes: payload.notes,
		mileage: payload.carChanged ? payload.mileage : null,
		fuelLevel: payload.carChanged ? payload.fuelLevel : 0,
	};
	const resp = await reqPost({
		url: Endpoint.RESERVATION_BY_ID.format(reservationId),
		data: mPayload,
	});
	const { data } = resp;
	return data;
}

export async function getReservationById(reservationId: number | string):
	Promise<ApiResponse<Reservation>> {
	const resp = await reqGet({

		url: Endpoint.RESERVATION_BY_ID.format(reservationId),
	});
	const { data } = resp;
	return data;
}

export async function saveReservationDossier(reservationId: string, dossier: string):
   Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.SAVE_DOSSIAR.format(reservationId),
		data: { dossier },
	});
	const { data } = resp;
	return data;
}

export async function transferTariff(pickupId: number, returnId: number):
Promise<ApiResponse<TransferTariff>> {
	const resp = await reqGet({
		url: Endpoint.TRANSFER_TARIFF.format(
			pickupId,
			returnId,
		),
	});
	const { data } = resp;
	return data;
}

export async function reservationProof(reservationId?: string): Promise<BlobPart> {
	const resp = await reqGet({
		responseType: 'blob',
		url: Endpoint.PROOF.format(reservationId),
	});
	const { data } = resp;
	return data;
}

export async function reservationContract(reservationId?: string | number):
   Promise<ApiResponse<Contract>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_CONTRACT.format(reservationId),
	});
	const data = resp.data as ApiResponse<Contract>;
	return data;
}

export async function sendContract(reservationId: string, email: string):
   Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.SEND_CONTRACT_BY_EMAIL.format(reservationId),
		data: { email },
	});
	const { data } = resp;
	return data;
}

export async function cancelReservation(reservationId: string): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.CANCEL_RESERVATION.format(reservationId),
	});
	const { data } = resp;
	return data;
}

export async function approveReservation(reservationId: string): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.APPROVE_RESERVATION.format(reservationId),
	});

	const { data } = resp;
	return data;
}

export async function startReservation(reservationId: string): Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.START_RESERVATION.format(reservationId),
	});
	const { data } = resp;
	return data;
}

export async function getTickets(params: ListDataControl, counterId: number[]):
   Promise<ApiResponse<Pagination<Ticket[]>>> {
	const query = `${counterId.map((item) => `CounterId=${item}&`)}`;
	const removeComma = query.replaceAll(',', '');

	const resp = await reqGet({
		url: Endpoint.TICKETS.format(
			removeComma,
			params.page,
			params.size,
			params.search,
		), // To do Empty
	});
	const { data } = resp;
	return data;
}

export async function getTicketById(ticketId: string): Promise<ApiResponse<Ticket>> {
	const resp = await reqGet({
		url: Endpoint.TICKET_BY_ID.format(ticketId), // To do Empty
	});

	const { data } = resp;
	return data;
}

export async function changeTicketStatus(status: number, ticketId: string):
   Promise<ApiResponse<Ticket>> {
	const resp = await reqPost({
		url: Endpoint.TICKET_STATUS.format(ticketId),
		data: { ticketId, status },
	});
	const { data } = resp;
	return data;
}

export async function addTicketMessage(payload: any, id: string): Promise<ApiResponse<Ticket>> {
	let downlink = '';
	if (payload.fileAttachment?.size) {
		const file = new FormData();
		file.append('file', payload?.fileAttachment);
		const res = await uploadFile(file);
		downlink = res.data.file;
	}
	const dataToUpload = {
		message: payload.message,
		fileAttachment: downlink,
		fileAttachmentDescription: payload.fileAttachmentDescription,
	};
	const resp = await reqPost({
		url: Endpoint.TICKET_MESSAGE.format(id),
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
}

export const getReservationTransactions = async (reservationId: string):
   Promise<ApiResponse<Transaction[]>> => {
	const resp = await reqGet({
		url: Endpoint.TRANSACTIONS.format(reservationId),
	});
	const { data } = resp;
	return data;
};

export const addReservationTransaction = async (reservationId: string, payload: any):
   Promise<ApiResponse<void>> => {
	const dataToUpload = { ...payload, dueDate: encodeDate(payload.dueDate), clientType: 1 };
	if (dataToUpload.discount === 0) dataToUpload.discount = null;
	const resp = await reqPost({
		url: Endpoint.TRANSACTIONS.format(reservationId),
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
};

export const getConflicts = async (reservationId: string, endDateToday?: boolean):
   Promise<ApiResponse<ReservationConflict>> => {
	const resp = await reqGet({
		url: Endpoint.CONFLICTS.format(reservationId, endDateToday),
	});

	const { data } = resp;
	return data;
};

export const findReservationConflicts = async (params: FindResConflictReq):
	Promise<ApiResponse<FindResConflict[]>> => {
	const dataToUpload = {
		...params,
		startDate: encodeDateTime(params.startDate, params.startTime),
		endDate: encodeDateTime(params.endDate, params.endTime),
	};
	const resp = await reqPost({
		url: Endpoint.FIND_CONFLICTS,
		data: { ...dataToUpload },
	});
	const { data } = resp;
	return data;
};

export async function getReservationCustomers(reservationId: string):
   Promise<ApiResponse<User[]>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_CUSTOMERS.format(reservationId),
	});
	const { data } = resp;
	return data;
}

export async function finalizeReservation(
	payload: FinalizeReservationReq,
	userId?: number,
	id?: string,
):
	Promise<ApiResponse<any>> {
	const dataToUpload = {
		notes: payload.notes,
		returnOrgnizationId: payload.returnOrgnizationId?.id,
		mileage: payload.endKilometer,
		fuelLevel: payload.endFuel,
		sessionUserId: userId,
		endDate: encodeDateTime(new Date(), new Date()),
	};
	const resp = await reqPost({
		url: Endpoint.FINISH_RESERVATION.format(id),
		data: { ...dataToUpload },
	});

	const { data } = resp;
	return data;
}

export async function carHistory(reservationId: string): Promise<ApiResponse<CarHistory[]>> {
	const resp = await reqGet({
		url: Endpoint.CAR_HISTORY.format(reservationId),
	});
	const { data } = resp;
	return data;
}

export async function reservationStatus(): Promise<ApiResponse<Status[]>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_STATUS,
	});
	const data: ApiResponse<Status[]> = await resp.data;
	return data;
}

export async function accountTypes(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.ACCOUNT_TYPES,
	});
	const data: ApiResponse<NameAndId[]> = await resp.data;
	return data;
}

export async function checkReservationContract(id: string): Promise<ApiResponse<{
    contractId: number;
    contractExists: boolean
  }>> {
	const resp = await reqGet({
		url: Endpoint.CHECK_CONTRACT.format(id),
	});
	const data = await resp.data;
	return data;
}
