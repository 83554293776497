import { CarItem, DefaultButton, TableItem } from '@Atoms';
import { formatDate } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { ErrorOutline } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { NotBilledToll, Pagination, PaginationTable } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface NotBilledProps {
   tolls?: Pagination<NotBilledToll[]>;
   setPagination: (params: PaginationTable) => void;
   onReservationClick: (item: NotBilledToll) => void;
   setSearch: (search: string) =>void
}

const NotBilledPage: FC<NotBilledProps> = (props) => {
	const {
		tolls, setPagination, onReservationClick, setSearch,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={tolls?.data || []}
						pagination
						total={tolls?.total}
						onSearching={(query) => setSearch(query)}
						onChange={(page, rowsPerPage) => setPagination({ page, size: rowsPerPage })}
						stripedRows
					>
						<TableColumn headerText="Cod." render={(item) => `${item.transaction?.transactionId || '#'}`} />
						<TableColumn
							headerText={t('enrollDev')}
							render={(item) => (
								<CarItem car={{
									licensePlate: item.transaction?.carPlate || t('noInformation'),
									name: item.transaction?.obenumber || t('noInformation'),
								}}
								/>
							)}
						/>
						<TableColumn
							headerText={t('exit')}
							render={(item) => (
								<TableItem
									title={item.transaction?.outTollName}
									subtitle={item.transaction?.outDate ? formatDate(item.transaction?.outDate) : t('noInformation')}
								/>
							)}
						/>
						<TableColumn
							headerText={t('value')}
							render={(item) => `${item.itemValue ? `${putDecimalPoints(item.itemValue)} €` : t('noInformation')}`}
						/>
						<TableColumn
							headerText={`${t('reservation')}/IMPRO`}
							headerOptions={{ align: 'right' }}
							align="right"
							render={(item) => (
								<>
									{item.reservation?.status?.id === 3 && (
										<Tooltip title={t('alreadyFinished') ?? ''} placement="top" arrow>
											<IconButton>
												<ErrorOutline color="error" />
											</IconButton>
										</Tooltip>
									)}
									{item.reservation.type.id === 2 ? (
										<DefaultButton
											title={`IMPRO # ${item.reservationId}`}
											color="info"
											onClick={() => {
												onReservationClick(item);
											}}
										/>
									) : (
										<DefaultButton
											title={`${t('reservation')} # ${item.reservationId}`}
											onClick={() => {
												onReservationClick(item);
											}}
										/>
									)}
								</>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default NotBilledPage;
