import {
	CarItem, DateLabel, DefaultButton, TableItem,
} from '@Atoms';
import { Close, Edit } from '@mui/icons-material';
import {
	DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { CarHistory } from '@Types';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IVehicleHistoryProps {
	vehicleHistory?: CarHistory[];
	handleClose: () => void;
	handleEditCar: (id: number) => void
}

const VehicleHistoryItem: React.FC<IVehicleHistoryProps> = (props) => {
	const { vehicleHistory, handleClose, handleEditCar } = props;
	const { t } = useTranslation('translations');

	return (
		<>
			<DialogTitle sx={{ textAlign: 'center' }}>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Typography>{t('vehicleHistory')}</Typography>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Stack>
			</DialogTitle>
			<Typography display="none">{t('tolls')}</Typography>
			<DialogContent sx={{ textAlign: 'center' }}>
				<DataTable
					data={vehicleHistory || []}
					search={false}
					stripedRows
				>
					<TableColumn
						headerText="ID"
						render={(item) => (
							<DefaultButton
								startIcon={<Edit />}
								title={`#${item.car.id}`}
								variant="text"
								onClick={() => handleEditCar(item.car.id)}
							/>
						)}
					/>
					<TableColumn
						headerText={t('registration')}
						render={(item) => (
							<CarItem car={item.car} />
						)}
					/>
					<TableColumn
						headerText={t('startEnd')}
						render={(item) => (
							<>
								<DateLabel date={item.startDate} showTime />
								{item.endDate ? <DateLabel date={item.endDate} /> : t('inUse')}
							</>
						)}
					/>
					<TableColumn
						headerText={t('directedBy')}
						render={(item) => (
							<TableItem title={item.user.name} subtitle={`${t('in')} ${moment(item.registration).format('DD/MM/YYYY HH:mm:ss')}`} />
						)}
					/>
				</DataTable>
			</DialogContent>
			<DialogActions>
				<DefaultButton title={t('close')} onClick={handleClose} />
			</DialogActions>
		</>
	);
};

export default VehicleHistoryItem;
