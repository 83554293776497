import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
	DateLabel, DefaultButton, DatePicker, CarItem,
} from '@Atoms';
import { DivergentCategory, Pagination, PaginationTable } from '@Types';
import { PaperContainer } from '../../molecules';
import { DataTable, TableColumn } from '../../organisms';

type DateRange = {
   startDate: Date;
   endDate: Date
}

interface DivergentCategoryProps{
   handleChange: (value: Date, key: string) => void;
   handleListAction: (action: string, itemId?: number) => void;
   reportData?: Pagination<DivergentCategory[]>;
   dateValues?: DateRange;
   loading: boolean;
   setPagination: (params: PaginationTable) => void;
   setSearch: (query: string) => void
}

const DivergentCategoriesPage: React.FC<DivergentCategoryProps> = (props) => {
	const {
		handleChange, handleListAction, reportData, dateValues, loading, setPagination, setSearch,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item xl={12} md={12}>
				<PaperContainer>
					<Grid container spacing={3}>
						<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
							<DatePicker
								label={t('startDate')}
								value={dateValues?.startDate}
								onChange={(value) => handleChange(value, 'startDate')}
							/>
						</Grid>
						<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
							<DatePicker
								label={t('endDate')}
								value={dateValues?.endDate}
								onChange={(value) => handleChange(value, 'endDate')}
							/>
						</Grid>
						<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
							<DefaultButton disabled={loading} title={t('search')} onClick={() => handleListAction(t('search'))} />
						</Grid>
					</Grid>
				</PaperContainer>
			</Grid>
			<Grid item xl={12} md={12}>
				<PaperContainer>
					<DataTable
						data={reportData?.data || []}
						pagination
						total={reportData?.total}
						onSearching={setSearch}
						onChange={(page, size) => setPagination({ page, size })}
					>
						<TableColumn
							headerText={t('registration')}
							render={(item) => (
								<CarItem
									car={{
										name: item.carName,
										licensePlate: item.carLicensePlate,
									}}
									hideName
								/>
							)}
						/>
						<TableColumn headerText={t('model')} render={(item) => item.carName} />
						<TableColumn headerText={t('start')} render={(item) => <DateLabel date={item.startDate} showTime />} />
						<TableColumn
							headerText={t('end')}
							render={(item) => (item.endDate ? <DateLabel date={item.endDate} showTime /> : t('inUse'))}
						/>
						<TableColumn headerText={t('category')} render={(item) => item.name} />
						<TableColumn
							headerText={t('reservation')}
							render={(item) => (
								<DefaultButton
									title={`#${item.reservationId}`}
									onClick={() => {
										handleListAction(t('reservation'), item.reservationId);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>

	);
};

export default DivergentCategoriesPage;
