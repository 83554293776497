/* eslint-disable @typescript-eslint/no-explicit-any */
import { encodeDateTime } from '@Helpers';
import {
	ApiResponse,
	DiscountBillable,
	ListDataControl,
	Pagination,
	Waybill,
	WaybillReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getWayBills(params: ListDataControl):
   Promise<ApiResponse<Pagination<Waybill[]>>> {
	const resp = await reqGet({
		url: Endpoint.WAY_BILLS.format(params.page, params.size, params.search),
	});
	const { data } = resp;
	return data;
}

export async function getWaybillById(id: number | string): Promise<ApiResponse<Waybill>> {
	const resp = await reqGet({
		url: Endpoint.WAYBILL_BY_ID.format(id),
	});
	const { data } = resp;
	return data;
}

export async function addNewWaybill(waybill: any): Promise<ApiResponse<void>> {
	let options = [] as number[];
	if (waybill.options.length > 0) {
		options = waybill.options.map((el: DiscountBillable) => el.id);
	}
	const dataToUpload = {
		additionalDriverId: waybill.additionalDriverId?.id,
		carId: waybill.carId.id,
		mainDriverId: waybill.mainDriverId.id,
		organizationId: waybill.organizationId?.id,
		pickupOrganizationId: waybill.pickupOrganizationId.id,
		returnOrganizationId: waybill.returnOrganizationId.id,
		statusId: waybill.statusId.id,
		userId: waybill.userId?.id,
		startDate: encodeDateTime(waybill.startDate, waybill.startTime),
		endDate: encodeDateTime(waybill.endDate, waybill.endTime),
		options,
	};

	const resp = await reqPost({
		url: Endpoint.WAY_BILL,
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
}

export async function updateWaybill(waybill: WaybillReq, id: string): Promise<ApiResponse<void>> {
	let options = [] as number[];
	if (waybill.options.length > 0) {
		options = waybill.options.map((el: DiscountBillable) => el.id);
	}
	const dataToUpload = {
		additionalDriverId: waybill.additionalDriverId?.id,
		carId: waybill.carId?.id,
		mainDriverId: waybill.mainDriverId?.id,
		organizationId: waybill.organizationId?.id,
		pickupOrganizationId: waybill.pickupOrganizationId?.id,
		returnOrganizationId: waybill.returnOrganizationId?.id,
		statusId: waybill.statusId?.id,
		userId: waybill.userId?.id,
		startDate: encodeDateTime(waybill.startDate, waybill.startTime),
		endDate: encodeDateTime(waybill.endDate, waybill.endTime),
		options,
		notes: waybill.notes,
	};
	const resp = await reqPost({
		url: Endpoint.WAYBILL_BY_ID.format(id), // to change
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
}

export async function getWaybillContract(id?: number | string): Promise<ApiResponse<string>> {
	const resp = await reqGet({
		url: Endpoint.WAYBILL_CONTRACT.format(id),
	});
	const { data } = resp;
	return data;
}
