import { useRequest } from '@Api';
import { userApi } from '@Requests';
import { Organization, User } from '@Types';
import { AppRoutes } from '@Routes';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFeedback, useSetting, useTitleBar } from '@Providers';
import { useEffectCustom } from '@Hooks';
import { useTranslation } from 'react-i18next';
import PromoteToAdmPage, { ADMIN_ACTION } from './PromoteToAdmPage';

const PromoteAdmin = () => {
	const { id } = useParams<{ id: string }>();
	const { addToast } = useFeedback();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { counter } = useSetting();

	const getUser = useRequest(([params]) => userApi.getUserById(params));

	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		if (id) {
			setTitle(t('promoteAdm'));
			getUser.execute(id);
		}
	}, [id]);

	const getAdmin = (): User[] | [] | undefined => {
		if (getUser.data) {
			return [getUser.data];
		}
		return [];
	};

	const promoteToAdmin = () => {
		setLoading(true);
		userApi.promoteUserToAdmin(id ?? '', [{ id: counter }] as Organization[])
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(AppRoutes.LIST_ADMINISTRATORS);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setLoading(false));
	};

	const onButtonClick = (action: string) => {
		switch (action) {
		case ADMIN_ACTION.YES:
			promoteToAdmin();
			break;
		case ADMIN_ACTION.NO:
			navigate(AppRoutes.LIST_USERS);
			break;
		default:
			break;
		}
	};

	return (
		<PromoteToAdmPage
			user={getAdmin()}
			handleClick={onButtonClick}
			loading={loading}
		/>
	);
};

export default PromoteAdmin;
