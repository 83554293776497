/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import { User } from '@Types';
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';
import { authApi } from '@Requests';
import useSetting from './SettingContext';

const PARTNER_ID = 99;

interface AuthContextProps {
   user?: User | null;
   setCurrentUser: (user: User | null) => void;
   requestNewToken: () => Promise<void>
}

const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider: FC = ({ children }) => {
	const tempUser = LocalStorageWrapper.get<User>(LocalStorageKeys.USER);
	const [user, setUser] = useState<User | null>(tempUser);
	const { setUserFirstCounter } = useSetting();

	const setCurrentUser = (currentUser: User | null) => {
		setUser(currentUser);
		/** If the user is also connected to partner counter remove from the list of counters */
		const ind = currentUser?.counters.findIndex((el) => el.id === PARTNER_ID);
		if (ind !== undefined) {
			currentUser?.counters.splice(ind, 1);
		}
		setUserFirstCounter(currentUser?.counters[0].organizationId);
		LocalStorageWrapper.set(LocalStorageKeys.COUNTER, currentUser?.counters[0].organizationId);
		LocalStorageWrapper.set(LocalStorageKeys.USER, currentUser);
		LocalStorageWrapper.set(LocalStorageKeys.REFRESH_TOKEN, currentUser?.refreshToken);
	};

	const requestNewToken = async () => {
		if (user) {
			authApi.refreshCurrentToken(user.refreshToken)
				.then(({ data }) => {
					LocalStorageWrapper.set(LocalStorageKeys.REFRESH_TOKEN, data.authenticationToken);
				})
				.catch(() => {
					setCurrentUser(null);
				});
		} else {
			setCurrentUser(null);
		}
	};

	const value = useMemo(() => ({ user, setCurrentUser, requestNewToken }), [user, requestNewToken]);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
	return useContext(AuthContext);
}
