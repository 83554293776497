import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { questionApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Question } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import QuestionPage from './QuestionPage';

const ListQuestion = () => {
	const { t } = useTranslation(['translations']);
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const [search, setSearch] = useState('');
	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [selectedIndex, setSelectedIndex] = useState<number>();

	const questions = useRequest(([params]) => questionApi.getQuestions(params));
	const changeQuestionStatus = useRequest((params) => questionApi.changeQuestionStatus(params));

	useEffect(() => {
		setTitle(t('questions'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			questions.execute(listDataControl);
		};
		getData();
	}, [search, pagination]);

	useEffectCustom(() => {
		const { data } = changeQuestionStatus;
		if (data && selectedIndex) {
			questions.setData((mPrev) => {
				const prev = mPrev;
				const index = prev?.data.findIndex((el) => el.id === selectedIndex);
				if (prev && index !== undefined) {
					prev.data[index] = data;
				}
				return prev;
			});
		}
	}, [changeQuestionStatus.data]);

	const handleListAction = (name: string, item?: Question) => {
		switch (name) {
		case t('new'):
			navigate(AppRoutes.ADD_NEW_FAQ);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_FAQ.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			setSelectedIndex(item?.id);
			changeQuestionStatus.execute(item?.id);
			break;
		default:
			break;
		}
	};

	return (
		<QuestionPage
			handleListAction={handleListAction}
			questions={questions.data}
			setPagination={setPagination}
			setSearch={setSearch}
		/>
	);
};

export default ListQuestion;
