import { CarItem, DefaultButton, TableItem } from '@Atoms';
import { formatTimeOnly } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { Categories } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const RESERVATION_TYPE = 1;

interface DayMovementTableProps {
	title: string;
	data?: Categories[];
	onReservationClick?: (action: string, id?: number) => void;
	showEndDate?: boolean
}

const DayMovementTable: FC<DayMovementTableProps> = (props) => {
	const {
		title, data, onReservationClick, showEndDate,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<PaperContainer title={title} badge={data?.length || 0} showBadge>
			<DataTable data={data || []} search={false}>
				<TableColumn
					headerText={t('reservation')}
					render={(item) => (
						<DefaultButton
							color={item.type.id === RESERVATION_TYPE ? 'primary' : 'info'}
							title={`${item.id} # ${item.type.id === RESERVATION_TYPE ? t('reservation') : t('wayBill')}`}
							onClick={() => {
								onReservationClick?.call(0, item.type.id === RESERVATION_TYPE ? t('reservation') : t('wayBill'), item.id);
							}}
						/>
					)}
				/>
				<TableColumn headerText={t('enrollment')} render={(item) => item.car && <CarItem car={item.car} />} />
				<TableColumn headerText={`${t('driver')}/${t('client')}`} render={(item) => <TableItem title={item.user} subtitle={item.organization} />} />
				<TableColumn
					headerText={t('hour')}
					render={(item) => (showEndDate
						? formatTimeOnly(item.endDate)
						: formatTimeOnly(item.startDate))}
				/>
			</DataTable>
		</PaperContainer>
	);
};

export default DayMovementTable;
