import { Space } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import i18n from '@Localization';
import { PaperContainer } from '@Molecules';
import { Grid, Typography } from '@mui/material';
import { useFeedback } from '@Providers';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, SetupIntent, StripeError } from '@stripe/stripe-js';
import { Card } from '@Types';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckoutForm from './CheckoutForm';
import UserCardItem from './UserCardItem';

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const lang = i18n.language;

const stripePromise = loadStripe(stripeKey ?? '', { locale: lang === 'pt' ? 'pt' : 'en-GB' });

interface PaymentCardProps {
	cards?: Card;
	name?: string,
	deleteCard: (id: string) => void
	refreshCard: () => void
}

const PaymentCardPage: FC<PaymentCardProps> = (props) => {
	const {
		cards, name, deleteCard, refreshCard,
	} = props;
	const { t } = useTranslation('translations');
	const { addToast, addDialog } = useFeedback();

	const [stripeResponse, setStripeResponse] = useState(null as SetupIntent | null);
	const [stripeError, setStripeError] = useState(null as StripeError | null);

	const checkResponse = () => {
		if (stripeError) {
			addDialog({ title: '', message: stripeError.message || '', error: true });
		} else if (stripeResponse) {
			addToast({ message: stripeResponse.status, error: false });
			refreshCard();
		}
	};

	useEffectCustom(() => {
		checkResponse();
	}, [stripeResponse, stripeError]);

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Typography variant="h6">{t('minCharge')}</Typography>
					<Typography variant="caption">{t('minChargeDescription')}</Typography>
				</PaperContainer>
				<Space height={3} />
				<PaperContainer>
					<Typography variant="h6">{t('paymentMethods')}</Typography>
					{cards?.methods?.length === 0 && <Typography variant="caption">{t('noPaymentMethods')}</Typography>}
					{cards && cards?.methods?.length > 0 && (
						<Grid container spacing={3}>
							{cards?.methods.map((el) => (
								<Grid item xl={3} lg={3} md={4} sm={12} xs={12} key={`credit-card-${el.id}`}>
									<UserCardItem
										key={el.id}
										title={el.name}
										type={`${el.brand} ${el.funding} - ${el.country}`}
										expiry={`${t('expiration')}: ${el.fullExpiry}`}
										cardNumber={`**** **** **** ${el.last4}`}
										remove={() => {
											deleteCard(el.id);
										}}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</PaperContainer>
				<Space height={3} />
				<PaperContainer>
					<Typography variant="h6">{t('addNewCard')}</Typography>
					<Typography variant="caption">{t('cardData')}</Typography>
					<Elements stripe={stripePromise}>
						<CheckoutForm
							clientSecret={cards?.intent.clientSecret}
							name={name}
							setStripeError={setStripeError}
							setStripeResponse={setStripeResponse}
						/>
					</Elements>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default PaymentCardPage;
