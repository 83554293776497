import { AddCircle, Build, TrendingUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Car, Pagination, PaginationTable } from '@Types';
import React, { FC } from 'react';
import {
	DefaultButton, StatusLabel, TableItem, SplitButtonDropdown,
} from '@Atoms';
import { DataTable, TableColumn } from '@Organisms';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import { formatDateOnly } from '@Helpers';

interface ListVehicleProps {
   handleListAction: (action: string, item?: Car) => void;
   cars?: Pagination<Car[]>;
   setSearch: (query: string) => void;
   setPagination: (params: PaginationTable) => void
}

const ListVehiclePage: FC<ListVehicleProps> = (props) => {
	const { t } = useTranslation('translations');
	const {
		handleListAction, cars, setSearch, setPagination,
	} = props;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					title={t('new')}
					startIcon={<AddCircle />}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={cars?.data || []}
						pagination
						onSearching={(query) => setSearch(query)}
						onChange={(page, size) => setPagination({ page, size })}
						total={cars?.total}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText="Info" render={(item) => item.maintenance && <Build color="error" />} />
						<TableColumn headerText={t('model')} render={(item) => item.name} />
						<TableColumn headerText={t('shortName')} render={(item) => item.shortName} />
						<TableColumn
							headerText={t('licenseDate')}
							render={(item) => (
								<TableItem
									title={item.licensePlate}
									subtitle={formatDateOnly(item.registration)}
								/>
							)}
						/>
						<TableColumn headerText={t('chassis')} render={(item) => item.chassis} />
						<TableColumn headerText={t('km')} render={(item) => `${item.mileage} Km`} />
						<TableColumn
							headerText={t('category')}
							render={(item: Car) => (

								item.categories.length > 0 && (
									<>
										<div>{item.categories[0]?.name}</div>
										<div>{item.categories[0]?.cta}</div>
									</>
								)
							)}
						/>
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<SplitButtonDropdown
									label={t('panel')}
									data={[
										t('edit'),
										t('fines'),
										t('locate'),
										t('changeStatus'),
									]}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									variant="outlined"
									icon={<TrendingUp />}
									handleButtonClick={() => handleListAction(t('details'), item)}
									onChange={(action) => handleListAction(action, item)}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListVehiclePage;
