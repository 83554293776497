/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useDropdown, useFeedback, useSetting, useTitleBar,
} from '@Providers';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikHelpers, FormikErrors } from 'formik';
import {
	CarMin,
	DiscountBillable,
	Organization, ReservationConflictReq, ReservationReq, User,
} from '@Types';
import { Space, StatusLabel } from '@Atoms';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import {
	cardApi,
	// cardApi,
	carsApi,
	categoryApi, discountApi, organizationApi, reservationApi, tariffApi, tollApi, userApi,
} from '@Requests';
import { useRequest } from '@Api';
import { Dialog } from '@Organisms';
import { TollItem } from '@Molecules';
import { formatCurrency, formatDate, getTomorrow } from '@Helpers';
import { getReservationPrice } from '@Calculator';
import { BILLABLE_ITEM_TYPES, STATUS_ID, USER_FIELD } from '@Constant';
import { Stack, Typography } from '@mui/material';
import CreateReservationPage, { SetFieldValue, SetSubmitting } from './CreateReservationPage';
import ReservationButtons from './ReservationButtons';
import VehicleHistoryItem from './VehicleHistoryItem';
import ConflictWarning from './ConflictWarning';

enum DIALOG_TYPES {
	APPROVE_RESERVATION = 1,
	CANCEL_RESERVATION = 2,
	RESERVATION_TOLLS = 3,
	VECHILE_HISTORY = 4,
}

enum RESERVATION_STATUS {
	CONFIRMED = 1,
	STARTED = 2,
	CLOSED = 3,
	CANCELLED = 4,
	PENDING = 5
}

const VIA_VERDE_ID = 7;

const CreateReservation = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const { id } = useParams();
	const navigate = useNavigate();

	const companies = useRequest(([params]) => (
		organizationApi.getOrganizations(params, STATUS_ID.ACTIVE)));
	const responsibles = useRequest(([params]) => userApi.getAllUsers(params, STATUS_ID.ACTIVE));
	const mainDrivers = useRequest(([params]) => userApi.getAllUsers(params, STATUS_ID.ACTIVE));
	const secondDrivers = useRequest(([params]) => userApi.getAllUsers(params, STATUS_ID.ACTIVE));
	const categories = useRequest(([param1, param2]) => categoryApi.getCategories(
		param1,
		param2,
		STATUS_ID.ACTIVE,
	));
	const cars = useRequest(([param1, param2, param3]) => (
		carsApi.getCarAvailableForReservation(param1, param2, param3)));
	const carHistory = useRequest(([params]) => reservationApi.carHistory(params));
	const reservationConflicts = useRequest(([params]) => reservationApi.getConflicts(params, false));
	const tolls = useRequest(([params]) => tollApi.tollsByReservation(params));
	const campaigns = useRequest(([param1, param2, param3]) => (
		discountApi.getDiscountCampaignForReservation(param1, param2, param3)));
	const billableItems = useRequest(() => (
		discountApi.getBillableDiscounts(BILLABLE_ITEM_TYPES.OPTIONALS)));
	const tariffs = useRequest(([param1]) => tariffApi.getTariffByOrganization(param1));
	const { status, counters } = useDropdown();
	const { counter } = useSetting();

	const [initialValues, setInitialValues] = useState<ReservationReq>({
		securityDeposit: '100,00',
		dossier: '',
		startDate: new Date(),
		endDate: getTomorrow(),
		startTime: new Date(),
		endTime: getTomorrow(),
		notes: '',
		chargeOvertime: false,
	} as ReservationReq);
	const [loading, setLoading] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const [dialogType, setDialogType] = useState(0);
	const [dialogContent, setDialogContent] = useState({ title: '', description: '' });
	const [disabledOptions, setDisabledOptions] = useState({
		edit: false,
		disableAll: false,
		disablePickupDate: false,
		startButton: false,
		showButtons: false,
	});
	const [reservationCost, setReservationCost] = useState({ days: 1, hours: 0, total: 0 });
	const [pendingFieldDialog, setPendingFieldDialog] = useState({ open: false, data: '', id: 0 });
	const [selectedCar, setSelectedCar] = useState<CarMin | null>(null);
	const [hasContract, setContract] = useState(false);
	const [isViaVerdeSelected, setIsViaVerdeSelected] = useState(false);

	useEffect(() => {
		setTitle(`${id ? t('edit') : t('new')} ${t('reservation')}`);
	}, [setTitle, t, id]);

	const getDropdown = (
		action: string,
		search?: string,
		itemId?: number,
		startDate?: Date,
		endDate?: Date,
	) => {
		const listDataControl = {
			page: 1,
			size: 1000,
			search,
		};

		switch (action) {
		case t('company'):
			if (!search) {
				return companies.setData(undefined);
			}
			companies.execute(listDataControl);
			break;
		case t('responsible'):
			if (!search) {
				return responsibles.setData(undefined);
			}
			responsibles.execute(listDataControl);
			break;
		case t('mainDriver'):
			if (!search) {
				return mainDrivers.setData(undefined);
			}
			mainDrivers.execute(listDataControl);
			break;
		case t('secondDriver'):
			if (!search) {
				return secondDrivers.setData(undefined);
			}
			secondDrivers.execute(listDataControl);
			break;
		case t('category'):
			if (!itemId) {
				categories.setData(undefined);
			}
			categories.execute(listDataControl, itemId);
			break;
		case t('car'):
			if (!itemId) {
				return cars.setData(undefined);
			}
			cars.execute(itemId, startDate, endDate);
			break;
		case t('campaign'):
		{
			if (!itemId) {
				return campaigns.setData(undefined);
			}
			const targetDate = new Date();
			campaigns.execute(listDataControl, itemId, targetDate);
			break;
		}
		default:
			break;
		}
		return null;
	};

	const getData = () => {
		setGlobalLoading(true);
		reservationApi.getReservationById(id ?? '')
			.then(({ data }) => {
				setTitle(
					<Stack direction="row" justifyContent="flex-start" alignItems="center">
						<Typography sx={{ mr: 1 }} variant="h5">{`${t('edit')} ${t('reservation')} ${id}`}</Typography>
						<Typography variant="caption" sx={{ mr: 1 }}>
							{t('createdAt')}
							:
							{formatDate(data.startDate)}
						</Typography>
						<div>
							<StatusLabel status={data.status} />
						</div>
					</Stack>,
				);
				setInitialValues({
					organizationId: data.organization,
					userId: data.user,
					mainDriverId: data.mainDriver,
					additionalDriverId: data.additionalDriver,
					pickupOrganizationId: data.pickupOrganization,
					returnOrganizationId: data.returnOrganization,
					startDate: new Date(data.startDate),
					endDate: new Date(data.endDate),
					startTime: new Date(data.startDate),
					endTime: new Date(data.endDate),
					groupId: data.tariff,
					categoryId: data.category,
					carId: data.car,
					securityDeposit: formatCurrency(data.securityDeposit),
					paymentMethodId: data.paymentMethod,
					dossier: data.dossier,
					chargeOvertime: data.chargeOvertime,
					campaignId: null,
					notes: data.notes,
					optionals: data.optionals,
					carChanged: false,
					fuelLevel: 0,
					mileage: 0,
					statusId: data.status,
					sellerUser: data.sellerUser,
					note: '',
				});
				setSelectedCar(data.car);
				setContract(data.hasContract);
				/**
				 * EDIT OPTIONS According to status of Reservation
				 */
				if (data.status.id === RESERVATION_STATUS.CONFIRMED) {
					setDisabledOptions({
						...disabledOptions,
						edit: true,
						startButton: true,
						showButtons: true,
					});
				} else if (data.status.id === RESERVATION_STATUS.STARTED) {
					setDisabledOptions({
						...disabledOptions,
						showButtons: true,
						edit: true,
						disablePickupDate: true,
						startButton: false,
					});
				} else if (data.status.id === RESERVATION_STATUS.CLOSED) {
					setDisabledOptions({
						edit: true,
						disableAll: true,
						disablePickupDate: true,
						startButton: false,
						showButtons: false,
					});
				} else if (data.status.id === RESERVATION_STATUS.CANCELLED) {
					setDisabledOptions({
						...disabledOptions,
						edit: true,
						disablePickupDate: true,
						showButtons: false,
					});
				} else if (data.status.id === RESERVATION_STATUS.PENDING) {
					setDisabledOptions({
						...disabledOptions,
						edit: true,
						disablePickupDate: true,
						showButtons: false,
					});
				}
				getDropdown(t('category'), '', data.tariff?.id);
				getDropdown(t('vehicle'), '', data.category?.id, new Date(data.startDate), new Date(data.endDate));
				billableItems.execute();
				carHistory.execute(id);
				tolls.execute(id);
				reservationConflicts.execute(id);
				setLoading(false);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
	};

	const getTariffs = (organizationId?: number | null) => {
		tariffs.execute(organizationId);
	};

	useEffectCustom(() => {
		if (id) {
			getTariffs();
			getData();
		} else {
			const defaultCounter = counters?.find((el) => el.id === counter);
			setInitialValues({
				...initialValues,
				statusId: status[0],
				pickupOrganizationId: defaultCounter || {} as Organization,
				returnOrganizationId: defaultCounter || {} as Organization,
				organizationId: {} as Organization,
				userId: {} as User,
				mainDriverId: {} as User,
				additionalDriverId: {} as User,
			});
			setDisabledOptions({
				edit: false,
				disableAll: false,
				disablePickupDate: false,
				startButton: false,
				showButtons: false,
			});
			billableItems.execute();
			getTariffs();
			setLoading(false);
		}
	}, [id]);

	const handleSubmit = (values: ReservationReq, formikHelpers: FormikHelpers<ReservationReq>) => {
		if (id) {
			reservationApi.updateReservationById(id ?? '', values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ALL_RESERVATIONS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			reservationApi.addNewReservation(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ALL_RESERVATIONS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};
	const getReservationProof = () => {
		setGlobalLoading(true);
		reservationApi
			.reservationProof(id)
			.then((res) => {
				const file = new Blob([res], { type: 'application/pdf' });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch(({ response }) => {
				addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				});
			})
			.finally(() => setGlobalLoading(false));
	};

	const checkReservationEditable = () => {
		/** If any of these fields is not set the reservation can be edited */
		/** note, organizationId, additionalId */
		if (!initialValues.note
			|| !initialValues.organizationId
			|| !initialValues.additionalDriverId) {
			navigate(AppRoutes.CHANGE_RESERVATION.formatMap({ id }));
		} else {
			addDialog({
				title: '',
				message: t('reservationNotChangable'),
				error: true,
			});
		}
	};

	const handleButtonAction = (action: string) => {
		switch (action) {
		case t('createNewUser'):
			navigate(AppRoutes.CREATE_USER);
			break;
		case t('createNewCompany'):
			navigate(AppRoutes.ADD_NEW_ORGANIZATION);
			break;
		case t('proof'):
			getReservationProof();
			break;
		case t('contract'):
			navigate(AppRoutes.RESERVATION_CONTRACT.formatMap({ reservationId: id }));
			break;
		case t('approveRequest'):
			setShowDialog(true);
			setDialogType(DIALOG_TYPES.APPROVE_RESERVATION);
			setDialogContent({ title: t('approveModalTitle'), description: t('approveModalSubTitle') });
			break;
		case t('cancelReservation'):
			setShowDialog(true);
			setDialogType(DIALOG_TYPES.CANCEL_RESERVATION);
			setDialogContent({ title: t('modalCancelQuestion'), description: t('modalCancelSub') });
			break;
		case t('services'):
			navigate(AppRoutes.RESERVATION_SERVICES.formatMap({ reservationId: id }));
			break;
		case t('transactions'):
			// Check If the Reservation is Initiated
			if (initialValues.statusId.id === RESERVATION_STATUS.STARTED) {
				// Check if Initiated Reservation has a contract or not
				if (hasContract) {
					navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: id }));
				} else {
					addDialog({
						title: t('noContract'),
						message: t('noContractMessage'),
						error: true,
					});
				}
			} else {
				navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: id }));
			}
			break;
		case t('vehicleHistory'):
			setShowDialog(true);
			setDialogType(DIALOG_TYPES.VECHILE_HISTORY);
			break;
		case t('tolls'):
			setShowDialog(true);
			setDialogType(DIALOG_TYPES.RESERVATION_TOLLS);
			break;
		case t('editReservation'):
			checkReservationEditable();
			break;
		case t('crm'):
			if (!initialValues.userId) {
				navigate(AppRoutes.RESERVATION_CRM.formatMap({ reservationId: id }));
			} else {
				navigate(AppRoutes.crm.formatMap({ reservationId: id, userId: initialValues.userId?.id }));
			}
			break;
		default:
			break;
		}
	};

	const handleCloseDialog = () => {
		setShowDialog(false);
	};

	const handleConfirmDialog = () => {
		switch (dialogType) {
		case DIALOG_TYPES.APPROVE_RESERVATION:
			setGlobalLoading(true);
			setShowDialog(false);
			setDialogType(0);
			setDialogContent({ title: '', description: '' });
			reservationApi.approveReservation(id ?? '')
				.then((res) => {
					addToast({ message: res.message, error: false });
					getData();
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => setGlobalLoading(false));
			break;
		case DIALOG_TYPES.CANCEL_RESERVATION:
			setGlobalLoading(true);
			setShowDialog(false);
			setDialogType(0);
			setDialogContent({ title: '', description: '' });
			reservationApi.cancelReservation(id ?? '')
				.then((res) => {
					addToast({ message: res.message, error: false });
					getData();
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => setGlobalLoading(false));
			break;

		default:
			break;
		}
	};

	const saveDossier = (value: string) => {
		setGlobalLoading(true);
		reservationApi.saveReservationDossier(id ?? '', value)
			.then((res) => addToast({ message: res.message, error: false }))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	const handleActions = (action: string, itemId?: number) => {
		switch (action) {
		case t('change'):
			navigate(AppRoutes.RESERVATION_SERVICES.formatMap({ reservationId: id }));
			break;
		case t('company'):
			navigate(AppRoutes.EDIT_ORGANIZATION.formatMap({ id: itemId }));
			break;
		case t('user'):
			navigate(AppRoutes.EDIT_USER.formatMap({ id: itemId }));
			break;
		case t('startRental'):
			break;
		case t('finalizeRental'):
			navigate(AppRoutes.FINALIZE_RESERVATION.formatMap({ id }));
			break;
		case t('cancelReservation'):
			setShowDialog(true);
			setDialogType(DIALOG_TYPES.CANCEL_RESERVATION);
			setDialogContent({ title: t('modalCancelQuestion'), description: t('modalCancelSub') });
			break;
		default:
			break;
		}
	};

	const validate = (values: ReservationReq) => {
		const errors = {} as FormikErrors<ReservationReq>;
		if (!values.userId) {
			if (!values.organizationId) {
				errors.userId = t('emptyField');
			}
			if (values.organizationId && Object.keys(values.organizationId).length === 0) {
				errors.userId = t('emptyField');
			}
		}
		if (values.userId && Object.keys(values.userId).length === 0) {
			if (!values.organizationId) {
				errors.userId = t('emptyField');
			}
			if (values.organizationId && Object.keys(values.organizationId).length === 0) {
				errors.userId = t('emptyField');
			}
		}
		if (!values.mainDriverId) {
			errors.mainDriverId = t('emptyField');
		}
		if (values.mainDriverId && Object.keys(values.mainDriverId).length === 0) {
			errors.mainDriverId = t('emptyField');
		}
		if (!values.pickupOrganizationId) {
			errors.pickupOrganizationId = t('emptyField');
		}
		if (values.pickupOrganizationId && Object.keys(values.pickupOrganizationId).length === 0) {
			errors.pickupOrganizationId = t('emptyField');
		}
		if (!values.returnOrganizationId) {
			errors.returnOrganizationId = t('emptyField');
		}
		if (values.returnOrganizationId && Object.keys(values.returnOrganizationId).length === 0) {
			errors.returnOrganizationId = t('emptyField');
		}
		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		if (!values.startTime) {
			errors.startTime = t('emptyField');
		}
		if (!values.endTime) {
			errors.endTime = t('emptyField');
		}
		if (!values.categoryId) {
			errors.categoryId = t('emptyField');
		}
		if (values.categoryId && Object.keys(values.categoryId).length === 0) {
			errors.categoryId = t('emptyField');
		}
		if (!values.statusId) {
			errors.statusId = t('emptyField');
		}
		if (values.statusId && Object.keys(values.statusId).length === 0) {
			errors.statusId = t('emptyField');
		}
		if (!values.groupId) {
			errors.groupId = t('emptyField');
		}
		if (values.groupId && Object.keys(values.groupId).length === 0) {
			errors.groupId = t('emptyField');
		}
		if (values.carChanged) {
			if (!values.mileage) {
				errors.mileage = t('emptyField');
			}
		}
		return errors;
	};

	const startReservation = (
		values: ReservationReq,
		setSubmitting: SetSubmitting,
	) => {
		if (!values.carId) {
			addDialog({
				title: t('warning'),
				message: t('startWithoutCarError'),
				error: true,
			});
			return null;
		}
		if (values.carId && Object.keys(values.carId).length === 0) {
			addDialog({
				title: t('warning'),
				message: t('startWithoutCarError'),
				error: true,
			});
			return null;
		}
		setSubmitting(true);
		reservationApi.updateReservationById(id ?? '', values)
			.then(() => {
				reservationApi.startReservation(id ?? '')
					.then((startRes) => {
						addDialog({
							title: startRes.title,
							message: startRes.message,
							error: false,
						});
						navigate(AppRoutes.LIST_ALL_RESERVATIONS);
					})
					.catch(({ response }) => addDialog({
						title: response.data.title,
						message: response.data.message,
						error: true,
					}))
					.finally(() => setSubmitting(false));
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setSubmitting(false));

		return null;
	};

	/** If a car is change after creating the reservation,
	 * check if the car is not booked by other reservation */
	const getReservationConflicts = (params: ReservationConflictReq) => {
		const reservationConflictParams = {
			startDate: params.startDate,
			endDate: params.endDate,
			startTime: params.startTime,
			endTime: params.endTime,
			carId: params.carId,
			currentReservationId: id,
		};
		reservationApi.findReservationConflicts(reservationConflictParams).then(({ data }) => {
			if (data.length > 0) {
				addDialog({
					title: t('vehicleBooked'),
					message: t('vehicleBookedDescription'),
					error: true,
				});
			}
		});
	};

	// CHECK USER PENDING FINANCIALS
	const checkDebts = (userId: number) => {
		userApi.getUserPendingFinancials(userId)
			.then((response) => {
				/** If no pending financials show dialog */
				if (response.data.length > 0) {
					addDialog({
						error: true,
						title: t('pendingFinancials'),
						message: t('modalFinancialMessage'),
					});
					return false;
				}
				return true;
			});
		return true;
	};

	/** Check if the user data is missing */
	const checkFields = (userId: number, fieldName: string, setFieldValue: SetFieldValue) => {
		userApi.getUserPendingFields(userId)
			.then((res) => {
				console.log(res.data);
				/** if there are some missing fields in the list */
				if (res.data.length > 0) {
					setPendingFieldDialog({ open: true, data: String(res.data), id: userId });
					setFieldValue(fieldName, null);
				}
			});
	};

	/** Check the user license by getting the user data */
	const checkUserDocuments = async (userId: number) => {
		const { data } = await userApi.getUserById(userId);
		/** if end point doesn't return a user return error and strings for dialog */
		if (!data) {
			return { error: true, title: t('notFound'), message: '' };
		}
		if (data.documents.length === 0) {
			return { error: true, title: t('invalidDocument'), message: t('invalidDocumentMessage') };
		}
		const license = data.documents?.find((el) => el.typeId === 3);
		/** check if the user has a license or not */
		if (license) {
			/** check if the user license number is enough in length */
			if (license.value.length < 3) {
				return { error: true, title: t('invalidDocument'), message: t('invalidDocumentMessage') };
			}
			const expirationDate = new Date(license.expirationDate);
			const currentDate = new Date();
			/** check if the user license is not expired */
			if (expirationDate <= currentDate) {
				return { error: true, title: t('expiredLicense'), message: t('expiredMessage') };
			}
		} else {
			return { error: true, title: 'not present', message: '' };
		}
		/** If the user license is OK */
		return { error: false, title: '', message: '' };
	};

	const checkUserData = async (user: User, fieldName: string, setFieldValue: SetFieldValue) => {
		switch (fieldName) {
		case USER_FIELD.USER: {
			checkDebts(user.id);
			checkFields(user.id, fieldName, setFieldValue);
			const documentResult = await checkUserDocuments(user.id);
			/** If the user documents has no error use same user as driver */
			if (!documentResult.error) {
				setFieldValue(USER_FIELD.DRIVER, user);
			}
		}
			break;
		case USER_FIELD.DRIVER:
			{
				checkDebts(user.id);
				checkFields(user.id, fieldName, setFieldValue);
				const documentResult = await checkUserDocuments(user.id);
				/** If the user documents has no error set driver */
				if (!documentResult.error) {
					setFieldValue(USER_FIELD.DRIVER, user);
				} else {
					addDialog({
						error: true,
						title: documentResult.title,
						message: documentResult.message,
					});
					setFieldValue(USER_FIELD.DRIVER, null);
				}
			}
			break;
		case USER_FIELD.SECOND_DRIVER:
			{
				checkDebts(user.id);
				checkFields(user.id, fieldName, setFieldValue);
				checkUserDocuments(user.id);
				const documentResult = await checkUserDocuments(user.id);
				/** If the user documents has no error set the second driver */
				if (!documentResult.error) {
					setFieldValue(USER_FIELD.SECOND_DRIVER, user);
				} else {
					addDialog({
						error: true,
						title: documentResult.title,
						message: documentResult.message,
					});
					setFieldValue(USER_FIELD.SECOND_DRIVER, null);
				}
			}
			break;

		default:
			break;
		}
	};

	const getReservationCost = async (values: ReservationReq) => {
		const { days, hours, total } = await getReservationPrice(
			values.startDate,
			values.startTime,
			values.endDate,
			values.endTime,
			values.categoryId,
			values.optionals,
			values.campaignId,
			values.pickupOrganizationId?.id,
			values.returnOrganizationId?.id,
			values.chargeOvertime,
		);
		setReservationCost({ days, hours, total });
	};

	const handleEditCar = (carId: number) => {
		navigate(AppRoutes.EDIT_VEHICLE.formatMap({ id: carId }));
	};

	const closePendingFieldDialog = () => {
		setPendingFieldDialog({ open: false, data: '', id: 0 });
	};

	const removeViaVerdeFromOptionals = (value: DiscountBillable[], setFieldValue: SetFieldValue) => {
		const val = [...value];
		const index = val.findIndex((el) => el.id === VIA_VERDE_ID);
		val.splice(index, 1);
		setFieldValue('optionals', val);
		setIsViaVerdeSelected(false);
	};

	const checkForUserCard = (values: ReservationReq, setFieldValue: SetFieldValue) => {
		const viaVerde = values.optionals?.find((el) => el.id === VIA_VERDE_ID);
		if (viaVerde) {
			if (!values.userId && !values.organizationId) {
				addDialog({
					title: t('itemNotPermitted'),
					message: t('viaVerdeValidCreditCard'),
					error: true,
				});
				return removeViaVerdeFromOptionals(values.optionals || [], setFieldValue);
			}
			if (values.userId
				&& Object.keys(values.userId).length === 0
				&& values.organizationId
				&& Object.keys(values.organizationId).length === 0) {
				addDialog({
					title: t('itemNotPermitted'),
					message: t('viaVerdeValidCreditCard'),
					error: true,
				});
				return removeViaVerdeFromOptionals(values.optionals || [], setFieldValue);
			}
			setGlobalLoading(true);
			cardApi.checkValidCard(values.userId?.id, values.organizationId?.id)
				.then((res) => {
					if (!res.data.haveCard) {
						addDialog({
							title: t('itemNotPermitted'),
							message: t('viaVerdeValidCreditCard'),
							error: true,
						});
						removeViaVerdeFromOptionals(values.optionals || [], setFieldValue);
					} else {
						setIsViaVerdeSelected(true);
					}
				})
				.catch(() => {
					addDialog({
						title: t('itemNotPermitted'),
						message: t('viaVerdeValidCreditCard'),
						error: true,
					});
					removeViaVerdeFromOptionals(values.optionals || [], setFieldValue);
				})
				.finally(() => setGlobalLoading(false));
		}
		return null;
	};

	return (
		<>
			{showDialog && (
				<Dialog
					open={showDialog}
					icon={(dialogType === DIALOG_TYPES.APPROVE_RESERVATION || dialogType === DIALOG_TYPES.CANCEL_RESERVATION) ? 'error' : undefined}
					handleClose={handleCloseDialog}
					title={dialogContent.title || ''}
					description={dialogContent.description || (
						<>
							{dialogType === DIALOG_TYPES.RESERVATION_TOLLS && (
								<TollItem
									tolls={tolls.data?.data}
									handleClose={handleCloseDialog}
									total={tolls.data?.data.reduce((sum, element) => {
										let mSum = sum;
										mSum += element.fare;
										return mSum;
									}, 0 as number)}
								/>
							)}
							{dialogType === DIALOG_TYPES.VECHILE_HISTORY && (
								<VehicleHistoryItem
									handleClose={handleCloseDialog}
									vehicleHistory={carHistory.data}
									handleEditCar={handleEditCar}
								/>
							)}

						</>
					)}
					secondaryButtonAction={handleCloseDialog}
					secondaryButtonText={t('close')}
					primaryButtonAction={handleConfirmDialog}
					primaryButtonText={t('confirm')}
					hideButtons={dialogType === DIALOG_TYPES.RESERVATION_TOLLS
						|| dialogType === DIALOG_TYPES.VECHILE_HISTORY}
				/>
			)}
			{pendingFieldDialog.open && (
				<Dialog
					open={pendingFieldDialog.open}
					icon="error"
					title={t('issuesFound')}
					description={`${t('theFields').format(pendingFieldDialog.data)} ${t('fieldsMessage')}`}
					handleClose={closePendingFieldDialog}
					primaryButtonText={t('edit')}
					secondaryButtonText={t('cancel')}
					secondaryButtonAction={closePendingFieldDialog}
					primaryButtonAction={() => handleActions(t('user'), pendingFieldDialog.id)}
				/>
			)}
			<ReservationButtons
				addNew={!id}
				loading={loading}
				handleButtonAction={handleButtonAction}
				reservationStatus={initialValues.statusId}
			/>
			<Space height={3} />
			{reservationConflicts.data
			&& (reservationConflicts.data.conflicts?.length !== 0
			|| reservationConflicts.data.notMatching) && (
				<ConflictWarning
					data={reservationConflicts.data}
					handleServiceClick={handleButtonAction}
				/>
			)}
			<Space height={3} />
			<CreateReservationPage
				initialValues={initialValues}
				campaigns={campaigns.data?.data}
				categories={categories.data?.data}
				secondDrivers={secondDrivers.data?.data}
				mainDrivers={mainDrivers.data?.data}
				responsibles={responsibles.data?.data}
				companies={companies.data?.data}
				cars={cars.data}
				getDropdown={getDropdown}
				onSubmit={handleSubmit}
				saveDossier={saveDossier}
				handleAction={handleActions}
				disableOptions={disabledOptions}
				validate={validate}
				startReservation={startReservation}
				getReservationConflict={getReservationConflicts}
				reservationCost={reservationCost}
				calculateCost={getReservationCost}
				checkUserData={checkUserData}
				selectedCar={selectedCar}
				billableItems={billableItems.data?.data}
				checkViaVerdeAllowed={checkForUserCard}
				isViaVerdeSelected={isViaVerdeSelected}
				tariffs={tariffs.data}
				getTariffs={getTariffs}
			/>
		</>
	);
};

export default CreateReservation;
