import {
	Autocomplete, DateLabel, DatePicker, DefaultButton, Description, Space, TableItem,
} from '@Atoms';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import {
	AddCircle, ArrowForward, Close, Refresh,
} from '@mui/icons-material';
import {
	Divider, Grid, IconButton, Stack, Typography,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { useDropdown } from '@Providers';
import {
	ApplicableCampaign,
	DiscountBillable, Reservation, Service, TransactionReq, User,
} from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserDropdownItem from '../services/UserDropdownItem';
import CampaignItem from './CampaignItem';

interface BillingProps {
	initialValues: TransactionReq,
	data: Service[];
	total: number;
	tax: number;
	handleSubmit: (values: TransactionReq, formikHelpers: FormikHelpers<TransactionReq>) => void;
	customers: User[] | undefined;
	discounts?: DiscountBillable[];
	handleAction: (action: string, id?: number) => void;
	validate: (values: TransactionReq) => FormikErrors<TransactionReq>;
	calculateTotal: (item: DiscountBillable | null) => void;
	reservation?: Reservation;
	applicableCampaign: ApplicableCampaign;
	handleApplyDiscount: () => void
}

const BillingItem: FC<BillingProps> = ({
	initialValues,
	data,
	total,
	tax,
	handleSubmit,
	customers,
	discounts,
	handleAction,
	validate,
	calculateTotal,
	reservation,
	applicableCampaign,
	handleApplyDiscount,
}) => {
	const { t } = useTranslation('translations');
	const { paymentMethods, account } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							isSubmitting, setFieldValue, touched, errors, values,
						}) => (
							<Form>
								<Typography variant="h6">{t('generate')}</Typography>
								{data?.length > 0 && (
									<>
										<Grid container spacing={3}>
											<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
												<Autocomplete
													label={t('client')}
													options={customers || []}
													disabled={isSubmitting}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															<UserDropdownItem user={item} />
														</li>
													)}
													defaultValue={values.clientId}
													error={touched.clientId && Boolean(errors.clientId)}
													helperText={touched.clientId && String(errors.clientId || '')}
													placeholder=""
													getOptionLabel={(item) => item.name || ''}
													onChange={(_, item) => {
														setFieldValue('clientId', item); // TODO Confirm the type and set type here
													}}
												/>
											</Grid>
											<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
												<Autocomplete
													label={t('formOfPayment')}
													options={paymentMethods || []}
													disabled={isSubmitting}
													defaultValue={values.paymentMethodId}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													error={touched.paymentMethodId && Boolean(errors.paymentMethodId)}
													helperText={touched.paymentMethodId && String(errors.paymentMethodId || '')}
													placeholder=""
													getOptionLabel={(item) => item.name || ''}
													onChange={(_, item) => {
														setFieldValue('paymentMethodId', item);
													}}
												/>
											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={3}>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<Autocomplete
													label={t('account')}
													options={account}
													disabled={isSubmitting}
													defaultValue={values.accountId}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.name}
														</li>
													)}
													error={touched.accountId && Boolean(errors.accountId)}
													helperText={touched.accountId && String(errors.accountId || '')}
													placeholder=""
													getOptionLabel={(item) => item.name || ''}
													onChange={(_, item) => setFieldValue('accountId', item)}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<DatePicker
													label={t('dueDate')}
													disabled={isSubmitting}
													error={touched.dueDate && Boolean(errors.dueDate)}
													helperText={touched.dueDate && String(errors.dueDate || '')}
													value={values.dueDate}
													onChange={(value) => setFieldValue('dueDate', value)}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
												<Autocomplete
													label={t('discount')}
													placeholder=""
													options={discounts || []}
													disabled={isSubmitting}
													defaultValue={values.discountRequestId}
													error={touched.discountRequestId && Boolean(errors.discountRequestId)}
													helperText={touched.discountRequestId && String(errors.discountRequestId || '')}
													getOptionLabel={(item) => item.note || ''}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															{item.note}
														</li>
													)}
													onChange={(_, item) => {
														setFieldValue('discountRequestId', item);
														calculateTotal(item);
													}}
												/>
											</Grid>
											<Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
												<DefaultButton
													disabled={isSubmitting}
													title={t('requestDiscount')}
													onClick={() => {
														handleAction(t('requestDiscount'));
													}}
												/>
											</Grid>
										</Grid>
										<Space height={2} />
										<Divider />
										<Space height={2} />
										<Typography variant="h6">{t('itemsToBill')}</Typography>
										<DataTable data={data} search={false}>
											<TableColumn headerText="ID" render={(item) => item.id} />
											<TableColumn
												headerText={t('date')}
												render={(item) => <DateLabel date={item.registration} showTime />}
											/>
											<TableColumn
												headerText={t('description')}
												render={(item) => (
													<TableItem
														title={item?.item?.name}
														subtitle={item?.item?.description}
													/>
												)}
											/>
											<TableColumn headerText={t('quantity')} render={(item) => item.quantity} />
											<TableColumn
												headerText={t('value')}
												render={(item) => {
													const discount = item.quantityDiscount / item.quantity;
													return putDecimalPoints(item.itemValue - discount);
												}}
											/>
											<TableColumn headerText={t('discount')} render={(item) => putDecimalPoints(item.discount)} />
											<TableColumn headerText={t('vat')} render={(item) => putDecimalPoints((((item.itemValue - (item.discount)) * item.quantity) - item.quantityDiscount) * (item.itemTax / 100))} />
											<TableColumn headerText={t('subTotal')} render={(item) => putDecimalPoints((((item.itemValue - (item.discount)) * item.quantity) - item.quantityDiscount) * (1 + item.itemTax / 100))} />
											<TableColumn
												render={(item) => (
													<IconButton
														disabled={isSubmitting}
														color="error"
														onClick={() => {
															handleAction(t('remove'), item.id);
														}}
													>
														<Close />
													</IconButton>
												)}
											/>
										</DataTable>
										<Space height={1} />
										{(reservation?.campaign && applicableCampaign.show) && (
											<>
												<CampaignItem
													reservation={reservation}
													applicableCampaign={applicableCampaign}
													handleApplyDiscount={handleApplyDiscount}
												/>
												<Space height={1} />
											</>
										)}
										<Typography variant="caption">
											{`${t('total')}: ${putDecimalPoints(total)}€ + ${t('vat')}: ${putDecimalPoints(tax.toFixed(2))}€ = `}
										</Typography>
										<Typography variant="caption" color="error">
											{putDecimalPoints((total + tax).toFixed(2))}
											€
										</Typography>
										<Space height={1} />
									</>
								)}
								{data.length === 0 && (
									<>
										<Space height={2} />
										<Description text={t('noServicesForTransaction')} />
										<Space height={2} />
									</>
								)}
								<Stack direction="row" justifyContent="space-between">
									<Stack direction="row">
										{data?.length > 0 && (
											<DefaultButton
												startIcon={<AddCircle />}
												loading={isSubmitting}
												title={t('createBilling')}
												type="submit"
											/>
										)}
										<DefaultButton
											startIcon={<Refresh />}
											title={t('refresh')}
											disabled={isSubmitting}
											sx={{ ml: 1 }}
											onClick={() => handleAction(t('refresh'))}
										/>
									</Stack>
									<DefaultButton
										startIcon={<ArrowForward />}
										title={t('goToServices')}
										onClick={() => handleAction(t('goToServices'))}
										variant="text"
									/>
								</Stack>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default BillingItem;
