import { DateLabel, DefaultButton } from '@Atoms';
import { formatCurrency } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { Assignment, TouchApp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { CardContainer, DataTable, TableColumn } from '@Organisms';
import { Invoice } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CreditNoteCardProps {
	handleCreateClick: () => void;
	creditMemo?: Invoice[];
	total: number;
	handleCreditNoteAction?: (action: string, item: Invoice) => void
}

const CreditNoteCard: FC<CreditNoteCardProps> = (props) => {
	const {
		handleCreateClick, creditMemo, total, handleCreditNoteAction,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<CardContainer
			title={t('creditNotes')}
			priceText={`${t('refunded')} ${formatCurrency(total)} €`}
			render={() => {
				if (creditMemo?.length === 0 || !creditMemo) return <Typography>{t('creditMemo')}</Typography>;
				return (
					<DataTable search={false} data={creditMemo || []}>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.dueDate} />} />
						<TableColumn headerText={t('value')} render={(item) => putDecimalPoints(item.amount)} />
						<TableColumn render={(item) => (!item.account?.externalId ? (
							<DefaultButton
								title={t('generate')}
								variant="outlined"
								startIcon={<TouchApp />}
								onClick={() => {
									handleCreditNoteAction?.call(0, t('generate'), item);
								}}
							/>
						) : (
							<DefaultButton
								title={t('access')}
								startIcon={<Assignment />}
								onClick={() => {
									handleCreditNoteAction?.call(0, t('access'), item);
								}}
							/>
						))}
						/>
					</DataTable>
				);
			}}
			handleButtonClick={handleCreateClick}
		/>
	);
};

export default CreditNoteCard;
