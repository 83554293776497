enum Endpoint {
	BASE_URL = 'https://dev.rent.api.innovaj.com/back-office/v1',

	// Authentications
	LOGIN = '/auth/login',
	LOGOUT = '/auth/logout',
	CHANGE_PASSWORD= '/auth/change-password',
	REFRESH_TOKEN= '/auth/refresh-token',
	RESET_PASSWORD= '/auth/ResetPassword',
	FORGOT_PASSWORD='/auth/forgot-password',
	CHECK_CHANGE_PASSWORD='https://dev.rent.api.innovaj.com/public/v1/check-change-password',
	RESET_FORGOT_PASSWORD = 'https://dev.rent.api.innovaj.com/public/v1/change-password',

	// Administrators
	CHANGE_ADMIN_STATUS= '/administrators/{0}/status',
	GET_ADMINS= '/administrators?Page={0}&Size={1}&Search={2}',

	// Dashboard Routes
	RESERVATION_SUMMARY= '/reservations/summary?organizationId={0}&date={1}',
	UNGENERATED_INVOICES= '/invoices/ungenerated?Page={0}&Size={1}&Search={2}{3}',
	DELAYED_DELIVERIES= '/reservations/delayed-deliveries',
	PARKED_CARS= '/cars/parked',
	PENDING_RESERVATIONS= '/reservations/pending',

	// Reservations
	RESERVATIONS= '/reservations?page={0}&size={1}&search={2}&carId={3}',
	RESERVATION='/reservations',
	RESERVATION_BY_ID= '/reservations/{0}',
	RESERVATION_ITEMS= '/reservations/{0}/reservation-items',
	DELETE_RESERVATION_ITEM= '/reservations/{0}/reservation-items/{1}',
	RESERVATION_CUSTOMERS= '/reservations/{0}/customers',
	PENDING_ITEMS= '/reservations/{0}/add-pending-items',
	CONFLICTS= '/reservations/{0}/conflicts?endDateShouldBeToday={1}',
	FIND_CONFLICTS = '/reservations/find-conflicts',
   PROOF= '/reservations/{0}/proof',
   CANCEL_RESERVATION= '/reservations/{0}/cancel',
   RESERVATION_CONTRACT= '/reservations/{0}/contract',
   APPROVE_RESERVATION= '/reservations/{0}/approve',
   START_RESERVATION= '/reservations/{0}/start',
   TRANSFER_TARIFF= '/reservations/transfer-tariff?pickupOrganizationId={0}&returnOrganizationId={1}',
   SAVE_DOSSIAR= '/reservations/{0}/save-dossier',
   SEND_CONTRACT_BY_EMAIL= '/reservations/{0}/send-contract-by-email',
   FINISH_RESERVATION= '/reservations/{0}/finish',
   CAR_HISTORY= '/reservations/{0}/cars-history',
   RESERVATION_TOLLS= '/tolls/by-reservation/{0}',
   RESERVATION_STATUS= '/reservations/statuses',
   ACCOUNT_TYPES= '/accounts/types',
   CHECK_CONTRACT = '/reservations/{0}/contract-exists',

	// Tickets
	TICKETS= '/tickets?{0}Page={1}&Size={2}&Search={3}',
	TICKET_BY_ID = '/tickets/{0}',
	TICKET_STATUS= '/tickets/{0}/status',
	TICKET_MESSAGE= '/tickets/{0}/add-message',

	// Transactions
   TRANSACTIONS= '/reservations/{0}/transactions',
    PAYMENT_METHODS= '/payments/methods',

   // Waybills
	WAY_BILLS= '/way-bills?page={0}&size={1}&search={2}',
	WAY_BILL='/way-bills',
	WAYBILL_BY_ID= '/way-bills/{0}',
	WAYBILL_CONTRACT='/way-bills/{0}/contract',

	// Users
	USERS= '/users?page={0}&size={1}&search={2}&StatusId={3}',
	USER = '/users',
	USER_BY_ID= '/users/{0}',
	PROMOTE_ADM= '/administrators',
	SEND_MESSAGE= '/users/{0}/send-message',
	RESET_USER_PASSWORD= '/users/{0}/reset-password',
	USER_BY_TAXID= '/users/by-fiscal-number',
	USER_STATUS= '/users/{0}/status',
	USER_PENDING_FIELDS= '/users/{0}/pending-fields',
	USER_PENDING_DOCUMENTS= '/users/{0}/pending-documents',
	USER_PENDING_FINANCIALS= '/users/{0}/pending-financials',
	USER_BALANCE = '/users/{0}/previous-balance',
	USER_VERIFY_DOCUMENTS = '/documents/document-not-used?value={0}&typeId={1}&countryId={2}',

	// Cards
	USER_CARDS= '/cards?clientId={0}&type={1}',
	CARD_BY_ID= '/cards/{0}',
	CARDS= '/cards',
	CHECK_VALID_CARD = '/cards/check-valid-cards?OrganizationId={0}&UserId={1}',

	// Social Network
	SOCIAL_NETWORKS='/social-networks',
	SOCIAL_NETWORKS_BY_ID='/social-networks/{0}',
	SOCIAL_NETWORKS_TYPES='/social-networks/types',
	SOCIAL_NETWORK_STATUS='/social-networks/{0}/status',

	// Banners
	BANNERS= '/banners?page={0}&size={1}&search={2}',
	BANNER='/banners',
	BANNER_BY_ID='/banners/{0}',
	CHANGE_BANNER_STATUS='/banners/{0}/status',

	// Brands
	BRANDS= '/brands?page={0}&size={1}&search={2}',
	BRAND='/brands',
	BRAND_BY_ID='/brands/{0}',

	// Fines
	FINES= '/fines',
	FINE_BY_CARID = '/fines?carId={0}&Page={1}&Size={2}&Search={3}',
	FINE_BY_ID= '/fines/{0}',

	// Cars
	CARS= '/cars?Page={0}&Size={1}&search={2}&StatusId={3}',
	CARS_FOR_RESERVATIONS= '/cars/available-for-reservation',
	CAR = '/cars',
	CAR_BY_ID= '/cars/{0}',
	RECENT_CAR_RESERVATIONS= '/cars/{0}/recent-reservations',
	FREE_CAR_PERIODS= '/cars/{0}/available-days',
	CAR_RESERVA_BY_DATE= '/cars/{0}/search-reservation-by-date',
	CAR_STATUS= '/cars/{0}/status',
	CAR_CATEGORIES= 'categories?page={0}&size={1}&search={2}&tariffId={3}',
	CAR_COLOR = '/cars/colors',
	CAR_FLEET='/cars/fleets',
	CAR_FUEL='/cars/fuel-types',

	// Feature
	FEATURES= 'features?page={0}&size={1}&search={2}',
	FEATURE_BY_ID = '/features/{0}',
	NEW_FEATURE = '/features',
	FEATURE_STATUS= '/features/{0}/status',
	FEATURE_TYPES= '/features/types',

	// Categories
	CATEGORIES='/categories?page={0}&size={1}&search={2}&TariffId={3}&StatusId={4}',
	CATEGORY= '/categories',
	CATEGORY_BY_ID= '/categories/{0}',
	CATEGORY_STATUS= '/categories/{0}/status',

	// Organization
	ORGANIZATIONS= '/organizations?page={0}&size={1}&search={2}&statusId={3}',
	ORGANIZATION = '/organizations',
	ORGANIZATION_BY_ID = '/organizations/{0}',
	ORGANIZATION_STATUS= '/organizations/{0}/status',

	// Counters
	COUNTERS= '/counters?page={0}&size={1}&search={2}&statusId={3}',
	COUNTER='/counters',
	COUNTER_STATUS= '/counters/{0}/status',
	COUNTER_BY_ID='/counters/{0}',

	// Tariff
	TARIFFS= '/tariffs?page={0}&size={1}&search={2}&StatusId={3}',
	TARIFFS_BY_ID= '/tariffs/{0}',
	TARIFF= '/tariffs',
	TARIFF_STATUS= '/tariffs/{0}/status',
	TARIFF_BY_ORGANIZATION='/tariffs/by-organization?organizationId={0}',

	// Maintenance
	MAINTENANCES= '/maintenances?page={0}&size={1}&search={2}&carId={3}',
	MAINTENANCE = '/maintenances',
	MAINTENANCE_BY_ID= '/maintenances/{0}',
	MAINTENANCE_INFO= '/maintenances/receive/{0}',
	MAINTENANCE_TYPES= '/maintenances/types',

	// Discounts
	BILLABLE_ITEMS= '/billable-items?typeId={0}',
	BILLABLE_DISCOUNTS_BY_CATEGORY='/billable-items/list?Quantity={0}&CategoryId={1}',
   CAMPAIGNS= '/campaigns?page={0}&size={1}&search={2}&CategoryId={3}&TargetDate={4}',
   CAMPAGN='/campaigns',
   CAMPAIGN_BY_ID = '/campaigns/{0}',
   CAMPAIGN_STATUS= '/campaigns/{0}/status',
   DELETE_BILLABLE_ITEM= '/billable-items/{0}/quantity-discounts/{1}',
   ITEMS_RELATED_TO_BILLABLE_ITEM='/billable-items/{0}/quantity-discounts',
   REQUEST_DISCOUNT= '/discount-request',
   RESERVATION_DISCOUNT= '/discount-request?ReservationId={0}&TypeId={1}&Page={2}&Size={3}&Search={4}',
   DISCOUNT_TYPES= '/billable-items/types',
   APPROVE_DISCOUNT_REQUEST = '/discount-request/{0}/approve',
   REJECT_DISCOUNT_REQUEST = '/discount-request/{0}/reject',

   // Tolls
   PENDING_TOLLS= '/tolls/pending?size={0}&page={1}&Search={2}',
   TOLL_MIGRATION= '/tolls/migration?StartDate={0}&EndDate={1}&{2}',
   TOLLS_NO_RESERVATION= '/tolls/with-no-reservation?page={0}&size={1}&search={2}',
   REGISTER_PAYMENT= '/tolls/register-payment?vvTransactionId={0}',
   LINK_TOLL= '/tolls/link-to?reservationId={0}&vvpId={1}',
   TOLL_LIVE_STATUS= '/tolls/change-status-live?StatusId={0}&{1}DriverId={2}',
   TOLL_STATUS_LOCAL= '/tolls/change-status-locally',
   TOLL_STATUS_TYPES= '/tolls/status-types',

   // Reports
   REPORTS_DIVERGANT_CATEGORIES= '/reports/divergent-categories?startDate={0}&endDate={1}&search={2}&page={3}&size={4}',
   REPORTS_UNGENERATED_INVOICES= '/reports/ungenerated-invoices?Page={0}&Size={1}&Search={2}{3}',
   REPORTS_COMMISSION= 'reports/commission{0}{1}{2}',
   REPORT_COMMISION_ADJUSTMENT= '/reports/adjustment-commission?StartDate={0}&EndDate={1}&BalconyId={2}&search={3}',
   REPORT_TOLL= '/reports/tolls?StartDate={0}&EndDate={1}{2}&Page={3}&Size={4}&Search={5}',
   REPORT_MAINTENANCE= '/reports/maintenance?CarId={0}&StartDate={1}&EndDate={2}&Page={3}&Size={4}&Search={5}',
   REPORT_INVOICES= '/reports/invoices{0}{1}',
   REPORT_PENDING_FINANCIALS= '/reports/pending-financial?StartDate={0}&EndDate={1}&Name={2}&ClientTypes={3}&ClientTypes={4}',
   CHECK_REVISION= '/cars/{0}/check-revision',

    // Questions
   QUESTIONS= '/questions?page={0}&size={1}&search={2}',
   QUESTION = '/questions',
   QUESTION_BY_ID = '/questions/{0}',
   QUESTION_STATUS= '/questions/{0}/status',

   // Tolerance
   TOLERANCE= '/tolerance',
   LIST_CRM= '/communication-logs?reservationId={0}&userId={1}',
   CRM= '/communication-logs',
   SUBJECT_TYPES= '/communication-logs/subject-types',
   MODAL_TYPES= '/communication-logs/modal-types',
   RECEPTIVITIES= '/communication-logs/receptivities',

   // Articles
   GET_ALL_ARTICLES='/articles?page={0}&size={1}&search={2}&languageId={3}',
   CREATE_ARTICLE = '/articles',
   ARTICLE_BY_ID = '/articles/{0}',
   ARTICLE_STATUS = '/articles/{0}/status',
   ARTICLE_STATUSES = '/articles/status',

   // Invoices
   GENERATE_INVOICE= 'invoices',
   INVOICE_BY_ID= '/invoices/{0}',
   CANCEL_INVOICE= '/invoices/{0}/cancel',
   SEND_BY_EMAIL= '/invoices/{0}/send-by-email',
   CREDIT_MEMO= '/invoices/credit-memo?referenceInvoiceId={0}',
   FIND_RECEIPT= '/invoices/{0}/find-receipt',
   CREATE_RECEIPT= '/invoices/{0}/receipts',
   REFUND_INVOICE= '/invoices/{0}/refund',
   INVOICE_ITEMS='/reservations/{0}/invoice-items',
   DOCUMENT_TYPES='/invoices/document-types',
   IMPORT_RECEIPT='/invoices/{0}/import-receipt',

   // Places
   COUNTRIES= '/locations/countries?page={0}&size={1}',
   STATES_BY_COUTRY_ID= '/locations/{0}/states',
   CITIES_BY_STATE_ID= '/locations/{0}/cities',

    // upload
   UPLOAD= '/upload',

	// MISC
	GROUP_TYPE = '/groups/types'
 }

export default Endpoint;
