import {
	CheckBox, Space, StatusLabel,
} from '@Atoms';
import { formatDate } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Grid, Typography } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import {
	LegacyTolls, Pagination,
} from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProcessTollsProps {
	tolls?: Pagination<LegacyTolls[]>;
	onCheckClick: (transactionId: number) => void;
	transactionId: number[];
	error: string
}

const ProcessTollPage: FC<ProcessTollsProps> = (props) => {
	const {
		tolls, onCheckClick, transactionId, error,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					{tolls?.data && (
						<DataTable
							data={tolls.data || []}
							search={false}
						>
							<TableColumn
								headerText="#"
								render={(item) => (
									<CheckBox
										checked={transactionId.includes(item.transactionId)}
										setChecked={() => {
											onCheckClick(item.transactionId);
										}}
										title=""
									/>
								)}
							/>
							<TableColumn headerText="Cod." render={(item) => item.transactionId} />
							<TableColumn headerText={t('device')} render={(item) => item.obenumber} />
							<TableColumn headerText={t('vehicle')} render={(item) => item.carPlate} />
							<TableColumn headerText={t('tolls')} render={(item) => item.outTollName} />
							<TableColumn headerText={t('date')} render={(item) => formatDate(item.outDate)} />
							<TableColumn headerText={t('value')} render={(item) => putDecimalPoints(item.value)} />
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						</DataTable>
					)}
					{error && (
						<>
							<Space height={2} />
							<Typography color="error">{error}</Typography>
						</>
					)}
				</PaperContainer>

				<Space height={2} />

			</Grid>
		</Grid>
	);
};

export default ProcessTollPage;
