import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { Grid } from '@mui/material';
import {
	DefaultButton, Dropdown, FinancialButton, StatusLabel,
} from '@Atoms';
import { AddCircle } from '@mui/icons-material';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { Pagination, PaginationTable, User } from '@Types';

interface ListUsersProps {
   handleListAction: (action: string, item?: User) => void;
   setPagination: (params: PaginationTable) => void;
   setSearch: (searchQuery: string) => void;
   users?: Pagination<User[]>
}

const ListUsersPage: FC<ListUsersProps> = (props) => {
	const {
		handleListAction, setPagination, setSearch, users,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={users?.data || []}
						pagination
						search
						onChange={(page, rowsPerPage) => setPagination({ page, size: rowsPerPage })}
						total={users?.total}
						onSearching={(query) => setSearch(query)}
						stripedRows
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('taxId')} render={(item) => item.taxId} />
						<TableColumn headerText={t('name')} render={(item) => item.name} />
						<TableColumn headerText={t('email')} render={(item) => item.email} />
						<TableColumn headerText={t('telephone')} render={(item) => item.phone} />
						<TableColumn
							headerText={t('status')}
							render={(item) => (
								<>
									<StatusLabel status={item.status} />
									{item.hasPendingFinancials && (
										<FinancialButton onClick={() => handleListAction(t('transactions'), item)} />
									)}
								</>
							)}
						/>
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<Dropdown
									data={[
										t('detail'),
										t('changeStatus'),
										t('resetPassword'),
										t('creditCards'),
										t('sendMessage'),
										t('linkToCounter'),
										t('promoteAdm'),
									]}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(action) => {
										handleListAction(action, item);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};
export default ListUsersPage;
