import {
	ApiResponse, ListDataControl, Pagination, Banner, BannerReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqDelete, reqGet, reqPost } from '../request.api';
import { uploadFile } from './upload.service';

export const getBanners = async (
	Params: ListDataControl,
	signal: AbortSignal,
): Promise<ApiResponse<Pagination<Banner[]>>> => {
	const resp = await reqGet({
		url: Endpoint.BANNERS.format(Params.page, Params.size, Params.search),
		signal,
	});
	const { data } = resp;
	return data;
};

export const saveBanner = async (banner: BannerReq): Promise<ApiResponse<void>> => {
	let downlink = '';
	if (typeof banner.file === 'object') {
		if (banner.file?.size) {
			const image = new FormData();
			image.append('file', banner.file);
			const res = await uploadFile(image);
			downlink = res.data.file;
		}
	}
	const dataToUpload = {
		name: banner.name,
		file: downlink,
	};

	const resp = await reqPost({
		url: Endpoint.BANNER,
		data: dataToUpload,
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const getBannerByID = async (
	id: string,
	signal: AbortSignal,
): Promise<ApiResponse<Banner>> => {
	const resp = await reqGet({
		url: Endpoint.BANNER_BY_ID.format(id),
		signal,
	});
	const data: ApiResponse<Banner> = await resp.data;
	return data;
};

export const deleteBannerByID = async (
	id: number,
): Promise<ApiResponse<void>> => {
	const resp = await reqDelete({
		url: Endpoint.BANNER_BY_ID.format(id),
		data: {},
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const updateBanner = async (
	banner: BannerReq,
	id: string,
): Promise<ApiResponse<void>> => {
	let downlink = banner.fileLink;
	if (banner.file?.size) {
		const image = new FormData();
		image.append('file', banner.file);
		const res = await uploadFile(image);
		downlink = res.data.file;
	}
	const dataToUpload = {
		name: banner.name,
		file: downlink,
	};
	const resp = await reqPost({
		url: Endpoint.BANNER_BY_ID.format(id),
		data: dataToUpload,
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const changeBannerStatus = async (id: number): Promise<ApiResponse<Banner>> => {
	const resp = await reqPost({
		url: Endpoint.CHANGE_BANNER_STATUS.format(id),
	});
	const { data } = resp;
	return data;
};
