import { useRequest } from '@Api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tariffApi } from '@Requests';
import { Tariff } from '@Types';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import ListTarriffPage from './ListTarriffPage';

const ListTariffs = () => {
	const { t } = useTranslation(['translations', 'users']);
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	const tariffs = useRequest(([params]) => tariffApi.getTariffs(params));
	const changeTariffStatus = useRequest(([params]) => tariffApi.changeTariffStatus(params));

	useEffect(() => {
		setTitle(t('teriffs'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: pagination.page, size: pagination.size, search,
			};
			tariffs.execute(listDataControl);
		};
		getData();
	}, [pagination, search]);

	useEffectCustom(() => {
		const { data } = changeTariffStatus;
		if (data) {
			tariffs.setData((mPrev) => {
				const prev = mPrev;
				const index = prev?.data.findIndex((el) => el.id === changeTariffStatus.data?.id);
				if (prev && index !== undefined) {
					prev.data[index] = data;
				}
				return prev;
			});
		}
	}, [changeTariffStatus.data]);

	const handleDropdownClick = (action: string, item?: Tariff) => {
		switch (action) {
		case t('detail'):
			navigate(AppRoutes.EDIT_TERRIF.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeTariffStatus.execute(item?.id);
			break;
		case t('new'):
			navigate(AppRoutes.ADD_NEW_TERRIF);
			break;
		default:
			break;
		}
	};

	return (
		<ListTarriffPage
			tariffs={tariffs.data}
			setSearch={setSearch}
			setPagination={setPagination}
			handleListAction={handleDropdownClick}
		/>
	);
};

export default ListTariffs;
