import {
	ApiResponse,
	Pagination,
	NewMaintenance,
	Maintenance,
	MaintenanceInfo,
	ListDataControl,
	NameAndId,
	RemoveMaintenanceReq,
} from '@Types';
import { normalizeCurrency } from '@Masks';
import { uploadFile } from './upload.service';
import { reqGet, reqPost } from '../request.api';
import Endpoint from '../endpoints.api';

export async function getMaintenances(params: ListDataControl, signal: AbortSignal, carId?: string):
   Promise<ApiResponse<Pagination<Maintenance[]>>> {
	const resp = await reqGet({
		url: Endpoint.MAINTENANCES.format(params.page, params.size, params.search, carId || ''),
		signal,
	});
	const { data } = resp;
	return data;
}

export async function getMaintenanceById(id: number | string): Promise<ApiResponse<Maintenance>> {
	const resp = await reqGet({
		url: Endpoint.MAINTENANCE_BY_ID.format(id),
	});
	const { data } = resp;
	return data;
}

export async function getMaintenanceInfoById(id: string): Promise<ApiResponse<MaintenanceInfo>> {
	const resp = await reqGet({
		url: Endpoint.MAINTENANCE_INFO.format(id),
	});
	const { data } = resp;
	return data;
}

export async function removeFromMaintenance(id: string, payload: RemoveMaintenanceReq):
	Promise<ApiResponse<void>> {
	const dataToUpload = {
		...payload,
		amount: normalizeCurrency(payload.amount),
		endDate: payload.endDate.toISOString(),
	};

	const resp = await reqPost({
		url: Endpoint.MAINTENANCE_INFO.format(id),
		data: dataToUpload,
	});

	const { data } = resp;
	return data;
}

export async function createNewMaintenance(newMaintenance: NewMaintenance):
   Promise<ApiResponse<void>> {
	const maintenanceToUpload = {
		carId: newMaintenance.carId.id,
		description: newMaintenance.description,
		organizationId: newMaintenance.organizationId.id,
		totalAmount: normalizeCurrency(newMaintenance.totalAmount),
		typeId: newMaintenance.typeId.id,
		startDate: newMaintenance.startDate.toISOString(),
		intendedOutDate: newMaintenance.intendedOutDate.toISOString(),
	};

	const resp = await reqPost({
		url: Endpoint.MAINTENANCE,
		data: maintenanceToUpload,
	});
	const { data } = resp;
	return data;
}

export async function updateMaintenanceById(maintenance: NewMaintenance, maintenanceId: string):
   Promise<ApiResponse<Maintenance>> {
	let downlink = '';
	if (maintenance.fileName.size) {
		const file = new FormData();
		file.append('file', maintenance.fileName);
		const uploadRes = await uploadFile(file);
		downlink = uploadRes.data.file;
	}

	const maintenanceToUpload = {
		carId: maintenance.carId.id,
		organizationId: maintenance.organizationId.id,
		typeId: maintenance.typeId.id,
		startDate: maintenance.startDate.toISOString(),
		intendedOutDate: maintenance.intendedOutDate.toISOString(),
		fileName: downlink,
		fileDescription: maintenance.fileDescription,
		description: maintenance.description,
		totalAmount: normalizeCurrency(maintenance.totalAmount),
		maintenanceId,
	};
	const resp = await reqPost({
		url: Endpoint.MAINTENANCE_BY_ID.format(maintenanceId),
		data: maintenanceToUpload,
	});
	const { data } = resp;
	return data;
}

export const getCarMaintenanceTypes = async (): Promise<ApiResponse<NameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.MAINTENANCE_TYPES,
	});
	const { data } = resp;
	return data;
};
