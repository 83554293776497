/* eslint-disable no-console */
import { reservationApi, discountApi } from '@Requests';
import { DiscountBillable, Campaign, Category } from '@Types';
import moment from 'moment';

const dateDiff = (startDateTime: Date, endDateTime: Date, chargeOverTime: boolean) => {
	const startDate = new Date(
		startDateTime.getTime() + startDateTime.getTimezoneOffset() * 60000,
	);
	const endDate = new Date(
		endDateTime.getTime() + endDateTime.getTimezoneOffset() * 60000,
	);

	// seconds amount
	let delta = Math.abs(endDate.valueOf() - startDate.valueOf()) / 1000;
	// calculate (and subtract) whole days
	let days = Math.floor(delta / 86400);
	delta -= days * 86400;
	// calculate (and subtract) whole hours
	let hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;
	// calculate (and subtract) whole minutes
	const minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;
	// what's left is seconds
	const seconds = delta % 60;

	if (chargeOverTime) {
		hours = minutes < 30 ? hours : hours + 1;
	} else {
		days = hours > 0 ? days + 1 : days;
		hours = 0;
	}

	return {
		days, hours, minutes, seconds,
	};
};

const getDateTime = (date: Date, time: Date) => {
	const dateString = moment(date).format('MM/DD/YYYY');
	const timeString = moment(time).format('HH:mm');

	const finalDate = new Date(`${dateString} ${timeString}`);
	return finalDate;
};

const getOptionalsAmount = (optionals?: DiscountBillable[]) => {
	const optionalsAmount = optionals?.reduce((sum, element) => {
		let mSum = sum;
		mSum += parseFloat(element.value);
		return mSum;
	}, 0 as number);
	return optionalsAmount || 0;
};

const getTransferTax = async (pickupId: number, returnId: number) => {
	const { data } = await reservationApi.transferTariff(pickupId, returnId);
	return data?.itemValue || 0;
};

async function getCurrentQuantityDiscount(
	itemID: number,
	unitValue: number,
	quantity: number,
	category: Category,
) {
	const { data } = await discountApi.getListBillableItemsByCategory(quantity, category?.id);
	if (data && itemID === 8) {
		if (data && data.length > 0) {
			const qtdDiscount = data.find((el) => el.id === 8);
			if (qtdDiscount === undefined) {
				return 0;
			}
			if (qtdDiscount?.isPercentage) {
				const calculatedDiscout = unitValue * (qtdDiscount.discount / 100);
				if (calculatedDiscout >= unitValue) {
					return unitValue;
				}
				return unitValue * (qtdDiscount.discount / 100);
			}
			if (qtdDiscount.discount >= unitValue) {
				return unitValue;
			}
			return qtdDiscount.discount;
		}
	}
	return 0;
}

async function getCampaignDiscount(itemID: number, unitValue: number, campaign: Campaign | null) {
	if (campaign) {
		if (campaign && campaign.item.id === itemID) {
			if (campaign.isPercentage) {
				const calculatedDiscout = unitValue * (campaign.discount / 100);
				if (calculatedDiscout >= unitValue) {
					return unitValue;
				}
				return unitValue * (campaign.discount / 100);
			}
			if (campaign.discount >= unitValue) {
				return unitValue;
			}
			return campaign.discount;
		}
	}
	return 0;
}

const getItemTotalRate = async (
	itemID: number,
	mUnitValue: number,
	quantity: number,
	campaign: Campaign | null,
	category: Category,
) => {
	let unitValue = mUnitValue;
	unitValue -= await getCampaignDiscount(itemID, unitValue, campaign);
	unitValue -= await getCurrentQuantityDiscount(itemID, unitValue, quantity, category);
	return quantity * unitValue;
};

export const getReservationPrice = async (
	startDate: Date,
	startHour:Date,
	endDate:Date,
	endHour:Date,
	category: Category,
	optionals: DiscountBillable[],
	campaign: Campaign | null,
	selPickupId: number,
	selReturn: number,
	chargeOverTime: boolean,
) => {
	const startDateTime = getDateTime(startDate, startHour);
	const endDateTime = getDateTime(endDate, endHour);

	const duration = dateDiff(startDateTime, endDateTime, chargeOverTime);
	const { pricePerDay } = { ...category };
	const { pricePerHour } = { ...category };
	const optionalsPrice = getOptionalsAmount(optionals);
	const transferTax = await getTransferTax(selPickupId, selReturn);
	const daysValue = await getItemTotalRate(
		8,
		pricePerDay,
		duration.days,
		campaign,
		category,
	); // ADD 8 as itemId,
	const hoursValue = await getItemTotalRate(
		9,
		pricePerHour,
		duration.hours,
		campaign,
		category,
	); // ADD 9 as itemId,
	console.log(duration);
	const total = (daysValue + hoursValue + optionalsPrice + transferTax) || 0;
	return { days: duration.days, hours: duration.hours, total };
};
