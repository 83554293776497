import React, { FC } from 'react';
import { Grid, Slider, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	Image,
	TextField,
	Autocomplete,
	Description,
	CheckBox,
	DefaultButton,
	Space,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Formik, Form, FormikHelpers, FormikErrors,
} from 'formik';
import { useDropdown } from '@Providers';
import { Flag } from '@mui/icons-material';
import { FinalizeReservationReq } from '@Types';
import { formatDateOnly, formatTimeOnly } from '@Helpers';

interface FinalizeReservationProps {
	initialValues: FinalizeReservationReq;
	handleSubmit: (
		values: FinalizeReservationReq,
		formikHelpers: FormikHelpers<FinalizeReservationReq>
		) => void;
	validate: (values: FinalizeReservationReq) => FormikErrors<FinalizeReservationReq>;
	hideButton?: boolean
}

const FinalizeReservationPage: FC<FinalizeReservationProps> = (props) => {
	const {
		initialValues, handleSubmit, validate, hideButton,
	} = props;
	const { counters } = useDropdown();
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validate={validate}
						enableReinitialize
					>
						{({
							values,
							setFieldValue,
							handleChange,
							errors,
							touched,
							isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={3} lg={2} md={4} sm={12} xs={12}>
										<Image
											src={initialValues.image}
											alt="car"
											height="100%"
											width="100%"
										/>
									</Grid>
									<Grid item xl={9} lg={10} md={8} sm={12} xs={12}>
										<Grid container spacing={3}>
											<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
												<TextField
													label={t('registration')}
													helperText=""
													disabled
													value={`${values.car.licensePlate}`}
													InputLabelProps={{ shrink: true }}
												/>
											</Grid>
											<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
												<TextField
													label={t('brand')}
													helperText=""
													disabled
													value={`${values.brand}`}
													InputLabelProps={{ shrink: true }}
												/>
											</Grid>
											<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
												<TextField
													label={t('model')}
													value={values.car.name}
													disabled
													InputLabelProps={{ shrink: true }}
												/>
											</Grid>
										</Grid>
										<Space height={2} />
										<Grid container spacing={3}>
											<Grid item xl={12} lg={10} md={8} sm={12} xs={12}>
												<TextField
													label={t('client')}
													value={`${values.client}`}
													disabled
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
										<TextField
											label={t('survey')}
											value={`${values.pickupOrganisation}`}
											disabled
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<TextField
											label={t('withDrawlDate')}
											value={`${formatDateOnly(values.startDate)}`}
											disabled
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<TextField
											label={t('hour')}
											value={`${formatTimeOnly(values.startTime)}`}
											disabled
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('returnCounter')}
											placeholder=""
											defaultValue={values.returnOrgnizationId}
											options={counters || []}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											getOptionLabel={(item) => item.officialName}
											onChange={(_, item) => setFieldValue('returnOrgnizationId', item)}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<TextField
											label={t('returnDate')}
											value={`${formatDateOnly(new Date())}`}
											disabled
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<TextField
											label={t('hour')}
											value={`${formatTimeOnly(new Date())}`}
											disabled
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<TextField
											label={t('initialKm')}
											value={`${values.startKilometer}`}
											disabled
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<TextField
											label={t('finalKm')}
											id="endKilometer"
											value={`${values.endKilometer}`}
											type="number"
											error={Boolean(errors.endKilometer)}
											helperText={errors.endKilometer}
											InputLabelProps={{ shrink: true }}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<Description text={t('initialFuel')} />
										<Slider
											aria-label="fuel-level"
											defaultValue={values.endFuel}
											valueLabelDisplay="auto"
											step={1}
											marks
											min={0}
											max={8}
											disabled
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<Description text={t('finalFuel')} />
										<Slider
											aria-label="fuel-level"
											defaultValue={8}
											// getAriaValueText={valuetext}
											valueLabelDisplay="auto"
											step={1}
											marks
											min={0}
											max={8}
											onChange={(_, value) => setFieldValue('endFuel', value)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<TextField
											multiline
											minRows={3}
											label={t('notes')}
											error={touched.notes && Boolean(errors.notes)}
											helperText={touched.notes && errors.notes}
											id="notes"
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								{!hideButton && (
									<>
										<CheckBox
											checked={values.checked}
											setChecked={() => {
												setFieldValue('checked', !values.checked);
											}}
											title={t('finalizeAffirmation')}
										/>
										<Space height={2} />
										<Divider />
										<Space height={2} />
										<DefaultButton
											disabled={!values.checked}
											loading={isSubmitting}
											title={t('finalizeRental')}
											startIcon={<Flag />}
											type="submit"
										/>
									</>
								)}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default FinalizeReservationPage;
