import React, { FC } from 'react';
import { countryToFlag } from '@Helpers';
import { Car, Country } from '@Types';
import { Box, useTheme } from '@mui/material';

interface DropDownItemProps {
  country?: Country;
  car?: Car;
  phone?: boolean;
}

const DropdownItem: FC<DropDownItemProps> = ({ country, car, phone }) => {
	const theme = useTheme();
	return (
		<div>
			{country && <Box sx={{ mr: 1 }} component="span">{countryToFlag(country?.shortName)}</Box>}
			{car && (
				<Box
					sx={{
						mr: 1,
						paddingLeft: '6px',
						paddingRight: '6px',
						paddingTop: '4px',
						paddingBottom: '4px',
						fontSize: 11,
						borderRadius: '0.25rem',
						fontWeight: 'bold',
						bgcolor: theme.palette.divider,
					}}
					component="span"
				>
					{car?.licensePlate}
				</Box>
			)}
			{phone && (
				<Box sx={{ mr: 1 }} component="span">
					{country?.phoneCode}
				</Box>
			)}
			<span>
				{country?.name || car?.name}
			</span>
		</div>
	);
};

export default DropdownItem;
