import React, { useEffect, useState } from 'react';
import { carsApi, fineApi } from '@Requests';
import { useRequest } from '@Api';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CarFine } from '@Types';
import { AppRoutes } from '@Routes';
import { useTitleBar } from '@Providers';
import { useEffectCustom } from '@Hooks';
import ListFinePage from './ListFinePage';

const ListFine = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const fines = useRequest(([param1, param2]) => fineApi.getFines(param1, param2));

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	useEffect(() => {
		const addTitle = async () => {
			if (id) {
				const vehicle = await carsApi.getCarByID(id);
				setTitle(`${t('fines')}  (${t('licensePlate')}: ${vehicle.data.licensePlate} - ${t('name')}: ${vehicle.data.name})`);
			} else {
				setTitle(`${t('fines')}`);
			}
		};
		addTitle();
	}, [setTitle, t, id]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			fines.execute(id, listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [id, pagination, search]);

	const handleListAction = (action: string, item?: CarFine) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.NEW_VEHICLE_FINE);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_VEHICLE_FINE.formatMap({ id: item?.id }));
			break;
		case t('listOfAllVehicles'):
			navigate(AppRoutes.VEHICLE_FINES);
			break;
		default:
			break;
		}
	};

	return (
		<ListFinePage
			fines={fines.data}
			handleListAction={handleListAction}
			filteredByVehicle={!!id}
			setPagination={setPagination}
			setSearch={setSearch}
		/>
	);
};

export default ListFine;
