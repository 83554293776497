import { DefaultButton } from '@Atoms';
import { formatDateOnly } from '@Helpers';
import {
	Stack,
	styled, Typography, useTheme,
} from '@mui/material';
import { ApplicableCampaign, Reservation } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CampaignItemProps {
	reservation?: Reservation;
	applicableCampaign?: ApplicableCampaign;
	handleApplyDiscount: () => void
}

const CampaignItem: FC<CampaignItemProps> = (props) => {
	const theme = useTheme();
	const { reservation, applicableCampaign, handleApplyDiscount } = props;
	const { t } = useTranslation('translations');

	const MBox = styled('div')(() => ({
		border: 1,
		borderColor: theme.palette.divider,
		borderStyle: 'solid',
		borderRadius: '7px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	}));

	const Hbox = styled('div')(() => ({
		height: '50px',
		borderTop: 1,
		borderTopColor: theme.palette.divider,
		borderTopStyle: 'solid',
		backgroundColor: theme.palette.divider,
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: '10px',
		paddingRight: '10px',
	}));

	return (
		<MBox>
			<Hbox>
				<Typography>{reservation?.campaign?.name}</Typography>
				<Typography variant="caption" sx={{ bgcolor: theme.palette.grey[400], borderRadius: 1, p: '5px' }}>
					{'Period: '}
					{formatDateOnly(reservation?.campaign?.startDate)}
					{' '}
					-
					{' '}
					{formatDateOnly(reservation?.campaign?.endDate)}
				</Typography>
			</Hbox>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '10px' }}>
				<Stack direction="column">
					<Typography variant="caption" component="div">
						<strong>
							{reservation?.campaign?.description}
						</strong>
					</Typography>
					<Typography variant="caption" component="div">
						Válido para o item:
						{' '}
						{applicableCampaign?.item?.item.name}
					</Typography>
				</Stack>
				<DefaultButton color={applicableCampaign?.cancel ? 'error' : 'primary'} onClick={handleApplyDiscount} title={applicableCampaign?.cancel ? t('cancel') : 'Aplicar descontos'} />
			</Stack>
		</MBox>
	);
};

export default CampaignItem;
