import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { organizationApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Organization } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListOrganizationPage from './ListOrganizationPage';

const ListOrganization = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	const organizations = useRequest(([params]) => organizationApi.getOrganizations(params));
	const changeOrganizationStatus = useRequest((params) => (
		organizationApi.changeOrganizationStatus(params)
	));

	useEffect(() => {
		setTitle(t('organizations'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			organizations.execute(listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	useEffectCustom(() => {
		const { data } = changeOrganizationStatus;
		if (data) {
			organizations.setData((prev) => {
				const index = organizations.data?.data.findIndex((el) => (
					el.id === changeOrganizationStatus?.data?.id));
				const mPrev = prev;
				if (mPrev && index !== undefined) {
					mPrev.data[index] = data;
				}
				return mPrev;
			});
		}
	}, [changeOrganizationStatus.data]);

	const onActionClick = (action: string, item?: Organization) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.ADD_NEW_ORGANIZATION);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_ORGANIZATION.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeOrganizationStatus.execute(item?.id);
			break;
		default:
			break;
		}
	};

	return (
		<ListOrganizationPage
			organizations={organizations.data}
			setSearch={setSearch}
			setPagination={setPagination}
			handleListAction={onActionClick}
		/>
	);
};

export default ListOrganization;
