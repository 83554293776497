import {
	Autocomplete, TextField, Space, DefaultButton, DropdownItem,
} from '@Atoms';
import { DROPDOWN_ACTION, ESTABLISHMENT_LIST } from '@Constant';
import {
	acceptNumbers,
	latitude, longitude, phone, postCode, taxId,
} from '@Masks';
import { PaperContainer } from '@Molecules';
import { CreditCard, Edit } from '@mui/icons-material';
import { Divider, Grid, Typography } from '@mui/material';
import {
	City, Country, OrganizationReq, State,
} from '@Types';
import { SetFieldValue } from 'components/pages/users/create/CreateUserPage';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateOrganizationProps {
   initialValues: OrganizationReq;
   id: boolean;
   handleCardClick?: () => void;
   countries: Country[];
   cities: City[];
   states: State[];
   requestById: (action: string, id: number) => void;
   validate: (values: OrganizationReq) => FormikErrors<OrganizationReq>;
   handleSubmit: (values: OrganizationReq, formikHelpers: FormikHelpers<OrganizationReq>) => void;
   validateNif: (value: string, setFieldValue: SetFieldValue) => void;
   isEdit: boolean;
   handleEditClick: () => void
}

const CreateOrganizationPage: FC<CreateOrganizationProps> = (props) => {
	const {
		initialValues,
		handleCardClick,
		id,
		countries,
		cities,
		states,
		requestById,
		validate,
		handleSubmit,
		validateNif,
		isEdit,
		handleEditClick,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			{id && (
				<Grid item sm={12} md={12}>
					<DefaultButton
						title={t('creditCards')}
						onClick={() => handleCardClick?.call(0)}
						startIcon={<CreditCard />}
					/>
				</Grid>
			)}
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values,
							errors,
							setFieldValue,
							touched,
							handleChange,
							isSubmitting,
						}) => (
							<Form autoComplete="off">
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={4}>
										<Autocomplete
											options={countries}
											defaultValue={values.countryId}
											label={t('parents')}
											placeholder=""
											disabled={isSubmitting || !isEdit}
											error={touched.countryId && Boolean(errors.countryId)}
											helperText={touched.countryId && String(errors.countryId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													<DropdownItem country={item} />
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => {
												setFieldValue('countryId', item);
												setFieldValue('stateId', undefined);
												setFieldValue('cityId', undefined);
												if (item) {
													requestById(DROPDOWN_ACTION.COUNTRY, item.id);
												}
											}}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={4}>
										<Autocomplete
											options={states}
											defaultValue={values.stateId}
											label={t('district')}
											disabled={isSubmitting || !isEdit}
											error={touched.stateId && Boolean(errors.stateId)}
											helperText={touched.stateId && String(errors.stateId || '')}
											placeholder=""
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => {
												setFieldValue('stateId', item);
												setFieldValue('cityId', undefined);
												if (item) {
													requestById(DROPDOWN_ACTION.STATE, item?.id);
												}
											}}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={4}>
										<Autocomplete
											options={cities}
											defaultValue={values.cityId}
											label={t('city')}
											disabled={isSubmitting || !isEdit}
											placeholder=""
											error={touched.cityId && Boolean(errors.cityId)}
											helperText={touched.cityId && String(errors.cityId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => {
												setFieldValue('cityId', item);
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={3} xl={4}>
										<TextField
											label={t('houseHold')}
											id="address"
											disabled={isSubmitting || !isEdit}
											error={touched.address && Boolean(errors.address)}
											helperText={touched.address && errors.address}
											type="text"
											value={values.address}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={3} xl={3}>
										<TextField
											label={`${t('neighborhood')}/${t('perish')}`}
											id="neighborhood"
											error={touched.neighborhood && Boolean(errors.neighborhood)}
											helperText={touched.neighborhood && errors.neighborhood}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.neighborhood}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={3} xl={3}>
										<TextField
											label={t('complement')}
											id="addressComplement"
											error={touched.addressComplement && Boolean(errors.addressComplement)}
											helperText={touched.addressComplement && errors.addressComplement}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.addressComplement}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={3} xl={2}>
										<TextField
											label={t('postalCode')}
											id="postalCode"
											error={touched.postalCode && Boolean(errors.postalCode)}
											helperText={touched.postalCode && errors.postalCode}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.postalCode}
											onChange={(e) => handleChange(postCode(e))}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={3} xl={4}>
										<TextField
											label={t('latitude')}
											id="latitude"
											error={touched.latitude && Boolean(errors.latitude)}
											helperText={touched.latitude && errors.latitude}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.latitude}
											onChange={(e) => handleChange(latitude(e))}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={3} xl={4}>
										<TextField
											label={t('longitude')}
											id="longitude"
											error={touched.longitude && Boolean(errors.longitude)}
											helperText={touched.longitude && errors.longitude}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.longitude}
											onChange={(e) => handleChange(longitude(e))}
										/>
									</Grid>
									<Grid item sm={12} xs={12} md={3} xl={4}>
										<TextField
											label={t('externalId')}
											value={values?.externalId}
											disabled
											id="externalId"
											type="string"
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Typography>
									{t('companyData')}
									:
								</Typography>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={4}>
										<TextField
											label={`${t('name')} ${t('official')}`}
											id="officialName"
											error={touched.officialName && Boolean(errors.officialName)}
											helperText={touched.officialName && errors.officialName}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.officialName}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={4}>
										<TextField
											label={`${t('name')} ${t('comercial', 'fantasyName')}`}
											id="fantasyName"
											error={touched.fantasyName && Boolean(errors.fantasyName)}
											helperText={touched.fantasyName && errors.fantasyName}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.fantasyName}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={4}>
										<TextField
											label={t('taxpayerNo')}
											id="taxId"
											onBlur={() => {
												if (values.countryId.id === 1) {
													validateNif(values.taxId, setFieldValue);
												}
											}}
											error={touched.taxId && Boolean(errors.taxId)}
											helperText={touched.taxId && errors.taxId}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.taxId}
											onChange={(e) => handleChange(taxId(e))}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={3} xl={3}>
										<Autocomplete
											options={ESTABLISHMENT_LIST}
											defaultValue={ESTABLISHMENT_LIST.find((el) => (
												el.id === values.establishmentNumber
											))}
											disabled={isSubmitting || !isEdit}
											error={touched.establishmentNumber && Boolean(errors.establishmentNumber)}
											helperText={touched.establishmentNumber && errors.establishmentNumber}
											label={`${t('number')} ${t('establishment')}`}
											placeholder=""
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops}>{item.name}</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => {
												setFieldValue('establishmentNumber', item?.id);
											}}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={3} xl={3}>
										<TextField
											label={t('email')}
											id="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											disabled={isSubmitting || !isEdit}
											type="text"
											value={values.email}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={3} xl={3}>
										<Autocomplete
											options={countries}
											defaultValue={values.phoneCountryId}
											label={t('countryCode')}
											placeholder=""
											disabled={isSubmitting || !isEdit}
											error={touched.phoneCountryId && Boolean(errors.phoneCountryId)}
											helperText={touched.phoneCountryId && String(errors.phoneCountryId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													<DropdownItem phone country={item} />
												</li>
											)}
											getOptionLabel={(item) => item.phoneCode || ''}
											onChange={(_, item) => {
												setFieldValue('phoneCountryId', item);
											}}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={3} xl={2}>
										<TextField
											label={t('contactNumber')}
											value={values.phone}
											disabled={isSubmitting || !isEdit}
											error={touched.phone && Boolean(errors.phone)}
											helperText={touched.phone && errors.phone}
											onChange={(event) => (values.phoneCountryId.id === 1
												? setFieldValue('phone', phone(event).target.value)
												: setFieldValue('phone', acceptNumbers(event).target.value))}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && <DefaultButton title={t('save')} type="submit" loading={isSubmitting} />}
								{!isEdit && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} />}
							</Form>
						) }
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateOrganizationPage;
