import { FontSizes } from '@Constant';
import { EditOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

interface EditLabelProps {
	title: string;
	onClick: () => void
}

const EditLabel: FC<EditLabelProps> = (props) => {
	const { title, onClick } = props;
	return (
		<Stack direction="row" alignItems="center">
			<Typography variant="caption">
				{title}
				:
			</Typography>
			<IconButton sx={{ p: 0, ml: 1 }} onClick={onClick}>
				<EditOutlined sx={{ fontSize: FontSizes.LG }} />
			</IconButton>
		</Stack>
	);
};

export default EditLabel;
