import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
	apiKey: 'AIzaSyA3ez7eOi90VDST655yBzm5SG7VyZc2rHE',
	authDomain: 'aj-rent-2019.firebaseapp.com',
	databaseURL: 'https://aj-rent-2019.firebaseio.com',
	projectId: 'aj-rent-2019',
	storageBucket: 'aj-rent-2019.appspot.com',
	messagingSenderId: '273108076943',
	appId: '1:273108076943:web:306a372cc6d979450823fa',
	measurementId: 'G-GVL1W6D6DR',
};

export const firebaseApp = initializeApp(firebaseConfig);
