import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, TableColumn } from '@Organisms';
import { CarItem, DefaultButton, TableItem } from '@Atoms';
import {
	PaginationTable, Transaction, Pagination,
} from '@Types';
import { Grid } from '@mui/material';
import { PaperContainer } from '@Molecules';
import { formatDate } from '@Helpers';
import { putDecimalPoints } from '@Masks';

interface NoBondsProps {
	handleListAction: (action: string, toll?: Transaction) => void;
	setSearch: (searchQuery: string) => void;
	setPagination: (params: PaginationTable) => void;
	tolls?: Pagination<Transaction[]>
}

const NoBondsPage: FC<NoBondsProps> = (props) => {
	const {
		handleListAction, setSearch, setPagination, tolls,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={tolls?.data || []}
						pagination
						onChange={(page, size) => setPagination({ page, size })}
						onSearching={setSearch}
						total={tolls?.total}
						stripedRows
					>
						<TableColumn headerText="Cod." render={(item) => item.transactionId} />
						<TableColumn
							headerText={t('enrollDev')}
							render={(item) => (
								<CarItem car={{ licensePlate: item.carPlate, name: item.obenumber }} />
							)}
						/>
						<TableColumn
							headerText={t('exit')}
							render={(item) => (
								<TableItem
									title={item.outTollName}
									subtitle={formatDate(item.outDate)}
								/>
							)}
						/>
						<TableColumn headerText={t('value')} render={(item) => `€ ${putDecimalPoints(item.fare)}`} />
						<TableColumn headerText={t('status')} render={(item) => item?.status?.name} />
						<TableColumn
							align="right"
							headerOptions={{ align: 'right' }}
							headerText={t('reservation')}
							render={(item) => (
								<>
									<DefaultButton
										onClick={() => { handleListAction(t('update'), item); }}
										title={t('update')}
										sx={{ mr: 1 }}
									/>
									<DefaultButton
										variant="outlined"
										onClick={() => { handleListAction(t('link'), item); }}
										title={t('link')}
										sx={{ mr: 1 }}
									/>
									<DefaultButton
										onClick={() => { handleListAction(t('register'), item); }}
										title={t('register')}
									/>
								</>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default NoBondsPage;
