import { useFeedback, useTitleBar } from '@Providers';
import { Article, ListDataControl, Pagination } from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { articleApi } from '@Requests';
import { useEffectCustom } from '@Hooks';
import { Dialog } from '@Organisms';
import { Image } from '@Atoms';
import { useRequest } from '@Api';
import { AppRoutes } from '@Routes';
import ListArticlePage from './ListArticlePage';

const ListArticles = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const navigate = useNavigate();
	const { addToast } = useFeedback();

	const [articles, setArticles] = useState<Pagination<Article[]> | undefined>();
	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const [search, setSearch] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState({ title: '', description: '' });

	const changeStatus = useRequest(([param1]) => (
		articleApi.changeArticleStatus(param1)));

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		setTitle(t('articles'));
		const getData = () => {
			const listControl: ListDataControl = {
				page: pagination.page,
				search,
				size: pagination.size,
			};
			setGlobalLoading(true);
			articleApi.getArticles(listControl, abortCtrl.signal)
				.then((res) => {
					setArticles(res.data);
				})
				.catch(({ response }) => {
					addToast({ message: response.data.message, error: true });
				})
				.finally(() => {
					setGlobalLoading(false);
				});
		};
		getData();
		return () => abortCtrl.abort();
	}, [search, pagination]);

	useEffectCustom(() => {
		const changeStatusLocally = () => {
			const { data } = changeStatus;
			if (data) {
				setArticles((prev) => {
					const mPrev = prev;
					const index = prev?.data.findIndex((el) => el.id === changeStatus.data?.id);
					if (mPrev?.data && index !== undefined) {
						mPrev.data[index] = data;
					}
					return mPrev;
				});
			}
		};
		changeStatusLocally();
	}, [changeStatus.data]);

	const handleListAction = (action: string, item?: Article) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.CREATE_ARTICLES);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_ARTICLES.formatMap({ id: item?.id }));
			break;
		case t('view'):
			setShowModal(true);
			setModalData({ ...modalData, title: item?.image || '', description: item?.description || '' });
			break;
		case t('access'):
			window.open(item?.link);
			break;
		case t('changeStatus'):
			changeStatus.execute(item?.id);
			break;
		default:
			break;
		}
	};

	const closeModal = () => {
		setShowModal(false);
		setModalData({ ...modalData, title: '' });
	};
	return (
		<>
			{showModal && (
				<Dialog
					title={(
						<Image
							alt="article"
							src={modalData.title}
							fallbackImage=""
							height={300}
							width={300}
						/>
					)}
					description={modalData.description}
					open={showModal}
					handleClose={closeModal}
				/>
			)}
			<ListArticlePage
				articles={articles}
				onPageChange={setPagination}
				onSearch={setSearch}
				handleListAction={handleListAction}
			/>
		</>
	);
};

export default ListArticles;
