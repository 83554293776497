/* eslint-disable no-console */
import { useRequest } from '@Api';
import { DefaultButton, Space } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import { useAuth, useFeedback, useTitleBar } from '@Providers';
import { reservationApi, carsApi, serviceApi } from '@Requests';
import { AppRoutes } from '@Routes';
import {
	CarMin, FinalizeReservationReq, Organization, Reservation,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Conflict from './Conflict';
import FinalizeReservationPage from './FinalizeReservationPage';
import UnbilledItems from './UnbilledItems';

const FinalizeReservation = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { id } = useParams();
	const navigate = useNavigate();
	const { addToast, addDialog } = useFeedback();
	const { user } = useAuth();

	const reservationConflicts = useRequest(([params]) => reservationApi.getConflicts(params, true));
	const reservationItems = useRequest(([params]) => (
		serviceApi.unbilledAndUngenerated(params)
	));

	const [initialValues, setInitialValues] = useState({
		car: {} as CarMin,
		brand: '',
		model: '',
		startDate: new Date(),
		startTime: new Date(),
		endDate: new Date(),
		endTime: new Date(),
		pickupOrganisation: '',
		returnOrganisation: '',
		client: '',
		startFuel: 0,
		endFuel: 8,
		startKilometer: 0,
		endKilometer: 0,
		notes: '',
		checked: false,
		returnOrgnizationId: {} as Organization,
		image: '',
	});
	const [resolveLoading, setResolveLoading] = useState(false);

	useEffect(() => {
		setTitle(`${t('finalizeRental')} - ${id}`);
	}, [id, t, setTitle]);

	const getReservation = async () => {
		const { data } = await reservationApi.getReservationById(id ?? '');
		return data;
	};

	const getCar = async (carId: number) => {
		const { data } = await carsApi.getCarByID(carId);
		return data;
	};

	const getData = async () => {
		setGlobalLoading(true);
		const reservation: Reservation = await getReservation();
		const car = await getCar(reservation.car.id);
		setInitialValues({
			// Set Brand
			...initialValues,
			brand: car.manufactory?.name,
			car: reservation.car,
			startDate: new Date(reservation.startDate),
			startTime: new Date(reservation.startDate),
			endDate: new Date(reservation.endDate),
			endTime: new Date(reservation.endDate),
			pickupOrganisation: reservation.pickupOrganization?.officialName,
			returnOrganisation: reservation.returnOrganization?.officialName,
			client: reservation.user?.name || '',
			startFuel: car.fuelLevel,
			endFuel: 8,
			notes: reservation.notes,
			checked: false,
			startKilometer: car.mileage,
			endKilometer: car.mileage,
			returnOrgnizationId: reservation.returnOrganization,
			image: car.coverImage,
		});
		setGlobalLoading(false);
		reservationConflicts.execute(id);
		reservationItems.execute(id);
	};

	useEffectCustom(() => {
		getData();
	}, []);

	const validate = (values: FinalizeReservationReq) => {
		const errors = {} as FormikErrors<FinalizeReservationReq>;
		if (values.startKilometer > values.endKilometer) {
			errors.endKilometer = t('endKilometerError');
		}
		return errors;
	};

	const handleSubmit = (
		values: FinalizeReservationReq,
		formikHelpers: FormikHelpers<FinalizeReservationReq>,
	) => {
		reservationApi.finalizeReservation(values, user?.userId, id)
			.then((res) => {
				addToast({
					message: res.message,
					error: false,
				});
				navigate(AppRoutes.LIST_ALL_RESERVATIONS);
			})
			.catch(({ response }) => addDialog({
				title: response.data?.title,
				message: response.data?.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	const resolveIssues = () => {
		setResolveLoading(true);
		reservationApi.addPendingItems(id)
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: id }));
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setResolveLoading(false));
	};

	const handleAction = (action: string) => {
		switch (action) {
		case t('gotoTransactions'):
			navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: id }));
			break;
		case t('automaticallyResolveIssues'):
			resolveIssues();
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
			break;
		default:
			break;
		}
	};

	return (
		<>
			<DefaultButton
				title={`${t('backTo')} ${t('reservation')} ${id}`}
				onClick={() => handleAction(t('reservation'))}
			/>
			<Space height={3} />
			{reservationItems.data && reservationItems.data?.length > 0
			&& (
				<>
					<UnbilledItems data={reservationItems.data} handleAction={handleAction} />
					<Space height={3} />
				</>
			)}
			{reservationConflicts.data
			&& (reservationConflicts.data.conflicts?.length !== 0
			|| reservationConflicts.data.notMatching) && (
				<>
					<Conflict
						data={reservationConflicts.data}
						loading={resolveLoading}
						handleAction={handleAction}
					/>
					<Space height={3} />
				</>
			)}
			<FinalizeReservationPage
				initialValues={initialValues}
				handleSubmit={handleSubmit}
				validate={validate}
				hideButton={reservationItems.data && reservationItems.data?.length > 0}
			/>
		</>
	);
};

export default FinalizeReservation;
