import { FontSizes } from '@Constant';
import { Brightness4 } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { useSetting } from '@Providers';
import React from 'react';

const ThemeToggle = () => {
	const { updateUIMode } = useSetting();
	const theme = useTheme();

	return (
		<IconButton onClick={updateUIMode} color="primary" sx={{ ml: 1 }}>
			<Brightness4 style={{ color: theme.palette.icon.main, fontSize: FontSizes.LG }} />
		</IconButton>
	);
};

export default ThemeToggle;
