import { DefaultButton, Description } from '@Atoms';
import { CloudDownload } from '@mui/icons-material';
import { TicketMessage } from '@Types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FileButtonProps {
	file: TicketMessage
}

const FileButton: React.FC<FileButtonProps> = (props) => {
	const { file } = props;
	const files = file?.file;
	const { t } = useTranslation('translations');
	// const fileName = file.files.url.split('/');
	const size = files?.size && files.size / 1000;
	return (
		<div>
			<Description fontSize={10} text={`${t('file')}: ${files?.name || ''}`} />
			<br />
			<Description fontSize={10} text={`${t('description')}: ${files?.description || ''}`} />
			<br />
			<Description fontSize={10} text={`${t('size')}: ${size} kB`} />
			<br />
			<a href={files.url} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
				<DefaultButton color="info" title={t('download')} startIcon={<CloudDownload />} />
			</a>
		</div>
	);
};

export default FileButton;
