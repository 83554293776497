import {
	Box, Stack, styled, TextField, Typography, useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface DefinitionInputProps {
  width: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTextField = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderRadius: '0 3px 3px 0',
			borderColor: theme.palette.divider,
			transition: 'border-color 0.3s',
			height: 45,
		},
		'&:hover fieldset': {
			borderColor: theme.palette.primary.main,
		},
	},
}));

const Label: FC<DefinitionInputProps> = ({ width, value, onChange }) => {
	const { t } = useTranslation(['translations']);
	const theme = useTheme();

	return (
		<Stack direction="row" sx={{ mt: '1px' }}>
			<Box
				border={1}
				borderColor={theme.palette.divider}
				sx={{
					height: 40,
					width: '130px',
					backgroundColor: theme.palette.divider,
					borderTopLeftRadius: 3,
					borderBottomLeftRadius: 3,
					// paddingLeft: 10,
					transition: 'border-color 0.3s',
					'&:hover': {
						borderColor: theme.palette.primary.main,
					},
				}}
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography sx={{
					fontsize: '1rem',
					fontWeight: 'normal',
				}}
				>
					{width === 'full' ? `${t('hours')}:` : `${t('minutes')}:`}

				</Typography>
			</Box>
			<CustomTextField
				id="outlined-number"
				size="small"
				label=""
				onChange={onChange}
				type="number"
				value={value}
				InputLabelProps={{
					shrink: false,
				}}
				variant="outlined"
				fullWidth
			/>
		</Stack>
	);
};

export default Label;
