import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { maintenanceApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Maintenance } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ListMaintenancePage from './ListMaintenancePage';

const ListMaintenance = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();
	const abortCtrl = new AbortController();
	const { id } = useParams();

	const maintenance = useRequest(([params]) => (
		maintenanceApi.getMaintenances(params, abortCtrl.signal, id)));

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');
	// const [total, setTotal] = useState<number | null>(null);

	useEffect(() => {
		setTitle(t('maintenance'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			maintenance.execute(listDataControl);
		};
		getData();
		return () => {
			abortCtrl.abort();
		};
	}, [pagination, search, id]);

	// useEffect(() => {
	// 	if (id) {
	// 		const mTotal = maintenance.data?.data?.reduce((tot, el) => {
	// 			let mTot = tot;
	// 			mTot += el.totalAmount;
	// 			return mTot;
	// 		}, 0);
	// 		setTotal(mTotal || null);
	// 	}
	// }, [maintenance.data, id]);

	const handleDropdownClick = (action: string, item?: Maintenance) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.NEW_MAINTENANCE);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_MAINTENANCE.formatMap({ id: item?.id }));
			break;
		case t('removeFUll'):
			navigate(AppRoutes.REMOVE_FROM_MAINTENANCE.formatMap({ id: item?.id }));
			break;
		default:
			break;
		}
	};

	const getDropdownOptions = (item: Maintenance) => {
		if (item.endDate) {
			return [t('detail')];
		}
		return [t('detail'), t('removeFUll')];
	};
	return (
		<ListMaintenancePage
			maintenance={maintenance.data}
			setPagination={setPagination}
			setSearch={setSearch}
			handleListAction={handleDropdownClick}
			getDropdownOptions={getDropdownOptions}
			// total={total}
		/>
	);
};
export default ListMaintenance;
