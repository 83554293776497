import {
	Autocomplete, DatePicker, DefaultButton,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdown } from '@Providers';
import { CommissionAdjustmentReq } from '@Types';
import { Search } from '@mui/icons-material';

interface CommissionAdjustmentFormProps {
	initialValues: CommissionAdjustmentReq;
	validate: (values: CommissionAdjustmentReq) => FormikErrors<CommissionAdjustmentReq>
	handleSubmit: (
		values: CommissionAdjustmentReq,
		formikHelpers: FormikHelpers<CommissionAdjustmentReq>
		) => void
}

const CommissionAdjustmentForm: FC<CommissionAdjustmentFormProps> = (props) => {
	const { initialValues, validate, handleSubmit } = props;
	const { t } = useTranslation('translations');
	const { counters } = useDropdown();
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values, setFieldValue, isSubmitting, errors,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<Autocomplete
											label={t('counters')}
											disabled={isSubmitting}
											placeholder=""
											error={!!errors.counterId}
											helperText={String(errors.counterId || '')}
											options={counters || []}
											defaultValue={values.counterId || null}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											getOptionLabel={(item) => item.officialName || ''}
											onChange={(_, item) => setFieldValue('counterId', item)}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<DatePicker
											disabled={isSubmitting}
											label={t('startDate')}
											error={!!errors.startDate}
											helperText={String(errors.startDate || '')}
											value={values.startDate}
											onChange={(value) => setFieldValue('startDate', value)}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<DatePicker
											disabled={isSubmitting}
											label={t('endDate')}
											error={!!errors.endDate}
											helperText={String(errors.endDate || '')}
											value={values.endDate}
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
										<DefaultButton
											title={t('search')}
											type="submit"
											loading={isSubmitting}
											startIcon={<Search />}
										/>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CommissionAdjustmentForm;
