import {
	CheckBox, DefaultButton, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Grid,
	Typography,
	Stack,
} from '@mui/material';
import { MessageReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SendMessageProps {
   user?: string;
   initialValues: MessageReq;
   validateForm: (values: MessageReq) => FormikErrors<MessageReq>;
   submitForm: (values: MessageReq, formikHelpers: FormikHelpers<MessageReq>) => void;
}

const SendMessagePage = (props: SendMessageProps) => {
	const {
		user,
		initialValues,
		validateForm,
		submitForm,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} lg={4} xl={4}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							onSubmit={(values, formikHelpers) => {
								submitForm(values, formikHelpers);
							}}
							validateOnBlur={false}
							validateOnChange={false}
							validateOnMount={false}
							validate={validateForm}
						>
							{({
								values,
								isSubmitting,
								errors,
								handleChange,
								setFieldValue,
							}) => (
								<Form autoComplete="off">
									<TextField label={t('recipient')} size="small" fullWidth value={user || ''} disabled autoComplete="none" />
									<Space height={2} />
									<TextField
										name="title"
										label={t('title')}
										value={values.title}
										onChange={handleChange}
										error={!!errors.title}
										helperText={errors.title}
									/>
									<Space height={2} />
									<TextField
										name="message"
										label={t('message')}
										onChange={handleChange}
										value={values.message}
										error={!!errors.message}
										helperText={errors.message}
										minRows={4}
										multiline
									/>
									<Space height={2} />
									<Stack justifyContent="space-between" direction="row">
										<CheckBox
											checked={values.pushNotification}
											disabled={isSubmitting}
											title={t('pushNotifications')}
											setChecked={() => { setFieldValue('pushNotification', !values.pushNotification); }}
										/>
										<CheckBox
											checked={values.email}
											disabled={isSubmitting}
											setChecked={() => { setFieldValue('email', !values.email); }}
											title={t('email')}
										/>
										<CheckBox
											checked={values.sms}
											disabled={isSubmitting}
											setChecked={() => { setFieldValue('sms', !values.sms); }}
											title={t('sms')}
										/>
									</Stack>
									{errors.pushNotification && (
										<Typography variant="caption" color="red">
											{errors.pushNotification}
										</Typography>
									)}
									<Space height={2} />
									<DefaultButton loading={isSubmitting} title={t('send')} type="submit" />
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default SendMessagePage;
