import {
	DatePicker, DefaultButton, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import { LegacyTollsReq } from '@Types';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProcessTollSearchProps {
	initialValues: LegacyTollsReq;
	handleSubmit: (values: LegacyTollsReq, formikHelpers: FormikHelpers<LegacyTollsReq>) => void
}

const ProcessTollSearchForm: FC<ProcessTollSearchProps> = (props) => {
	const { initialValues, handleSubmit } = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
					>
						{({
							values, handleChange, setFieldValue, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
										<TextField
											label={t('registration')}
											disabled={isSubmitting}
											type="string"
											helperText=""
											id="registration"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
										<TextField
											label={t('deviceNo')}
											disabled={isSubmitting}
											type="number"
											helperText=""
											id="deviceNo"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
										<DatePicker
											label={t('startDate')}
											disabled={isSubmitting}
											value={values.startDate}
											onChange={(value) => setFieldValue('startDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
										<DatePicker
											label={t('endDate')}
											disabled={isSubmitting}
											value={values.endDate}
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
										<DefaultButton
											title={t('search')}
											type="submit"
											loading={isSubmitting}
										/>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
				<Space height={3} />
			</Grid>
		</Grid>
	);
};

export default ProcessTollSearchForm;
