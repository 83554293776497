import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { invoiceApi } from '@Requests';
import { AppRoutes } from '@Routes';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ReceiptPage from './ReceiptPage';

const Receipt = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const navigate = useNavigate();
	const abortCtrl = new AbortController();

	const invoices = useRequest(([params]) => invoiceApi.getInvoiceById(params, abortCtrl.signal));

	useEffect(() => {
		setTitle(t('creditNotes'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		if (id) {
			invoices.execute(id);
		}
		return () => {
			abortCtrl.abort();
		};
	}, []);

	const handleButtonAction = (action: string) => {
		switch (action) {
		case t('backButton'):
			navigate(-1);
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: invoices.data?.invoice?.reservationId }));
			break;
		default:
			break;
		}
	};

	return (
		<ReceiptPage
			handleButtonAction={handleButtonAction}
			data={invoices.data}
			id={id}
			loading={invoices.loading}
		/>
	);
};

export default Receipt;
