import { useRequest } from '@Api';
import { reportApi } from '@Requests';
import { useTitleBar } from '@Providers';
import { PendingFinancialsReq } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import PendingFinancialForm from './PendingFinancialForm';
import PendingFinancialsPage from './PendingFinancialsPage';

const FinancialPending = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();

	const [initialValues] = useState({
		name: '',
		startDate: new Date(),
		endDate: new Date(),
		company: false,
		indivisual: false,
	});

	const getFinancials = useRequest(([params]) => reportApi.getPendingFinancials(params));

	useEffect(() => {
		setTitle(t('pendingTransactions'));
	}, [setTitle, t]);

	const handleListAction = (action: string, id: number) => {
		switch (action) {
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
			break;
		default:
			break;
		}
	};

	const searchReports = (values: PendingFinancialsReq) => {
		getFinancials.execute(values);
	};

	return (
		<>
			<PendingFinancialForm
				initialValues={initialValues}
				submit={searchReports}
			/>
			<PendingFinancialsPage
				values={getFinancials.data}
				handleListAction={handleListAction}
			/>
		</>
	);
};

export default FinancialPending;
