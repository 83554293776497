export const replaceCommaWithDecimal = (value: string) => {
	let val = value;
	val = val.replace('.', '');
	val = val.replace(',', '.');
	return val;
};
export const normalizeCurrency = (value: string) => {
	let val = value;
	val = val.replace('.', '');
	val = val.replace(',', '.');
	return val;
};

export const formatCurrency = (value?: number) => {
	let val = value?.toFixed(2);
	val = `${val || 0}`;
	if (val.includes('.')) {
		val = val.replace('.', ',');
	} else {
		val = val.concat(',00');
	}
	val = val.replace(/(?=(\d{3})+(\D))\B/g, '.');
	return val;
};

export const formatPercentage = (value?: number) => {
	let val = value?.toFixed(2);
	val = `${val || 0}`;
	if (val.includes('.')) {
		val = val.replace('.', ',');
		val += '%';
	} else {
		val = val.concat(',00%');
	}
	val = val.replace(/(?=(\d{3})+(\D))\B/g, '.');
	return val;
};
