import { DefaultButton, Dropdown, StatusLabel } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { AddCircle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Organization, Pagination, PaginationTable } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ListCounterProps {
   handleListAction: (action: string, item?: Organization) => void;
   setSearch: (searchQuery: string) => void;
   setPagination: (params: PaginationTable) => void;
   counters?: Pagination<Organization[]>
}

const ListCountersPage: FC<ListCounterProps> = (props) => {
	const {
		handleListAction, setSearch, setPagination, counters,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={counters?.data || []}
						onChange={(page, rowsPerPage) => {
							setPagination({ page, size: rowsPerPage });
						}}
						onSearching={(query) => setSearch(query)}
						pagination
						total={counters?.total}
						stripedRows
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('nif')} render={(item) => item.taxId} />
						<TableColumn headerText={t('name')} render={(item) => item.fantasyName} />
						<TableColumn headerText={t('email')} render={(item) => item.email} />
						<TableColumn headerText={t('telephone')} render={(item) => item.phone} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<Dropdown
									data={[t('detail'), t('changeStatus')]}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(action) => {
										handleListAction(action, item);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListCountersPage;
