import { useTitleBar } from '@Providers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TrackVehiclePage from './TrackVehiclePage';

const TrackVehicle = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();

	useEffect(() => {
		setTitle(t('trackingDetails'));
	}, [id, setTitle, t]);

	return (
		<TrackVehiclePage />
	);
};

export default TrackVehicle;
