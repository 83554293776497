import { useEffectCustom } from '@Hooks';
import { authApi } from '@Requests';
import { useFeedback } from '@Providers';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { NameAndId } from '@Types';
import { AppRoutes } from '@Routes';
import ResetPasswordPage from './ResetPasswordPage';

export interface InitialValues {
	password: string;
	confirmPassword: string
}

const ResetPassword = () => {
	const [initialValues] = useState({ password: '', confirmPassword: '' });
	const { t } = useTranslation('translations');
	const { token } = useParams();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [expired, setExpired] = useState(false);
	const [success, setSuccess] = useState(false);
	const [user, setUser] = useState<NameAndId | null>(null);

	useEffectCustom(() => {
		const checkToken = () => {
			if (token) {
				authApi.checkChangePassword(token)
					.then((res) => { setUser(res.data); })
					.catch(({ response }) => {
						addToast({ message: response.data.message, error: true });
						setExpired(true);
					});
			}
		};
		checkToken();
	}, []);

	const validate = (values: InitialValues) => {
		const errors = {} as FormikErrors<InitialValues>;
		if (!values.password) {
			errors.password = t('emptyField');
		}
		if (!(/[a-zA-Z]/.test(values.password))) {
			errors.password = t('passwordAplhabets');
		}

		if (!(/[0-9]/.test(values.password))) {
			errors.password = t('passwordNumber');
		}

		if (!(/[^a-zA-Z0-9]/.test(values.password))) {
			errors.password = t('passwordSpecial');
		}
		if (values.password.length < 8) {
			errors.password = t('passwordLength');
		}
		if (!values.confirmPassword) {
			errors.confirmPassword = t('emptyField');
			if (values.confirmPassword.length < 8) {
				errors.confirmPassword = t('passwordLength');
			}
		}
		if (values.password !== values.confirmPassword) {
			errors.confirmPassword = t('passwordsMatch');
		}
		return errors;
	};

	const submit = (values: InitialValues, formikHelpers: FormikHelpers<InitialValues>) => {
		authApi.resetPassword({ token: token ?? '', newPassword: values.password })
			.then((res) => {
				addToast({
					message: res.message,
					error: false,
				});
				setSuccess(true);
				navigate(AppRoutes.LOGIN);
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	return (
		<ResetPasswordPage
			initialValues={initialValues}
			validate={validate}
			user={user}
			expired={expired}
			success={success}
			submit={submit}
		/>
	);
};

export default ResetPassword;
