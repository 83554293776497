import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import moment from 'moment';

interface DateLabelProps {
	date: string | undefined;
	showTime?: boolean;
}

const DateLabel: FC<DateLabelProps> = (props) => {
	const { date, showTime } = props;
	return (
		<Box>
			<Typography sx={{ fontSize: 13 }}>{date ? moment(date).format('DD/MM/YYYY') : ''}</Typography>
			{showTime && (
				<Typography sx={{ fontSize: 10 }}>{date ? moment(date).format('HH:MM:SS') : ''}</Typography>
			)}
		</Box>
	);
};

export default DateLabel;
