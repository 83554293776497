import {
	DefaultButton, Space, TextField, RadioButton,
} from '@Atoms';
import { putDecimalPoints } from '@Masks';
import {
	Check, Close, Delete, Refresh,
} from '@mui/icons-material';
import {
	Alert,
	Box, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { InvoiceItem } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CreditProps {
   handleAction: (action: string) => void;
   items: {items?: InvoiceItem[], total?: number};
   onRemove: (item: InvoiceItem) => void;
   onChange: (key: string, value: string) => void;
   handleCreditNoteCountChange: (
	item: InvoiceItem, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
   loading?: boolean;
   error: string
}

const RADIO_VALUES = {
	CANCELLING: 'CANCELLING',
	FIXING: 'FIXING',
};

const CreditNoteItem: FC<CreditProps> = ({
	handleAction, items, onRemove, onChange, handleCreditNoteCountChange, loading, error,
}) => {
	const { t } = useTranslation('translations');

	if (items.items?.length === 0) {
		return (
			<>
				<DialogTitle>
					<Stack direction="row" justifyContent="space-between">
						<Typography>
							{t('itemsToBill')}
						</Typography>
						<Close
							onClick={() => { handleAction(t('cancel')); }}
						/>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Alert icon={false} severity="success">
						{t('noItems')}
					</Alert>
				</DialogContent>
				<DialogActions>
					<DefaultButton
						color="info"
						title={t('cancel')}
						onClick={() => { handleAction(t('cancel')); }}
					/>

					<DefaultButton
						startIcon={<Refresh />}
						title={t('refresh')}
						onClick={() => handleAction(t('refresh'))}
					/>
				</DialogActions>
			</>
		);
	}
	return (
		<>
			<DialogTitle>
				<Stack direction="row" justifyContent="space-between">
					<Typography>{t('itemsToBill')}</Typography>
					<IconButton
						sx={{ m: 0, p: 0 }}
						onClick={() => {
							handleAction(t('cancel'));
						}}
					>
						<Close />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				{error && (
					<Alert icon={false} severity="error">
						{error}
					</Alert>
				)}
				<Typography>{t('noteBehaviour')}</Typography>
				<RadioButton
					options={[
						{ label: t('cancellation'), value: RADIO_VALUES.CANCELLING },
						{ label: t('correction'), value: RADIO_VALUES.FIXING },
					]}
					subText={[t('cancelSubMessage'), t('correctionSub')]}
					onChange={(value) => onChange('type', value)}
				/>
				<Space height={2} />
				<Divider />
				<Space height={2} />
				<Typography>{t('itemsToBill')}</Typography>
				<DataTable data={items.items || []} search={false}>
					<TableColumn
						headerText={t('action')}
						render={(item) => (
							<IconButton
								onClick={() => {
									onRemove(item);
								}}
								color="error"
							>
								<Delete />
							</IconButton>
						)}
					/>
					<TableColumn headerText={t('item')} render={(item) => item.name} />
					<TableColumn headerText={t('value')} render={(item) => putDecimalPoints((item.itemValue - (item.quantityDiscount / item.quantity)))} />
					<TableColumn headerText={t('discount')} render={(item) => putDecimalPoints(item.discount)} />
					<TableColumn headerText={t('vat')} render={(item) => `${item.itemTax}%`} />
					<TableColumn
						headerText={t('amount')}
						render={(item) => (
							<TextField
								disabled={false}
								title=""
								value={item.quantity}
								id="quantity"
								helperText=""
								type="number"
								onChange={(event) => {
									if (parseInt(event.target.value, 10) < 1) return null;
									handleCreditNoteCountChange(item, event);
									return null;
								}}
							/>
						)}
					/>
				</DataTable>
				<Space height={2} />
				<Typography>
					{t('refund')}
					:
					{' '}
					{putDecimalPoints(items.total || 0)}
					{' '}
					€
				</Typography>
				<Space height={2} />
				<TextField
					multiline
					minRows={3}
					label={t('motive')}
					onChange={(e) => onChange('reason', e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Box>
					<DefaultButton
						color="info"
						title={t('cancel')}
						onClick={() => {
							handleAction(t('cancel'));
						}}
					/>
					<DefaultButton
						startIcon={<Check />}
						sx={{ ml: 1 }}
						title={t('confirm')}
						onClick={() => handleAction(t('confirm'))}
						loading={loading}
					/>
				</Box>
			</DialogActions>

		</>
	);
};

export default CreditNoteItem;
