import { useRequest } from '@Api';
import { Space } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import { Divider } from '@mui/material';
import { useSetting, useTitleBar } from '@Providers';
import { dashboardApi } from '@Requests';
import { AppRoutes } from '@Routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DayMovements from './DayMovements';
import DelayedDeliveries from './DelayedDeliveries';
import NotGenInvoices from './NotGenInvoices';
import ParkedCars from './ParkedCars';
import PendingReservations from './PendingReservations';

enum RESERVATION_TYPE {
	REGULAR=1,
	WAYBILL=2
}

const Dashboard = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { counter } = useSetting();

	const pendingReservation = useRequest(() => dashboardApi.getPendingReservations());
	const parkedVehicles = useRequest(() => dashboardApi.getParkedVehicles());
	const delayedDeliveries = useRequest(() => dashboardApi.getDelayedDeliveries());
	const pendingInvoices = useRequest(() => dashboardApi.getPendingInvoices());
	const daymovements = useRequest(([param1, param2]) => (
		dashboardApi.getDayMovements(param1, param2)));

	const [selectedDate, setSelectedDate] = useState(new Date());

	useEffect(() => {
		setTitle(t('dashboard'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = async () => {
			Promise.all([
				pendingReservation.execute(),
				parkedVehicles.execute(),
				delayedDeliveries.execute(),
				pendingInvoices.execute(),
				daymovements.execute(selectedDate, counter),
			]);
		};
		getData();
	}, []);

	const handleButtonAction = (action: string, id: number, typeId?: number) => {
		switch (action) {
		case t('reservation'):
			if (typeId === RESERVATION_TYPE.REGULAR) {
				navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
			}
			if (typeId === RESERVATION_TYPE.WAYBILL) {
				navigate(AppRoutes.EDIT_TRANSPORT_GUIDE.formatMap({ id }));
			}
			break;
		case t('registration'):
			navigate(AppRoutes.EDIT_VEHICLE.formatMap({ id }));
			break;
		case t('enrollmentModel'):
			navigate(AppRoutes.EDIT_VEHICLE.formatMap({ id }));
			break;
		case t('invoice'):
			navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: id }));
			break;
		default:
			break;
		}
	};

	const handleActionClick = (action: string, id?: number) => {
		switch (action) {
		case t('view'):
			daymovements.execute(selectedDate, counter);
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
			break;
		case t('wayBill'):
			navigate(AppRoutes.EDIT_TRANSPORT_GUIDE.formatMap({ id }));
			break;
		default:
			break;
		}
	};

	return (
		<>
			<PendingReservations
				pendingRes={pendingReservation.data}
				handleClick={handleButtonAction}
			/>
			<Space height={3} />
			<ParkedCars
				data={parkedVehicles.data}
				onCarPlatePress={handleButtonAction}
			/>
			<Space height={3} />
			<DelayedDeliveries
				data={delayedDeliveries.data}
				onButtonClick={handleButtonAction}
			/>
			<Space height={3} />
			<NotGenInvoices
				data={pendingInvoices.data}
				handleClick={handleButtonAction}
			/>
			<Space height={3} />
			<Divider />
			<Space height={3} />
			<DayMovements
				dayMovements={daymovements.data}
				date={selectedDate}
				setSelectedDate={setSelectedDate}
				handleActionClick={handleActionClick}
				loading={daymovements.loading}
			/>
		</>
	);
};

export default Dashboard;
