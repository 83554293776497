/* eslint-disable max-len */
import {
	ApiResponse,
	Pagination,
	CommissionAdjustment,
	DivergentCategory,
	Financial,
	MaintenanceReport,
	TollReport,
	UngeneratedInvoice,
	PendingFinancialsReq,
	MaintenanceReportReq,
	TollReportReq,
	CommissionReq,
	CommissionResponse,
	Organization,
	CommissionAdjustmentReq,
	ListDataControl,
	InvoiceReportReq,
	NameAndId,
	Invoice,
} from '@Types';
import { removeComma } from '@Helpers';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

export async function getDivergentCategories(
	startDate: Date,
	endDate: Date,
	page: number,
	size: number,
	search: string,
): Promise<ApiResponse<Pagination<DivergentCategory[]>>> {
	const resp = await reqGet({
		url: Endpoint.REPORTS_DIVERGANT_CATEGORIES.format(startDate.toISOString(), endDate.toISOString(), search, page, size),
	});

	const { data } = resp;
	return data;
}
export async function getUngeneratedInvoices(
	page: number,
	size: number,
	counters: Organization[],
	search: string,
): Promise<ApiResponse<UngeneratedInvoice[]>> {
	let query = '';
	if (counters.length > 0) {
		query = `${counters.map((el) => `OrganizationId=${el.id}`)}`;
		query = query.replaceAll(',', '');
	}

	const resp = await reqGet({
		url: Endpoint.UNGENERATED_INVOICES.format(page, size, search, query),
	});

	const data = resp.data as ApiResponse<UngeneratedInvoice[]>;
	return data;
}
export async function getUngeneratedInvoicesReport(
	listDataControl: ListDataControl,
	counters: Organization[],
): Promise<ApiResponse<Pagination<UngeneratedInvoice[]>>> {
	const { page, size, search } = listDataControl;
	let query = '';
	if (counters.length > 0) {
		query = `${counters.map((el) => `&OrganizationId=${el.id}`)}`;
		query = query.replaceAll(',', '');
	}

	const resp = await reqGet({
		url: Endpoint.REPORTS_UNGENERATED_INVOICES.format(page, size, search, query),
	});

	const data = resp.data as ApiResponse<Pagination<UngeneratedInvoice[]>>;
	return data;
}

export async function getCommissionReport(params: CommissionReq): Promise<ApiResponse<CommissionResponse[]>> {
	const pickupQuery = removeComma(`?${params.pickups.map((el) => `Pickups=${el.id}&`)}`);
	const tariffQuery = removeComma(`${params.tariffs.map((el) => `Tariffs=${el.id}&`)}`);
	const date = `StartDate=${params.startDate.toISOString()}&EndDate=${params.endDate.toISOString()}`;
	const resp = await reqGet({
		url: Endpoint.REPORTS_COMMISSION.format(pickupQuery, tariffQuery, date),
	});
	const { data } = resp;
	return data;
}

export async function getCommissionAdjustmentReport(
	params: CommissionAdjustmentReq,
	search: string,
): Promise<ApiResponse<CommissionAdjustment>> {
	const encodedStartDate = params.startDate.toISOString();
	const encodedEndDate = params.endDate.toISOString();

	const resp = await reqGet({
		url: Endpoint.REPORT_COMMISION_ADJUSTMENT.format(encodedStartDate, encodedEndDate, params?.counterId?.id, search),
	});

	const { data } = resp;
	return data;
}

export async function getTollReports(params: TollReportReq, listDataControl: ListDataControl): Promise<ApiResponse<Pagination<TollReport[]>>> {
	const startDateForApi = params.startDate.toISOString();
	const endDateForApi = params.endDate.toISOString();
	const typeQuery = `${params.types.map((el) => `&Types=${el.id}`)}`.replaceAll(',', '');
	const resp = await reqGet({
		url: Endpoint.REPORT_TOLL.format(startDateForApi, endDateForApi, typeQuery, listDataControl.page, listDataControl.size, listDataControl.search),
	});
	const { data } = resp;
	return data;
}

export async function getMaintenanceReport(params?: MaintenanceReportReq): Promise<ApiResponse<MaintenanceReport>> {
	const startDate = params?.StartDate ? encodeURIComponent(params?.StartDate.toISOString()) : '';
	const endDate = params?.EndDate ? encodeURIComponent(params?.EndDate.toISOString()) : '';
	const resp = await reqGet({
		url: Endpoint.REPORT_MAINTENANCE.format(params?.CarId.id, startDate, endDate, params?.Page, params?.Size, params?.Search),
	});
	const { data } = resp;
	return data;
}

export async function getInvoices(params: ListDataControl, invoiceData?: InvoiceReportReq): Promise<ApiResponse<Pagination<Invoice[]>>> {
	const paginationQuery = `?Page=${params.page}&Size=${params.size}&Search=${params.search}`;
	let query = '';
	if (invoiceData) {
		const dataToUpload = {
			...invoiceData,
			startDate: invoiceData.startDate ? invoiceData.startDate.toISOString() : '',
			endDate: invoiceData.endDate ? invoiceData.endDate.toISOString() : '',
		};
		const pickUpQuery = `${dataToUpload.Pickups.map((el) => `Pickups=${el.id}`)}`.replaceAll(',', '');
		query = `&TaxId=${dataToUpload.nifId}&Serial=${dataToUpload.series}&Year=${dataToUpload.year}&ReservationId=${dataToUpload.reservation}&StartDate=${dataToUpload.startDate}&EndDate=${dataToUpload.endDate}&${pickUpQuery}&Types=${dataToUpload.documentType.id}`;
	}
	const resp = await reqGet({
		url: Endpoint.REPORT_INVOICES.format(paginationQuery, query),
	});
	const { data } = resp;
	return data;
}

export async function getPendingFinancials(payload: PendingFinancialsReq): Promise<ApiResponse<Financial[]>> {
	let startDate = '';
	let endDate = '';
	let company = '';
	let indivisual = '';
	if (payload.startDate) {
		startDate = payload.startDate.toISOString();
	}
	if (payload.endDate) {
		endDate = payload.endDate.toISOString();
	}
	if (payload.company) {
		company = '2';
	}
	if (payload.indivisual) {
		indivisual = '1';
	}

	const resp = await reqGet({
		url: Endpoint.REPORT_PENDING_FINANCIALS.format(startDate, endDate, payload.name, company, indivisual),
	});
	const { data } = resp;
	return data;
}

export async function getDocumentTypes(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.DOCUMENT_TYPES,
	});
	const data = resp.data as ApiResponse<NameAndId[]>;
	return data;
}
