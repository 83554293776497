/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { waybillApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Waybill } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListWaybillPage from './ListWaybillPage';

const ListWaybills = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();

	const waybills = useRequest(([params]) => (
		waybillApi.getWayBills(params)
	));

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	useEffect(() => {
		setTitle(t('wayBills'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			waybills.execute(listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	function base64toBlob(base64Data: string) {
		const sliceSize = 1024;
		const byteCharacters = atob(base64Data);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);

		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);

			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: 'application/pdf' });
	}

	const getContract = (id?: number) => {
		setGlobalLoading(true);
		waybillApi
			.getWaybillContract(id)
			.then((res) => {
				const blob = base64toBlob(res.data);
				if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
					(window.navigator as any).msSaveOrOpenBlob(blob, 'pdfBase64.pdf');
				} else {
					const blobUrl = URL.createObjectURL(blob);
					window.open(blobUrl);
				}
			})
			.catch(({ response }) => { addToast({ message: response.data.message, error: true }); })
			.finally(() => setGlobalLoading(false));
	};

	const handleDropdownClick = (name: string, item?: Waybill) => {
		switch (name) {
		case t('new'):
			navigate(AppRoutes.ADD_NEW_TRANSPORT_GUIDE);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_TRANSPORT_GUIDE.formatMap({ id: item?.id }));
			break;
		case t('print'):
			getContract(item?.id);
			break;
		default:
			break;
		}
	};
	return (
		<ListWaybillPage
			waybills={waybills.data}
			setSearch={setSearch}
			setPagination={setPagination}
			handleListAction={handleDropdownClick}
		/>
	);
};

export default ListWaybills;
