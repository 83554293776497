/* eslint-disable no-console */
import { DateLabel, DefaultButton } from '@Atoms';
import { formatCurrency } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { TouchApp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { CardContainer, DataTable, TableColumn } from '@Organisms';
import { Receipt } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ReceiptCardProps {
	handleCreateClick: () => void;
	receipts?: Receipt[];
	total: number;
	handleReceiptAction: (action: string, item: Receipt) => void;
	handleImportClick: () => void;
	importLoading?: boolean
}

const ReceiptCard: FC<ReceiptCardProps> = (props) => {
	const {
		handleCreateClick, receipts, total, handleReceiptAction, handleImportClick, importLoading,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<CardContainer
			title={t('receipts')}
			priceText={`${t('received')} ${formatCurrency(total)} €`}
			render={() => {
				if (receipts?.length === 0 || !receipts) { return <Typography>{t('receiptDescription')}</Typography>; }
				return (
					<DataTable search={false} data={receipts || []}>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.dueDate} showTime />} />
						<TableColumn headerText={t('value')} render={(item) => `${putDecimalPoints(item.amount)} €`} />
						<TableColumn
							render={(item) => (!item.externalId ? (
								<DefaultButton
									title={t('generate')}
									variant="outlined"
									startIcon={<TouchApp />}
									onClick={() => {
										handleReceiptAction(t('generate'), item);
									}}
								/>
							) : (
								<DefaultButton
									title={t('openInvoice')}
									onClick={() => {
										handleReceiptAction(t('openInvoice'), item);
									}}
								/>
							))}
						/>
					</DataTable>
				);
			}}
			handleButtonClick={handleCreateClick}
			showImport
			handleImportClick={handleImportClick}
			importLoading={importLoading}
		/>
	);
};

export default ReceiptCard;
