import {
	DateLabel, DefaultButton, Space, TableItem,
} from '@Atoms';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import {
	Box,
	Divider,
	Grid,
	Typography,
	useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Commission, CommissionResponse } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CommissionReportProps {
	commissions?: CommissionResponse[];
	handleListAction: (action: string, id: number, invoiceId?: number, identifier?: string) => void;
	returnCommission: (commission: Commission) => React.ReactNode
}

const CommissionReportPage: FC<CommissionReportProps> = (props) => {
	const { commissions, handleListAction, returnCommission } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>

				{commissions?.map((el) => (
					<Box sx={{ mt: 3 }} key={el.organizationName}>
						<PaperContainer>
							<Typography variant="h6" color="textSecondary">{el.organizationName}</Typography>
							<Space height={1} />
							<DataTable data={el.commissions || []} search={false} shadedHead>
								<TableColumn
									headerOptions={{}}
									headerText={t('reservation')}
									footer=""
									render={(item) => (
										<DefaultButton
											variant="text"
											onClick={() => handleListAction(t('reservation'), item.reservationId)}
											title={item.reservationId}
										/>
									)}
								/>
								<TableColumn
									headerOptions={{}}
									headerText={t('date')}
									render={(item) => <DateLabel date={item.registration} showTime />}
									footer={<TableItem title={t('total')} color="red" />}
								/>
								<TableColumn
									headerOptions={{}}
									headerText={t('grossValue')}
									render={(item) => putDecimalPoints(item.netAmount)}
									footer={<TableItem title={putDecimalPoints(el.totalGrossAmount)} color="red" />}
								/>
								<TableColumn
									headerText={t('discount')}
									render={(item) => (
										<TableItem
											title={`${(((item.discount + item.itemsDiscount) * 100) / item.netAmount).toFixed(2)}%`}
											subtitle={`${putDecimalPoints(item.discount + item.itemsDiscount)}€`}
										/>
									)}
									footer={<TableItem title={putDecimalPoints(el.totalDiscount)} color="red" />}
								/>
								<TableColumn
									headerOptions={{}}
									headerText={t('netCash')}
									render={(item) => (
										putDecimalPoints((item.netAmount - (item.discount + item.itemsDiscount))
											.toFixed(2))
									)}
									footer={(
										<TableItem
											title={putDecimalPoints(el.totalNetAmount)}
											color={theme.palette.success.dark}
										/>
									)}
								/>
								<TableColumn
									headerText={t('taxCommission')}
									render={(item) => returnCommission(item)}
									footer={<TableItem title={putDecimalPoints(el.totalCommissions)} color="red" />}
								/>
								<TableColumn
									headerOptions={{}}
									headerText={t('vat')}
									render={(item) => putDecimalPoints(item.iva)}
									footer={<TableItem title={putDecimalPoints(el.totalIVA)} color="red" />}
								/>
								<TableColumn
									headerOptions={{}}
									headerText={t('total')}
									render={(item) => putDecimalPoints(item.amount)}
									footer={<TableItem title={putDecimalPoints(el.totalAmount)} color="red" />}
								/>
								<TableColumn
									headerOptions={{}}
									headerText={t('tolls')}
									render={(item) => putDecimalPoints(item.tollAmount)}
									footer={(
										<TableItem
											title={putDecimalPoints(el.totalTollAmount)}
											color={theme.palette.success.dark}
										/>
									)}
								/>
								<TableColumn
									headerOptions={{}}
									headerText={`${t('employee')} / ${t('costumer')}`}
									render={(item) => (
										<TableItem
											title={item.sellerName}
											subtitle={item.clientName}
										/>
									)}
								/>
								<TableColumn headerOptions={{}} headerText={t('paymentMethod')} render={(item) => item.paymentMethod} />
								<TableColumn
									headerOptions={{}}
									headerText={t('invoice')}
									render={(item) => (
										item.fullIdentifier && (
											<DefaultButton
												title={item.fullIdentifier}
												onClick={() => handleListAction(t('invoice'), item.reservationId, item.invoiceId, item.fullIdentifier)}
											/>
										)
									)}
								/>
							</DataTable>
							<Space height={3} />
							<Divider />
							<Space height={3} />
							<Typography variant="h5">
								{t('commissionableVal')}
								: €
								{' '}
								{putDecimalPoints(el.totalNetAmount - el.totalTollAmount)}
								{' '}
								(
								{t('netCash')}
								{' '}
								-
								{' '}
								{t('tolls')}
								)
							</Typography>
							<Typography variant="h5">
								{t('idealCommission')}
								: €
								{' '}
								{putDecimalPoints(el.totalIdealCommissions.toFixed(2))}
							</Typography>
							<Typography variant="h5">
								{t('actualCommission')}
								: €
								{' '}
								{putDecimalPoints(el.totalCommissions.toFixed(2))}
							</Typography>
							<Typography variant="caption">
								*
								{' '}
								{t('commissionNotIncludeVat')}
								.
							</Typography>
							<Typography variant="caption">
								*
								{' '}
								{t('tollColumnNotIncludeVat')}
								.
							</Typography>
							<Space height={3} />
							<Divider />
							<Space height={3} />
						</PaperContainer>
					</Box>
				))}

			</Grid>
		</Grid>
	);
};

export default CommissionReportPage;
