import { LoginReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '@Validation';
import { authApi } from '@Requests';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { useAuth, useFeedback, useSetting } from '@Providers';
import LoginPage from './LoginPage';

const Login = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setCurrentUser } = useAuth();
	const { addDialog } = useFeedback();
	const { uniqueHash } = useSetting();

	const initialValue = {
		email: '',
		password: '',
	};

	const validate = (values: LoginReq): FormikErrors<LoginReq> => {
		const errors = {} as FormikErrors<LoginReq>;
		if (!values.email) {
			errors.email = t('emptyField');
		} else if (!validateEmail(values.email)) {
			errors.email = t('validEmail');
		}
		if (!values.password) {
			errors.password = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (props: LoginReq, helpers: FormikHelpers<LoginReq>) => {
		authApi.loginUser({ ...props, deviceUniqueId: uniqueHash })
			.then((res) => {
				setCurrentUser(res.data);
				navigate(AppRoutes.DASHBOARD);
			})
			.catch(({ response }) => {
				addDialog({ title: response.data.title, message: response.data.message, error: true });
			})
			.finally(() => {
				helpers.setSubmitting(false);
			});
	};

	const forgotPassword = () => {
		navigate(AppRoutes.FORGOT_PASSWORD);
	};

	return (
		<LoginPage
			initialValues={initialValue}
			handleSubmit={handleSubmit}
			validate={validate}
			forgotPassword={forgotPassword}
		/>
	);
};

export default Login;
