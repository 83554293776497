import { useRequest } from '@Api';
import { Space } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { carsApi } from '@Requests';
import { AppRoutes } from '@Routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MaintenanceAlert from './MaintenanceAlert';
import VehicleDetailPage from './VehicleDetailPage';

const VehicleDetail = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const navigate = useNavigate();

	const car = useRequest(() => carsApi.getCarByID(id));
	const recentReservations = useRequest(() => carsApi.getCarRecentReservations(id));
	const searchResByDate = useRequest(([params]) => carsApi.carReservationByDate(params, id ?? ''));
	const freePeriods = useRequest(() => carsApi.freePeriods(id));
	const carRevision = useRequest(([param1]) => carsApi.getCarRevisions(param1));

	const [date, setDate] = useState({ date: new Date(), time: new Date() });
	const [dateError, setDateError] = useState('');

	useEffect(() => {
		setTitle(t('vehicleDetail'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			car.execute();
			recentReservations.execute();
			freePeriods.execute();
			carRevision.execute(id);
		};
		let unsubscribe = false;
		if (!unsubscribe) {
			getData();
		}
		return () => {
			unsubscribe = false;
		};
	}, []);

	const onReservationDetailClick = (reservationId: number) => {
		navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
	};

	const onChange = (key: string, value: Date) => {
		setDateError('');
		setDate({ ...date, [key]: value });
	};

	const onReservationSearch = () => {
		if (!date.date) {
			return setDateError(t('emptyField'));
		}
		searchResByDate.execute(date);
		return null;
	};

	const handleListAction = (action: string, reservationId?: number) => {
		switch (action) {
		case t('fines'):
			navigate(AppRoutes.VEHICLE_FINES_ID.formatMap({ id }));
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_VEHICLE.formatMap({ id }));
			break;
		case t('maintenance'):
			navigate(AppRoutes.MAINTENANCE_VEHICLE.formatMap({ id }));
			break;
		case t('latestBooking'):
			navigate(AppRoutes.LIST_CAR_RESERVATIONS.formatMap({ carId: id }));
			break;
		case t('details'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id: reservationId }));
			break;
		default:
			break;
		}
	};

	return (
		<>
			<MaintenanceAlert revision={carRevision.data} />
			<Space height={3} />
			<VehicleDetailPage
				car={car.data}
				handleListAction={handleListAction}
				carReservations={recentReservations.data}
				onChange={onChange}
				onReservationSearch={onReservationSearch}
				reservation={searchResByDate?.data && searchResByDate.data[0]}
				loading={searchResByDate.loading}
				error={dateError}
				onResClick={onReservationDetailClick}
				date={date}
				freePeriods={freePeriods.data}
				reservationError={searchResByDate.data && searchResByDate.data?.length === 0}
			/>
		</>
	);
};

export default VehicleDetail;
