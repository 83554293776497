import { PaperContainer } from '@Molecules';
import {
	DefaultButton, Space, TextField, Autocomplete,
} from '@Atoms';
import { Divider, Grid } from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TariffReq } from '@Types';
import { useDropdown } from '@Providers';
import { Edit } from '@mui/icons-material';

interface CreateTariffPage {
   initialValues: TariffReq;
   validate: (values: TariffReq) => FormikErrors<TariffReq>;
   handleSubmit: (values: TariffReq, formikHelpers: FormikHelpers<TariffReq>) => void;
   isEdit: boolean;
   handleEditClick: () => void
}

const CORPORATE_TYPE = 2;

const CreateTerrifPage: FC<CreateTariffPage> = (props) => {
	const {
		initialValues, validate, handleSubmit, isEdit, handleEditClick,
	} = props;
	const { companies, groupTypes } = useDropdown();
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12} />
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						enableReinitialize
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values,
							errors,
							handleChange,
							isSubmitting,
							setFieldValue,
							touched,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
										<TextField
											id="name"
											label={t('name')}
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											value={values.name}
											disabled={isSubmitting || !isEdit}
											type="text"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
										<Autocomplete
											label={t('company')}
											placeholder=""
											options={companies || []}
											disabled={isSubmitting || !isEdit || values.typeId?.id === 2}
											multiple
											defaultValue={values.organizations}
											isOptionEqualToValue={(value, option) => value.id === option.id}
											error={touched.organizations && Boolean(errors.organizations)}
											helperText={touched.organizations && String(errors.organizations || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>{item.officialName}</li>
											)}
											getOptionLabel={(item) => item.officialName || ''}
											onChange={(_, item) => {
												setFieldValue('organizations', item);
											}}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
										<Autocomplete
											label={t('type')}
											placeholder=""
											disabled={isSubmitting || !isEdit}
											error={touched.typeId && Boolean(errors.typeId)}
											helperText={touched.typeId && String(errors.typeId || '')}
											options={groupTypes || []}
											defaultValue={values.typeId}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>{item.name}</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => {
												setFieldValue('typeId', item);
												if (item?.id === CORPORATE_TYPE) {
													setFieldValue('organizations', []);
												}
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
										<TextField
											id="description"
											label={t('description')}
											error={touched.description && Boolean(errors.description)}
											helperText={touched.description && errors.description}
											title=""
											value={values.description}
											disabled={isSubmitting || !isEdit}
											type="text"
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
										<TextField
											id="days"
											label={t('creditDays')}
											error={touched.days && Boolean(errors.days)}
											helperText={touched.days && errors.days}
											title=""
											value={values.days}
											disabled={isSubmitting || !isEdit}
											type="number"
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
										<TextField
											id="commissionRate"
											label={t('commissionRate')}
											error={touched.commissionRate && Boolean(errors.commissionRate)}
											helperText={touched.commissionRate && errors.commissionRate}
											title=""
											value={values.commissionRate}
											disabled={isSubmitting || !isEdit}
											type="number"
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && <DefaultButton title={t('save')} type="submit" loading={isSubmitting} />}
								{!isEdit && <DefaultButton onClick={handleEditClick} title={t('edit')} startIcon={<Edit />} />}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateTerrifPage;
