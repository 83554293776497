import { Space } from '@Atoms';
import { formatDateOnly } from '@Helpers';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Box, Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { MaintenanceReport, PaginationTable } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface MaintenanceCostPage {
	data?: MaintenanceReport;
	setPagination: (params: PaginationTable) => void;
	setSearch: (searchQuery: string) => void
}

const MaintenanceCostPage: FC<MaintenanceCostPage> = (props) => {
	const { data, setPagination, setSearch } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<DataTable
						data={data?.maintenances?.data || []}
						total={data?.maintenances?.total}
						search={false}
						onChange={(page, size) => setPagination({ page, size })}
						onSearching={(query) => setSearch(query)}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('local')} render={(item) => item.local} />
						<TableColumn headerText={t('entry')} render={(item) => formatDateOnly(item.startDate)} />
						<TableColumn headerText={t('exit')} render={(item) => formatDateOnly(item.endDate)} />
						<TableColumn headerText={t('value')} render={(item) => putDecimalPoints(item.totalAmount)} />
					</DataTable>
					<Space height={3} />
					<Box sx={{
						backgroundColor: theme.palette.error.main,
						p: 1,
						color: theme.palette.background.default,
						fontWeight: 'bolder',
					}}
					>
						{`${t('totalMaintValue')}: € ${putDecimalPoints(data?.amount)}`}
					</Box>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default MaintenanceCostPage;
