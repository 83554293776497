import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { normalizeCurrency } from '@Masks';
import { useFeedback, useTitleBar } from '@Providers';
import { maintenanceApi } from '@Requests';
import { RemoveMaintenanceReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import RemoveFromMaintenancePage from './RemoveFromMaintenancePage';

const RemoveFromMaintenance = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const { id } = useParams();
	const navigate = useNavigate();

	const getMaintenanceDetails = useRequest(([params]) => (
		maintenanceApi.getMaintenanceInfoById(params)
	));

	const [initialValues] = useState({
		description: '',
		amount: '',
		endDate: new Date(),
	});

	useEffect(() => {
		setTitle(t('removeFUll'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			getMaintenanceDetails.execute(id ?? '');
		};
		if (id) {
			getData();
		}
	}, [id]);

	const validate = (values: RemoveMaintenanceReq) => {
		const errors = {} as FormikErrors<RemoveMaintenanceReq>;
		const amount = normalizeCurrency(values.amount);
		if (!values.description) {
			errors.description = t('emptyField');
		}
		if (!amount || amount === 0) {
			errors.amount = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		if (getMaintenanceDetails.data?.startDate) {
			if ((new Date(getMaintenanceDetails.data?.startDate) > values.endDate)) {
				errors.endDate = t('intendedDateError');
			}
		}
		return errors;
	};

	const removeFromMaintenance = (
		values: RemoveMaintenanceReq,
		formikHelpers: FormikHelpers<RemoveMaintenanceReq>,
	) => {
		maintenanceApi.removeFromMaintenance(id ?? '', values)
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(-1);
			})
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => formikHelpers.setSubmitting(true));
	};

	return (
		<RemoveFromMaintenancePage
			initialValues={initialValues}
			data={getMaintenanceDetails.data}
			validate={validate}
			handleSubmit={removeFromMaintenance}
		/>
	);
};

export default RemoveFromMaintenance;
