import {
	Box,
	createTheme,
	CssBaseline, PaletteMode, Theme, ThemeProvider, useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { AppRouter } from '@Routes';
import {} from './utils/extensions/string.extensions';
import '@Localization';
import {
	AuthProvider, CacheProvider, DropdownProvider, SettingProvider, TitleBarProvider, useLanguage,
} from '@Providers';
import { ErrorDailog, Toast } from '@Organisms';
import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import { getDesignTokens } from '@Theme';
import { withContext, LoadingMessage } from '@Hocs';
import { useTranslation } from 'react-i18next';
import { useEffectCustom } from '@Hooks';

const App = () => {
	const [theme, setTheme] = React.useState<Theme>();
	const [mode, setMode] = React.useState<PaletteMode>();
	const { locale } = useLanguage();
	const { i18n } = useTranslation();

	const toggleColorMode = () => {
		setMode((prevMode) => {
			const nMode = prevMode === 'light' ? 'dark' : 'light';
			LocalStorageWrapper.set(LocalStorageKeys.THEME, nMode);
			return nMode;
		});
	};

	const isPrefsDark = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		let initialMode = LocalStorageWrapper.get(LocalStorageKeys.THEME) as PaletteMode;
		if (!initialMode) {
			initialMode = isPrefsDark ? 'dark' : 'light';
		}
		setMode(initialMode);
	}, [isPrefsDark]);

	useEffectCustom(() => {
		const lang = i18n.language;
		document.documentElement.lang = lang;
		getDesignTokens(mode ?? 'light').then((th) => {
			setTheme(createTheme(th, locale));
		});
	}, [mode, locale]);

	return (
		<Box>
			{!theme ? <LoadingMessage /> : (
				<TitleBarProvider>
					<ThemeProvider theme={theme}>
						<SettingProvider updateUIMode={toggleColorMode}>
							<Toast />
							<ErrorDailog />
							<AuthProvider>
								<CacheProvider>
									<DropdownProvider>
										<AppRouter />
										<CssBaseline />
									</DropdownProvider>
								</CacheProvider>
							</AuthProvider>
						</SettingProvider>
					</ThemeProvider>
				</TitleBarProvider>
			)}
		</Box>

	);
};

export default withContext(App);
