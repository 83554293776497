import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { adminApi } from '@Requests';
import { Administrator } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListAdministratorsPage from './ListAdministratorsPage';

const ListAdmins = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const [search, setSearch] = useState('');

	const admins = useRequest(([params]) => adminApi.getAdministrators(params));
	const changeStatus = useRequest(([params]) => adminApi.changeAdminStatus(params));

	useEffect(() => {
		setTitle(t('administrators'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			admins.execute(listDataControl);
		}
	}, [pagination, search]);

	const alterStatus = (item: Administrator) => {
		changeStatus.execute(item.id);
	};

	useEffectCustom(() => {
		const { data } = changeStatus;
		if (data) {
			admins.setData((mPrev) => {
				const index = mPrev?.data.findIndex((el) => el.id === data.id);
				const prev = mPrev;
				if (index !== undefined && prev) {
					prev.data[index] = data;
				}
				return prev;
			});
		}
	}, [changeStatus.data]);

	return (
		<ListAdministratorsPage
			admins={admins.data}
			setPagination={setPagination}
			setSearch={setSearch}
			changeStatus={alterStatus}
		/>
	);
};

export default ListAdmins;
