/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PaperContainer } from '@Molecules';
import {
	Divider, Grid, Stack, Typography, useTheme,
} from '@mui/material';
import {
	Form, Formik, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	City, Country, State, UserReq,
} from '@Types';
import {
	Autocomplete,
	CheckBox, DatePicker, DefaultButton, DropdownItem, FileInput, Image, Space, TextField,
} from '@Atoms';
import {
	acceptNumbers, phone, postCode, taxId,
} from '@Masks';
import { camera } from '@Images';
import { DROPDOWN_ACTION } from '@Constant';
import { getMinBirthDate } from '@Helpers';
import { Edit } from '@mui/icons-material';
import { useValidation } from './useUserValidation';

export type SetFieldValue = (field: string, value: string, shouldValidate?: boolean) => void;

interface CreateUserPage {
	initialValues: UserReq;
	countries: Country[];
	states: State[];
	cities: City[];
	handleSubmit: (values: UserReq, formikHelpers: FormikHelpers<UserReq>) => void;
	checkNif: (value: string, setFieldValue: SetFieldValue) => void;
	getDropdown: (action: string, dropdownId: number) => void;
	isEdit: boolean;
	handleEditClick: () => void;
	validateUserDocuments: (value: string,
		typeId: number,
		countryId: number,
		setFieldValue: SetFieldValue,
		field: string)=>void
}

const USER_NAME_MAX_LENGTH = 120;

const CreateUserPage: FC<CreateUserPage> = (props) => {
	const {
		initialValues,
		countries,
		states,
		cities,
		handleSubmit,
		checkNif,
		getDropdown,
		isEdit,
		handleEditClick,
		validateUserDocuments,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const MIN_DATE_OF_BIRTH = getMinBirthDate();
	const { userSchema } = useValidation();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validationSchema={userSchema}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						{({
							values, setFieldValue, touched, errors, handleChange, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} md={4}>
										<Stack
											border={1}
											sx={{ height: '110px', width: '110px', borderColor: theme.palette.divider }}
											component="span"
											alignItems="center"
											justifyContent="center"
										>
											<Image
												src={values.profileImageUrl}
												height={100}
												width={100}
												fallbackImage={camera}
												alt="User"
											/>
										</Stack>
										<Space height={1} />
										<FileInput
											label=""
											accept="image/png, image/gif, image/jpeg"
											disabled={isSubmitting || !isEdit}
											onChange={(event) => {
												if (event.target.files) {
													if (event.target.files.length > 0) {
														setFieldValue('profileImage', event.target.files[0]);
														setFieldValue('profileImageUrl', URL.createObjectURL(event.target?.files[0]));
													}
												}
											}}
										/>
										<Space height={2} />
										<Typography variant="h5">{t('contact')}</Typography>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} md={4}>
										<Autocomplete
											options={countries || []}
											placeholder=""
											disabled={isSubmitting || !isEdit}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.nationalityId && Boolean(errors.nationalityId)}
											helperText={touched.nationalityId && String(errors.nationalityId || '')}
											label={t('nationality')}
											defaultValue={values.nationalityId}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													<DropdownItem country={item} />
												</li>
											)}
											getOptionLabel={(item) => item?.name || ''}
											onChange={(event, item) => setFieldValue('nationalityId', item)}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<TextField
											title=""
											label={t('name')}
											type="text"
											value={values.name}
											inputProps={{ maxLength: USER_NAME_MAX_LENGTH }}
											id="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											disabled={isSubmitting || !isEdit}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<DatePicker
											label={t('birth')}
											value={values.birthdate}
											maxDate={MIN_DATE_OF_BIRTH}
											error={touched.birthdate && Boolean(errors.birthdate)}
											helperText={touched.birthdate && String(errors.birthdate || '')}
											disabled={isSubmitting || !isEdit}
											onChange={(value) => setFieldValue('birthdate', value)}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
										<Autocomplete
											options={countries}
											label={t('countryCode')}
											disabled={isSubmitting || !isEdit}
											defaultValue={values.phoneCountryId}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.phoneCountryId && Boolean(errors.phoneCountryId)}
											helperText={touched.phoneCountryId && String(errors.phoneCountryId || '')}
											placeholder={t('countryCode')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													<DropdownItem country={item} phone />
												</li>
											)}
											getOptionLabel={(item) => item?.phoneCode || ''}
											onChange={(event, item) => setFieldValue('phoneCountryId', item)}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
										<TextField
											title=""
											label={t('telephone')}
											type="text"
											disabled={isSubmitting || !isEdit}
											value={values.phone}
											error={touched.phone && Boolean(errors.phone)}
											helperText={touched.phone && String(errors.phone || '')}
											id="name"
											onChange={(event) => setFieldValue('phone', values.phoneCountryId.id === 1 ? phone(event).target.value : acceptNumbers(event).target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<TextField
											title=""
											label={t('email')}
											type="text"
											value={values.email}
											id="email"
											disabled={isSubmitting || !isEdit}
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Typography variant="h5">{t('taxAddress')}</Typography>
								<ul>
									<li>{t('taxAddressReq')}</li>
								</ul>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} md={4}>
										<Autocomplete
											options={countries}
											label={t('parents')}
											defaultValue={values.countryId}
											placeholder={t('parents')}
											disabled={isSubmitting || !isEdit}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.countryId && Boolean(errors.countryId)}
											helperText={touched.countryId && String(errors.countryId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													<DropdownItem country={item} />
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(event, item) => {
												setFieldValue('countryId', item);
												setFieldValue('taxId', '');
												if (item) {
													getDropdown(DROPDOWN_ACTION.COUNTRY, item?.id);
												}
												setFieldValue('stateId', null);
												setFieldValue('cityId', null);
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<Autocomplete
											label={`${t('state')}/${t('district')}`}
											options={states}
											disabled={values.countryId?.id !== 1 || isSubmitting || !isEdit}
											defaultValue={values.stateId}
											placeholder={t('state')}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.stateId && Boolean(errors.stateId)}
											helperText={touched.stateId && String(errors.stateId || '')}
											renderOption={(AutoCompleteprops, item) => (
												<li {...AutoCompleteprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(event, item) => {
												setFieldValue('stateId', item);
												if (item) {
													getDropdown(DROPDOWN_ACTION.STATE, item?.id);
												}
												setFieldValue('cityId', null);
											}}
										/>

									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										{/* if the country Id is 1 render a dropdown if not 1 render text input */}
										{values.countryId?.id === 1 ? (
											<Autocomplete
												label={`${t('city')}/${t('muncipality')}`}
												options={cities}
												defaultValue={values.cityId}
												placeholder={t('city')}
												disabled={isSubmitting || !isEdit}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												error={touched.cityId && Boolean(errors.cityId)}
												helperText={touched.cityId && String(errors.cityId || '')}
												renderOption={(AutoCompleteprops, item) => (
													<li {...AutoCompleteprops} key={item.id}>
														{item.name}
													</li>
												)}
												getOptionLabel={(item) => item?.name || ''}
												onChange={(_, item) => {
													setFieldValue('cityId', item);
													setFieldValue('alternateCityName', '');
												}}
											/>
										) : (
											<TextField
												label={`${t('city')}/${t('muncipality')}`}
												type="text"
												value={values.alternateCityName}
												disabled={isSubmitting || !isEdit}
												id="alternateCityName"
												error={touched.alternateCityName && Boolean(errors.alternateCityName)}
												helperText={touched.alternateCityName && errors.alternateCityName}
												onChange={(event) => {
													setFieldValue('alternateCityName', event.target.value);
													setFieldValue('cityId', 0);
													setFieldValue('stateId', 0);
												}}
											/>
										)}
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} md={4}>
										<TextField
											label={`${t('address')}`}
											title=""
											type="text"
											value={values.address}
											id="address"
											error={touched.address && Boolean(errors.address)}
											helperText={touched.address && errors.address}
											disabled={isSubmitting || !isEdit}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<TextField
											label={`${t('neighborhood')}/${t('perish')}/${t('locality')}`}
											title=""
											type="text"
											value={values.neighborhood}
											id="neighborhood"
											error={touched.neighborhood && Boolean(errors.neighborhood)}
											helperText={touched.neighborhood && errors.neighborhood}
											disabled={isSubmitting || !isEdit}
											onChange={handleChange}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={2}>
										<TextField
											label={t('postalCode')}
											title=""
											type="text"
											id="neighborhood"
											disabled={isSubmitting || !isEdit}
											error={touched.postalCode && Boolean(errors.postalCode)}
											helperText={touched.postalCode && errors.postalCode}
											value={values.postalCode}
											onChange={(event) => setFieldValue('postalCode', values.countryId.id === 1
												? postCode(event).target.value
												: acceptNumbers(event).target.value)}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<TextField
											label={`${t('nif')}/${t('taxId')}`}
											title=""
											type="text"
											id="taxId"
											inputProps={{ maxLength: values.countryId?.id === 1 ? 10 : 30 }}
											disabled={isSubmitting || !isEdit}
											onBlur={() => checkNif(values.taxId, setFieldValue)}
											error={touched.taxId && Boolean(errors.taxId)}
											helperText={touched.taxId && errors.taxId}
											value={values.taxId}
											onChange={(event) => {
												if (values.countryId && values.countryId?.id === 1) {
													setFieldValue('taxId', taxId(event).target.value);
												} else {
													setFieldValue('taxId', event.target.value);
												}
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Typography variant="h5">{t('documents')}</Typography>
								<Stack direction="row" alignItems="center">
									<CheckBox
										title=""
										disabled={isSubmitting || !isEdit}
										checked={values.disableRequireDocs}
										setChecked={() => {
											setFieldValue('disableRequireDocs', !values.disableRequireDocs);
										}}
									/>
									<Typography
										variant="caption"
										color={theme.palette.success.main}
									>
										{t('documentRequirementDismiss')}
									</Typography>
								</Stack>
								{values.disableRequireDocs && (
									<ul>
										<Typography color="primary" variant="caption">
											<li>{t('clientNoUsageAsDriver')}</li>
										</Typography>
									</ul>
								)}
								{!values.disableRequireDocs && (
									<div>
										<ul>
											<Typography color="textPrimary" variant="caption">
												<li>{t('citizenNumberOrPassport')}</li>
											</Typography>
											<Typography color="textPrimary" variant="caption">
												<li>{t('drivingLicenceMandatory')}</li>
											</Typography>
										</ul>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6} md={3}>
												<Autocomplete
													options={countries}
													label={t('parents')}
													disabled={isSubmitting || !isEdit}
													defaultValue={values.documents[0]?.countryId}
													placeholder={t('countryCode')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															<DropdownItem country={item} />
														</li>
													)}
													getOptionLabel={(item) => item?.name || ''}
													onChange={(event, item) => {
														setFieldValue('documents[0].countryId', item);
													}}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[0]?.countryId || '')
													}
												</Typography>

												<Space height={2} />
												<Autocomplete
													options={countries}
													label={t('parents')}
													disabled={isSubmitting || !isEdit}
													defaultValue={values.documents[1]?.countryId}
													placeholder={t('countryCode')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															<DropdownItem country={item} />
														</li>
													)}
													getOptionLabel={(item) => item?.name || ''}
													onChange={(_, item) => {
														setFieldValue('documents[1].countryId', item);
													}}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[1]?.countryId || '')
													}
												</Typography>
												<Space height={2} />
												<Autocomplete
													options={countries}
													disabled={isSubmitting || !isEdit}
													label={t('parents')}
													defaultValue={values.documents[2]?.countryId}
													placeholder={t('countryCode')}
													renderOption={(AutoCompleteprops, item) => (
														<li {...AutoCompleteprops} key={item.id}>
															<DropdownItem country={item} />
														</li>
													)}
													getOptionLabel={(item) => item?.name || ''}
													onChange={(event, item) => {
														setFieldValue('documents[2].countryId', item);
													}}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[2]?.countryId || '')
													}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<TextField
													label={t('citizenNumber')}
													title=""
													disabled={isSubmitting || !isEdit}
													type="text"
													onBlur={() => validateUserDocuments(
														values.documents[0]?.value,
														values.documents[0]?.typeId,
														values.documents[0]?.countryId.id,
														setFieldValue,
														'documents[0].value',
													)}
													value={values.documents[0]?.value}
													id="citizenValue"
													onChange={(e) => {
														setFieldValue('documents[0].value', e.target.value);
													}}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[0]?.value || '')
													}
												</Typography>
												<Space height={2} />
												<TextField
													title=""
													type="text"
													disabled={isSubmitting || !isEdit}
													label={t('passport')}
													onBlur={() => validateUserDocuments(
														values.documents[1]?.value,
														values.documents[1]?.typeId,
														values.documents[1]?.countryId.id,
														setFieldValue,
														'documents[1].value',
													)}
													value={values.documents[1]?.value}
													id="passportValue"
													onChange={(e) => {
														setFieldValue('documents[1].value', e.target.value);
													}}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[1]?.value || '')
													}
												</Typography>
												<Space height={2} />
												<TextField
													title=""
													label={t('driversLicence')}
													type="text"
													onBlur={() => validateUserDocuments(
														values.documents[2]?.value,
														values.documents[2]?.typeId,
														values.documents[2]?.countryId.id,
														setFieldValue,
														'documents[2].value',
													)}
													disabled={isSubmitting || !isEdit}
													value={values.documents[2]?.value}
													id="licenseValue"
													helperText=""
													onChange={(e) => {
														setFieldValue('documents[2].value', e.target.value);
													}}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[2]?.value || '')
													}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<DatePicker
													label={t('issue')}
													value={values.documents[0]?.issueDate}
													helperText=""
													clearable
													disabled={isSubmitting || !isEdit}
													maxDate={new Date()}
													onChange={(value) => setFieldValue('documents[0].issueDate', value)}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[0]?.issueDate || '')
													}
												</Typography>
												<Space height={2} />
												<DatePicker
													label={t('issue')}
													value={values.documents[1]?.issueDate}
													helperText=""
													maxDate={new Date()}
													clearable
													disabled={isSubmitting || !isEdit}
													onChange={(value) => setFieldValue('documents[1].issueDate', value)}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[1]?.issueDate || '')
													}
												</Typography>
												<Space height={2} />
												<DatePicker
													label={t('issue')}
													value={values.documents[2]?.issueDate}
													helperText=""
													maxDate={new Date()}
													disabled={isSubmitting || !isEdit}
													clearable
													onChange={(value) => setFieldValue('documents[2].issueDate', value)}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[2]?.issueDate || '')
													}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<DatePicker
													label={t('validity')}
													value={values.documents[0]?.expirationDate}
													helperText=""
													disabled={isSubmitting || !isEdit}
													clearable
													minDate={new Date()}
													onChange={(value) => setFieldValue('documents[0].expirationDate', value)}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[0]?.expirationDate || '')
													}
												</Typography>
												<Space height={2} />
												<DatePicker
													label={t('validity')}
													value={values.documents[1]?.expirationDate}
													helperText=""
													disabled={isSubmitting || !isEdit}
													minDate={new Date()}
													clearable
													onChange={(value) => setFieldValue('documents[1].expirationDate', value)}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[1]?.expirationDate || '')
													}
												</Typography>
												<Space height={2} />
												<DatePicker
													label={t('validity')}
													value={values.documents[2]?.expirationDate}
													disabled={isSubmitting || !isEdit}
													minDate={new Date()}
													clearable
													onChange={(value) => setFieldValue('documents[2].expirationDate', value)}
												/>
												<Typography variant="caption" color="error">
													{
														// @ts-ignore
														errors?.documents && String(errors?.documents[2]?.expirationDate || '')
													}
												</Typography>
											</Grid>
										</Grid>
									</div>
								)}
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && <DefaultButton title={t('save')} type="submit" loading={isSubmitting} />}
								{!isEdit && <DefaultButton title={t('edit')} onClick={handleEditClick} startIcon={<Edit />} />}
							</Form>
						) }
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateUserPage;
