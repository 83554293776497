import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import {
	Autocomplete,
	CarItem,
	DefaultButton,
	Space,
	TableItem,
	TextField,
	DatePicker,
	TimePicker,
	FileInput,
} from '@Atoms';
import { Download, Edit, List } from '@mui/icons-material';
import {
	Divider, Grid, Typography,
} from '@mui/material';
import { PaperContainer } from '@Molecules';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { CarFineAttachemnts, CarFineReq } from '@Types';
import { DataTable, TableColumn } from '@Organisms';
import moment from 'moment';
import { currency } from '@Masks';
import { useDropdown } from '@Providers';

interface CreateFineProps {
   initialValues: CarFineReq;
   validate: (values: CarFineReq) => FormikErrors<CarFineReq>;
   attachments?: CarFineAttachemnts[];
   handleSubmit: (values: CarFineReq, formikHelpers: FormikHelpers<CarFineReq>) => void;
   handleListAction: (action: string) => void;
   isEdit: boolean;
   id: boolean;
   handleEditClick: () => void
}

const CreateFinePage: FC<CreateFineProps> = (props) => {
	const {
		initialValues,
		validate,
		attachments,
		handleSubmit,
		handleListAction,
		isEdit,
		id,
		handleEditClick,
	} = props;
	const { t } = useTranslation('translations');
	const { cars } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					title={t('listFine')}
					onClick={() => {
						handleListAction(t('listFine'));
					}}
					startIcon={<List />}
				/>
			</Grid>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							touched,
							errors,
							setFieldValue,
							values,
							handleChange,
							isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
										<Autocomplete
											key="ad-12"
											label={t('vehicle')}
											placeholder=""
											options={cars || []}
											disabled={isSubmitting || !isEdit}
											error={touched.carId && Boolean(errors.carId)}
											helperText={touched.carId && String(errors.carId || '')}
											defaultValue={values.carId}
											getOptionLabel={(item) => `${item?.licensePlate || ''}${item.name || ''}`}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													<CarItem car={item} hideName />
													{item.name}
												</li>
											)}
											onChange={(event, item) => {
												setFieldValue('carId', item);
											}}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
										<TextField
											type="text"
											id="amount"
											label={t('fineValue')}
											value={values.amount}
											disabled={isSubmitting || !isEdit}
											error={touched.amount && Boolean(errors.amount)}
											helperText={touched.amount && errors.amount}
											onChange={(event) => setFieldValue('amount', currency(event).target.value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
										<DatePicker
											label={t('date')}
											value={values.date}
											error={touched.date && Boolean(errors.date)}
											helperText={touched.date && String(errors.date || '')}
											disabled={isSubmitting || !isEdit}
											onChange={(value) => setFieldValue('date', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
										<TimePicker
											label={t('hour')}
											value={values.time}
											error={touched.time && Boolean(errors.time)}
											helperText={touched.time && String(errors.time || '')}
											disabled={isSubmitting || !isEdit}
											onChange={(value) => {
												setFieldValue('time', value);
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
										<TextField
											label={t('local')}
											type="text"
											disabled={isSubmitting || !isEdit}
											value={values.local}
											id="local"
											error={touched.local && Boolean(errors.local)}
											helperText={touched.local && errors.local}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
										<TextField
											label={t('observation')}
											minRows={3}
											disabled={isSubmitting || !isEdit}
											multiline
											value={values.note}
											error={touched.note && Boolean(errors.note)}
											helperText={touched.note && errors.note}
											name="note"
											id="note"
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={8} sm={6} xs={12}>
										<Typography variant="h6">{t('attachment')}</Typography>
										<Space height={1} />
										<FileInput
											label=""
											disabled={isSubmitting || !isEdit}
											placeholder={values.file?.name}
											onChange={(event) => {
												if (event.target.files) {
													setFieldValue('file', event?.target?.files[0]);
												}
											}}
										/>
										<Space height={2} />
										<TextField
											label={t('description')}
											type="text"
											value={values.fileNote}
											id="fileNote"
											error={touched.fileNote && Boolean(errors.fileNote)}
											helperText={touched.fileNote && errors.fileNote}
											disabled={isSubmitting || !isEdit}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
										{id && (
											<DataTable data={attachments || []} search={false}>
												<TableColumn
													headerText={t('file')}
													render={(item) => (
														<DefaultButton
															variant="outlined"
															startIcon={<Download />}
															onClick={() => window.open(item.url, '_blank')}
															title={t('download')}
														/>
													)}
												/>
												<TableColumn headerText={t('size')} render={(item) => `${item.size} kB`} />
												<TableColumn headerText={t('description')} render={(item) => item.description} />
												<TableColumn
													headerText={t('sendBy')}
													render={(item) => (
														<TableItem title={item.createdBy.name} subtitle={moment(item.registration).format('DD/MM/YYYY HH:mm')} />
													)}
												/>
											</DataTable>
										)}
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && (
									<DefaultButton
										loading={isSubmitting}
										title={t('save')}
										type="submit"
									/>
								)}
								{!isEdit && (
									<DefaultButton
										title={t('edit')}
										startIcon={<Edit />}
										onClick={handleEditClick}
									/>
								)}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>

		</Grid>
	);
};

export default CreateFinePage;
