/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRequest } from '@Api';
import { CACHE_KEY, RESERVATION_STATUS, STATUS_ID } from '@Constant';
import { useEffectCustom } from '@Hooks';
import {
	carsApi,
	counterApi,
	discountApi,
	dropdownApi,
	featureApi,
	maintenanceApi,
	organizationApi,
	placesApi,
	tariffApi,
	tollApi,
	reservationApi,
	transactionApi,
	commLogsApi,
	categoryApi,
	reportApi,
	brandApi,
} from '@Requests';
import {
	Car,
	CarFeature,
	Category,
	Country,
	DiscountBillable,
	FeatureType,
	NameAndId,
	Organization,
	Status,
	Tariff,
	Brand,
	CarColor,
	Pagination,
} from '@Types';
import React, {
	createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@Providers';
import { delay } from '@General';

export interface DropdownContextProps {
   countries: Country[];
   counters?: Organization[];
   featureTypes?: FeatureType[];
   cars?: Car[];
   setCars: Dispatch<SetStateAction<Pagination<Car[]> | undefined>>;
   maintenanceTypes: NameAndId[];
   groupTypes?: Status[];
   companies?: Organization[];
   billableItems?: DiscountBillable[];
   vehicleCategories?: Category[];
   tariffs?: Tariff[];
   documentTypes?: NameAndId[];
   campaigns: NameAndId[];
   status: Status[];
   resStatus: Status[];
   paymentMethods: NameAndId[];
   colors?: CarColor[];
   fleets?: NameAndId[];
   fuels?: NameAndId[];
   brands?: Brand[];
   subjectTypes: NameAndId[];
   receptivities: NameAndId[];
   modalTypes: NameAndId[];
   carFeatures: CarFeature[];
   tollStatus?: NameAndId[];
   account: NameAndId[];
   discountTypes?: NameAndId[];
   displacements: {name: string, id: number, value: string}[];
   carType: NameAndId[];
   getDropdowns: () => Promise<void>;
}

export const DropdownContext = createContext<DropdownContextProps>(
	{} as DropdownContextProps,
);

export const DropdownProvider: FC = ({ children }) => {
	const { t } = useTranslation('translations');
	const { user } = useAuth();
	const listDataControl = {
		page: 1,
		size: 100000,
		search: '',
	};

	const counters = useRequest(([param1, param2]) => counterApi.getCounters(param1, param2), {
		cache: true,
		cacheKey: CACHE_KEY.COUNTERS,
	});
	const groupTypes = useRequest(() => dropdownApi.getGroupTypes(), {
		cache: true,
		cacheKey: CACHE_KEY.GROUPTYPES,
	});
	const cars = useRequest(([param1, param2]) => carsApi.getCars(param1, param2), {
		cache: true,
		cacheKey: CACHE_KEY.CARS,
	});
	const tariffs = useRequest(([param1, param2]) => tariffApi.getTariffs(param1, param2), {
		cache: true,
		cacheKey: CACHE_KEY.TARIFFS,
	});
	const companies = useRequest(([param1, param2]) => organizationApi.getOrganizations(
		param1,
		param2,
	), {
		cache: true,
		cacheKey: CACHE_KEY.COMPANIES,
	});
	const tollStatus = useRequest(() => tollApi.tollsStatusTypes(), {
		cache: true,
		cacheKey: CACHE_KEY.TOLL_STATUS,
	});
	const documentTypes = useRequest(() => reportApi.getDocumentTypes(), {
		cache: true,
		cacheKey: CACHE_KEY.DOCUMENT_TYPES,
	});
	const brands = useRequest(([params]) => brandApi.getBrands(params), {
		cache: true,
		cacheKey: CACHE_KEY.BRANDS,
	});
	const colors = useRequest(() => carsApi.carColors(), {
		cache: true,
		cacheKey: CACHE_KEY.COLORS,
	});
	const fleets = useRequest(() => carsApi.carFleets(), {
		cache: true,
		cacheKey: CACHE_KEY.FLEETS,
	});
	const fuels = useRequest(() => carsApi.fuelTypes(), {
		cache: true,
		cacheKey: CACHE_KEY.FUELS,
	});

	const displacements = [
		{ name: '1.0', id: 1, value: '1000' },
		{ name: '1.4', id: 2, value: '1400' },
		{ name: '1.6', id: 3, value: '1600' },
		{ name: '1.8', id: 4, value: '1800' },
		{ name: '2.0', id: 5, value: '2000' },
	];

	const carType = [
		{ name: t('lightCommercial'), id: 1 },
		{ name: t('lightPassenger'), id: 2 },
	];

	const discountTypes = useRequest(
		() => discountApi.getDiscountTypes(),
		{ cache: true, cacheKey: CACHE_KEY.DISCOUNT_TYPES },
	);

	const vehicleCategories = useRequest(
		() => categoryApi.getCategories(listDataControl, undefined, STATUS_ID.ACTIVE),
		{ cache: true, cacheKey: CACHE_KEY.VEHICLE_CATEGORIES },
	);
	const billableItems = useRequest(
		() => discountApi.getBillableDiscounts(),
		{ cache: true, cacheKey: CACHE_KEY.BILLABLE_ITEMS },
	);
	const featureTypes = useRequest(
		() => featureApi.getFeatureTypes(),
		{ cache: true, cacheKey: CACHE_KEY.FEATURE_TYPES },
	);

	// const [featureTypes, setFeatureTypes] = useState([] as FeatureType[]);
	const [maintenanceTypes, setMaintenanceTypes] = useState([] as NameAndId[]);
	const [countries, setCountries] = useState([] as Country[]);
	const [status, setStatus] = useState([] as Status[]);
	const [resStatus, setResStatus] = useState([] as Status[]);
	const [account, setAccount] = useState([] as NameAndId[]);
	const [paymentMethods, setPaymentMethods] = useState([] as NameAndId[]);
	const [subjectTypes, setSubjectTypes] = useState([] as NameAndId[]);
	const [receptivities, setReceptivities] = useState([] as NameAndId[]);
	const [modalTypes, setModalTypes] = useState([] as NameAndId[]);

	const [campaigns] = useState([] as NameAndId[]);
	const [carFeatures] = useState({} as CarFeature[]);

	const getDropdowns = async () => {
		await delay(500);
		const [
			mMaintenanceTypes,
			mCountries,
			mStatus,
			mAccountTypes,
			mPaymentMethods,
			mSubjectType,
			mReceptivities,
			mModalTypes,
		] = await Promise.all([
			maintenanceApi.getCarMaintenanceTypes(),
			placesApi.getCountries(),
			reservationApi.reservationStatus(),
			reservationApi.accountTypes(),
			transactionApi.getPaymentMethods(),
			commLogsApi.getSubjectTypes(),
			commLogsApi.getReceptivities(),
			commLogsApi.getModalTypes(),
		]);
		counters.execute(listDataControl, STATUS_ID.ACTIVE);
		groupTypes.execute();
		cars.execute(listDataControl, STATUS_ID.ACTIVE);
		tariffs.execute(listDataControl, STATUS_ID.ACTIVE);
		companies.execute(listDataControl, STATUS_ID.ACTIVE);
		tollStatus.execute();
		documentTypes.execute();
		brands.execute(listDataControl);
		colors.execute();
		fleets.execute();
		fuels.execute();
		discountTypes.execute();
		vehicleCategories.execute();
		billableItems.execute();
		featureTypes.execute();

		setMaintenanceTypes(mMaintenanceTypes.data);
		setCountries(mCountries.data.data);
		setStatus(mStatus.data);
		const RStatus = [] as Status[];
		mStatus.data?.forEach((el) => {
			if (el.id === RESERVATION_STATUS.CONFIRMED) {
				RStatus.push(el);
			}
			if (el.id === RESERVATION_STATUS.STARTED) {
				RStatus.push(el);
			}
		});
		setResStatus(RStatus);
		setAccount(mAccountTypes.data);
		setPaymentMethods(mPaymentMethods.data);
		setSubjectTypes(mSubjectType.data);
		setReceptivities(mReceptivities.data);
		setModalTypes(mModalTypes.data);
	};

	useEffectCustom(() => {
		if (user) {
			getDropdowns();
		}
	}, []);

	const value = useMemo(() => ({
		countries,
		counters: counters.data?.data,
		featureTypes: featureTypes.data,
		cars: cars.data?.data,
		setCars: cars.setData,
		maintenanceTypes,
		groupTypes: groupTypes.data,
		companies: companies.data?.data,
		billableItems: billableItems.data?.data,
		vehicleCategories: vehicleCategories.data?.data,
		tariffs: tariffs.data?.data,
		documentTypes: documentTypes.data,
		campaigns,
		status,
		resStatus,
		paymentMethods,
		colors: colors.data,
		fleets: fleets.data,
		fuels: fuels.data,
		brands: brands.data?.data,
		subjectTypes,
		receptivities,
		modalTypes,
		carFeatures,
		tollStatus: tollStatus.data,
		account,
		discountTypes: discountTypes.data,
		displacements,
		carType,
		getDropdowns,
	}), [countries,
		counters,
		featureTypes,
		cars,
		maintenanceTypes,
		groupTypes,
		companies,
		billableItems,
		vehicleCategories,
		tariffs,
		documentTypes,
		campaigns,
		status,
		paymentMethods,
		colors,
		fleets,
		fuels,
		brands,
		subjectTypes,
		receptivities,
		modalTypes,
		carFeatures,
		tollStatus,
		account,
		discountTypes,
		displacements,
		getDropdowns,
	]);
	return <DropdownContext.Provider value={value}>{children}</DropdownContext.Provider>;
};

export default function useDropdown() {
	return useContext(DropdownContext);
}
