/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-debugger */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import { User } from '@Types';
import i18next from 'i18next';
import Endpoint from './endpoints.api';

const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';

const axiosConfig = {
	baseURL: BASE_URL,
};
const UNAUTORIZED = 401;
const STATUS_SUCCESS = 200;

const $axios = axios.create(axiosConfig);

const request = async (config: AxiosRequestConfig) => {
	const user = LocalStorageWrapper.get<User>(LocalStorageKeys.USER);
	const lang = LocalStorageWrapper.get<string>(LocalStorageKeys.LOCALE)?.substring(0, 2);
	const bearerToken = user?.authenticationToken;
	const counterId = LocalStorageWrapper.get<number>(LocalStorageKeys.COUNTER);

	config.headers = { // eslint-disable-line no-param-reassign
		...config.headers,
		'Access-Control-Allow-Origin': BASE_URL,
		counterId: counterId!,
		organizationId: counterId!,
		Lang: lang ?? i18next.language.substring(0, 2),
		Accept: 'application/json',
		...(bearerToken
			? {
				Authorization: `Bearer ${bearerToken}`,
			}
			: {}),
	};

	/** CHECK IF THE USER IS UNAUTHORIZED AND REFRESHE THE TOKEN */
	/** AXIOS RESPONSE INTERCEPTOR */
	$axios.interceptors.response.use(
		(response) => response,
		async (error: AxiosError) => {
			const originalRequest = error.config;
			if (error?.response?.status === UNAUTORIZED) {
				const mUser = await LocalStorageWrapper.get<User>(LocalStorageKeys.USER);
				// debugger;
				try {
					const resp = await $axios({
						url: Endpoint.REFRESH_TOKEN,
						method: 'POST',
						data: {
							refreshToken: mUser?.refreshToken,
						},
						headers: {
							...config.headers,
							'Access-Control-Allow-Origin': BASE_URL,
							Lang: lang ?? i18next.language.substring(0, 2),
							Accept: 'application/json',
							Authorization: `Bearer ${mUser?.authenticationToken}`,
						},
					});
					if (resp.status !== STATUS_SUCCESS) {
						LocalStorageWrapper.remove(LocalStorageKeys.USER);
						window.location.reload();
						return null;
					}
					/**
						If the token is refreshed successfully then retry the original request with the new
						authentication token
				 	*/
					LocalStorageWrapper.set(LocalStorageKeys.USER, resp.data.data);
					const returnValue = await $axios({
						...originalRequest,
						headers: {
							...originalRequest.headers,
							Authorization: `Bearer ${resp.data.data.authenticationToken}`,
						},
					});
					return returnValue;
				} catch (err) {
					LocalStorageWrapper.remove(LocalStorageKeys.USER);
					window.location.reload();
				}
			}
			throw error;
		},
	);

	const returnValue = await $axios({ ...config });
	return returnValue;
};

export const reqGet = async (config: AxiosRequestConfig) => request({
	method: 'GET',
	...config,
});

export const reqPost = async (config: AxiosRequestConfig) => request({
	method: 'POST',
	...config,
});

export const reqDelete = async (config: AxiosRequestConfig) => request({
	method: 'DELETE',
	...config,
});

export default request;
