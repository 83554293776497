import {
	Divider,
	Typography,
	Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import {
	DefaultButton,
	DropdownItem,
	Autocomplete,
	Space,
} from '@Atoms';
import { LanguageCountry, Tolerance } from '@Types';
import { PaperContainer } from '@Molecules';
import Label from './DefinitionLabel';
// import { globalStyles } from '../../data/globalStyles/GlobalStyles';
// import { ContainerTopBar } from '../../components/molecules';

interface SettingPageProps {
   changeTolerance: () => void;
   tolerances?: Tolerance[];
   lang?: string | null
   updateData: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number, time: string) => void;
   saveLanguage: (item: LanguageCountry) => void;
   handleApplyClick: () => void;
   allowChangeLanguage: boolean
}

export const language = [
	{
		id: 1,
		isoCode: '620',
		name: 'Portuguese',
		phoneCode: '+351',
		shortName: 'pt',
		language: 'pt',
		code: 'ptPT',
	},
	{
		id: 2, isoCode: '620', name: 'English', phoneCode: '44', shortName: 'gb', language: 'en', code: 'enUS',
	},
] as LanguageCountry[];

const SettingPage: FC<SettingPageProps> = (props) => {
	const {
		changeTolerance,
		tolerances,
		lang,
		updateData,
		saveLanguage,
		handleApplyClick,
		allowChangeLanguage,
	} = props;
	const { t } = useTranslation(['translations']);

	const capitalizeFirst = (word: string) => {
		const firstLetter = word.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
		return firstLetter;
	};

	return (
		<div>
			<PaperContainer title={t('vehDeliveryTol')}>
				<Grid container spacing={3}>
					{tolerances?.map((item) => (
						<Grid item xl={3} lg={3} md={4} sm={12} xs={12} key={item.weekDayId}>
							<Typography>{capitalizeFirst(item.weekDay)}</Typography>
							<Space height={1} />
							<Label
								width="full"
								value={item.hours}
								onChange={(e) => {
									updateData(e, item.weekDayId, 'hours');
								}}
							/>
							<Label
								width="half"
								value={item.minutes}
								onChange={(e) => {
									updateData(e, item.weekDayId, 'minutes');
								}}
							/>
						</Grid>
					))}
				</Grid>
				<Space height={2} />
				<Divider />
				<Space height={2} />
				<DefaultButton title={t('record')} onClick={changeTolerance} />
			</PaperContainer>
			<Space height={3} />
			<PaperContainer>
				<Typography>{t('language')}</Typography>
				<Divider />
				<Space height={2} />
				<Grid container spacing={3}>
					<Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
						<Autocomplete
							placeholder=""
							label=""
							disableClearable
							defaultValue={language.find((el) => el.language === lang)}
							options={language}
							renderOption={(inputprops, item) => (
								<li {...inputprops}>
									<DropdownItem country={item} />
								</li>
							)}
							getOptionLabel={(item) => item.name}
							onChange={(_, item) => {
								if (item) {
									saveLanguage(item);
								}
							}}
						/>
					</Grid>
					<Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
						<DefaultButton title={t('apply')} size="medium" onClick={handleApplyClick} disabled={!allowChangeLanguage} />
					</Grid>
				</Grid>
			</PaperContainer>
		</div>
	);
};

export default SettingPage;
