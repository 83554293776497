import {
	Autocomplete, DefaultButton, Space, TableItem,
} from '@Atoms';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import { Search } from '@mui/icons-material';
import { DataTable, TableColumn } from '@Organisms';
import {
	Organization, Pagination, PaginationTable, UngeneratedInvoice,
} from '@Types';
import { useDropdown } from '@Providers';
import { FontSizes } from '@Constant';

interface UngeneratedInvoicesProps {
   ungeneratedInvoices?: Pagination<UngeneratedInvoice[]>;
   setSearch: (searchQuery: string) => void;
   setPagination: (params: PaginationTable) => void;
   onChange: (items: Organization[]) => void;
   onSubmit: () => void;
   loading: boolean;
   handleListAction: (action: string, id: number) => void
}

const UngeneratedInvoicesPage: FC<UngeneratedInvoicesProps> = (props) => {
	const {
		ungeneratedInvoices,
		setSearch,
		setPagination,
		onChange,
		onSubmit,
		loading,
		handleListAction,
	} = props;
	const { t } = useTranslation('translations');
	const { counters } = useDropdown();

	return (
		<>
			<PaperContainer>
				<Grid container spacing={3}>
					<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
						<Autocomplete
							label={t('counters')}
							placeholder="Select one"
							multiple
							renderOption={(inputprops, item) => (
								<li {...inputprops} key={item.id} style={{ fontSize: FontSizes.LG }}>
									{`${item.officialName} - ${item.fullAddress}`}
								</li>
							)}
							getOptionLabel={(item) => item.officialName}
							options={counters || []}
							disabled={loading}
							onChange={(event, item) => {
								onChange(item);
							}}
						/>
					</Grid>
					<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
						<DefaultButton
							title={t('search')}
							onClick={onSubmit}
							startIcon={<Search />}
							loading={loading}
						/>
					</Grid>
				</Grid>

			</PaperContainer>
			<Space height={3} />
			<PaperContainer>
				<DataTable
					data={ungeneratedInvoices?.data || []}
					total={ungeneratedInvoices?.total}
					onSearching={(query) => setSearch(query)}
					onChange={(page, size) => setPagination({ page, size })}
					pagination
				>
					<TableColumn headerText={t('counters')} render={(item) => item.fantasyName} />

					<TableColumn headerText={t('saleman')} render={(item) => item.sellerName} />

					<TableColumn
						headerText={t('invoice')}
						render={(item) => (
							<TableItem
								title={`#${item.id}`}
								subtitle={item.clientTaxId}
								link
								color="blue"
								onClick={() => handleListAction(t('invoice'), item.reservationId)}
							/>

						)}
					/>

					<TableColumn
						headerText={t('reservation')}
						headerOptions={{ align: 'right' }}
						align="right"
						render={(item) => (
							<DefaultButton
								title={`#${item.reservationId}`}
								onClick={() => {
									handleListAction(t('reservation'), item.reservationId);
								}}
							/>
						)}
					/>
				</DataTable>
			</PaperContainer>
		</>

	);
};

export default UngeneratedInvoicesPage;
