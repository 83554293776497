import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { brandApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Brand } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListBrandPage from './ListBrandPage';

const ListBrand = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const brands = useRequest(([params]) => brandApi.getBrands(params));

	const [pagination, setPagination] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState('');

	useEffect(() => {
		setTitle(t('brands'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			brands.execute(listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	const handleListAction = (action: string, item?: Brand) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.NEW_VEHICLE_BRAND);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_VEHICLE_BRAND.formatMap({ id: item?.id }));
			break;

		default:
			break;
		}
	};

	return (
		<ListBrandPage
			setPagination={setPagination}
			setSearch={setSearch}
			brands={brands.data}
			handleListAction={handleListAction}
		/>
	);
};
export default ListBrand;
