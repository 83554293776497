import {
	ApiResponse, NameAndId, Service, TransactionReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getPaymentMethods(): Promise<ApiResponse<NameAndId[]>> {
	const resp = await reqGet({
		url: Endpoint.PAYMENT_METHODS,
	});
	const data: ApiResponse<NameAndId[]> = await resp.data;
	return data;
}

export const addTransaction = async (
	reservationId: string,
	payload: TransactionReq,
	items?: Service[],
	sessionUserId?: number,
	applyCampagin?: boolean,
):
   Promise<ApiResponse<void>> => {
	const {
		discountRequestId, paymentMethodId, clientId, accountId,
	} = payload;
	const dataToUpload = {
		paymentMethodId: paymentMethodId.id,
		clientId: clientId.id,
		accountId: accountId.id,
		reservationItems: items?.reduce((itemIds, el) => {
			itemIds.push(el.id);
			return itemIds;
		}, [] as number[]),
		discountRequestId: discountRequestId?.discountRequestId,
		dueDate: payload.dueDate.toISOString(),
		clientType: clientId.type === 'CLI' ? 1 : 2,
		sessionUserId,
		applyCampagin,
	};

	const resp = await reqPost({
		url: Endpoint.TRANSACTIONS.format(reservationId),
		data: dataToUpload,
	});
	const { data } = resp;
	return data;
};
