import { useAuth, useFeedback, useTitleBar } from '@Providers';
import { authApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { PasswordReq } from '@Types';
import { FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChangePasswordPage from './ChangePasswordPage';

const ChangePassword = () => {
	const initialValues = { currentPassword: '', newPassword: '', confirmPassword: '' };
	const navigate = useNavigate();
	const { addToast, addDialog } = useFeedback();
	const { t } = useTranslation('translations');
	const { user } = useAuth();
	const { setTitle } = useTitleBar();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTitle(`${t('changeYourPassword')} - ${user?.name}`);
	}, [setTitle, t, user]);

	const changePasswordRequest = async (
		values: PasswordReq,
		formikHelpers: FormikHelpers<PasswordReq>,
	) => {
		setLoading(true);
		authApi.changePassword(values)
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(AppRoutes.DASHBOARD);
			})
			.catch(({ response }) => {
				addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				});
			})
			.finally(() => {
				formikHelpers.setSubmitting(false);
				setLoading(false);
			});

		return null;
	};

	const validatePassword = (values: PasswordReq) => {
		const errors: Partial<PasswordReq> = {};
		if (!values.currentPassword) {
			errors.currentPassword = t('emptyField');
		}
		if (!values.newPassword) {
			errors.newPassword = t('emptyField');
		}
		if (!values.confirmPassword) {
			errors.confirmPassword = t('emptyField');
		}
		if (values.confirmPassword !== values.newPassword) {
			errors.confirmPassword = t('passwordMatch');
		}
		return errors;
	};

	return (
		<ChangePasswordPage
			loading={loading}
			validate={validatePassword}
			submit={changePasswordRequest}
			initialValues={initialValues}
		/>
	);
};

export default ChangePassword;
