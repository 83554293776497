import { ApiResponse, Status } from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

export const getGroupTypes = async (): Promise<ApiResponse<Status[]>> => {
	const resp = await reqGet({
		url: Endpoint.GROUP_TYPE,
	});
	const { data } = resp;
	return data;
};
