import { FontSizes } from '@Constant';
import { LocationOn } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

interface LabelProps {
  title: string;
  value: string;
}

const LocationListItem: FC<LabelProps> = ({ title, value }) => (
	<Stack direction="row" sx={{ mt: '3px' }}>
		<LocationOn sx={{ fontSize: FontSizes.LG, fontWeight: 'bold', '&:hover': { cursor: 'pointer' } }} />
		<Typography
			sx={{
				fontSize: FontSizes.MD,
				maxLines: 2,
				wordBreak: 'break-word',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				display: '-webkit-box',
				'-webkit-line-clamp': 2,
				'-webkit-box-orient': 'vertical',
				maxWidth: '300px',
			}}
		>
			{title}
			:
		</Typography>
		<Typography
			sx={{
				fontSize: FontSizes.MD,
				maxLines: 2,
				wordBreak: 'break-word',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				display: '-webkit-box',
				'-webkit-line-clamp': 2,
				'-webkit-box-orient': 'vertical',
				maxWidth: '300px',
				ml: '5px',
				mr: '5px',
			}}
		>
			{value}
		</Typography>
	</Stack>
);

export default LocationListItem;
