import { useRequest } from '@Api';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { counterApi } from '@Requests';
import { Organization } from '@Types';
import { useEffectCustom } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { useTitleBar } from '@Providers';
import { AppRoutes } from '@Routes';
import ListCountersPage from './ListCountersPage';

const ListCounter = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const counter = useRequest(([params]) => counterApi.getCounters(params));
	const changeCounterStatus = useRequest((params) => counterApi.changeCounterStatus(params));

	const [pagination, setPagination] = useState({ size: 10, page: 0 });
	const [search, setSearch] = useState('');

	useEffect(() => {
		setTitle(t('counters'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			counter.execute(listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	useEffectCustom(() => {
		const { data } = changeCounterStatus;
		if (data) {
			counter.setData((prev) => {
				const index = counter.data?.data.findIndex((el) => (
					el.id === changeCounterStatus?.data?.id));
				const mPrev = prev;
				if (mPrev && index !== undefined) {
					mPrev.data[index] = data;
				}
				return mPrev;
			});
		}
	}, [changeCounterStatus.data]);

	const handleDropdownClick = (action: string, item?: Organization) => {
		switch (action) {
		case t('changeStatus'):
			changeCounterStatus.execute(item?.id);
			break;
		case t('new'):
			navigate(AppRoutes.ADD_NEW_COUNTER);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_COUNTER.formatMap({ id: item?.id }));
			break;
		default:
			break;
		}
	};
	return (
		<ListCountersPage
			counters={counter.data}
			setSearch={setSearch}
			setPagination={setPagination}
			handleListAction={handleDropdownClick}
		/>
	);
};

export default ListCounter;
