import {
	Autocomplete, DefaultButton, Dropdown, Space, StatusLabel, TableItem,
} from '@Atoms';
import { FontSizes } from '@Constant';
import { formatDate } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { Edit, Search } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { useDropdown } from '@Providers';
import {
	Organization, Pagination, PaginationTable, Ticket,
} from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ListTicketProps {
   handleListAction: (action: string, ticket?: Ticket, reservationId?: number) => void;
   tickets?: Pagination<Ticket[]>;
   setPagination: (params: PaginationTable) => void;
   setSearch: (searchQuery: string) => void;
   handleCounterChange: (counters: Organization[]) => void
}

const ListTicketPage: FC<ListTicketProps> = (props) => {
	const {
		handleListAction, tickets, setPagination, setSearch, handleCounterChange,
	} = props;
	const { t } = useTranslation('translations');
	const { counters } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<Grid container spacing={3}>
					<Grid item sm={12} md={12}>
						<PaperContainer>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4}>
									<Autocomplete
										label={t('counters')}
										placeholder=""
										multiple
										options={counters || []}
										renderOption={(inputprops, item) => (
											<li {...inputprops} key={item.id}>
												{item.officialName}
											</li>
										)}
										getOptionLabel={(item) => item.officialName || ''}
										onChange={(_, items) => {
											handleCounterChange(items);
										}}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<DefaultButton
										title={t('search')}
										onClick={() => {
											handleListAction(t('search'));
										}}
										startIcon={<Search />}
									/>
								</Grid>
							</Grid>
						</PaperContainer>
					</Grid>
				</Grid>
				<Space height={3} />
				<PaperContainer>
					<Divider />
					<Space height={2} />
					<DataTable
						data={tickets?.data || []}
						total={tickets?.total}
						pagination
						search
						onChange={(page, size) => setPagination({ page, size })}
						onSearching={(item) => setSearch(item)}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('user')} render={(item) => item.user?.name} />
						<TableColumn headerText={t('subject')} render={(item) => item.subject?.name} />
						<TableColumn headerText={t('message')} render={(item) => <TableItem title={item.note} />} />
						<TableColumn headerText={t('registeredIn')} render={(item) => formatDate(item.registration)} />
						<TableColumn
							headerText={t('details')}
							render={(item) => (
								<>
									<TableItem
										icon
										title={item.reservation?.pickupOrganization}
										subtitle={item.reservation?.returnOrganization}
									/>
									<DefaultButton
										startIcon={<Edit sx={{ fontSize: FontSizes.LG }} />}
										title={`${t('reservation')} #${item.reservation?.id}`}
										size="small"
										onClick={() => handleListAction(t('reservation'), undefined, item.reservation?.id)}
										variant="text"
									/>
								</>
							)}
						/>
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							align="right"
							render={(item) => (
								<Dropdown
									data={[t('edit')]}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(dropdownItem) => handleListAction(dropdownItem, item)}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListTicketPage;
