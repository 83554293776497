import { useRequest } from '@Api';
import { Car } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { carsApi } from '@Requests';
import { useTitleBar } from '@Providers';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import ListVehiclePage from './ListVehiclePage';

const ListVehicle = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle } = useTitleBar();

	const [pagination, setPagination] = useState({ size: 10, page: 0 });
	const [search, setSearch] = useState('');

	const cars = useRequest(([params]) => carsApi.getCars(params));

	const changeCarStatus = useRequest(([params]) => carsApi.changeCarStatus(params));

	useEffect(() => {
		setTitle(t('vehicles'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const { data } = changeCarStatus;
		if (data) {
			cars.setData((prev) => {
				const index = cars.data?.data.findIndex((el) => el.id === changeCarStatus?.data?.id);
				const mPrev = prev;
				if (mPrev && index !== undefined) {
					mPrev.data[index] = data;
				}
				return mPrev;
			});
		}
	}, [changeCarStatus.data]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = { page: pagination.page, size: pagination.size, search };
			cars.execute(listDataControl);
		};
		getData();
	}, [pagination, search]);

	const changeStatus = (carId?: number) => {
		changeCarStatus.execute(carId);
	};

	const handleDropdownClick = (action: string, item?: Car) => {
		switch (action) {
		case t('details'):
			navigate(AppRoutes.VEHICLE_DETAIL.formatMap({ id: item?.id }));
			break;
		case t('new'):
			navigate(AppRoutes.NEW_VEHICLE);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_VEHICLE.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeStatus(item?.id);
			break;
		case t('fines'):
			navigate(AppRoutes.VEHICLE_FINES_ID.formatMap({ id: item?.id }));
			break;
		case t('locate'):
			navigate(AppRoutes.TRACK_VEHICLE.formatMap({ id: item?.id }));
			break;
		default:
			break;
		}
	};
	return (
		<ListVehiclePage
			cars={cars.data}
			handleListAction={handleDropdownClick}
			setPagination={setPagination}
			setSearch={setSearch}
		/>
	);
};

export default ListVehicle;
