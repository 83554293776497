import {
	DateLabel, DefaultButton, Dropdown, StatusLabel, TableItem,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Visibility } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Article, Pagination, PaginationTable } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface ArticlesListPageProps {
   articles: Pagination<Article[]> | undefined;
   onPageChange: (pagination: PaginationTable) => void;
   onSearch: (query: string) => void;
   handleListAction: (action: string, item?: Article) => void
}

const ListArticlePage: FC<ArticlesListPageProps> = (props) => {
	const {
		articles, onPageChange, onSearch, handleListAction,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={articles?.data || []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={articles?.total}
						>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('recorded')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn headerText={t('title')} render={(item) => `${item.title || ''}`} />
							<TableColumn headerText={t('language')} render={(item) => <TableItem title={item.language?.name || ''} />} />
							<TableColumn
								headerText={t('banner')}
								render={(item) => (
									<DefaultButton
										startIcon={<Visibility />}
										title={t('view')}
										variant="outlined"
										onClick={() => handleListAction(t('view'), item)}
									/>
								)}
							/>
							<TableColumn
								headerText={t('link')}
								render={(item) => (
									<DefaultButton
										title={t('access')}
										variant="outlined"
										onClick={() => handleListAction(t('access'), item)}
									/>
								)}
							/>
							<TableColumn
								headerText={t('status')}
								render={(item) => <StatusLabel status={item.status} />}
							/>
							<TableColumn
								headerText={t('')}
								render={(article) => (
									<Dropdown
										variant="contained"
										data={[t('detail'), t('changeStatus')]}
										label={t('action')}
										onChange={(action) => handleListAction(action, article)}
										renderItem={(item) => ({ optionLabel: item, optionValue: item })}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default ListArticlePage;
