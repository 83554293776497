import { Grid, Typography, useTheme } from '@mui/material';
import { AddCircle, Build } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Maintenance, Pagination, PaginationTable } from '@Types';
import {
	CarItem, DateLabel, DefaultButton, Dropdown, Space,
} from '@Atoms';
import React, { FC } from 'react';
import { DataTable, TableColumn } from '@Organisms';
import { PaperContainer } from '@Molecules';
import { padId } from '@Helpers';

interface ListMaintenanceProps {
   handleListAction: (action: string, item?: Maintenance) => void;
   getDropdownOptions: (item: Maintenance) => string[];
   maintenance?: Pagination<Maintenance[]>;
   setSearch: (searchQuery: string) => void;
   setPagination: (params: PaginationTable) => void;
//    total: number | null
}

const ListMaintenancePage: FC<ListMaintenanceProps> = (props) => {
	const {
		handleListAction, getDropdownOptions, maintenance, setSearch, setPagination,
	} = props;

	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={maintenance?.data || []}
						total={maintenance?.total}
						pagination
						onSearching={(query) => setSearch(query)}
						onChange={(page, rowsPerPage) => setPagination({ page, size: rowsPerPage })}
					>
						<TableColumn headerText="ID" render={(item) => padId(item.id, 4, true)} />
						<TableColumn headerText={t('registration')} render={(item) => <CarItem car={item.car} />} />
						<TableColumn headerText={t('local')} render={(item) => item.organization.officialName} />
						<TableColumn headerText={t('entry')} render={(item) => <DateLabel date={item.startDate} />} />
						<TableColumn headerText={t('forcast')} render={(item) => <DateLabel date={item.intendedOutDate} />} />
						<TableColumn
							headerText={t('delivered')}
							render={(item) => (item.endDate
								? <DateLabel date={item.endDate} />
								: <Build style={{ fontSize: 15, color: theme.palette.error.main }} />)}
						/>
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<Dropdown
									label={t('action')}
									data={getDropdownOptions(item)}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(action) => {
										handleListAction(action, item);
									}}
								/>
							)}
						/>
					</DataTable>
					{maintenance && maintenance?.data?.length === 0 && (
						<>
							<Space height={1} />
							<Typography textAlign="center" variant="h6">{t('noRecords')}</Typography>
						</>
					)}
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListMaintenancePage;
