import { DefaultButton } from '@Atoms';
import { DashboardContainer } from '@Molecules';
import { LinearProgress } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Car } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const MAX_FUEL_LEVEL = 8;

interface ParkedCarsProps {
   data: Car[] | undefined;
   onCarPlatePress?: (action: string, id: number) => void;
}

const ParkedCars: FC<ParkedCarsProps> = (props) => {
	const { data, onCarPlatePress } = props;
	const { t } = useTranslation('translations');
	return (
		<DashboardContainer
			title={t('carsInPark')}
			badgeColor="success"
			badgeNumber={data?.length}
			hidden
		>
			<DataTable data={data || []} search={false}>
				<TableColumn
					headerText={t('registration')}
					render={(item) => (
						<DefaultButton
							title={item.licensePlate}
							onClick={() => onCarPlatePress?.call(0, t('registration'), item.id)}
						/>
					)}
				/>
				<TableColumn headerText={t('year')} render={(item) => item.year} />
				<TableColumn headerText={t('model')} render={(item) => item.name} />
				<TableColumn
					headerText={t('fuel')}
					render={(item) => (
						<LinearProgress
							variant="determinate"
							value={(item.fuelLevel / MAX_FUEL_LEVEL) * 100}
							sx={{
								borderRadius: '5px',
								height: '10px',
							}}
						/>
					)}
				/>
			</DataTable>
		</DashboardContainer>
	);
};

export default ParkedCars;
