import { DefaultButton, Space, TextField } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Divider, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CreateBrandProps {
   error: string;
   handleChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
   brand?: string;
   handleClick: () => void;
   loading: boolean
}

const CreateBrandPage: React.FC<CreateBrandProps> = (props) => {
	const {
		error, handleChange, brand, handleClick, loading,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Grid container spacing={3}>
						<Grid item xl={6} lg={6} md={8} xs={12}>
							<TextField
								label={t('name')}
								helperText={error}
								id="name"
								error={!!error}
								disabled={loading}
								type="text"
								value={brand}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Space height={2} />
					<Divider />
					<Space height={2} />
					<DefaultButton
						loading={loading}
						title={t('save')}
						onClick={handleClick}
					/>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateBrandPage;
