import {
	Autocomplete, DatePicker, DefaultButton, Space, TextField,
} from '@Atoms';
import { Check, Close } from '@mui/icons-material';
import {
	DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography, Alert,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdown } from '@Providers';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { ReceiptReq } from '@Types';
import { currency } from '@Masks';
import { formatCurrency } from '@Helpers';
import { removeGreaterValue } from '@Validation';

interface ReceiptModalProps {
	initialValues: ReceiptReq;
	validate: (values: ReceiptReq) => FormikErrors<ReceiptReq>;
	handleSubmit: (values: ReceiptReq, formikHelpers: FormikHelpers<ReceiptReq>) => void;
	handleClose: () => void;
	total: number
}

const ReceiptModalItem: FC<ReceiptModalProps> = (props) => {
	const {
		initialValues, validate, handleSubmit, handleClose, total,
	} = props;
	const { t } = useTranslation('translations');
	const { paymentMethods, account } = useDropdown();

	return (
		<Formik
			initialValues={initialValues}
			validate={validate}
			onSubmit={handleSubmit}
		>
			{({
				handleChange, setFieldValue, touched, errors, values, isSubmitting,
			}) => {
				const mTotal = total < 0 ? 0 : total;
				return (
					<Form>
						<DialogTitle>
							<Stack direction="row" justifyContent="space-between">
								<Typography>
									{t('new')}
									{' '}
									{t('receipt')}
								</Typography>
								<IconButton sx={{ p: 0, m: 0 }} onClick={handleClose}>
									<Close />
								</IconButton>
							</Stack>
						</DialogTitle>
						<DialogContent>
							<Alert icon={false} severity="error">
								{`${t('pendingPayment')} ${formatCurrency(total < 0 ? 0 : total)} €`}
							</Alert>
							<Space height={1} />
							<Autocomplete
								label={t('formOfPayment')}
								placeholder=""
								options={paymentMethods}
								renderOption={(inputprops, item) => (
									<li {...inputprops} key={item.id}>
										{item.name}
									</li>
								)}
								disabled={isSubmitting}
								defaultValue={values.paymentMethodId}
								getOptionLabel={(item) => item.name || ''}
								error={touched.paymentMethodId && Boolean(errors.paymentMethodId)}
								helperText={touched.paymentMethodId && String(errors.paymentMethodId || '')}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								onChange={(_, item) => {
									setFieldValue('paymentMethodId', item);
								}}
							/>
							<Space height={2} />
							<Autocomplete
								label={t('account')}
								placeholder=""
								options={account}
								defaultValue={values.accountId}
								disabled={isSubmitting}
								error={touched.accountId && Boolean(errors.accountId)}
								helperText={touched.accountId && String(errors.accountId || '')}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderOption={(inputprops, item) => (
									<li {...inputprops} key={item.id}>
										{item.name}
									</li>
								)}
								getOptionLabel={(item) => item.name || ''}
								onChange={(_, item) => {
									setFieldValue('accountId', item);
								}}
							/>
							<Space height={2} />
							<Grid container spacing={3}>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
									<TextField
										label={t('value')}
										id="value"
										type="text"
										value={values.value}
										disabled={isSubmitting}
										onBlur={(event) => {
											setFieldValue('value', removeGreaterValue(event, mTotal).target.value);
										}}
										error={touched.value && Boolean(errors.value)}
										helperText={touched.value && String(errors.value || '')}
										onChange={(event) => {
											setFieldValue('value', currency(event).target.value);
										}}
									/>
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
									<DatePicker
										label={t('date')}
										disabled={isSubmitting}
										error={touched.date && Boolean(errors.date)}
										helperText={touched.date && String(errors.date || '')}
										onChange={(date) => setFieldValue('date', date)}
									/>
								</Grid>
							</Grid>
							<Space height={2} />
							<TextField
								id="note"
								value={values.note}
								label={t('description')}
								multiline
								disabled={isSubmitting}
								minRows={3}
								onChange={handleChange}
							/>
						</DialogContent>
						<DialogActions>
							<DefaultButton
								color="info"
								title={t('cancel')}
								disabled={isSubmitting}
								onClick={handleClose}
							/>
							<DefaultButton
								startIcon={<Check />}
								type="submit"
								title={t('confirm')}
								loading={isSubmitting}
							/>
						</DialogActions>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ReceiptModalItem;
