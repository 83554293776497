import {
	DatePicker,
	DefaultButton,
	Autocomplete,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useDropdown } from '@Providers';
import { CommissionReq } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CommissionReportFormProps {
	initialValues: CommissionReq;
	handleSubmit: (values: CommissionReq, formikHelpers: FormikHelpers<CommissionReq>) => void;
	validate: (values: CommissionReq) => FormikErrors<CommissionReq>
}

const CommissionReportForm: FC<CommissionReportFormProps> = (props) => {
	const { initialValues, handleSubmit, validate } = props;
	const { t } = useTranslation('translations');
	const { counters, tariffs } = useDropdown();
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values, setFieldValue, errors, touched, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<Autocomplete
											multiple
											options={counters || []}
											disabled={isSubmitting}
											label={t('counters')}
											placeholder=""
											error={!!errors.pickups}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.fantasyName}
												</li>
											)}
											getOptionLabel={(item) => item.fantasyName}
											onChange={(_, item) => {
												setFieldValue('pickups', item);
											}}
										/>
									</Grid>
									<Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
										<Autocomplete
											multiple
											options={tariffs || []}
											label={t('teriffs')}
											disabled={isSubmitting}
											error={!!errors.tariffs}
											placeholder=""
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name}
											onChange={(_, item) => {
												setFieldValue('tariffs', item);
											}}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
										<DatePicker
											label={t('startDate')}
											disabled={isSubmitting}
											error={touched.startDate && Boolean(errors.startDate)}
											helperText={String(errors.startDate || '')}
											value={values.startDate}
											onChange={(value) => setFieldValue('startDate', value)}
										/>
									</Grid>
									<Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
										<DatePicker
											label={t('endDate')}
											disabled={isSubmitting}
											error={touched.endDate && Boolean(errors.endDate)}
											helperText={String(errors.endDate || '')}
											value={values.endDate}
											onChange={(value) => setFieldValue('endDate', value)}
										/>
									</Grid>
									<Grid item xl={1} lg={1} md={3} sm={12} xs={12}>
										<DefaultButton title={t('search')} type="submit" loading={isSubmitting} startIcon={<Search />} />
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CommissionReportForm;
