/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
	Autocomplete as AutocompleteMUI,
	AutocompleteChangeDetails,
	AutocompleteChangeReason,
	AutocompleteRenderInputParams,
	AutocompleteRenderOptionState,
	TextField, CircularProgress, useTheme,
} from '@mui/material';
import { useEffectCustom } from '@Hooks';

// Change the type of Value looking to other property, in this case 'Multiple'
type Value<T, Multiple> = Multiple extends undefined | false
	? T | null
	: Array<T>;

interface AutocompleteProps<T, Multiple extends undefined | boolean> {
	label: string;
	placeholder: string;
	multiple?: Multiple;
	loading?: boolean;
	options: T[];
	groupBy?: (option: T) => string;
	getOptionLabel?: (option: T) => string;
	limitTags?: number;
	defaultValue?: Value<T, Multiple>;
	disabled?: boolean;
	renderOption?: (
		props: React.HTMLAttributes<HTMLLIElement>,
		option: T,
		state: AutocompleteRenderOptionState,
	) => React.ReactNode;
	getOptionDisabled?: (option: T) => boolean;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	error?: boolean;
	helperText?: string | false;
	noOptionsText?: string;
	/**
	 * Callback fired when the value changes.
	 *
	 * @param {object} event The event source of the callback.
	 * @param {T|T[]} value The new value of the component.
	 * @param {string} reason One of "create-option",
	 * "select-option", "remove-option", "blur", "clear".
	 */
	onChange?: (
		event: React.ChangeEvent<any>,
		value: Value<T, Multiple>,
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<T>,
	) => void;

	onInputChange?: (text: string) => void;
	disableClearable?: boolean
}

const defaultOptions = {
	loading: false,
};

const Autocomplete = <T, Multiple extends boolean | undefined = undefined>(
	mProps: AutocompleteProps<T, Multiple>,
) => {
	const props = { ...defaultOptions, ...mProps };
	const theme = useTheme();

	const [val, setVal] = useState<Value<T, Multiple> | undefined>();
	const [search, setSearch] = React.useState('');

	useEffectCustom(() => {
		const delayDebounceFn = setTimeout(() => {
			props.onInputChange?.call(0, search);
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [search]);

	useEffectCustom(() => {
		if (props.defaultValue !== val) {
			setVal(props.defaultValue);
		}
	}, [props.defaultValue]);

	const renderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			{...params}
			onChange={(event) => {
				setSearch(event.target.value);
			}}
			sx={{
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: theme.palette.divider,
						transition: 'border-color 0.3s',
					},
				},
				'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					borderColor: theme.palette.primary.main,
				},
			}}
			variant="outlined"
			error={props.error}
			helperText={props.helperText}
			label={props.label}
			size="small"
			placeholder={props.placeholder}
			autoComplete="off"
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{props.loading && (
							<CircularProgress color="inherit" size={15} />
						)}
						{!props.loading && params.InputProps.endAdornment}
					</>
				),
			}}
		/>
	);

	const aParams = {
		autoHighlight: true,
		filterSelectedOptions: true,
		multiple: props.multiple,
		limitTags: props.limitTags,
		options: props.options,
		groupBy: props.groupBy,
		getOptionLabel: props.getOptionLabel,
		renderOption: props.renderOption,
		getOptionDisabled: props.getOptionDisabled,
		onChange: props.onChange,
		disableClearable: props.disableClearable,
		noOptionsText: props.noOptionsText,
	};

	return val === undefined ? (
		<AutocompleteMUI
			key="auto-1"
			{...aParams}
			size="small"
			disabled={props.disabled}
			renderInput={(params) => renderInput(params)}
		/>
	) : (
		<AutocompleteMUI
			key="auto-2"
			{...aParams}
			size="small"
			disabled={props.disabled}
			renderInput={(params) => renderInput(params)}
			isOptionEqualToValue={props.isOptionEqualToValue}
			value={val}
		/>
	);
};

export default Autocomplete;
