import { DefaultButton } from '@Atoms';
import { AddCircle, ImportExport } from '@mui/icons-material';
import {
	Box, Stack, Typography, useTheme,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface CardContainerProps {
	title: string;
	priceText: string;
	render: () => ReactNode;
	handleButtonClick: () => void;
	showImport?: boolean;
	handleImportClick?: () => void;
	importLoading?: boolean
}

const CardContainer: FC<CardContainerProps> = (props) => {
	const {
		title, priceText, render, handleButtonClick, showImport, handleImportClick, importLoading,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Box sx={{
			border: 1,
			borderColor: theme.palette.divider,
			borderStyle: 'solid',
			borderRadius: '7px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		}}
		>
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				pl: 2,
				pr: 2,
				pt: 1,
			}}
			>
				<Typography>{title}</Typography>
				{render()}
			</Box>
			<Box sx={{
				height: '50px',
				borderTop: 1,
				borderTopColor: theme.palette.divider,
				borderTopStyle: 'solid',
				backgroundColor: theme.palette.divider,
				borderBottomLeftRadius: 7,
				borderBottomRightRadius: 7,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				pl: 2,
				pr: 2,
			}}
			>
				<Typography>{priceText}</Typography>
				<Stack direction="row">
					{showImport && <DefaultButton loading={importLoading} title={t('import')} sx={{ mr: 1 }} startIcon={<ImportExport />} onClick={handleImportClick} />}
					<DefaultButton title={t('create')} startIcon={<AddCircle />} onClick={handleButtonClick} />
				</Stack>
			</Box>
		</Box>
	);
};

export default CardContainer;
