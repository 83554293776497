import { CarItem, TextField } from '@Atoms';
import { Box, Stack, Typography } from '@mui/material';
import { Reservation } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LinkPaymentProps {
	onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	reservation?: Reservation;
	reservationError?: string
}

const LinkPaymentItem: FC<LinkPaymentProps> = (props) => {
	const { onChange, reservation, reservationError } = props;
	const { t } = useTranslation('translations');
	return (
		<Box sx={{ pt: 1 }}>
			{!reservation && (
				<TextField
					error={Boolean(reservationError)}
					helperText={reservationError}
					type="number"
					label={t('reservation')}
					onChange={onChange}
				/>
			)}
			{reservation && (
				<Stack direction="row">
					<CarItem car={reservation.car} hideName />
					<Typography variant="subtitle1">{reservation.user.name}</Typography>
				</Stack>
			)}
		</Box>
	);
};

export default LinkPaymentItem;
