export const getImageURL = (image: string | File | undefined | null) => {
	if (typeof image === 'string') {
		return image;
	}
	if (image === undefined) {
		return '';
	}
	if (image === null) {
		return '';
	}
	if (!image.size) {
		return '';
	}
	return URL.createObjectURL(image);
};
