import { useFeedback, useSetting, useTitleBar } from '@Providers';
import {
	Car, DiscountBillable, Organization, Status, User, WaybillReq,
} from '@Types';
import {
	counterApi,
	organizationApi, tollApi, userApi, waybillApi,
} from '@Requests';
import { FormikHelpers, FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { Dialog } from '@Organisms';
import { TollItem } from '@Molecules';
import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { STATUS_ID } from '@Constant';
import CreateWaybillPage from './CreateWaybillPage';

const STATUS_PENDING = 5;
const STATUS_CONFIRMED = 1;
const STATUS_STARTED = 2;

const CreateWaybill = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();
	const { addToast, addDialog } = useFeedback();
	const { counter } = useSetting();

	const tolls = useRequest(([params]) => tollApi.tollsByReservation(params));

	const [editable, setEditable] = useState(false);
	const [responsibles, setResponsibles] = useState([] as User[]);
	const [mainDrivers, setMainDrivers] = useState([] as User[]);
	const [secondDrivers, setSecondDrivers] = useState([] as User[]);
	const [companies, setCompanies] = useState([] as Organization[]);
	const [counters, setCounters] = useState([] as Organization[]);
	const [showTolls, setShowTolls] = useState(false);
	const [initialValues, setInitialValues] = useState({
		additionalDriverId: {} as User,
		startDate: new Date(),
		endDate: new Date(Date.now() + 86400000),
		startTime: new Date(),
		endTime: new Date(),
		userId: {} as User,
		mainDriverId: {} as User,
		pickupOrganizationId: {} as Organization,
		returnOrganizationId: {} as Organization,
		carId: {} as Car,
		notes: '',
		statusId: {} as Status,
		organizationId: {} as Organization,
		options: [] as DiscountBillable[],
	});
	const [isEdit, setEdit] = useState(false);

	const getCounters = async () => {
		const listDataControl = {
			page: 1,
			size: 100000,
			search: '',
		};
		const mCounters = await counterApi.getCounters(listDataControl, STATUS_ID.ACTIVE);
		return mCounters.data.data;
	};

	useEffectCustom(() => {
		const getData = () => {
			waybillApi.getWaybillById(id ?? '')
				.then(async ({ data }) => {
					tolls.execute(id);
					const mCounters = await getCounters();
					setCounters(mCounters);
					setInitialValues({
						additionalDriverId: data.additionalDriver,
						startDate: new Date(data.startDate),
						endDate: new Date(data.endDate),
						startTime: new Date(data.startDate),
						endTime: new Date(data.endDate),
						userId: data.user,
						mainDriverId: data.mainDriver,
						pickupOrganizationId: data.pickupCounter,
						returnOrganizationId: data.returnCounter,
						carId: data.car,
						notes: data.notes,
						statusId: data.status,
						organizationId: data.organization,
						options: data.optionals,
					});
					setEditable(
						data.status.id === STATUS_CONFIRMED
						|| data.status.id === STATUS_PENDING
						|| data.status.id === STATUS_STARTED,
					);
					setEdit(false);
				});
		};
		if (id) {
			getData();
		} else {
			setEditable(true);
		}
	}, [id]);

	useEffect(() => {
		const initializeWaybill = async () => {
			const mCounters = await getCounters();
			setCounters(mCounters);
			const currentCounter = mCounters.find((el) => el.id === counter);
			setInitialValues({
				startDate: new Date(),
				endDate: new Date(Date.now() + 86400000),
				startTime: new Date(),
				endTime: new Date(),
				userId: {} as User,
				mainDriverId: {} as User,
				pickupOrganizationId: currentCounter,
				returnOrganizationId: currentCounter,
				carId: {} as Car,
				notes: '',
				statusId: {} as Status,
				organizationId: {} as Organization,
				options: [] as DiscountBillable[],
			} as WaybillReq);
		};
		if (id) {
			setTitle(`${t('wayBill')} ${t('detail')}`);
		} else {
			setTitle(`${t('new')} ${t('wayBill')}`);
			setEdit(true);
			initializeWaybill();
		}
	}, [setTitle, id, t, counter]);

	const handleSearchUsers = async (search: string, type: string) => {
		if (!search) {
			return null;
		}
		const listDataControl = {
			page: 1,
			size: 100000,
			search,
		};
		switch (type) {
		case t('responsible'): {
			const mResponsibles = await userApi.getAllUsers(listDataControl, STATUS_ID.ACTIVE);
			setResponsibles(mResponsibles.data.data);
			break;
		}
		case t('mainDriver'): {
			const mMainDrivers = await userApi.getAllUsers(listDataControl, STATUS_ID.ACTIVE);
			setMainDrivers(mMainDrivers.data.data);
			break;
		}
		case t('secondDriver'): {
			const mSecondDrivers = await userApi.getAllUsers(listDataControl, STATUS_ID.ACTIVE);
			setSecondDrivers(mSecondDrivers.data.data);
			break;
		}
		case t('company'): {
			const mCompanies = await organizationApi.getOrganizations(listDataControl, STATUS_ID.ACTIVE);
			setCompanies(mCompanies.data.data);
			break;
		}
		default:
			break;
		}
		return null;
	};

	const validate = (values: WaybillReq) => {
		const errors = {} as FormikErrors<WaybillReq>;
		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		if (!values.userId) {
			errors.userId = t('emptyField');
		}
		if (values.userId && Object.keys(values.userId).length === 0) {
			errors.userId = t('emptyField');
		}
		if (!values.mainDriverId) {
			errors.mainDriverId = t('emptyField');
		}
		if (values.mainDriverId && Object.keys(values.mainDriverId).length === 0) {
			errors.mainDriverId = t('emptyField');
		}
		if (!values.pickupOrganizationId) {
			errors.pickupOrganizationId = t('emptyField');
		}
		if (values.pickupOrganizationId && Object.keys(values.pickupOrganizationId).length === 0) {
			errors.pickupOrganizationId = t('emptyField');
		}
		if (!values.returnOrganizationId) {
			errors.returnOrganizationId = t('emptyField');
		}
		if (values.returnOrganizationId && Object.keys(values.returnOrganizationId).length === 0) {
			errors.returnOrganizationId = t('emptyField');
		}
		if (values.returnOrganizationId
			&& values.pickupOrganizationId
			&& Object.keys(values.pickupOrganizationId).length > 0
			&& Object.keys(values.returnOrganizationId).length > 0
			&& values.pickupOrganizationId?.id === values.returnOrganizationId?.id) {
			errors.returnOrganizationId = t('sameCountersError');
		}
		if (!values.carId) {
			errors.carId = t('emptyField');
		}
		if (values.carId && Object.keys(values.carId).length === 0) {
			errors.carId = t('emptyField');
		}
		if (!values.statusId) {
			errors.statusId = t('emptyField');
		}
		if (values.statusId && Object.keys(values.statusId).length === 0) {
			errors.statusId = t('emptyField');
		}

		return errors;
	};

	const handleSubmit = (values: WaybillReq, formikHelpers: FormikHelpers<WaybillReq>) => {
		if (id) {
			waybillApi.updateWaybill(values, id ?? '')
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ALL_TRANSPORT_GUIDES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			waybillApi.addNewWaybill(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ALL_TRANSPORT_GUIDES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const onTollClick = () => {
		setShowTolls(true);
	};

	const closeTollDialog = () => {
		setShowTolls(false);
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')}  ${t('wayBill')}`);
		setEdit(true);
	};

	return (
		<>
			{showTolls && (
				<Dialog
					open={showTolls}
					handleClose={closeTollDialog}
					description={(
						<TollItem
							tolls={tolls.data?.data}
							handleClose={closeTollDialog}
							total={tolls.data?.data.reduce((sum, element) => {
								let mSum = sum;
								mSum += element.fare;
								return mSum;
							}, 0 as number)}
						/>
					)}
					hideButtons
				/>
			)}
			<CreateWaybillPage
				isEdit={editable}
				initialValues={initialValues}
				responsibles={responsibles}
				mainDrivers={mainDrivers}
				secondDrivers={secondDrivers}
				companies={companies}
				validate={validate}
				handleSearchUsers={handleSearchUsers}
				handleSubmit={handleSubmit}
				onTollClick={onTollClick}
				showTollButton={!!id}
				readOnly={!isEdit}
				handleEditClick={handleEditClick}
				counters={counters}
			/>
		</>
	);
};

export default CreateWaybill;
