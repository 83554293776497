import { formatCurrency } from '@Helpers';

export const removeGreaterValue = (
	event: React.ChangeEvent<HTMLTextAreaElement |HTMLInputElement>,
	maxValue: number,
) => {
	const mEvent = event;
	let { value } = mEvent.target;
	if (parseFloat(value) > maxValue) {
		value = formatCurrency(maxValue);
	}
	mEvent.target.value = value;
	return mEvent;
};
