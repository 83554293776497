import {
	Divider, Grid, Stack,
} from '@mui/material';
import { Ticket, TicketMessageReq } from '@Types';
import { PaperContainer } from '@Molecules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	DefaultButton, Space, StatusLabel, DateLabel, TextField, Title, FileInput, TableItem, Description,
} from '@Atoms';
import { ArrowBack, Edit, RotateLeft } from '@mui/icons-material';
import { DataTable, TableColumn } from '@Organisms';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { TICKET_STATUS } from '@Constant';
import { padId } from '@Helpers';
import TicketListItem from './TicketListItem';
import FileButton from './FileButton';

interface EditTicketProps {
	ticket?: Ticket;
	onActionClick?: (action: string, statusId?: number) => void;
	initialValues: TicketMessageReq;
	validate: (values: TicketMessageReq) => FormikErrors<TicketMessageReq>;
	onSaveMessage: (values: TicketMessageReq, helpers: FormikHelpers<TicketMessageReq>) => void;
	loading: boolean;
handleMessageSearch: (search: string) => void
}

const EditTicketPage: React.FC<EditTicketProps> = (props) => {
	const {
		ticket, onActionClick, initialValues, validate, onSaveMessage, loading, handleMessageSearch,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<Stack direction="row" justifyContent="space-between">
						<div>
							<DefaultButton title={t('back')} startIcon={<ArrowBack />} onClick={() => onActionClick?.call(0, t('back'))} />
							<DefaultButton title={t('history')} sx={{ ml: 1, textTransform: 'none' }} startIcon={<RotateLeft />} onClick={() => onActionClick?.call(0, t('history'))} />
						</div>
						{
							ticket?.status.id === TICKET_STATUS.OPEN && (
								<div>
									<DefaultButton title={t('startAnalysis')} onClick={() => onActionClick?.call(0, t('startAnalysis'), TICKET_STATUS.UNDER_ANALYSIS)} loading={loading} />
									<DefaultButton title={t('cancel')} sx={{ ml: 1, textTransform: 'none' }} color="error" onClick={() => onActionClick?.call(0, t('cancel'), TICKET_STATUS.CANCELLED)} loading={loading} />
								</div>
							)
						}
						{
							ticket?.status.id === TICKET_STATUS.UNDER_ANALYSIS && (
								<div>
									<DefaultButton title={t('markResolved')} onClick={() => onActionClick?.call(0, t('cancel'), TICKET_STATUS.RESOLVED)} loading={loading} />
									<DefaultButton title={t('cancel')} sx={{ ml: 1, textTransform: 'none' }} color="error" onClick={() => onActionClick?.call(0, t('cancel'), TICKET_STATUS.CANCELLED)} loading={loading} />
								</div>
							)
						}
					</Stack>
				</PaperContainer>
				<Space height={3} />
				<PaperContainer>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<TicketListItem title={`${t('user')}:`} value={`#${ticket?.user?.id ?? ''} | ${ticket?.user?.name ?? ''}`} />
						</Grid>
						<Grid item xs={12} md={4}>
							<TicketListItem title={`${t('subject')}:`} value={ticket?.subject?.name ?? ''} />
						</Grid>
						<Grid item xs={12} md={4}>
							<TicketListItem title={`${t('status')}:`} value={<StatusLabel status={ticket?.status} />} />
						</Grid>
					</Grid>
					<Space height={2} />
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<TicketListItem
								title={`${t('contact')}:`}
								value={(
									<>
										<a style={{ fontSize: 12 }} href={`tel:${`${ticket?.user?.phoneCountry?.phoneCode}${ticket?.user?.phone}` || ''}`}>
											{ticket?.user?.phoneCountry?.phoneCode}
											{' '}
											{ticket?.user?.phone || ''}
											{' '}
										</a>
										/
										<a style={{ fontSize: 12 }} href={`mailto:${`${ticket?.user?.email}` || ''}`}>
											{' '}
											{ticket?.user?.email || ''}
										</a>

									</>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TicketListItem title={`${t('registrationIn')}:`} value={<DateLabel date={ticket?.registration} showTime />} />
						</Grid>
						<Grid item xs={12} md={4}>
							<TicketListItem
								title={`${t('pickupIn')}:`}
								value={`${ticket?.reservation?.pickupOrganization || ''}
							 `}
							/>
							<TicketListItem
								title={`${t('return')}:`}
								value={`${ticket?.reservation?.returnOrganization || ''}
							 `}
							/>
							<DefaultButton
								variant="text"
								startIcon={<Edit />}
								title={`${t('reservation')} ${padId(String(ticket?.reservation.id || ''), 4)}`}
								onClick={() => onActionClick?.call(0, t('reservation'), ticket?.reservation.id)}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={12} md={8}>
							<TicketListItem title={`${t('message')}:`} value={ticket?.note} />
						</Grid>
					</Grid>
					<Space height={2} />
					<Divider />
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={onSaveMessage}
					>
						{({
							values, handleChange, setFieldValue, errors, isSubmitting,
						}) => (
							<Form>
								<Space height={2} />
								<TextField
									label={t('message')}
									name="message"
									multiline
									minRows={3}
									disabled={isSubmitting}
									error={!!errors.message}
									helperText={errors.message}
									value={values.message}
									onChange={handleChange}
								/>
								<Space height={2} />
								<Title text="Anexo" />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xs={12} md={4}>
										<FileInput
											label={t('attachment')}
											onChange={(
												event: React.ChangeEvent<HTMLInputElement>,
											) => {
												if (event.target.files) {
													setFieldValue('fileAttachment', event.target.files[0]);
												}
											}}
											placeholder={values.fileAttachment?.name}
											disabled={isSubmitting}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xs={12} md={4}>
										<TextField
											label={t('writeDescription')}
											name="fileAttachmentDescription"
											value={values.fileAttachmentDescription}
											error={!!errors.fileAttachmentDescription}
											helperText={errors.fileAttachmentDescription}
											disabled={isSubmitting}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<DefaultButton loading={isSubmitting} type="submit" title={t('add')} />
							</Form>
						)}
					</Formik>

					<Space height={2} />
					<Divider />
					<Space height={2} />
					<DataTable data={ticket?.messages || []} onSearching={handleMessageSearch}>
						<TableColumn headerText="ID" render={(item) => item.ticketMessageId} />
						<TableColumn headerText={t('message')} render={(item) => <TableItem title={item.note} />} />
						<TableColumn headerText={t('attachment')} render={(item) => (item.file && <FileButton file={item} />)} />
						<TableColumn
							headerText={t('registrationIn')}
							render={(item) => (
								<>
									<DateLabel date={item.registration} showTime />
									<Description fontSize={10} text={`${t('by')}: ${item.user.name}`} />
								</>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default EditTicketPage;
