import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import { useFeedback, useLanguage, useTitleBar } from '@Providers';
import { toleranceApi } from '@Requests';
import { LanguageCountry, Tolerance } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import SettingPage, { language } from './Definitions';

const Setting = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();

	const tolerances = useRequest(() => toleranceApi.getTolerance());

	const [tolerance, setTolerance] = useState<Tolerance[]>();
	const [lang, setLang] = useState<string | null>();
	const [locale, setLocale] = useState<LanguageCountry>();
	const [languageChanged, setLanguageChanged] = useState(false);
	const { changeLocale } = useLanguage();

	const getData = async () => {
		tolerances.execute();
	};

	useEffect(() => {
		setTitle(t('definitions'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			getData();
			const tempLang = LocalStorageWrapper.get(LocalStorageKeys.LOCALE) as string;
			if (!tempLang) {
				setLang(i18next.language.substring(0, 2));
				setLocale(language.find((el) => el.language === i18next.language.substring(0, 2)));
			} else {
				setLang(tempLang.substring(0, 2));
				setLocale(language.find((el) => el.language === tempLang.substring(0, 2)));
			}
		}
		return () => {
			unsubscribe = true;
		};
	}, []);

	const changeTolerance = () => {
		if (tolerance) {
			toleranceApi.updateTolerance({ toleranceModels: tolerance })
				.then((res) => addToast({
					message: res.message,
					error: false,
				}))
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}));
		}
	};

	const updateData = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		index: number,
		time: string,
	) => {
		const value = parseInt(event.target.value, 10);
		if (value < 0 || Number.isNaN(value)) {
			return null;
		}
		if (tolerances.data) {
			const temp = [...tolerances.data];
			if (time === 'minutes') {
				temp[index - 1].minutes = parseInt(event.target.value, 10);
			} else {
				temp[index - 1].hours = parseInt(event.target.value, 10);
			}
			setTolerance(temp);
		}
		return null;
	};

	const applyLanguage = () => {
		if (locale) {
			changeLocale(locale.code);
			setLanguageChanged(false);
		}
	};

	const saveLanguage = (item: LanguageCountry) => {
		setLocale(item);
		setLang(item.language);
		setLanguageChanged(true);
	};

	return (
		<SettingPage
			changeTolerance={changeTolerance}
			tolerances={tolerances.data}
			lang={lang}
			updateData={updateData}
			saveLanguage={saveLanguage}
			handleApplyClick={applyLanguage}
			allowChangeLanguage={languageChanged}
		/>
	);
};

export default Setting;
