import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitleBar, useFeedback } from '@Providers';
import { MaintenanceReportReq, Car, MaintenanceReport } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import { reportApi } from '@Requests';
import { Space } from '@Atoms';
import MaintenanceCostPage from './MaintenanceCostPage';
import MaintenanceCostForm from './MaintenanceCostForm';

const MaintenanceCost = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast } = useFeedback();

	const [maintenanceReport, setMaintenanceReport] = useState({} as MaintenanceReport);
	const [pagination, setPagination] = useState({ page: 0, size: 0 });
	const [search, setSearch] = useState('');
	const [initialValues] = useState({
		CarId: {} as Car,
		StartDate: new Date(),
		EndDate: new Date(),
		Page: 0,
		Size: 10,
		Search: '',
	});

	useEffect(() => {
		setTitle(t('maintenanceReports'));
	}, [setTitle, t, pagination, search]);

	const searchMaintenanceReports = (
		values: MaintenanceReportReq,
		formikHelpers: FormikHelpers<MaintenanceReportReq>,
	) => {
		reportApi.getMaintenanceReport(values)
			.then((res) => setMaintenanceReport(res.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => formikHelpers.setSubmitting(false));
	};

	const validate = (values: MaintenanceReportReq) => {
		const errors = {} as FormikErrors<MaintenanceReportReq>;
		if (!values.CarId) {
			errors.CarId = t('emptyField');
		}
		if (!values.StartDate) {
			errors.StartDate = t('emptyField');
		}
		if (!values.EndDate) {
			errors.StartDate = t('emptyField');
		}
		return errors;
	};

	return (
		<>
			<MaintenanceCostForm
				initialValues={initialValues}
				validate={validate}
				handleSubmit={searchMaintenanceReports}
			/>
			<Space height={3} />
			{maintenanceReport && (
				<MaintenanceCostPage
					data={maintenanceReport}
					setPagination={setPagination}
					setSearch={setSearch}

				/>
			)}
		</>
	);
};

export default MaintenanceCost;
