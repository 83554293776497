import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeedback, useTitleBar } from '@Providers';
import {
	CampaignReq, Category, DiscountBillable, IsPercentage,
} from '@Types';
import { FormikHelpers, FormikErrors } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { discountApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import { putDecimalPoints } from '@Masks';
import CreateCampaignForm, { discountType } from './CreateCampaignForm';

const CreateCampaign = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({
		startDate: new Date(),
		endDate: new Date(),
		name: '',
		description: '',
		rules: '',
		discount: '',
		isPercentage: {} as IsPercentage,
		itemId: {} as DiscountBillable,
		categories: [] as Category[],
	});
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('campaign')} ${t('detail')}`);
		} else {
			setTitle(`${t('new')} ${t('campaign')}`);
			setIsEdit(true);
		}
	}, [setTitle, t, id]);

	useEffectCustom(() => {
		const getCampaign = () => {
			discountApi.getDiscountCampaignById(id ?? '')
				.then(({ data }) => setInitialValues({
					startDate: new Date(data.startDate),
					endDate: new Date(data.endDate),
					name: data.name,
					description: data.description,
					rules: data.rules,
					discount: data.isPercentage ? String(data.discount) : putDecimalPoints(data.discount),
					isPercentage: discountType.find((el) => (
						el.id === data.isPercentage
					)) ?? {} as IsPercentage,
					itemId: data.item,
					categories: data.categories,
				}))
				.catch(({ response }) => addToast({ message: response.data.message, error: true }));
		};
		if (id) {
			getCampaign();
		}
	}, []);

	const validate = (values: CampaignReq) => {
		const errors = {} as FormikErrors<CampaignReq>;

		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.description) {
			errors.description = t('emptyField');
		}
		if (!values.rules) {
			errors.rules = t('emptyField');
		}
		if (!values.discount) {
			errors.discount = t('emptyField');
		}
		if (Object.keys(values.isPercentage).length === 0) {
			errors.isPercentage = t('emptyField');
		}
		if (Object.keys(values.itemId).length === 0) {
			errors.itemId = t('emptyField');
		}
		if (values.categories?.length < 1) {
			errors.categories = t('emptyField');
		}

		return errors;
	};

	const handleSubmit = (values: CampaignReq, formikHelpers: FormikHelpers<CampaignReq>) => {
		if (id) {
			discountApi.updateCompaign(values, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.DISCOUNT_CAMPAIGN);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			discountApi.addNewCampaign(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.DISCOUNT_CAMPAIGN);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setIsEdit(true);
		setTitle(`${t('edit')} ${t('campaign')}`);
	};

	return (
		<CreateCampaignForm
			initialValues={initialValues}
			validate={validate}
			handleSubmit={handleSubmit}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
		/>
	);
};

export default CreateCampaign;
