import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Typography } from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { Attachment, NewMaintenance } from '@Types';
import {
	Autocomplete,
	CarItem,
	DatePicker,
	DefaultButton,
	FileInput,
	Space,
	TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { useDropdown } from '@Providers';
import { currency } from '@Masks';
import { CloudDownload, Edit } from '@mui/icons-material';
// import { Autocomplete } from '@Atoms';

interface CreateCarMaintenanceProps {
   initialValues: NewMaintenance;
   id: boolean;
   isEdit: boolean;
   handleEditClick: () => void;
   attachments: Attachment[];
   validate: (values: NewMaintenance) => FormikErrors<NewMaintenance>;
   handleSubmit: (values: NewMaintenance, formikHelpers: FormikHelpers<NewMaintenance>) => void
}

const CreateCarMaintenance: React.FC<CreateCarMaintenanceProps> = (props) => {
	const {
		initialValues, id, isEdit, handleEditClick, validate, handleSubmit, attachments,
	} = props;
	const { t } = useTranslation('translations');
	const { cars, maintenanceTypes, companies } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values,
							errors,
							isSubmitting,
							handleChange,
							touched,
							setFieldValue,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={4}>
										<Autocomplete
											options={cars || []}
											label={t('car')}
											placeholder=""
											disabled={isSubmitting || !isEdit}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.carId && Boolean(errors.carId)}
											helperText={touched.carId && String(errors.carId || '')}
											defaultValue={values.carId}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													<CarItem car={item} hideName />
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => (item.name ? `${item.name} - ${item.licensePlate}` : '')}
											onChange={(_, item) => setFieldValue('carId', item)}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={4}>
										<Autocomplete
											options={maintenanceTypes}
											label={t('maintenanceType')}
											disabled={isSubmitting || !isEdit}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.typeId && Boolean(errors.typeId)}
											helperText={touched.typeId && String(errors.typeId || '')}
											placeholder=""
											defaultValue={values.typeId}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('typeId', item)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={4}>
										<DatePicker
											label={t('entry')}
											value={values.startDate}
											error={touched.startDate && Boolean(errors.startDate)}
											helperText={touched.startDate && String(errors.startDate || '')}
											disabled={isSubmitting || !isEdit}
											onChange={(value) => setFieldValue('startDate', value)}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={4}>
										<DatePicker
											error={Boolean(errors.intendedOutDate)}
											helperText={String(errors.intendedOutDate || '')}
											minDate={values.startDate}
											value={values.intendedOutDate}
											label={t('expectedExit')}
											disabled={isSubmitting || !isEdit}
											onChange={(value) => setFieldValue('intendedOutDate', value)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={6} xs={12} md={4}>
										<Autocomplete
											label={t('local')}
											placeholder=""
											disabled={isSubmitting || !isEdit}
											options={companies || []}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.organizationId && Boolean(errors.organizationId)}
											helperText={touched.organizationId && String(errors.organizationId || '')}
											defaultValue={values.organizationId}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											getOptionLabel={(item) => item.officialName || ''}
											onChange={(_, item) => setFieldValue('organizationId', item)}
										/>
									</Grid>
									<Grid item sm={6} xs={12} md={4}>
										<TextField
											type="text"
											id="totalAmount"
											value={values.totalAmount}
											disabled={isSubmitting || !isEdit}
											label={t('budget')}
											error={touched.totalAmount && Boolean(errors.totalAmount)}
											helperText={touched.totalAmount && errors.totalAmount}
											onChange={(event) => setFieldValue('totalAmount', currency(event).target.value)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item sm={12} xs={12} md={8}>
										<TextField
											label={t('comments')}
											multiline
											disabled={isSubmitting || !isEdit}
											minRows={4}
											id="description"
											value={values.description}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{id && (
									<>
										<Grid container spacing={3}>
											<Grid item sm={12} xs={12} md={8}>
												<Typography variant="h5">{t('attachment')}</Typography>
												<Space height={2} />
												<FileInput
													label=""
													disabled={isSubmitting || !isEdit}
													placeholder={values.fileName.name}
													onChange={(e) => {
														if (e.target?.files) {
															setFieldValue('fileName', e.target.files[0]);
														}
													}}
												/>
												<Space height={2} />
												<TextField
													id="fileDescription"
													label={t('description')}
													value={values.fileDescription}
													disabled={isSubmitting || !isEdit}
													error={touched.fileDescription && Boolean(errors.fileDescription)}
													helperText={touched.fileDescription && errors.fileDescription}
													title=""
													type="text"
													onChange={handleChange}
												/>
												<Space height={2} />
												<Divider />
												<Space height={2} />
												<Grid container spacing={3}>
													<Grid item xl={4} lg={4} md={4} sm={4}>
														<Typography variant="caption">{t('file')}</Typography>
													</Grid>
													<Grid item xl={4} lg={4} md={4} sm={4}>
														<Typography variant="caption">{t('description')}</Typography>
													</Grid>
												</Grid>
												{attachments.map((item, index) => (
													<Grid key={`${item.fileName}-${index.toString()}`} container spacing={3}>
														<Grid item xl={4} lg={4} md={4} sm={4}>
															<DefaultButton
																variant="text"
																startIcon={<CloudDownload />}
																title={t('download')}
																onClick={() => {
																	window.open(item.fileName, '_blank');
																}}
															/>
														</Grid>
														<Grid item xl={4} lg={4} md={4} sm={4}>
															<Typography variant="caption">{item.description}</Typography>
														</Grid>
													</Grid>
												))}
												<Divider />
											</Grid>
										</Grid>
										<Space height={2} />
										<Divider />
										<Space height={2} />
									</>
								)}
								{isEdit && <DefaultButton loading={isSubmitting} title={t('save')} type="submit" />}
								{!isEdit && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} />}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateCarMaintenance;
