import {
	ListDataControl, ApiResponse, Pagination, Question,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getQuestions(params: ListDataControl):
   Promise<ApiResponse<Pagination<Question[]>>> {
	const resp = await reqGet({
		url: Endpoint.QUESTIONS.format(params.page, params.size, params.search),
	});
	const data = resp.data as ApiResponse<Pagination<Question[]>>;
	data.statusCode = resp.status;
	return data;
}
export async function getQuestionById(id: number | string): Promise<ApiResponse<Question>> {
	const resp = await reqGet({
		url: Endpoint.QUESTION_BY_ID.format(id),
	});

	const { data } = resp;
	return data;
}
export async function changeQuestionStatus(questionId: number): Promise<ApiResponse<Question>> {
	const resp = await reqPost({
		url: Endpoint.QUESTION_STATUS.format(questionId),
	});

	const { data } = resp;
	return data;
}
export async function addNewQuestion(faq: { question: string; answer: string }):
   Promise<ApiResponse<void>> {
	const resp = await reqPost({
		url: Endpoint.QUESTION,
		data: faq,
	});

	const { data } = resp;
	return data;
}
export async function updateQuestion(
	faq: {
      question: string;
      answer: string;
   },
	id?: number | string,
): Promise<ApiResponse<Question>> {
	const resp = await reqPost({
		url: Endpoint.QUESTION_BY_ID.format(id),
		data: faq,
	});

	const data = resp.data as ApiResponse<Question>;
	return data;
}
