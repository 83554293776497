import { useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reportApi } from '@Requests';
import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { Organization } from '@Types';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import UngeneratedInvoicesPage from './UngeneratedInvoicesPage';

const UngeneratedInvoices = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();

	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const [searchUngen, setSearchUngen] = useState('');
	const [selectedCounters, setSelectedCounters] = useState<Organization[]>([]);

	const getUngeneratedInvoicesRequest = useRequest(([param]) => (
		reportApi.getUngeneratedInvoicesReport(
			param,
			selectedCounters,
		)
	));

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search: searchUngen,
			};
			getUngeneratedInvoicesRequest.execute(listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, searchUngen]);

	useEffect(() => {
		setTitle(t('ungeneratedInvoices'));
	}, [setTitle, t]);

	const onChange = (item: Organization[]) => {
		setSelectedCounters(item);
	};

	const onSubmit = () => {
		getUngeneratedInvoicesRequest.execute();
	};

	const handleListAction = (action: string, id: number) => {
		switch (action) {
		case t('invoice'):
			navigate(AppRoutes.RESERVATION_TRANSACTIONS.formatMap({ reservationId: id }));
			break;
		case t('reservation'):
			navigate(AppRoutes.EDIT_RESERVATION.formatMap({ id }));
			break;
		default:
			break;
		}
	};

	return (
		<UngeneratedInvoicesPage
			ungeneratedInvoices={getUngeneratedInvoicesRequest.data}
			setSearch={setSearchUngen}
			setPagination={setPagination}
			onChange={onChange}
			onSubmit={onSubmit}
			loading={getUngeneratedInvoicesRequest.loading}
			handleListAction={handleListAction}
		/>
	);
};

export default UngeneratedInvoices;
