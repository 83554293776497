import { DefaultButton } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Alert, AlertTitle, List } from '@mui/material';
import { ReservationConflict } from '@Types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConflictProps {
	data?: ReservationConflict;
	handleServiceClick: (action: string) => void
}

const ConflictWarning:React.FC<ConflictProps> = (props) => {
	const { data, handleServiceClick } = props;
	const { t } = useTranslation('translations');

	return (
		<PaperContainer>
			<Alert severity="warning">
				<AlertTitle>
					{t('attentionTitle')}
				</AlertTitle>
				<List sx={{ p: 0 }}>
					<li>{data?.notMatching}</li>
				</List>
				<DefaultButton
					title={t('chekForInconsistancies')}
					variant="text"
					onClick={() => handleServiceClick(t('services'))}
				/>
			</Alert>
		</PaperContainer>
	);
};

export default ConflictWarning;
