import { AddCircle } from '@mui/icons-material';
import React, { FC } from 'react';
import { putDecimalPoints } from '@Masks';
import { formatDateOnly } from '@Helpers';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
	DefaultButton, Dropdown, StatusLabel, TableItem,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { Campaign, Pagination, PaginationTable } from '@Types';

interface ListCampaignProps {
   handleListAction: (action: string, item?: Campaign) => void;
   campaigns?: Pagination<Campaign[]>;
   setPagination: (params: PaginationTable) => void;
   setSearch: (searchQuery: string) => void
}

const ListCampaignPage: FC<ListCampaignProps> = (props) => {
	const {
		handleListAction, campaigns, setPagination, setSearch,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={campaigns?.data || []}
						total={campaigns?.total}
						pagination
						onChange={(page, rowsPerPage) => setPagination({ page, size: rowsPerPage })}
						onSearching={(query) => setSearch(query)}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn
							headerText={t('nameDescription')}
							render={(item) => <TableItem title={item.name} subtitle={item.description} />}
						/>
						<TableColumn
							headerText={t('discountItem')}
							render={(item) => (
								<TableItem
									title={`${putDecimalPoints(item.discount)}${item.isPercentage ? '%' : '€'} ${t('in')}`}
									subtitle={item.item.name}
								/>
							)}
						/>
						<TableColumn
							headerText={t('startEnd')}
							render={(item) => (
								<TableItem
									title={formatDateOnly(item.registration)}
									subtitle={formatDateOnly(item.endDate)}
								/>
							)}
						/>
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<Dropdown
									data={[t('detail'), t('changeStatus')]}
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									onChange={(name) => {
										handleListAction(name, item);
									}}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListCampaignPage;
