import { Autocomplete, DefaultButton } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid } from '@mui/material';
import { ProcessTollReq, User } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdown } from '@Providers';

interface ProcessTollProps {
	initialValues: ProcessTollReq;
	handleSubmit: (values: ProcessTollReq, formikHelpers: FormikHelpers<ProcessTollReq>) => void;
	getDropdownUsers: (query: string) => void;
	users?: User[];
	validate: (values: ProcessTollReq) => FormikErrors<ProcessTollReq>
	usersLoading: boolean;
}

const ProcessTollForm: FC<ProcessTollProps> = (props) => {
	const {
		initialValues, handleSubmit, getDropdownUsers, users, validate, usersLoading,
	} = props;
	const { t } = useTranslation('translations');
	const { tollStatus } = useDropdown();

	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validate={validate}
					>
						{({
							values, setFieldValue, errors, touched, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
										<Autocomplete
											options={tollStatus || []}
											label={t('selectAnAction')}
											placeholder=""
											disabled={isSubmitting}
											defaultValue={values.StatusId}
											error={touched.StatusId && Boolean(errors.StatusId)}
											helperText={String(errors.StatusId || '')}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('StatusId', item)}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
										<Autocomplete
											options={users || []}
											label={t('selectACustumer')}
											error={touched.DriverId && Boolean(errors.DriverId)}
											helperText={touched.DriverId && String(errors.DriverId || '')}
											placeholder=""
											disabled={isSubmitting}
											defaultValue={values.DriverId}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{`${item.name} - ${item.taxId}`}
												</li>
											)}
											loading={usersLoading}
											onInputChange={(text) => getDropdownUsers(text)}
											getOptionLabel={(item) => item.name || ''}
											onChange={(_, item) => setFieldValue('DriverId', item)}
										/>
									</Grid>
									<Grid item xl={4} lg={4} md={2} sm={12} xs={12}>
										<DefaultButton title={t('process')} type="submit" loading={isSubmitting} />
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ProcessTollForm;
