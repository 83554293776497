import {
	Autocomplete,
	DatePicker,
	DefaultButton,
	DefaultSelect,
	Description,
	DropdownItem,
	FileInput,
	Image,
	Space,
	TextField,
} from '@Atoms';
import { LANGUAGES } from '@Constant';
import { getImageURL } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { ArrowBack, Edit } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { CreateArticlesReq, NameAndId } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { camera } from '@Images';

export interface CreateArticlePageProps {
   initialValues: CreateArticlesReq;
   onCreateArticle: (values: CreateArticlesReq,
      formikHelpers: FormikHelpers<CreateArticlesReq>) => void;
   validate: (values: CreateArticlesReq) => FormikErrors<CreateArticlesReq>;
   categories?: NameAndId[];
   statuses?: NameAndId[];
   handleBackClick: () => void;
   isEdit: boolean;
   handleEditClick: () => void
}

const CreateArticlePage: FC<CreateArticlePageProps> = (props) => {
	const { t } = useTranslation('translations');
	const {
		initialValues,
		validate,
		onCreateArticle,
		categories,
		statuses,
		handleBackClick,
		isEdit,
		handleEditClick,
	} = props;

	return (
		<Box>
			<Grid
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} lg={12}>
					<DefaultButton startIcon={<ArrowBack />} title={t('back')} onClick={handleBackClick} />
				</Grid>
				<Grid item xs={12} md={12} lg={12}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							validateOnMount={false}
							enableReinitialize
							validateOnBlur={false}
							validateOnChange={false}
							validate={validate}
							onSubmit={onCreateArticle}
						>
							{({
								values, setFieldValue, handleChange, errors, isSubmitting, touched,
							}) => (
								<Form autoComplete="off">
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<div aria-hidden>
												<Image
													height={180}
													width="100%"
													src={values.image
														? getImageURL(values.image)
														: getImageURL(camera)}
													alt="article-item-image"
												/>
											</div>
											<Description text={errors.image || ''} color="red" />
											<Space height={2} />
											<FileInput
												label=""
												disabled={isSubmitting || !isEdit}
												placeholder={typeof values.image === 'string' ? undefined : values.image?.name}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
													if (event.target.files) {
														if (event.target.files.length > 0) {
															setFieldValue('image', event.target.files[0]);
														}
													}
												}}
											/>
											<Space height={2} />
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<TextField
												label={t('title')}
												name="title"
												value={values.title}
												onChange={handleChange}
												disabled={isSubmitting || !isEdit}
												error={Boolean(touched.title && errors.title)}
												helperText={touched.title && errors.title}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<TextField
												label={t('link')}
												name="link"
												InputLabelProps={{ shrink: true }}
												value={values.link}
												onChange={handleChange}
												disabled={isSubmitting || !isEdit}
												error={!!errors.link}
												helperText={errors.link}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<DefaultSelect
												options={categories || []}
												label={t('category')}
												value={values.typeId}
												error={!!errors.typeId}
												disabled={isSubmitting || !isEdit}
												helperText={errors.typeId}
												renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
												onChange={(event) => {
													setFieldValue('typeId', event.target.value);
												}}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<TextField
												label={t('subTitle')}
												name="subtitle"
												disabled={isSubmitting || !isEdit}
												value={values.subtitle}
												onChange={handleChange}
												error={!!errors.subtitle}
												helperText={errors.subtitle}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<Autocomplete
												options={LANGUAGES}
												label={t('language')}
												disabled={isSubmitting || !isEdit}
												placeholder={t('language')}
												error={Boolean(errors.languageId)}
												helperText={String(errors.languageId || '')}
												defaultValue={LANGUAGES.find((el) => el.id === values.languageId)}
												getOptionLabel={(option) => option.name}
												renderOption={(autocompleteprops, option) => (
													<li {...autocompleteprops} key={option.name}>
														<DropdownItem country={option} />
													</li>
												)}
												onChange={((_, value) => {
													if (value) { setFieldValue('languageId', value?.id); }
												})}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<DefaultSelect
												options={statuses || []}
												label={t('status')}
												value={values.statusId}
												error={!!errors.statusId}
												disabled={isSubmitting || !isEdit}
												helperText={errors.statusId}
												renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
												onChange={(event) => {
													setFieldValue('statusId', event.target.value);
												}}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<TextField
												label={t('extra1')}
												name="extra1"
												disabled={isSubmitting || !isEdit}
												value={values.extra1}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<TextField
												label={t('extra2')}
												name="extra2"
												disabled={isSubmitting || !isEdit}
												value={values.extra2}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<DatePicker
												label={t('startDate')}
												value={values.startDate}
												disabled={isSubmitting || !isEdit}
												onChange={(value) => setFieldValue('startDate', value)}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<DatePicker
												label={t('endDate')}
												minDate={values.startDate}
												value={values.endDate}
												disabled={isSubmitting || !isEdit}
												onChange={(value) => setFieldValue('endDate', value)}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={12}>
											<TextField
												label={t('description')}
												multiline
												minRows={4}
												name="description"
												disabled={isSubmitting || !isEdit}
												value={values.description}
												onChange={handleChange}
												error={!!errors.description}
												helperText={errors.description}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									{isEdit && <DefaultButton title={t('save')} type="submit" loading={isSubmitting} />}
									{!isEdit && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} />}
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CreateArticlePage;
