import { DefaultButton, Dropdown, StatusLabel } from '@Atoms';
import { AddCircle } from '@mui/icons-material';
import {
	Grid,
	useTheme,
	Stack,
	Box,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Banner } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { formatDate } from '@Helpers';
import { PaperContainer } from '@Molecules';

interface ListBannersProps {
 handleListAction: (action: string, item?: Banner) => void;
 banners?: Banner[]
}

const ListBannersPage: FC<ListBannersProps> = (props) => {
	const { handleListAction, banners } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					title={`${t('translations:new')} ${t('translations:banner')}`}
					startIcon={<AddCircle />}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>

					<Grid container spacing={3}>
						<Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
							<Box sx={{ minHeight: '50%' }}>
								<Carousel
									sx={{ height: '100%' }}
									navButtonsProps={{
										style: {
											backgroundColor: theme.palette.primary.main,
											borderRadius: 30,
										},
									}}
									indicatorIconButtonProps={{
										style: {
											padding: '1px', // 1
											color: theme.palette.divider,
										},
									}}
									activeIndicatorIconButtonProps={{
										style: {
											color: theme.palette.primary.main,
										},
									}}
									indicatorContainerProps={{
										style: {
											marginTop: '5px', // 5
											textAlign: 'center', // 4
										},
									}}
								>
									{banners?.map((item) => (
										<Stack
											key={item.id}
											border={1}
											borderColor={theme.palette.divider}
											sx={{
												pt: '15px', pb: '15px',
											}}
											alignItems="center"
											justifyContent="center"

										>
											<img src={item.file} alt="banner" height="90%" width="90%" />
										</Stack>
									))}
								</Carousel>
							</Box>
						</Grid>
						<Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
							<div style={{ height: '65vh' }}>
								<DataTable data={banners || []} search={false}>
									<TableColumn headerText="ID" render={(item) => item.id} />
									<TableColumn headerText={t('recorded')} render={(item) => formatDate(item.registration)} />
									<TableColumn headerText={t('name')} render={(item) => item.name} />
									<TableColumn
										headerText={t('status')}
										render={(item) => (
											<StatusLabel status={item.status} />
										)}
									/>
									<TableColumn
										headerText={t('action')}
										render={(item) => (
											<Dropdown
												data={[t('edit'), t('changeStatus'), t('remove')]}
												label={t('action')}
												renderItem={(dropdownItem) => ({
													optionLabel: dropdownItem,
													optionValue: dropdownItem,
												})}
												onChange={(action) => {
													handleListAction(action, item);
												}}
											/>
										)}
									/>
								</DataTable>
							</div>
						</Grid>
					</Grid>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListBannersPage;
