import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@mui/icons-material';
import { putDecimalPoints } from '@Masks';
import { DataTable, TableColumn } from '@Organisms';
import {
	StatusLabel,
	DefaultButton,
	Dropdown,
} from '@Atoms';
import { Category, Pagination, PaginationTable } from '@Types';
import { Grid } from '@mui/material';
import { PaperContainer } from '@Molecules';

interface CategoriesProps {
   handleListAction: (action: string, item?: Category) => void
   categories?: Pagination<Category[]>;
   setSearch: (query: string) => void;
   setPagination: (params: PaginationTable) => void
}

const CategoriesPage: FC<CategoriesProps> = (props) => {
	const { t } = useTranslation('translations');
	const {
		handleListAction, categories, setSearch, setPagination,
	} = props;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>

					<DataTable
						data={categories?.data || []}
						pagination
						onSearching={(query) => setSearch(query)}
						onChange={(page, rowsPerPage) => setPagination({ page, size: rowsPerPage })}
						total={categories?.total}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('category')} render={(item) => item.name} />
						<TableColumn headerText="CTA" render={(item) => item.cta} />
						<TableColumn headerText={t('dailyPrice')} render={(item) => `€${putDecimalPoints(item.pricePerDay)}`} />
						<TableColumn headerText={t('teriff')} render={(item) => item.tariff.name} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('action')}
							render={(item) => (
								<Dropdown
									label={t('action')}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
									data={[t('detail'), t('changeStatus')]}
									onChange={(dropdownItem) => handleListAction(dropdownItem, item)}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>

		</Grid>
	);
};

export default CategoriesPage;
