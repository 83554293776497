/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Slide, Snackbar } from '@mui/material';
import { useFeedback } from '@Providers';
import React from 'react';

const SlideComponent = (props: any) => <Slide {...props} direction="down" />;

const Toast = () => {
	const {
		open, toastProps, removeToast,
	} = useFeedback();

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={open}
				key="simple-toast"
				autoHideDuration={3000}
				TransitionComponent={SlideComponent}
				onClose={removeToast}
			>
				<Alert color={toastProps?.error ? 'error' : 'success'}>{toastProps?.message}</Alert>
			</Snackbar>
		</div>
	);
};

export default Toast;
