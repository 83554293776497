import { formatCurrency, normalizeCurrency } from '@Helpers';
import { useFeedback, useTitleBar } from '@Providers';
import { fineApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Car, CarFineAttachemnts, CarFineReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateFinePage from './CreateFinePage';

const CreateFine = () => {
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();
	const { addToast, addDialog } = useFeedback();

	const [initialValues, setInitialValues] = useState({
		carId: {} as Car,
		amount: '',
		note: '',
		date: new Date(),
		time: new Date(),
		local: '',
		file: {} as File,
		fileNote: '',
		fileString: '',
	});
	const [attachments, setAttachments] = useState([] as CarFineAttachemnts[]);
	const [isEdit, setEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('fine')} ${t('detail')}`);
		} else {
			setTitle(`${t('new')} ${t('fine')}`);
			setEdit(true);
		}
	}, [setTitle, id, t]);

	useEffect(() => {
		if (id) {
			fineApi.getCarFineByID(id).then(({ data }) => {
				setInitialValues({
					carId: data.car,
					amount: formatCurrency(data.amount),
					note: data.note,
					date: new Date(data.date),
					time: new Date(data.date),
					local: data.local,
					file: {} as File,
					fileNote: '',
					fileString: '',
				});
				setAttachments(data.attachements);
			});
		}
	}, [id]);

	const handleListAction = (action: string) => {
		switch (action) {
		case t('listFine'):
			navigate(-1);
			break;
		default:
			break;
		}
	};

	const validate = (values: CarFineReq) => {
		const errors = {} as FormikErrors<CarFineReq>;
		//   fileNote: yup.string().when("fileString", {
		//      is: (value: string) => value && value.length > 0,
		//      then: yup.string().required(t("vehicles:emptyField")),
		//   }),
		const amount = parseFloat(normalizeCurrency(values.amount));
		if (!values.carId) {
			errors.carId = t('emptyField');
		}
		if (values.carId && Object.keys(values.carId).length === 0) {
			errors.carId = t('emptyField');
		}
		if (!amount || amount === 0) {
			errors.amount = t('emptyField');
		}
		if (!values.date) {
			errors.date = t('emptyField');
		}
		if (!values.time) {
			errors.time = t('emptyField');
		}
		if (!values.note) {
			errors.note = t('emptyField');
		}
		if (!values.local) {
			errors.local = t('emptyField');
		}
		if (values.file.size) {
			if (!values.fileNote) {
				errors.fileNote = t('emptyField');
			}
		}
		return errors;
	};

	const handleSubmit = (values: CarFineReq, formikHelpers: FormikHelpers<CarFineReq>) => {
		if (id) {
			fineApi.updateCarFine(values, id ?? '')
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_FINES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			fineApi.saveCarFine(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.VEHICLE_FINES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('fine')}`);
		setEdit(true);
	};

	return (
		<CreateFinePage
			initialValues={initialValues}
			validate={validate}
			attachments={attachments}
			handleSubmit={handleSubmit}
			handleListAction={handleListAction}
			id={!!id}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
		/>
	);
};

export default CreateFine;
