import {
	ApiResponse,
	ListDataControl,
	Pagination,
	Tariff,
	TariffReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export async function getTariffs(params: ListDataControl, statusId?: number):
   Promise<ApiResponse<Pagination<Tariff[]>>> {
	const resp = await reqGet({
		url: Endpoint.TARIFFS.format(params.page, params.size, params.search, statusId || ''),
	});
	const data: ApiResponse<Pagination<Tariff[]>> = await resp.data;
	return data;
}

export async function getTariffById(id: number | string): Promise<ApiResponse<Tariff>> {
	const resp = await reqGet({
		url: Endpoint.TARIFFS_BY_ID.format(id),
	});
	const data: ApiResponse<Tariff> = await resp.data;
	return data;
}

export async function changeTariffStatus(id: number): Promise<ApiResponse<Tariff>> {
	const resp = await reqPost({
		url: Endpoint.TARIFF_STATUS.format(id),
	});
	const data: ApiResponse<Tariff> = await resp.data;
	return data;
}

export async function addNewTariff(tariff: TariffReq): Promise<ApiResponse<void>> {
	const tariffToUpload = {
		...tariff,
		organizations: tariff.organizations.reduce((orgIds, el) => {
			orgIds.push(el.id);
			return orgIds;
		}, [] as number[]),
		typeId: tariff.typeId.id,
	};
	const resp = await reqPost({
		url: Endpoint.TARIFF,
		data: tariffToUpload,
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
}

export async function updateTariffById(tariff: TariffReq, tariffId: number | string):
   Promise<ApiResponse<void>> {
	const tariffToUpload = {
		...tariff,
		tariffId,
		organizations: tariff.organizations.reduce((orgIds, el) => {
			orgIds.push(el.id);
			return orgIds;
		}, [] as number[]),
		typeId: tariff.typeId.id,
	};
	const resp = await reqPost({
		url: Endpoint.TARIFFS_BY_ID.format(tariffId),
		data: tariffToUpload,
	});

	const data: ApiResponse<void> = await resp.data;
	return data;
}

export async function getTariffByOrganization(organizationId?: number):
	Promise<ApiResponse<Tariff[]>> {
	const resp = await reqGet({
		url: Endpoint.TARIFF_BY_ORGANIZATION.format(organizationId),
	});
	const { data } = resp;
	return data;
}
