import { TableItem } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Delete } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { Service } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@Helpers';

interface ServicesProps {
	services?: Service[];
	deleteReservationService?: (id: number) => void
}

const IS_TOLL = 11;
const IS_DAILY = 8;

const ServicesPage: FC<ServicesProps> = (props) => {
	const { services, deleteReservationService } = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<DataTable data={services || []} search={false}>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn
							headerText={t('date')}
							render={(item) => (
								<TableItem
									title={formatDate(item.registration)}
									subtitle={`${t('createdBy')}: ${item.author ? item.author?.name : t('system')}`}
								/>
							)}
						/>
						<TableColumn
							headerText={t('description')}
							render={(item) => (
								<TableItem title={item.item?.name} subtitle={item.item?.description} />
							)}
						/>
						<TableColumn headerText={t('quantity')} render={(item) => item.quantity} />
						<TableColumn headerText={t('value')} render={(item) => formatCurrency(item.itemValue)} />
						<TableColumn headerText={t('discount')} render={(item) => formatCurrency(item.discount)} />
						<TableColumn headerText={t('vat')} render={(item) => `${item.itemTax}%`} />
						<TableColumn
							render={(item) => (!item.transaction
								&& item.item?.itemId !== IS_TOLL
								&& item.item?.itemId !== IS_DAILY) && (
								<IconButton
									color="error"
									onClick={() => {
										deleteReservationService?.call(0, item.id);
									}}
								>
									<Delete />
								</IconButton>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ServicesPage;
