import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { DateLabel, Dropdown, StatusLabel } from '@Atoms';
import { Administrator, Pagination, PaginationTable } from '@Types';

interface ListAdministratorProps {
   setPagination: (params: PaginationTable) => void;
   setSearch: (searchQuery: string) => void;
   admins?: Pagination<Administrator[]>
   changeStatus: (item: Administrator) => void
}

const ListAdministratorsPage: FC<ListAdministratorProps> = (props) => {
	const {
		setPagination, setSearch, admins, changeStatus,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={admins?.data || []}
						pagination
						onChange={(page, rowsPerPage) => setPagination({ page, size: rowsPerPage })}
						onSearching={(searchString) => setSearch(searchString)}
						total={admins?.total}
					>
						<TableColumn headerText="ID" render={(item) => item.userId} />
						<TableColumn headerText={t('name')} render={(item) => item.name} />
						<TableColumn
							headerText={t('email')}
							render={(item) => item.email}
						/>
						<TableColumn headerText={t('telephone')} render={(item) => item.phone} />
						<TableColumn headerText={t('registrationDate')} render={(item) => <DateLabel date={item.registration} showTime={false} />} />
						<TableColumn
							headerText={t('group')}
							render={(item) => item.role?.name}
						/>
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn render={(item) => (
							<Dropdown
								data={[t('changeStatus')]}
								label={t('action')}
								renderItem={(dropdownItem) => ({
									optionLabel: dropdownItem,
									optionValue: dropdownItem,
								})}
								onChange={() => changeStatus(item)}
							/>
						)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListAdministratorsPage;
