import {
	ApiResponse, ListDataControl, Pagination, Brand,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getBrands = async (params: ListDataControl):
   Promise<ApiResponse<Pagination<Brand[]>>> => {
	const resp = await reqGet({
		url: Endpoint.BRANDS.format(params.page, params.size, params.search),
	});
	const data: ApiResponse<Pagination<Brand[]>> = await resp.data;
	return data;
};

export const saveBrand = async (name: string): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.BRAND,
		data: { name },
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};

export const getBrandByID = async (id: number | string): Promise<ApiResponse<Brand>> => {
	const resp = await reqGet({
		url: Endpoint.BRAND_BY_ID.format(id),
		data: {},
	});
	const data: ApiResponse<Brand> = await resp.data;
	return data;
};

export const updateBrand = async (brandId: string, name: string): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.BRAND_BY_ID.format(brandId),
		data: { brandId, name },
	});
	const data: ApiResponse<void> = await resp.data;
	return data;
};
