import React, { FC } from 'react';
import {
	DefaultButton, FileInput, Image, Space, TextField,
} from '@Atoms';
import { useTranslation } from 'react-i18next';
import {
	Divider, Grid,
} from '@mui/material';
import { BannerReq } from '@Types';
import {
	Formik, Form, FormikErrors, FormikHelpers,
} from 'formik';
import { getImageURL } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { camera } from '@Images';

interface CreateBannerProps {
	initialValues: BannerReq;
	validate: (values: BannerReq) => FormikErrors<BannerReq>;
	submit: (values: BannerReq, formikHelpers: FormikHelpers<BannerReq>) => void
}

const BANNER_NAME_MAX_CHARACTERS = 40;

const CreateBannerPage: FC<CreateBannerProps> = (props) => {
	const { initialValues, validate, submit } = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12} />
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						enableReinitialize
						validate={validate}
						onSubmit={submit}
						validateOnMount={false}
						validateOnBlur={false}
						validateOnChange={false}
					>
						{({
							values, touched, errors, handleChange, setFieldValue, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={4}>
									<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
										<TextField
											disabled={isSubmitting}
											title=""
											label={t('name')}
											type="text"
											id="name"
											inputProps={{ maxLength: BANNER_NAME_MAX_CHARACTERS }}
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											value={values.name}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
										<div>
											<FileInput
												label={t('image')}
												disabled={isSubmitting}
												error={touched.file && Boolean(errors.file)}
												helperText={touched.file && String(errors.file || '')}
												onChange={(event) => {
													if (event.target.files) {
														setFieldValue('file', event?.target?.files[0]);
														setFieldValue('fileLink', URL.createObjectURL(event.target?.files[0]));
													}
												}}
											/>
										</div>
										<Image
											src={getImageURL(values.fileLink || camera)}
											alt="banner"
											height={210}
											width={210}
											style={{ marginTop: 20 }}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<DefaultButton title={t('save')} type="submit" loading={isSubmitting} />
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};
export default CreateBannerPage;
