import { PaperContainer } from '@Molecules';
import { DefaultButton } from '@Atoms';
import {
	Alert,
	AlertTitle,
	ListItem,
	List,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { Service } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Circle } from '@mui/icons-material';
import { FontSizes } from '@Constant';

interface UnbilledItemsProps {
	data?: Service[];
	handleAction: (action: string) => void
}

const UnbilledItems: FC<UnbilledItemsProps> = (props) => {
	const { data, handleAction } = props;
	const { t } = useTranslation('translations');
	return (
		<PaperContainer>
			<Alert severity="error">
				<AlertTitle>
					{t('unBilledCreatedItems')}
				</AlertTitle>
				<List sx={{ p: 0 }}>
					{data?.map((el) => (
						<ListItem sx={{ p: 0 }}>
							<ListItemIcon>
								<Circle sx={{ fontSize: FontSizes.LG }} />
							</ListItemIcon>
							<ListItemText primary={`${el.item?.name} (1 item)`} />
						</ListItem>
					))}
				</List>
				<DefaultButton
					color="error"
					title={t('gotoTransactions')}
					onClick={() => handleAction(t('gotoTransactions'))}
				/>
			</Alert>
		</PaperContainer>
	);
};

export default UnbilledItems;
