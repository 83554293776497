import { DatePicker, DefaultButton, Space } from '@Atoms';
import { Grid, Typography } from '@mui/material';
import { Movement } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DayMovementTable from './DayMovementTable';

interface DayMovementsProps {
	dayMovements?: Movement;
	date: Date;
	setSelectedDate: (date: Date) => void;
	handleActionClick: (action: string, id?: number) => void;
	loading: boolean;
}

const DayMovements: FC<DayMovementsProps> = (props) => {
	const {
		dayMovements, date, setSelectedDate, handleActionClick, loading,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Grid container spacing={3}>
			<Grid item sm={12} md={12}>
				<Grid container spacing={3}>
					<Grid item sm={12} md={12}>
						<Typography color="textSecondary" variant="caption">
							{t('movementsText')}
						</Typography>
					</Grid>
				</Grid>
				<Space height={2} />
				<Grid container spacing={3}>
					<Grid item sm={12} xs={12} md={8} lg={6} xl={6}>
						<DatePicker value={date} onChange={(value) => setSelectedDate(value)} />
					</Grid>
					<Grid item sm={12} xs={12} md={8} lg={6} xl={6}>
						<DefaultButton
							title={t('view')}
							size="medium"
							onClick={() => handleActionClick(t('view'))}
							loading={loading}
						/>
					</Grid>
				</Grid>
				<Space height={2} />
				<Grid container spacing={3}>
					<Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
						<DayMovementTable
							title={`${t('comming')} (${t(`${dayMovements?.dayString || ''}`)})`}
							data={dayMovements?.toArrive}
							onReservationClick={handleActionClick}
							showEndDate
						/>
					</Grid>
					<Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
						<DayMovementTable
							title={`${t('leaving')} (${t(`${dayMovements?.dayString || ''}`)})`}
							data={dayMovements?.toLeave}
							onReservationClick={handleActionClick}
						/>
					</Grid>
				</Grid>
				<Space height={2} />
				<Grid container spacing={3}>
					<Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
						<DayMovementTable
							title={`${t('initiates')} (${t(`${dayMovements?.dayString || ''}`)})`}
							data={dayMovements?.started}
							onReservationClick={handleActionClick}
						/>
					</Grid>
					<Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
						<DayMovementTable
							title={`${t('closed')} (${t(`${dayMovements?.dayString || ''}`)})`}
							data={dayMovements?.finished}
							onReservationClick={handleActionClick}
							showEndDate
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DayMovements;
