export const removeComma = (value: string) => {
	const tempValue = value.replaceAll(',', '');
	return tempValue;
};

export const convertToHtml = (str: string) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(str, 'text/html');
	return doc.body;
};
