/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { normalizeCurrency } from '@Masks';
import { encodeDate } from '@Helpers';
import {
	ApiResponse, Invoice, InvoiceCostumer, InvoiceItem, Receipt, ReceiptReq,
} from '@Types';
import { reqGet, reqPost } from '../request.api';
import Endpoint from '../endpoints.api';

export const generateInvoice = async (transactionId: number | undefined, type: string):
   Promise<ApiResponse<{invoiceId: number}>> => {
	const resp = await reqPost({
		url: Endpoint.GENERATE_INVOICE,
		data: { transactionId, type },
	});
	const { data } = resp;
	return data;
};

export const getInvoiceById = async (id: string, signal: AbortSignal):
	Promise<ApiResponse<InvoiceCostumer>> => {
	const resp = await reqGet({
		url: Endpoint.INVOICE_BY_ID.format(id),
		signal,
	});
	const { data } = resp;
	return data;
};

export const cancelInvoice = async (invoiceId: string, reason: string, signal: AbortSignal):
   Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.CANCEL_INVOICE.format(invoiceId),
		signal,
		data: { reason },
	});
	const { data } = resp;
	return data;
};

export const sendInvoiceByEmail = async (invoiceId: string, email: string, signal: AbortSignal):
   Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.SEND_BY_EMAIL.format(invoiceId),
		signal,
		data: { email },
	});
	const { data } = resp;
	return data;
};

export const getCreditMemo = async (referenceInvoiceId: string, signal: AbortSignal):
   Promise<ApiResponse<Invoice[]>> => {
	const resp = await reqGet({
		url: Endpoint.CREDIT_MEMO.format(referenceInvoiceId),
		signal,
	});
	const { data } = resp;
	// data.data.invoices = resp.data.data;
	// data.data.total = 0;
	// resp.data.invoices
	// 	// eslint-disable-next-line no-return-assign
	// 	.reduce((total: any, el: { amount: any; }) => (total += el.amount), 0);
	return data;
};

export const getReceipt = async (invoiceId: string, signal: AbortSignal):
   Promise<ApiResponse< Receipt[]>> => {
	const resp = await reqGet({
		url: Endpoint.FIND_RECEIPT.format(invoiceId),
		signal,
	});
	const { data } = resp;
	// data.data.receipts = resp.data.data;
	// data.data.total = data.data.receipts
	// 	// eslint-disable-next-line no-return-assign
	// 	.reduce((total: any, el: { amount: any; }) => (total += el.amount), 0);
	return data;
};

export const createReceipt = async (invoiceId: string, payload: ReceiptReq, signal: AbortSignal):
   Promise<ApiResponse<{invoiceId: number}>> => {
	const dataToUpload = {
		accountId: payload.accountId.id,
		paymentMethodId: payload.paymentMethodId.id,
		date: encodeDate(payload.date),
		value: normalizeCurrency(payload.value),
		invoiceId,
	};

	const resp = await reqPost({
		url: Endpoint.CREATE_RECEIPT.format(invoiceId),
		data: dataToUpload,
		signal,
	});
	const { data } = resp;
	return data;
};

interface IItems {id: number; quantity: number; discount: number}

export const refundInvoice = async (payload: {
	reason: string, type: string, items: InvoiceItem[]
}, invoiceId: string, signal: AbortSignal):
   Promise<ApiResponse<{invoiceId: number}>> => {
	const dataToUpload = {
		reason: payload.reason,
		type: payload.type,
		invoiceId,
		items: [] as IItems[],
	};
	const items = payload.items.reduce((a, el) => {
		const newArr = a;
		newArr.push({
			id: el.reservationItemId,
			quantity: el.quantity,
			discount: el.quantityDiscount,
		});
		return newArr;
	}, [] as IItems[]);
	dataToUpload.items = items;

	const resp = await reqPost({
		url: Endpoint.REFUND_INVOICE.format(invoiceId),
		data: dataToUpload,
		signal,
	});
	const { data } = resp;
	return data;
};

export const importReceipt = async (invoiceId: string) => {
	const resp = await reqPost({
		url: Endpoint.IMPORT_RECEIPT.format(invoiceId),
	});
	const { data } = resp;
	return data;
};
