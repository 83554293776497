/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FontSizes } from '@Constant';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
	useTheme, Typography, Stack, Collapse, Chip,
} from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaperContainer from '../paper-container/PaperContainer';

interface ContainerTopBarProps {
  title: string;
  children: ReactNode;
  badgeNumber?: number;
  badgeColor?: 'default' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  seeAll?: boolean;
  onSeeAllClick?: () => void;
  hidden?: boolean;
}

const DashboardContainer: FC<ContainerTopBarProps> = (props) => {
	const {
		title, children, badgeColor = 'error', badgeNumber, seeAll, onSeeAllClick, hidden,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [menuActive, setMenuActive] = useState<boolean>(!hidden);

	const showContent = () => {
		setMenuActive(!menuActive);
	};

	return (
		<Collapse
			// borderTop={1}
			in={menuActive}
			collapsedSize={50}
			// borderColor={theme.palette.divider}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				sx={{
					borderTopColor: theme.palette.divider,
					borderTopWidth: 1,
					borderBottomColor: theme.palette.divider,
					borderBottomWidth: 1,
					borderBottomStyle: 'solid',
					pt: '10px',
					pb: '10px',
					bgcolor: theme.palette.background.paper,
					pl: '15px',
					pr: '15px',
				}}
			>
				<Stack direction="row" spacing={1} alignItems="center">
					<Typography sx={{ fontSize: FontSizes.LG, fontWeight: 'bold' }}>{title}</Typography>
					<Chip
						size="small"
						label={badgeNumber}
						color={badgeColor}
					/>

					{/* {activity && <ActivityIndicator />} */}
				</Stack>
				<div>
					{seeAll && (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						// eslint-disable-next-line jsx-a11y/no-static-element-interactions
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						<span onClick={onSeeAllClick}>
							{t('seeAll')}
						</span>
					)}
					{!menuActive ? (
						<KeyboardArrowDown sx={{ '&:hover': { cursor: 'pointer' } }} color="disabled" onClick={showContent} />
					) : (
						<KeyboardArrowUp sx={{ '&:hover': { cursor: 'pointer' } }} color="disabled" onClick={showContent} />
					)}
				</div>
			</Stack>
			<PaperContainer>{children}</PaperContainer>
		</Collapse>
	);
};

export default DashboardContainer;
