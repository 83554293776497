import { DefaultButton } from '@Atoms';
import { DashboardContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { Invoice } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface InvoicesProps {
   data: Invoice[] | undefined;
   handleClick?: (action: string, id: number, typeId?: number) => void;
}

const NotGenInvoices: FC<InvoicesProps> = (props) => {
	const { data, handleClick } = props;
	const { t } = useTranslation('translations');
	return (
		<DashboardContainer
			title={t('ungeneratedInvoices')}
			badgeNumber={data?.length}
			hidden
		>
			<DataTable data={data || []} search={false}>
				<TableColumn headerText={t('counter')} render={(item) => item.fantasyName} />
				<TableColumn headerText={t('saleman')} render={(item) => item.sellerName} />
				<TableColumn
					headerText={t('invoice')}
					render={(item) => (
						<DefaultButton
							variant="text"
							color="info"
							title={`#${item.id}`}
							onClick={() => handleClick?.call(0, t('invoice'), item.reservationId)}
						/>
					)}
				/>
				<TableColumn
					align="right"
					render={(item) => (
						<DefaultButton
							title={`${t('reservation')} #${item.reservationId}`}
							onClick={() => handleClick?.call(0, t('reservation'), item.reservationId, item.reservationType?.id)}
						/>
					)}
				/>
			</DataTable>
		</DashboardContainer>
	);
};

export default NotGenInvoices;
