import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Brand, Pagination, PaginationTable } from '@Types';
import { DataTable, TableColumn } from '@Organisms';
import { PaperContainer } from '../../../molecules';
import { DefaultButton } from '../../../atoms';

interface ListBrandProps {
   handleListAction: (action: string, item?: Brand) => void;
   brands?: Pagination<Brand[]>;
   setSearch: (searchQuery: string) => void;
   setPagination: (params: PaginationTable) => void
}

const ListBrandPage:FC<ListBrandProps> = (props) => {
	const {
		handleListAction, brands, setSearch, setPagination,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton
					startIcon={<AddCircle />}
					title={t('new')}
					onClick={() => {
						handleListAction(t('new'));
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={brands?.data || []}
						pagination
						onSearching={(query) => setSearch(query)}
						onChange={(page, size) => setPagination({ page, size })}
						total={brands?.total}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('name')} render={(item) => item.name} />
						<TableColumn
							headerText={t('action')}
							headerOptions={{ align: 'center' }}
							align="center"
							render={(item) => (
								<DefaultButton
									title={t('edit')}
									onClick={() => handleListAction(t('edit'), item)}
								/>
								// <Dropdown
								// 	label={t('edit')}
								// 	renderItem={(dropdownItem) => ({
								// 		optionLabel: dropdownItem,
								// 		optionValue: dropdownItem,
								// 	})}
								// 	data={[t('edit')]}
								// 	onChange={(dropdownItem) => handleListAction(dropdownItem, item)}
								// />
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListBrandPage;
