import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import { userApi } from '@Requests';
import { AppRoutes } from '@Routes';
import { Organization, User } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LinkUserItem from './LinkUserItem';
import ListUsersPage from './ListUsersPage';

const ListUsers = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast, addDialog } = useFeedback();

	const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
	});
	const [search, setSearch] = useState('');
	const [showCounterModal, setShowCounterModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [selectedCounter, setSelectedCounter] = useState<Organization[]>([] as Organization[]);
	const [selectedCounterError, setSelectedCounterError] = useState('');

	const getUsers = useRequest(([params]) => userApi.getAllUsers(params));
	const changeStatus = useRequest(([params]) => userApi.changeUserStatus(params));

	useEffect(() => {
		setTitle(t('users'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const { data } = changeStatus;
		if (data) {
			getUsers.setData((prev) => {
				const mPrev = prev;
				const index = mPrev?.data.findIndex((el) => el.id === data?.id);
				if (mPrev && index !== undefined) {
					mPrev.data[index] = data;
				}
				return mPrev;
			});
		}
	}, [changeStatus.data]);

	useEffectCustom(() => {
		let unsubscribe = false;
		if (!unsubscribe) {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			getUsers.execute(listDataControl);
		}
		return () => {
			unsubscribe = true;
		};
	}, [pagination, search]);

	const resetPassword = (id?: number) => {
		setGlobalLoading(true);
		userApi.resetUserPassword(id)
			.then((res) => addToast({ message: res.message, error: false }))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setGlobalLoading(false));
	};

	const handleListAction = (name: string, item?: User) => {
		switch (name) {
		case t('new'):
			navigate(AppRoutes.CREATE_USER);
			break;
		case t('detail'):
			navigate(AppRoutes.EDIT_USER.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeStatus.execute(item?.id);
			break;
		case t('promoteAdm'):
			navigate(AppRoutes.PROMOTE_USER_TO_ADMIN.formatMap({ id: item?.id }));
			break;
		case t('resetPassword'):
			resetPassword(item?.id);
			break;
		case t('creditCards'):
			navigate(AppRoutes.ADD_PAYMENT_CARD.formatMap({ clientId: item?.id, typeId: 1 }));
			break;
		case t('sendMessage'):
			navigate(AppRoutes.NEW_MESSAGE.formatMap({ id: item?.id }));
			break;
		case t('linkToCounter'):
			if (item) {
				setSelectedUser(item);
			}
			setShowCounterModal(true);
			break;
		default:
			break;
		}
	};

	const closeCounterDialog = () => {
		setShowCounterModal(false);
		setSelectedCounter([]);
	};

	const handleAddUserToCounter = () => {
		if (!selectedCounter || selectedCounter.length === 0) {
			return setSelectedCounterError(t('emptyField'));
		}
		setGlobalLoading(true);
		setSelectedCounterError('');

		userApi.promoteUserToAdmin(selectedUser?.id, selectedCounter)
			.then((res) => {
				setShowCounterModal(false);
				addToast({ message: res.message, error: false });
				navigate(AppRoutes.LIST_ADMINISTRATORS);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
		return null;
	};

	const handleCounterChange = (counter: Organization[]) => {
		setSelectedCounter(counter);
		setSelectedCounterError('');
	};

	return (
		<>
			<Dialog
				open={showCounterModal}
				title={t('linkToCounter')}
				description={(
					<LinkUserItem
						user={selectedUser}
						onChange={handleCounterChange}
						selectedCounterError={selectedCounterError}
						selectedCounters={selectedCounter}
					/>
				)}
				handleClose={closeCounterDialog}
				primaryButtonText={t('save')}
				secondaryButtonText={t('cancel')}
				secondaryButtonAction={closeCounterDialog}
				primaryButtonAction={handleAddUserToCounter}
			/>
			<ListUsersPage
				users={getUsers.data}
				handleListAction={handleListAction}
				setPagination={setPagination}
				setSearch={setSearch}
			/>
		</>
	);
};

export default ListUsers;
