import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { FontSizes } from '@Constant';

interface TextProps {
  title: string;
  value: string | number;
  direction?: 'row' | 'column';
  margin?: string | number;
  fontSize?: string
}
const ListItem: FC<TextProps> = ({
	title, value, direction = 'column', margin = '10px', fontSize = FontSizes.LG,
}) => (
	<Stack direction={direction} sx={{ mt: margin }}>

		<Typography sx={{
			fontSize,
			fontWeight: 'bold',
		}}
		>
			{title}
			:
		</Typography>
		<Typography sx={{
			fontSize,
			color: 'grey',
		}}
		>
			{value}

		</Typography>
	</Stack>
);

export default ListItem;
