import { DefaultButton } from '@Atoms';
import { FontSizes } from '@Constant';
import {
	Badge,
	Box,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

interface PaperContainerProps {
   children: ReactNode;
   title?: string;
   seeAll?: boolean;
   handleClick?: () => void;
   dropdown?: boolean;
   padding?: number;
   badge?: number;
   showBadge?: boolean
}

const PaperContainer: FC<PaperContainerProps> = (props) => {
	const {
		children, title, seeAll, handleClick, dropdown, padding = 2, badge, showBadge,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [hidden, setHidden] = useState(true);

	return (
		<Box sx={{
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(padding),
		}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				sx={{
					borderBottom: title ? 1 : 0,
					borderBottomStyle: 'solid',
					borderBottomColor: theme.palette.divider,
				}}
			>
				{title && (
					<Stack direction="row" alignItems="center">
						<Typography
							color="textSecondary"
							sx={{
								fontSize: FontSizes.LG,
								fontWeight: 'bold',
								pr: 2,
							}}
						>
							{title}
						</Typography>
						{showBadge && (
							<Badge
								badgeContent={badge}
								showZero
								sx={{
									'.MuiBadge-badge': {
										backgroundColor: theme.palette.divider,
									},
								}}
							/>
						)}
					</Stack>
				)}
				{dropdown && (
					<Box
						onClick={() => setHidden(!hidden)}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						{seeAll && <DefaultButton title={t('seeAll')} sx={{ mb: 1, p: '4px' }} variant="outlined" onClick={() => handleClick?.call(0)} />}
						{hidden ? (
							<KeyboardArrowDown sx={{
								ml: 1,
								'&:hover': {
									cursor: 'pointer',
								},
							}}
							/>
						) : (
							<KeyboardArrowUp sx={{
								ml: 1,
								'&:hover': {
									cursor: 'pointer',
								},
							}}
							/>
						)}
					</Box>
				)}
			</Stack>
			{hidden && (
				<Box>
					{children}
				</Box>
			)}
		</Box>
	);
};

export default PaperContainer;
