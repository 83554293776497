import { getDayFromDate } from '@Helpers';
import {
	ApiResponse,
	Car,
	Reservation,
	Invoice,
	Movement,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

export async function getPendingReservations(): Promise<ApiResponse<Reservation[]>> {
	const resp = await reqGet({
		url: Endpoint.PENDING_RESERVATIONS,
	});
	const data: ApiResponse<Reservation[]> = await resp.data;
	return data;
}

export async function getParkedVehicles(): Promise<ApiResponse<Car[]>> {
	const resp = await reqGet({
		url: Endpoint.PARKED_CARS,
	});
	const data: ApiResponse<Car[]> = await resp.data;
	return data;
}

export async function getDelayedDeliveries(): Promise<ApiResponse<Reservation[]>> {
	const resp = await reqGet({
		url: Endpoint.DELAYED_DELIVERIES,
	});
	const data: ApiResponse<Reservation[]> = await resp.data;
	return data;
}

export async function getPendingInvoices(): Promise<ApiResponse<Invoice[]>> {
	const resp = await reqGet({
		url: Endpoint.UNGENERATED_INVOICES,
	});
	const data: ApiResponse<Invoice[]> = await resp.data;
	return data;
}

export async function getDayMovements(date: Date, counter: number): Promise<ApiResponse<Movement>> {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_SUMMARY.format(counter, date.toISOString()), // COUNTER TODO
	});
	const data: ApiResponse<Movement> = await resp.data;
	data.data.dayString = getDayFromDate(date);
	return data;
}
