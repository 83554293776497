/* eslint-disable react/no-danger */
import { DefaultButton, TableItem } from '@Atoms';
import { formatDate } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { FilterAlt } from '@mui/icons-material';
import { DataTable, TableColumn } from '@Organisms';
import { CommunicationLog } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ListCrmProps {
	// byReservation: boolean;
	commLogs: CommunicationLog[];
	// commLogUser?: CommunicationLog[];
	handleReservationClick: (reservationId: number) => void
}

const ListCRMPage: FC<ListCrmProps> = (props) => {
	const {
		commLogs, handleReservationClick,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<PaperContainer>
			<DataTable
				search={false}
				data={commLogs || []}
			>
				<TableColumn headerText="ID" render={(item) => item.id} />
				<TableColumn headerText={t('subject')} render={(item) => item.subjectType.name} />
				<TableColumn headerText={t('modal')} render={(item) => item.modalType.name} />
				<TableColumn headerText={t('receptivity')} render={(item) => item.receptivity.name} />
				<TableColumn headerText={t('observation')} render={(item) => <div dangerouslySetInnerHTML={{ __html: item?.comment }} />} />
				<TableColumn headerText={t('user')} render={(item) => item.user.name} />
				<TableColumn
					headerText={t('registeredIn')}
					render={(item) => <TableItem title={formatDate(item.registration)} subtitle={`${t('by')}: ${item.regiteredBy?.name}`} />}
				/>
				<TableColumn
					render={(item) => (
						item.reservation && (
							<DefaultButton
								startIcon={<FilterAlt />}
								title={`${t('reservation')}#${item.reservation?.id}`}
								onClick={() => handleReservationClick(item.reservation?.id)}
							/>
						)
					)}
				/>
			</DataTable>
		</PaperContainer>
	);
};

export default ListCRMPage;
