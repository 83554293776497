import {
	Autocomplete, DatePicker, DefaultButton, DropdownItem, Space, TextField, TimePicker,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Divider, Grid } from '@mui/material';
import { useDropdown } from '@Providers';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Organization, User, WaybillReq } from '@Types';
import { Edit, Toll } from '@mui/icons-material';
import { putDecimalPoints } from '@Masks';

interface CreateWaybillProps {
	initialValues: WaybillReq;
	responsibles: User[];
	mainDrivers: User[];
	secondDrivers: User[];
	companies: Organization[];
	validate: (values: WaybillReq) => FormikErrors<WaybillReq>;
	handleSearchUsers: (query: string, action: string) => void;
	handleSubmit: (values: WaybillReq, formikHelpers: FormikHelpers<WaybillReq>) => void;
	isEdit: boolean;
	onTollClick: () => void;
	showTollButton: boolean;
	readOnly: boolean;
	handleEditClick: () => void;
	counters: Organization[]
}

const CreateWaybillPage: FC<CreateWaybillProps> = (props) => {
	const {
		initialValues,
		responsibles,
		mainDrivers,
		secondDrivers,
		companies,
		validate,
		handleSearchUsers,
		isEdit,
		handleSubmit,
		onTollClick,
		showTollButton,
		readOnly,
		handleEditClick,
		counters,
	} = props;
	const { t } = useTranslation('translations');
	const {
		cars, billableItems, status,
	} = useDropdown();
	return (
		<Grid container spacing={3}>
			{showTollButton && (
				<Grid item sm={12} md={12}>
					<DefaultButton title={t('tolls')} startIcon={<Toll />} onClick={onTollClick} />
				</Grid>
			)}
			<Grid item sm={12} md={12}>
				<PaperContainer>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleSubmit}
					>
						{({
							values, setFieldValue, errors, touched, isSubmitting,
						}) => (
							<Form>
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={companies || []}
											label={t('company')}
											disabled={isSubmitting || readOnly}
											defaultValue={values.organizationId}
											placeholder={t('organization')}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											onInputChange={(name) => handleSearchUsers(name, t('company'))}
											getOptionLabel={(item) => item?.officialName || ''}
											onChange={(event, item) => setFieldValue('organizationId', item)}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={responsibles}
											label={t('responsible')}
											disabled={isSubmitting || readOnly}
											defaultValue={values.userId}
											error={touched.userId && Boolean(errors.userId)}
											helperText={touched.userId && String(errors.userId || '')}
											placeholder={t('responsible')}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item?.name || ''}
											onChange={(event, item) => {
												setFieldValue('userId', item);
												setFieldValue('mainDriverId', item);
											}}
											onInputChange={(name) => handleSearchUsers(name, t('responsible'))}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={mainDrivers}
											label={t('mainDriver')}
											disabled={isSubmitting || readOnly}
											defaultValue={values.mainDriverId}
											error={touched.mainDriverId && Boolean(errors.mainDriverId)}
											helperText={touched.mainDriverId && String(errors.mainDriverId || '')}
											placeholder={t('mainDriver')}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item?.name || ''}
											onChange={(event, item) => setFieldValue('mainDriverId', item)}
											onInputChange={(name) => handleSearchUsers(name, t('mainDriver'))}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={secondDrivers}
											label={t('secondDriver')}
											disabled={isSubmitting || readOnly}
											defaultValue={values.additionalDriverId}
											placeholder={t('secondDriver')}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.name}
												</li>
											)}
											getOptionLabel={(item) => item?.name || ''}
											onChange={(event, item) => setFieldValue('additionalDriverId', item)}
											onInputChange={(name) => handleSearchUsers(name, t('secondDriver'))}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={counters || []}
											label={t('pickupIn')}
											disabled={isSubmitting || readOnly}
											defaultValue={values.pickupOrganizationId}
											error={touched.pickupOrganizationId && Boolean(errors.pickupOrganizationId)}
											helperText={touched.pickupOrganizationId && String(errors.pickupOrganizationId || '')}
											placeholder=""
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											getOptionLabel={(item) => item?.officialName || ''}
											onChange={(event, item) => setFieldValue('pickupOrganizationId', item)}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Grid container spacing={3}>
											<Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
												<DatePicker
													label={t('pickupDate')}
													value={values.startDate}
													error={touched.startDate && Boolean(errors.startDate)}
													helperText={touched.startDate && String(errors.startDate || '')}
													disabled={isSubmitting || readOnly}
													onChange={(value) => setFieldValue('startDate', value)}
												/>
											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TimePicker
													label={`${t('hour')}`}
													error={touched.startTime && Boolean(errors.startTime)}
													helperText={touched.startTime && String(errors.startTime || '')}
													value={values.startTime}
													disabled={isSubmitting || readOnly}
													onChange={(value) => setFieldValue('startTime', value)}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={counters || []}
											label={t('deliverIn')}
											disabled={isSubmitting || readOnly}
											defaultValue={values.returnOrganizationId}
											placeholder=""
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={Boolean(errors.returnOrganizationId)}
											helperText={String(errors.returnOrganizationId || '')}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item.officialName}
												</li>
											)}
											getOptionLabel={(item) => item?.officialName || ''}
											onChange={(event, item) => setFieldValue('returnOrganizationId', item)}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Grid container spacing={3}>
											<Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
												<DatePicker
													label={t('deliveryDate')}
													value={values.endDate}
													minDate={values.startDate}
													error={touched.endDate && Boolean(errors.endDate)}
													helperText={touched.endDate && String(errors.endDate || '')}
													disabled={isSubmitting || readOnly}
													onChange={(value) => setFieldValue('endDate', value)}
												/>
											</Grid>
											<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
												<TimePicker
													label={t('hour')}
													error={touched.endTime && Boolean(errors.endTime)}
													helperText={touched.endTime && String(errors.endTime || '')}
													value={values.endTime}
													disabled={isSubmitting || readOnly}
													onChange={(value) => setFieldValue('endTime', value)}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={cars || []}
											label={t('vehicl')}
											disabled={isSubmitting || readOnly}
											defaultValue={values.carId}
											placeholder=""
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.carId && Boolean(errors.carId)}
											helperText={touched.carId && String(errors.carId || '')}
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													<DropdownItem car={item} />
												</li>
											)}
											getOptionLabel={(item) => item?.name || ''}
											onChange={(event, item) => setFieldValue('carId', item)}
										/>
									</Grid>
									<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
										<Autocomplete
											options={billableItems || []}
											label={t('optionals')}
											disabled={isSubmitting || readOnly}
											multiple
											defaultValue={values.options}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											placeholder=""
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{`${item?.name} - ${putDecimalPoints(item?.value || 0)} €`}
												</li>
											)}
											getOptionLabel={(item) => `${item?.name} - ${putDecimalPoints(item?.value || 0)} €`}
											onChange={(event, item) => setFieldValue('options', item)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<TextField
											disabled={isSubmitting || readOnly}
											label={t('observation')}
											multiline
											minRows={3}
											value={values.notes}
											onChange={(event) => {
												setFieldValue('notes', event.target.value);
											}}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								<Grid container spacing={3}>
									<Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
										<Autocomplete
											options={status}
											label={t('status')}
											disabled={isSubmitting || !isEdit || readOnly}
											defaultValue={values.statusId}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											error={touched.statusId && Boolean(errors.statusId)}
											helperText={touched.statusId && String(errors.statusId || '')}
											placeholder=""
											renderOption={(inputprops, item) => (
												<li {...inputprops} key={item.id}>
													{item?.name}
												</li>
											)}
											getOptionLabel={(item) => item?.name || ''}
											onChange={(_, item) => setFieldValue('statusId', item)}
										/>
									</Grid>
								</Grid>
								<Space height={2} />
								<Divider />
								<Space height={2} />
								{isEdit && !readOnly && <DefaultButton title={t('save')} loading={isSubmitting} type="submit" />}
								{isEdit && readOnly && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} />}
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateWaybillPage;
