import { Delete } from '@mui/icons-material';
import {
	Card, Grid, CardHeader, IconButton, Divider, CardContent, Typography, useTheme,
} from '@mui/material';
import React, { FC } from 'react';

interface CardItemProps {
   title?: string;
   type?: string;
   expiry?: string;
   cardNumber?: string;
   remove: () => void;
}

const UserCardItem: FC<CardItemProps> = (props) => {
	const {
		title, remove, type, expiry, cardNumber,
	} = props;
	const theme = useTheme();

	return (
		<Grid item xs={12} md={12}>
			<Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
				<CardHeader
					sx={{ bgcolor: theme.palette.divider }}
					subheader={title}
					action={(
						<IconButton aria-label="delete" sx={{ ml: 1 }} onClick={remove}>
							<Delete color="error" />
						</IconButton>
					)}
				/>
				<Divider />
				<CardContent>
					<Typography>{type?.toUpperCase()}</Typography>
					<Typography variant="body2">{expiry}</Typography>
					<Typography>{cardNumber}</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default UserCardItem;
